import { Modal } from "react-bootstrap";
import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ArrowColor } from "../../MarketToday/MarketTodayConstant";
import { useTranslation } from "react-i18next";
import "./Signup.scss";
import { COUNTRIES, PHONE_CODES } from "./SignupConstants";
import DetailsSubmit from "components/DetailsSubmit/DetailsSubmit";
import {
	scroller,
	formatPhoneValue,
	deFormatterPhoneValue,
	checkInputPhone,
	getRequestParam,
	setError,
} from "./SignupUtils";
import {
	CheckBox,
	AutoComplete,
	ValidationLabel,
	CompanySearch,
} from "./SignupFormControl/SignupFormControl";
const Signup = (props) => {
	const { t } = useTranslation();
	const [agree, setAgree] = useState(false);
	const [agreeOptional, setAgreeOptional] = useState(false);
	const [fname, setfname] = useState();
	const [lname, setlname] = useState();
	const [title, settitle] = useState();
	const [city, setcity] = useState();
	const [country, setcountry] = useState("");
	const [mail, setmail] = useState();
	const [phoneCode, setphoneCode] = useState("+852");
	const [phoneNum, setphoneNum] = useState();
	const [wechat, setwechat] = useState("");
	const [website, setwebsite] = useState("");
	const [detailsSubmitted, setDetailsSubmitted] = useState(false);
	const [companyCodeEntered, setCompanyCodeEntered] = useState("");
	const [companyNameEntered, setCompanyNameEntered] = useState("");
	const ref = useRef(null);
	const countryRef = useRef(null);
	const phoneAreaCodeRef = useRef(null);
	const inputs = {
		fname: fname,
		lname: lname,
		companyCodeEntered: companyCodeEntered,
		title: title,
		city: city,
		country: country,
		mail: mail,
		phone: `${phoneCode}${phoneNum}`,
		wechat: wechat,
		website: website,
	};

	const onSubmitClick = async () => {
		Object.keys(inputs).forEach((key) => {
			if (key === "phone") {
				checkInput(null, { key: key, value: phoneNum });
			} else checkInput(null, { key: key, value: inputs[key] });
		});
		let errors = document.getElementsByClassName("error");
		let noErrorFlag = true;

		Object.keys(errors).forEach((key) => {
			if (errors[key].hidden === false) {
				noErrorFlag = false;
			}
		});
		if (noErrorFlag) {
			onClosePopup();
			setDetailsSubmitted(true);

			//Save details to eloqua
			//For values please refer db.json line no. 663
			const params = getRequestParam(
				mail,
				fname,
				lname,
				companyNameEntered,
				title,
				country,
				city,
				phoneNum,
				phoneCode,
				agree,
				wechat,
				website,
				agreeOptional,
				companyCodeEntered
			);

			await fetch("https://s1770935282.t.eloqua.com/e/f2", {
				method: "POST",
				body: params,
			});
		}
	};

	const onAgree = () => {
		setAgree(!agree);
	};

	const onAgreeOptional = () => {
		setAgreeOptional(!agreeOptional);
	};

	const onClosePopup = () => {
		setAgree(false);
		props.onHide();
	};

	const inputUpdate = (e, name) => {
		if (e) {
			switch (name) {
				case "fname":
					setfname(e?.target?.value);
					break;
				case "lname":
					setlname(e?.target?.value);
					break;
				case "companyCodeEntered":
					setCompanyCodeEntered(e?.target?.value);
					break;
				case "companyCodeSelected":
					setCompanyCodeEntered(e.symbol);
					setCompanyNameEntered(e.name);
					break;
				case "title":
					settitle(e?.target?.value);
					break;
				case "city":
					setcity(e?.target?.value);
					break;
				case "country":
					setcountry(e);
					let event = { target: { value: e, name: name } };
					checkInput(event, name);
					break;
				case "mail":
					setmail(e?.target?.value);
					break;
				case "phonecode":
					setphoneCode(e);
					break;
				case "phone":
					setphoneNum(e?.target?.value);
					break;
				case "wechat":
					setwechat(e?.target?.value);
					break;
				case "website":
					setwebsite(e?.target?.value);
					break;
				default:
					break;
			}
		}
	};

	const mailValidation = (currentTarget) => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (regex.test(currentTarget?.value) === false) {
			setError(currentTarget?.name, true);
		}
	};

	const phoneValidation = (value) => {
		if (value?.length < 8 || value?.length > 16) setError("phone", true);
		else setError("phone", false);
	};

	const checkInput = (e, name) => {
		if (e) {
			if (
				(e.target?.value.length === 0 && e.target.name !== "country") ||
				(e.target?.name === "country" && !e.target.value)
			) {
				setError(e.target?.name, true);
			} else {
				setError(e.target?.name, false);
			}
			if (e.target?.name === "mail") {
				mailValidation(e.target);
			}
			if (e.target?.name === "phone") {
				phoneValidation(e.target?.value);
			}
		} else {
			if (
				!name?.value ||
				name?.value === "" ||
				(name?.key === "phone" && (name?.value?.length < 8 || name?.value?.length > 16))
			) {
				setError(name?.key, true);
			} else if (name?.key === "mail") {
				mailValidation(name);
			} else {
				setError(name?.key, false);
			}
		}
	};

	const closeLogin = () => {
		setDetailsSubmitted(false);
	};

	return (
		<>
			<Modal
				{...props}
				backdrop="static"
				aria-labelledby="contained-modal-title-vcenter"
				dialogClassName={"modalContainer-signup hk-sectionhdr"}
				centered
			>
				<Modal.Header className={"header-signup no-header-border"} id="signupHeader">
					<h5 className="signup-text-header" hidden={true}>
						{t("Create an account")}
					</h5>
					<button
						data-testid="close-btn"
						type="button"
						class="btn-close"
						aria-label="Close"
						onClick={onClosePopup}
					></button>
				</Modal.Header>
				<Modal.Body className={"body-signup"} onScroll={() => scroller()}>
					<div className="signup-title">
						<h5 data-testid="signup-text" className="signup-text">
							{t("Create an account")}
						</h5>
						<div
							className="d-flex justify-content-between signup-bar-required-container"
							style={{ marginBottom: "20px" }}
						>
							<div className="hk-sectionhdr-line signup-text-bar"></div>
							<div className="required-text">{t("*Required")}</div>
						</div>
					</div>

					<div className="inputs-signup">
						<div className="input-box-container">
							<div className="l-input">
								<input
									autoComplete="off"
									id="fname-input"
									name={"fname"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "fname")}
									placeholder={t("First name*")}
								/>
								<ValidationLabel id="errorfname" message={t("First name required")} />
							</div>
							<div className="r-input">
								<input
									autoComplete="off"
									id="lname-input"
									name={"lname"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "lname")}
									placeholder={t("Last name*")}
								/>
								<ValidationLabel id="errorlname" message={t("Last name required")} />
							</div>
						</div>
						<div className="input-box-container">
							<div ref={ref} className="l-input">
								<CompanySearch
									ref={ref}
									companyCodeEntered={companyCodeEntered}
									setCompanyCodeEntered={setCompanyCodeEntered}
									checkInput={checkInput}
									inputUpdate={inputUpdate}
									t={t}
								/>
								<ValidationLabel
									id="errorcompanyCodeEntered"
									message={t("Company name required")}
								/>
							</div>
							<div className="r-input">
								<input
									autoComplete="off"
									id="title"
									name={"title"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "title")}
									placeholder={t("Title/Department*")}
								/>
								<ValidationLabel
									id="errortitle"
									message={t("Title/Department required")}
								/>
							</div>
						</div>
						<div className="input-box-container">
							<div className="l-input">
								<input
									autoComplete="off"
									id="city"
									name={"city"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "city")}
									placeholder={t("City*")}
								/>
								<ValidationLabel id="errorcity" message={t("City required")} />
							</div>
							<div className="country r-input">
								<AutoComplete
									options={COUNTRIES}
									placeholder={t("Country/Territory*")}
									name="country"
									className="country"
									noResultMessage="No results found"
									ref={countryRef}
									onSelect={inputUpdate}
									onBlur={(e) => {
										if (e.code !== "Tab") {
											if (
												countryRef.current &&
												!countryRef.current.state.selected.length
											) {
												countryRef.current.clear();
												e.target.value = "";
											}
											checkInput(e, "country");
										}
									}}
								/>
								<ValidationLabel
									id="errorcountry"
									message={t("Country/Territory required")}
								/>
							</div>
						</div>
						<div className="input-box-container">
							<div className="l-input">
								<input
									autoComplete="off"
									id="mail"
									name={"mail"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "mail")}
									placeholder={t("Email address*")}
								/>
								<ValidationLabel
									id="errormail"
									message={t("Valid email address required")}
								/>
							</div>
							<div className="phone-number d-inline-block r-input">
								<div className="d-flex">
									<AutoComplete
										options={PHONE_CODES}
										name="phonecode"
										className="phone-code"
										noResultMessage="No results"
										ref={phoneAreaCodeRef}
										onSelect={inputUpdate}
										selected={phoneCode}
										onBlur={(e) => {
											if (e.code !== "Tab") {
												if (
													phoneAreaCodeRef.current &&
													!phoneAreaCodeRef.current.state.selected.length
												) {
													e.target.value = phoneCode;
													phoneAreaCodeRef.current.state.text = phoneCode;
													const previousSelecedItem = [
														{
															code: phoneCode,
															label: phoneCode,
														},
													];
													phoneAreaCodeRef.current.state.selected = previousSelecedItem;
												}
											}
										}}
									/>
									<input
										autoComplete="off"
										type="text"
										id="phone"
										name={"phone"}
										onKeyDown={(e) => {
											checkInputPhone(e);
										}}
										onBlur={(e) => {
											if (e.code !== "Tab") checkInput(e);
											formatPhoneValue(e);
										}}
										onFocus={(e) => deFormatterPhoneValue(e)}
										onChange={(e) => inputUpdate(e, "phone")}
										placeholder={t("Phone number*")}
									/>
								</div>
								<ValidationLabel
									id="errorphone"
									message={t("Valid phone number required")}
								/>
							</div>
						</div>
						<div className="input-box-container last-input-container">
							<div className="l-input">
								<input
									autoComplete="off"
									id="wechat"
									name={"wechat"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "wechat")}
									placeholder={t("Wechat ID (optional)")}
								/>
							</div>
							<div className="r-input">
								<input
									autoComplete="off"
									id="website"
									name={"website"}
									onBlur={(e) => {
										if (e.code !== "Tab") checkInput(e);
									}}
									onChange={(e) => inputUpdate(e, "website")}
									placeholder={t("Website (optional)")}
								/>
							</div>
						</div>
					</div>

					<div className="agree-msg-signup">
						<div className="div-1">
							<CheckBox message={t("Signup Agreement Message")} onClick={onAgree} />
						</div>
						<div className="div-2">
							<CheckBox
								message={t("Signup Optional Message")}
								onClick={onAgreeOptional}
							/>
						</div>
						<button
							className="btn-primary agree-btn"
							disabled={!agree}
							onClick={onSubmitClick}
						>
							{t("Submit")}
						</button>
					</div>
					<div className="login-msg">
						<span>{t("Already have an account?")}</span>
						<button
							data-testid="toggle-btn"
							id="login-button"
							onClick={() => {
								props.makeLoginVisible();
							}}
						>
							{t("Login Registration")}
						</button>
						<button data-testid="scrollRightButton" aria-label={t("Scroll Right")}>
							<FontAwesomeIcon icon={faChevronRight} color={ArrowColor.RED} />
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<DetailsSubmit show={detailsSubmitted} onHide={closeLogin}></DetailsSubmit>
		</>
	);
};

export default Signup;

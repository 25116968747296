import { t } from "i18next";
import moment, { locale } from "moment";
import linkIcon from "assets/img/link.png";
import Utils from "../../../utils/utils";
import squreUpRightArrow from "../../../assets/icons/squreUpRightArrow.svg";
import CorporateCalendarInfoPopUp from "./CorporateCalendarInfoPopUp";

const CorporateCalenderEvent = ({
	eventData,
	keyName,
	isPastEventTabSelected,
	carouselCurrentSlide,
}) => {
	const getEventOtherLinks = () => {
		return (
			((eventData?.report?.webcastReplay && isPastEventTabSelected) ||
				(eventData?.report?.webcastLive && !isPastEventTabSelected) ||
				eventData?.report?.slide) && (
				<div className="other-links-of-event">
					{eventData?.report?.webcastReplay && isPastEventTabSelected && (
						<div>
							<span>
								<strong>{t("Webcast_Replay_CC")}</strong>
							</span>
							<div className="calendar-event-popup-link">
								<a
									href={eventData?.report?.webcastReplay}
									target="_blank"
									rel="noreferrer"
									className="event-anchor-link-popup"
								>
									<img src={squreUpRightArrow} alt="link-icon"></img>
									<span>
										&nbsp;<strong>{t("View_CC")}</strong>
									</span>
								</a>
							</div>
						</div>
					)}
					{eventData?.report?.webcastLive && !isPastEventTabSelected && (
						<div>
							<span>
								<strong>{t("Webcast_Live_CC")}</strong>
							</span>
							<div className="calendar-event-popup-link">
								<a
									className="event-anchor-link-popup"
									href={eventData?.report?.webcastLive}
									target="_blank"
									rel="noreferrer"
								>
									<img src={squreUpRightArrow} alt="link-icon"></img>
									<span>
										&nbsp;<strong>{t("View_CC")}</strong>
									</span>
								</a>
							</div>
						</div>
					)}
					{eventData?.report?.slide && (
						<div>
							<span>
								<strong>{t("Slide_CC")}</strong>
							</span>
							<div className="calendar-event-popup-link">
								<a
									href={eventData?.report?.slide}
									target="_blank"
									rel="noreferrer"
									className="event-anchor-link-popup"
								>
									<img src={squreUpRightArrow} alt="link-icon"></img>
									<span>
										&nbsp;<strong>{t("View_CC")}</strong>
									</span>
								</a>
							</div>
						</div>
					)}
				</div>
			)
		);
	};

	const renderEventLink = (linkUrl, linkText) => {
		return linkUrl ? (
			<div className="card-event-link">
				<div className="event-link">
					<a href={linkUrl} target="_blank" rel="noreferrer">
						<img src={linkIcon} alt="" className="linkIcon" />
						<span>&nbsp;&nbsp;</span>
						{t(linkText)}
					</a>
				</div>
			</div>
		) : (
			<></>
		);
	};

	return (
		<>
			<div className="event-card-body">
				<div
					className={`card-event-event ${isPastEventTabSelected ? "is-past-event" : ""}`}
				>
					<div className="event-event">
						{isPastEventTabSelected ? t("Past Event") : t("Upcoming")}
					</div>
				</div>

				{((eventData?.report?.webcastReplay && isPastEventTabSelected) ||
					(eventData?.report?.webcastLive && !isPastEventTabSelected) ||
					eventData?.report?.slide) && (
					<CorporateCalendarInfoPopUp
						keyName={`corporate-calender-${eventData.title}`}
						carouselCurrentSlide={carouselCurrentSlide}
					>
						{getEventOtherLinks()}
					</CorporateCalendarInfoPopUp>
				)}

				<div className="card-event-type">
					<div className="event-type">{eventData.typeName}</div>
				</div>
				<div className="card-event-desc">
					<div className="event-desc">{eventData.title}</div>
				</div>
				<div className="card-event-date">
					<div className="event-date">
						{t("Updated")}
						{": "}
						{`${moment(eventData.asOfDate, "YYYY-MM-DD")
							.locale(t("lang"))
							.format(t("formatDate"))}`}
					</div>
				</div>
			</div>

			<div className="event-card-body">
				<div className="event-card-schedule-body">
					{eventData.fiscalYear && eventData.fiscalPeriod && (
						<div className="card-event-period">
							<div className="event-schedule-label">{t("Period Corporate Calendar")}</div>
							<div className="event-schedule">
								{!isNaN(eventData.fiscalPeriod) ? "Q" : ""}
								{`${eventData.fiscalPeriod} ${eventData.fiscalYear}`}
							</div>
						</div>
					)}
					<div className="card-event-schedule">
						<div className="event-schedule-label">{t("Schedule")}</div>
						<div className="event-schedule">
							{`${moment(
								Utils.getUTCtoHKTDateTime(
									eventData.date,
									eventData.time,
									"YYYY-MM-DD HH:mm"
								)
							)
								.locale(t("lang"))
								.format(t("formatDate"))}`}
							<br />
							{eventData.time.includes("00:00:00")
								? ""
								: `${moment(
										Utils.getUTCtoHKTDateTime(
											eventData.date,
											eventData.time,
											"YYYY-MM-DD HH:mm"
										)
								  )
										.locale(t("lang"))
										.format(t("formatTime"))} ${t("HKT")}`}
						</div>
					</div>
				</div>
				{eventData?.urlPressRelease &&
					renderEventLink(eventData?.urlPressRelease, "View Press Release")}
			</div>
		</>
	);
};

export default CorporateCalenderEvent;

/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { Card, Tabs, Tab, Popover, OverlayTrigger } from "react-bootstrap";
import "./HoldingAnalysis.scss";
import Holdings from "./Holdings";
import BreakDown from "./BreakDown/BreakDown";
import useInstitutionsList from "components/hooks/BigDough/useInstitutionsList";
import useFundList from "components/hooks/BigDough/useFundList";
import { useTranslation } from "react-i18next";
import InfoIconToolTip from "../../assets/img/InfoIconGray.svg";
import InfoIcon from "../../assets/img/InfoIconBlue.svg";

const HoldingAnalysis = ({ instrumentXid }) => {
	const { t } = useTranslation();
	const [loadingHeld, setLoadingHeld] = useState(false);
	const [loadingBuyer, setLoadingBuyer] = useState(false);
	const [loadingSeller, setLoadingSeller] = useState(false);
	const [loadingFund, setLoadingFund] = useState(false);
	const [heldData, setHeldData] = useState([]);
	const [buyerData, setBuyerData] = useState([]);
	const [sellerData, setSellerData] = useState([]);
	const [fundData, setFundData] = useState([]);
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	const {
		fundList: fundApiData,
		error: errorFund,
		loading: stillLoadingFund,
	} = useFundList(instrumentXid);
	const {
		institutionList: heldApiData,
		error: errorHeld,
		loading: stillLoadingHeld,
	} = useInstitutionsList(instrumentXid, "Total Shares Held");
	const {
		institutionList: buyerApiData,
		error: errorBuyer,
		loading: stillLoadingBuyer,
	} = useInstitutionsList(instrumentXid, "Total Shares Bought");
	const {
		institutionList: sellerApiData,
		error: errorSeller,
		loading: stillLoadingSeller,
	} = useInstitutionsList(instrumentXid, "Total Shares Sold");

	useEffect(() => {
		setLoadingHeld(stillLoadingHeld);
		setHeldData(heldApiData);
	}, [heldApiData, stillLoadingHeld]);

	const renderOverlay = (type) => (
		<Popover className="hk-corp-popover-container">
			<Popover.Body className="hk-corp-popover">
				<div className="error-popover" data-testid="error-corp-popover">
					<div>
						<div className="">
							<img src={InfoIconToolTip} alt="Alert" />
						</div>
					</div>
					<div className="error-txt">
						{type === "Buyers" ? t("BUYER POPOVER TEXT") : t("SELLER POPOVER TEXT")}
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	// On tab change method to set differnt tabs data for chart. As chart does not load with display: none
	// Don't remove below implementation
	const handleTabSelect = (tab, e) => {
		switch (tab) {
			case "institutions":
				setHeldData(heldApiData);
				setBuyerData(null);
				setSellerData(null);
				setFundData(null);
				break;
			case "buyers":
				setBuyerData(buyerApiData);
				setHeldData(null);
				setSellerData(null);
				setFundData(null);
				setLoadingBuyer(stillLoadingBuyer);
				break;
			case "sellers":
				setSellerData(sellerApiData);
				setHeldData(null);
				setBuyerData(null);
				setFundData(null);
				setLoadingSeller(stillLoadingSeller);
				break;
			case "funds":
				setFundData(fundApiData);
				setHeldData(null);
				setBuyerData(null);
				setSellerData(null);
				setLoadingFund(stillLoadingFund);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<Card className="holdings-analysis">
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Holdings Analysis")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				<Card.Body className="card-body-holdings">
					<BreakDown instrumentXid={instrumentXid} />
					<Tabs
						defaultActiveKey="institutions"
						transition={false}
						className="holding-tab"
						onSelect={handleTabSelect}
					>
						<Tab eventKey="institutions" title={t("Top 10 Institutions")}>
							<Holdings
								error={errorHeld}
								data={heldData}
								loading={loadingHeld}
								tabName="Institutional"
							></Holdings>
						</Tab>
						<Tab
							eventKey="buyers"
							title={
								<div>
									{t("Top 10 Institutional Buyers")}
									<OverlayTrigger placement="top" overlay={renderOverlay("Buyers")}>
										<img
											src={`${cdnDomain}${InfoIcon}`}
											alt="Buyers Info"
											data-testid="Buyers-info"
											className="holdingInfoIconBlue"
										/>
									</OverlayTrigger>
								</div>
							}
						>
							<Holdings
								error={errorBuyer}
								data={buyerData}
								loading={loadingBuyer}
								tabName="Buyers"
							></Holdings>
						</Tab>
						<Tab
							eventKey="sellers"
							title={
								<div>
									{t("Top 10 Institutional Sellers")}
									<OverlayTrigger placement="top" overlay={renderOverlay("Sellers")}>
										<img
											src={`${cdnDomain}${InfoIcon}`}
											alt="Sellers Info"
											data-testid="Sellers-info"
											className="holdingInfoIconBlue"
										/>
									</OverlayTrigger>
								</div>
							}
						>
							<Holdings
								error={errorSeller}
								data={sellerData}
								loading={loadingSeller}
								tabName="Sellers"
							></Holdings>
						</Tab>
						<Tab eventKey="funds" title={t("Top 10 Funds")}>
							<Holdings
								error={errorFund}
								data={fundData}
								loading={loadingFund}
								tabName="Funds"
							></Holdings>
						</Tab>
					</Tabs>
				</Card.Body>
			</Card>
		</>
	);
};

export default HoldingAnalysis;

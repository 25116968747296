import { useContext, useEffect, useState } from "react";
import { AuthContext } from "components/hooks/useAuthentication";
import ErrorIconSignup from "assets/img/ErrorIconSignup.png";
import infoIcon from "assets/img/InfoIconRed.png";
import BannerCloseIcon from "assets/img/BannerCloseIcon.png";
import Signup from "components/Header/Signup/Signup";
import Login from "components/Login/Login";
import { useTranslation } from "react-i18next";
import "./Banner.scss";

const Banner = () => {
	const releasedocEN = `${process.env.PUBLIC_URL}/releaseDocs/HKEX IR Connect V2.0 Release Note.pdf`;
	const releasedocCN = `${process.env.PUBLIC_URL}/releaseDocs/香港交易所投资服务通2.0版本发布公告.pdf`;
	const releasedocHK = `${process.env.PUBLIC_URL}/releaseDocs/香港交易所投資服務通2.0版本發布公告.pdf`;
	const { signInUrl, isEntitled } = useContext(AuthContext);
	const [showSignup, setShowSignup] = useState(false);
	const [loginFlag, setLoginFlag] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const { isAuthenticated } = useContext(AuthContext);
	const { t } = useTranslation();

	useEffect(() => {
		if (!isEntitled) {
			document.getElementsByClassName("banner")[0].removeAttribute("hidden");
		} else document.getElementsByClassName("banner")[0].setAttribute("hidden", true);
	}, [isEntitled]);

	const openSignup = () => {
		setShowSignup(true);
		setLoginFlag(false);
	};

	const closeSignup = () => {
		setShowSignup(false);
	};
	const getDocUrl = (docname) => {
		return `${window.MD && window.MD.CDN_DOMAIN}${docname}`;
	};
	const getReleaseDoc = () => {
		switch (t("releasedoc")) {
			case "releasedocEN":
				return releasedocEN;
			case "releasedocCN":
				return releasedocCN;
			case "releasedocHK":
				return releasedocHK;
			default:
				return releasedocEN;
		}
	};
	const closeLogin = () => {
		setShowLogin(false);
	};

	const makeSignupVisible = () => {
		setShowSignup(true);
		setLoginFlag(false);
	};

	const makeLoginVisible = () => {
		setLoginFlag(true);
		setShowLogin(true);
	};

	const closeBanner = () => {
		document.getElementsByClassName("banner")[0].setAttribute("hidden", true);
	};
	const closeReleaseBanner = () => {
		document.getElementsByClassName("releaseBanner")[0].setAttribute("hidden", true);
	};
	return (
		<>
			{isAuthenticated && (
				<div data-testid="releaseBanner" className="releaseBanner">
					<img
						id="releaseBanner-info"
						className="releaseBannerInfoIcon"
						src={ErrorIconSignup}
						alt="infoIcon"
					/>
					<span>
						{t("releaseMsg")}
						<a
							data-testid="releaseLink"
							aria-label="ReleaseDoc"
							target="_blank"
							href={getDocUrl(getReleaseDoc())}
							rel="noreferrer"
						>
							{t("hereLink")}
						</a>
						{t("forDetails")}
					</span>
					<img
						data-testid="releasebanner-close"
						id="releasebanner-close"
						src={BannerCloseIcon}
						alt="BannerCloseIcon"
						onClick={() => closeReleaseBanner()}
					/>
				</div>
			)}
			<div data-testid="banner" className="banner" hidden={true}>
				<img id="banner-info" src={ErrorIconSignup} alt="ErrorIconSignup" />
				<span>
					{t("notEntitledUsermsg1")}
					<a data-testid="open-signup" onClick={() => openSignup()}>
						{t("notEntitledUsermsg2")}
					</a>
					{t("notEntitledUsermsg3")}
				</span>
				<img
					data-testid="banner-close"
					id="banner-close"
					src={BannerCloseIcon}
					alt="BannerCloseIcon"
					onClick={() => closeBanner()}
				/>
			</div>
			{loginFlag ? (
				<Login
					show={showLogin}
					onHide={closeLogin}
					signInUrl={signInUrl}
					makeSignupVisible={makeSignupVisible}
				/>
			) : (
				<Signup
					show={showSignup}
					onHide={closeSignup}
					makeLoginVisible={makeLoginVisible}
				/>
			)}
		</>
	);
};

export default Banner;

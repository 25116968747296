import React from "react";
import "./InstitutionsTable.scss";
import { Table } from "react-bootstrap";
import CONSTANTS from "../HoldingsChartConstants";
import formatter from "utils/formatUtils";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import "locale/CommonLocale";

const InstitutionsTable = (data) => {
	const { t } = useTranslation();
	const tableData = data.data;

	const getChangeClass = (val) => {
		if (val > 0) return "change-pstv";
		else if (val < 0) return "change-ngtv";
		else return "";
	};

	const getChangeValue = (val) => {
		if (val === 0) {
			return "0.00";
		} else if (val) {
			if (val > 0) return `+${val.toLocaleString("en-US")}`;
			else return val.toLocaleString("en-US");
		} else return "-";
	};

	const getMarketValue = (val) => {
		if (val === 0) {
			return "0.00";
		} else if (val) {
			let calculatedVal = formatter.number(val / 1e6, 2);
			return calculatedVal < 0.01 ? "<.01" : calculatedVal;
		} else return "-";
	};

	const getPercentHeld = (val) => {
		if (val === 0) {
			return "0.00";
		} else if (val < 0.01) {
			return "<.01";
		} else if (val) {
			return formatter.number(val, 2);
		} else return "-";
	};

	const getName = (op1, op2) => {
		if (op1) return op1;
		else return op2;
	};

	return (
		<React.Fragment>
			<Table
				striped
				responsive="sm"
				borderless
				id="institutionsTable"
				className="holdings-tbl"
				data-testid="institutionsTable"
			>
				<thead>
					<tr>
						<th className="first-col">
							{data.tabName === "Funds" ? t("Fund Name") : t("Institution Name")}
						</th>
						<th className="second-col">{t("% S/O")}</th>
						<th className="third-col">
							<span>{t("Value")}</span>
							<br></br>
							{`(${t("USD MM")})`}
						</th>
						<th className="fourth-col">
							{t("Shares")}
							{/* Below code to use if sorting reqd for Shares column
								<span>
								<button onClick={sortShares}>
									<i className="fa fa-caret-down sort-icon"></i>
								</button>
							</span> */}
						</th>
						<th className="fifth-col">{t("Change")}</th>
						<th className="sixth-col">
							{t("As of")} {t("Date")}
						</th>
					</tr>
				</thead>
				<tbody>
					{(data.loading || !tableData || tableData.length < 1) &&
						[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
							return (
								<tr>
									<td aria-hidden="true" className="column-first">
										<div className="col-one">
											<div className="new-cls">
												<Skeleton circle className="circle" />
											</div>

											<Skeleton className="insti-table-name-skeleton" />
										</div>
									</td>
									<td className="column-second">
										<Skeleton className="percentHeld-skeleton" />
									</td>
									<td className="column-second">
										<Skeleton className="marketValue-skeleton" />
									</td>
									<td className="column-second">
										<Skeleton className="share-skeleton" />
									</td>
									<td className={"column-second"}>
										<Skeleton className="share-skeleton" />
									</td>
									<td className="column-second">
										<Skeleton className="share-skeleton" />
									</td>
								</tr>
							);
						})}
					{tableData &&
						!data.loading &&
						tableData.length > 0 &&
						tableData.map((item, index) => {
							return (
								<tr key={index}>
									<td aria-hidden="true" className="column-first">
										<div className="col-one">
											<div className="new-cls">
												<span
													style={{ background: CONSTANTS.tableColors[index] }}
													className="circle"
												></span>
											</div>

											<div className="name">
												{(item.organizationName || item.fundName) &&
													getName(item.organizationName, item.fundName)}
												{((!item.organizationName && !item.fundName) ||
													(item.organizationName === undefined &&
														item.fundName === undefined)) &&
													"-"}
											</div>
										</div>
									</td>
									<td className="column-second">{getPercentHeld(item.percentHeld)}</td>
									<td className="column-second">{getMarketValue(item.marketValue)}</td>
									<td className="column-second">
										{(item.shareQuantity && item.shareQuantity.toLocaleString("en-US")) ||
											(item.shareQuantity === undefined && "-")}
									</td>
									<td
										className={`column-second ${getChangeClass(
											item.shareQuantityChange
										)}`}
									>
										{getChangeValue(item.shareQuantityChange)}
									</td>
									<td
										className={`column-second ${
											t("lang") === "zh-cn" ? "holding-date" : ""
										}`}
									>
										{((item.portfolioDate || item.positionDate) &&
											moment(
												formatter.date(
													item.portfolioDate ? item.portfolioDate : item.positionDate,
													"DD MMM YYYY"
												)
											)
												.locale(t("lang"))
												.format(t("formatHolding"))) ||
											(!item.portfolioDate &&
												item.portfolioDate === undefined &&
												item.positionDate === undefined &&
												!item.positionDate &&
												"-")}
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</React.Fragment>
	);
};

export default InstitutionsTable;

import React, { useState, useRef, useEffect } from "react";
import { Overlay, Popover } from "react-bootstrap";
import AlertIcon from "../../../assets/img/AlertIcon.svg";
import { useTranslation } from "react-i18next";
import "./ErrorPopover.scss";

const ErrorPopover = ({ showPopover, target, setShowPopover, msg }) => {
	const [showErrorPopover, setShowErrorPopover] = useState(showPopover);
	const [targetElement, setTargetElement] = useState(target);
	const [timeoutVal, setTimeoutVal] = useState();
	const ref = useRef(null);

	useEffect(() => {
		setShowErrorPopover(showPopover);
		setTargetElement(target);
		clearTimeout(timeoutVal);
		setTimeoutVal(
			setTimeout(() => {
				setShowPopover(false);
			}, 6000)
		);
	}, [showPopover]);

	const { t } = useTranslation();
	const msgShown = msg
		? msg
		: "Max criteria reached. Please remove prior selections to add more.";

	return (
		<>
			<div ref={ref}>
				<Overlay
					show={showErrorPopover}
					target={targetElement}
					placement="top"
					container={ref}
					containerPadding={20}
					rootClose
					onHide={() => setShowPopover(false)}
				>
					<Popover id="popover-contained" className="errorpopover">
						<Popover.Body className="error-popover-body">
							<div className="error-popover">
								<div className="error-icon">
									<img src={AlertIcon} alt="Alert" />
								</div>
								<div className="error-txt-msg">{t(msgShown)}</div>
							</div>
						</Popover.Body>
					</Popover>
				</Overlay>
			</div>
		</>
	);
};

export default ErrorPopover;

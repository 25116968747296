// XREF call and set data for Typeahead
import XRefAPIUtils from "../../services/RequestUtils/XRef/XRefAPIUtils";

const getXrefData = async (symbol, limit) => {
	let options = [];
	const equityClassificationId = 3;
	try {
		const xrefResponse = await XRefAPIUtils.getXrefData(
			symbol,
			limit,
			equityClassificationId
		);
		if (xrefResponse && xrefResponse.data && xrefResponse.data.data) {
			const data = xrefResponse.data.data;
			if (data && data.items) {
				const searchResults = isNaN(symbol)
					? data.items.sort(sortByName)
					: data.items.sort(sortBySymbol);
				options.push(...searchResults);
				return options;
			}
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};
const sortBySymbol = (a, b) => {
	if (parseInt(a.symbol) > parseInt(b.symbol)) {
		return 1;
	}
	if (parseInt(a.symbol) < parseInt(b.symbol)) {
		return -1;
	}
	return 0;
};
const sortByName = (a, b) => {
	if (a.name > b.name) {
		return 1;
	}
	if (a.name < b.name) {
		return -1;
	}
	return 0;
};
export default { getXrefData };

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useInstitutionsList = (instrumentXid, listType) => {
	const [institutionList, setInstitutionList] = useState();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const apiUrl = `${Constants.URLS.BIG_DOUGH_INSTITUTIONS_LIST}${instrumentXid}?listType=${listType}`;

	useEffect(() => {
		if (!instrumentXid) return;
		setLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (
					response.status === 200 &&
					response?.data?.data?.institutionList &&
					response?.data?.data?.instrumentXid === instrumentXid
				) {
					let institutionListData = response.data.data.institutionList;
					setInstitutionList(institutionListData);
				} else {
					setInstitutionList(null);
					throw response;
				}
			} catch (e) {
				setInstitutionList(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [instrumentXid, listType, apiUrl]);

	return { institutionList, error, loading, fetchedAt };
};

export default useInstitutionsList;

import { useState, useEffect } from "react";
import { BENCHMARKS } from "components/Charting/Constants/Benchmarks";
import XRefAPIUtils from "services/RequestUtils/XRef/XRefAPIUtils";
import Utils from "utils/utils";

const useBenchmarks = () => {
	const [benchmarks, setBenchmarks] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [fetchedAt, setFetchedAt] = useState(null);
	useEffect(() => {
		async function init() {
			setLoading(true);
			try {
				let indexes = BENCHMARKS.reduce((acc, { id }) => {
					return acc + id + ",";
				}, "");
				indexes = indexes.slice(0, -1);
				const xrefResponse = await XRefAPIUtils.getXrefDataforVenueXids(indexes);
				if (xrefResponse && xrefResponse.data && xrefResponse.data.data) {
					const data = xrefResponse.data.data;
					if (data) {
						if (Array.isArray(data.items)) {
							data.items.map((venue, index) => {
								BENCHMARKS[index].name = venue.name;
								BENCHMARKS[index].symbol = venue.symbol;
							});
							setBenchmarks(BENCHMARKS);
						}
					}
				}
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, []);
	return { benchmarks, error, loading, fetchedAt };
};

export default useBenchmarks;

import React from "react";
import formatter from "utils/formatUtils";
import "../MarketToday.scss";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ArrowColor } from "../MarketTodayConstant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "components/Lib/CustomSkeleton/CustomSkeletonTheme";

const MarketCard = ({ loading, data, error, benchmarks }) => {
	const { t } = useTranslation();
	const getClassForCol = (val) => {
		if (val.toString().indexOf("-") >= 0) {
			return "card-text-red ";
		} else if (val === 0) {
			return "card-text-grey";
		} else return "card-text-green";
	};

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1201 },
			items: 6,
			partialVisibilityGutter: 17,
		},
		desktop: {
			breakpoint: { max: 1200, min: 1025 },
			items: 5,
			partialVisibilityGutter: 17,
		},
		tablet: {
			breakpoint: { max: 1024, min: 768 },
			items: 4,
			partialVisibilityGutter: 17,
		},
		mobileSmall: {
			breakpoint: { max: 390, min: 0 },
			items: 2,
			partialVisibilityGutter: 17,
		},
		mobileMedium: {
			breakpoint: { max: 550, min: 391 },
			items: 2,
			partialVisibilityGutter: 40,
		},
		mobileLarge: {
			breakpoint: { max: 650, min: 551 },
			items: 3,
			partialVisibilityGutter: 17,
		},
		mobileXLarge: {
			breakpoint: { max: 767, min: 651 },
			items: 4,
			partialVisibilityGutter: 17,
		},
	};

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		const {
			carouselState: { currentSlide, totalItems, slidesToShow },
		} = rest;
		return (
			<div className="nav buttons multi-carousel-button">
				<button
					data-testid="scrollLeftButton"
					onClick={() => previous()}
					aria-label={t("Scroll Left")}
				>
					<FontAwesomeIcon
						icon={faChevronLeft}
						className={currentSlide === 0 ? "disable" : ""}
						color={ArrowColor.WHITE}
					/>
				</button>
				<div className="bar" />
				<button
					data-testid="scrollRightButton"
					onClick={() => next()}
					aria-label={t("Scroll Right")}
				>
					<FontAwesomeIcon
						icon={faChevronRight}
						className={currentSlide === totalItems - slidesToShow ? "disable" : ""}
						color={ArrowColor.WHITE}
					/>
				</button>
			</div>
		);
	};

	// Commenting below code and we will handle errors separately
	// if (error) throw error;
	const showSkeleton = () => {
		return Array.from(Array(10).keys()).map((index) => {
			return (
				<CustomSkeletonTheme>
					<Col key={index} className="card-container">
						<Row className="market-card">
							<Row className="card-subtitle">{<Skeleton width={"99px"} />}</Row>
							<Row className="card-title">{<Skeleton width={"139px"} />}</Row>
							<Row className={undefined}>{<Skeleton width={"160px"} />}</Row>
						</Row>
					</Col>
				</CustomSkeletonTheme>
			);
		});
	};
	if (!loading && data.length === 0)
		return (
			<Col className="card-container">
				<Row className="market-card">
					<h5>{t("No data found")}</h5>
				</Row>
			</Col>
		);

	return (
		<Carousel
			responsive={responsive}
			className="carousel-inner"
			autoPlay={false}
			showDots={false}
			infinite={false}
			additionalTransfrom={0}
			shouldResetAutoplay={false}
			arrows={false}
			autoPlaySpeed={0}
			centerMode={false}
			draggable
			focusOnSelect={false}
			partialVisible
			swipeable
			customButtonGroup={<ButtonGroup />}
			renderButtonGroupOutside={true}
		>
			{loading
				? showSkeleton()
				: data.map((item, index) => {
						var values = item.data;
						var result = benchmarks.find((obj) => {
							return obj.id === values.venueXid.toString();
						});
						return (
							<Col key={index} className="card-container">
								<Row className="market-card">
									<Row className="card-subtitle">{result.name}</Row>
									<Row className="card-title">
										{formatter.numberWithoutTrailingZero(values.lastTrade.last, 2, 2)}
									</Row>
									<Row className={getClassForCol(values.lastTrade.change)}>
										{formatter.formatPosNegVal(values.lastTrade.change.toFixed(2), 2) +
											" (" +
											formatter.formatPosNegValForChangePercent(
												values.changePercent.today.toFixed(2)
											) +
											"%)"}
									</Row>
								</Row>
							</Col>
						);
				  })}
		</Carousel>
	);
};

export default MarketCard;

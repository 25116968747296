import { useState, useEffect, useContext } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const usePeersShareholder = (
	instrumentXid,
	peerInstrumentXidList,
	numberOfResults,
	setInstitutionList
) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);

	useEffect(() => {
		if (!peerInstrumentXidList || !instrumentXid || peerInstrumentXidList.length === 0) {
			setLoading(true);
			setError(null);
			setInstitutionList(null);
			setLoading(false);
			setFetchedAt(Utils.getHKTTime());
			return;
		}
		setLoading(true);
		setError(null);
		const apiUrl = `${Constants.URLS.BIG_DOUGH_INSTITUTIONS_LIST_PEERS_SHAREHOLDER}?instrumentXidList=${instrumentXid}&peerInstrumentXidList=${peerInstrumentXidList}&numberOfResults=${numberOfResults}`;

		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.institutionlist) {
					let institutionListData = response.data.data.institutionlist;
					setInstitutionList(institutionListData);
				} else {
					setInstitutionList(null);
					throw response;
				}
			} catch (e) {
				setInstitutionList(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [instrumentXid, peerInstrumentXidList]);

	return { error, loading, fetchedAt };
};

export default usePeersShareholder;

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useBasicFundamentals = (venueXid) => {
	const [basicFundamentals, setBasicFundamentals] = useState([]);
	const [basicError, setBasicError] = useState(null);
	const [basicLoading, setBasicLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const [revenueSales, setRevenueSales] = useState({});
	const [revenueAsOfDate, setAsOfDate] = useState();
	const [adyAsofDate, setAdyAsofDate] = useState();
	const apiUrl = `${Constants.URLS.FACTSET_FUNDAMENTALS}${venueXid}`;

	useEffect(() => {
		if (!venueXid) return;
		setBasicLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data) {
					let revenueSalesData = response.data.data.fundamentals.find(
						(item) => item.measure === "Revenue"
					);
					setRevenueSales(revenueSalesData);
					if (response?.data?.data?.valuation) {
						let basicData = response.data.data.valuation;
						setBasicFundamentals(basicData);
						let Date = response.data.data.fundamentals.find((f) => f.mrfy).mrfy?.dateAsOf;
						setAsOfDate(Date);
						setAdyAsofDate(
							response.data.data.valuation.items.find(
								(f) => f.measure === "DividendYield"
							).dateAsOf
						);
					} else {
						setBasicFundamentals(null);
						setAsOfDate(null);
						setAdyAsofDate(null);
					}
				} else {
					setRevenueSales(null);
					setAsOfDate(null);
					throw response;
				}
			} catch (e) {
				setRevenueSales(null);
				setBasicFundamentals(null);
				setBasicError(e);
				setAsOfDate(null);
			} finally {
				setBasicLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [venueXid, apiUrl]);

	return {
		basicFundamentals,
		revenueSales,
		basicError,
		basicLoading,
		fetchedAt,
		revenueAsOfDate,
		adyAsofDate,
	};
};

export default useBasicFundamentals;

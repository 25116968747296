import Search from "components/Search/Search";
import React, { useState, useContext, useEffect } from "react";

import searchIcon from "assets/img/search_icon_mobile.svg";
import "./MobileSearch.scss";
import { SymbolContext } from "components/hooks/useSymbol";
import { useTranslation } from "react-i18next";
import anchorCloseBtn from "assets/img/anchorCloseBtn.png";
import { hideScroll } from "utils/utils";

const MobileSearch = () => {
	const [showSearch, setShowSearch] = useState(false);
	const { t } = useTranslation();
	const {
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
		loading: loadingSymbol,
	} = useContext(SymbolContext);
	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const searchIconClickHandler = () => {
		setShowSearch(true);
	};
	const closeBtnHandler = () => {
		setShowSearch(false);
	};
	useEffect(() => {
		if (showSearch) {
			hideScroll(true);
		} else {
			hideScroll(false);
		}
	}, [showSearch]);

	return (
		<div>
			<button className="searchBtn">
				<img
					className="search-icon-btn"
					src={`${cdnDomain}${searchIcon}`}
					alt={t("Search")}
					onClick={searchIconClickHandler}
				></img>
			</button>
			{showSearch && (
				<div class="search-popup">
					<div className="closeBtnContainer">
						<button
							className="closeBtn"
							onClick={closeBtnHandler}
							data-testid="close_searchpopup"
						>
							<img src={anchorCloseBtn} alt={t("Close")} className="anchorIcon" />
						</button>
					</div>
					<div className="body">
						<label className="labelText">{t("Search for companies")}</label>
						<Search
							setSymbol={setSymbol}
							isProfile={true}
							setVenueXid={setVenueXid}
							setInstrumentXid={setInstrumentXid}
							setEquityShortName={setEquityShortName}
							setEquityName={setEquityName}
							setWSODCompany={setWSODCompany}
							loading={false}
							closeOuterPopup={closeBtnHandler}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default MobileSearch;

import "./ResearchBreakdownChart.scss";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export default function ResearchBreakdownChart({
	sum,
	researchRatings,
	actionType,
	changeCssForIndex,
	setHoverDivName,
	loading,
}) {
	const onDivEnter = (index) => {
		setHoverDivName(index, "Enter");
	};

	const onDivLeave = (index) => {
		setHoverDivName(index, "Leave");
	};

	// Code to update css classes when mouseOver or mouseLeave on chart
	useEffect(() => {
		if (changeCssForIndex !== undefined && changeCssForIndex !== null) {
			let x = document.getElementById("researchBar").children[changeCssForIndex];
			// To remove the css from rest of the divs
			let y = document.getElementById("researchBar").children;
			for (const element of y) {
				element.classList.remove("backgrnd");
			}
			// end To remove the css from rest of the divs
			x.classList.add("backgrnd"); // Add class to current index
			if (actionType === "Leave") {
				x.classList.remove("backgrnd");
			}
		}
	}, [actionType, changeCssForIndex]);
	// Code to update css classes when mouseOver or mouseLeave on chart

	return (
		<div className="research-bar" id="researchBar">
			{researchRatings &&
				researchRatings.map((item, index) => {
					return (
						<div
							key={index}
							onMouseEnter={() => onDivEnter(index)}
							onMouseLeave={() => onDivLeave(index)}
							className={item.label.toLowerCase()}
							style={{
								width: `${(100 * researchRatings[index].value) / sum}%`,
								display: (100 * researchRatings[index].value) / sum === 0 && "none",
							}}
							data-testid={"div-row" + (index + 1)}
						></div>
					);
				})}
		</div>
	);
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./footer.scss";
import { useTranslation } from "react-i18next";
const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer className="text-center bg-dark-cerulean fixed-bottom">
			<Container>
				<Row className="card-wrapper card-wrapper-footer bg-dark-cerulean text-white gy-0 gx-0">
					<Col md={10}>
						<p className="fz-12 text-start">{t("copyright_text")}</p>
					</Col>
					<Col md={2} className="contact-us">
						<a
							className="fz-12 text-end text-start-xs"
							href="mailto:DL-IRConnectSupport@ihsmarkit.com"
						>
							{t("Contact Us")}
						</a>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;

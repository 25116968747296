export const ResponsiveCarouselWidth = {
	LARGEDESKTOP: {
		carouselWidth: 200,
	},
	SMALLDESKTOP: {
		carouselWidth: 180,
	},
	TABLET: {
		carouselWidth: 145,
	},
	MOBILE: {
		carouselWidth: 135,
	},
};

export const ClassList = {
	SHOW: "show",
	HIDE: "hide",
};

export const PointerEvents = {
	INITIAL: "initial",
	NONE: "none",
};

export const ArrowColor = {
	WHITE: "#FFFFFF",
	GREY: "#71747D",
	LIGHT_GREY: "#B9B9B9",
	RED: "#F4364C",
};

export const TimeDetails = (t, date) => {
	return `${t("All data in HK$. Data delayed at least 15 minutes.")} ${t("As of")} ${
		date || ""
	} ${t("HKT")}`;
};

export const MARKET_TODAY = [
	"568838",
	"586439",
	"609430562",
	"691727508",
	"622594542",
	"603858",
	"565671903",
	"475604063",
	"20806338",
	"11959411",
];

import { useCallback, useState } from "react";
import moment from "moment-timezone";
import Utils from "utils/utils";
import jwt from "jwt-decode";
import RequestService from "../../services/RequestService";
import Constants from "../../utils/constants";

const useToken = (setUserName, setAuthenticated) => {
	const code = new URLSearchParams(window.location.search).get("code");
	const SAM_REDIRECT_URI = window.MD && window.MD.SAM_REDIRECT_URI;
	const [isEntitled, setIsEntitled] = useState(true);

	const getRefreshTokenParams = (userSub) => {
		return `grantType=refresh_token&userSub=${userSub}&redirectUri=${SAM_REDIRECT_URI}`;
	};

	const getRefreshToken = async () => {
		const userInfoValue = JSON.parse(localStorage.getItem("userInfo"));
		let params = getRefreshTokenParams(userInfoValue?.sub);
		//if expriey time is nearby 58/59 min
		try {
			await fetchToken(params);
		} catch (error) {
			console.log(error);
		}
	};

	const getAccessTokenParams = () => {
		return `grantType=authorization_code&code=${code}&redirectUri=${SAM_REDIRECT_URI}`;
	};

	async function getAccessToken() {
		localStorage.removeItem("logoutAt");
		let params = getAccessTokenParams();
		try {
			await fetchToken(params);
		} catch (error) {
			logOut();
			throw error;
		}
	}

	const logOut = () => {
		let id_token = localStorage.getItem("id_token");
		const SAM_URL_END_SESSION = window.MD && window.MD.SAM_URL_END_SESSION;
		if (id_token) {
			let logOutUrl = `${SAM_URL_END_SESSION}${id_token}&post_logout_redirect_uri=${encodeURIComponent(
				SAM_REDIRECT_URI
			)}`;
			fetch(logOutUrl);
		}
		setAuthenticated(false);
		setIsEntitled(false);
		localStorage.removeItem("id_token");
		localStorage.setItem("logoutAt", moment(new Date()).unix());
		function deleteAllCookies() {
			let cookies = document.cookie.split(";");
			for (const element of cookies) {
				let cookie = element;
				let eqPos = cookie.indexOf("=");
				let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
			}
		}
		deleteAllCookies();
		//Proper Logout
	};

	async function fetchAccessToken(apiInstance, requestParam) {
		try {
			const accessTokenApiUrl = `${Constants.URLS.Access_TOKEN}?${requestParam}`;
			return await apiInstance.get(accessTokenApiUrl);
		} catch (error) {
			console.log("Error: Access token endpoint failed");
			return {};
		}
	}

	async function fetchToken(requestParam) {
		const apiInstance = await RequestService.init();
		const accessTokenResponse = await fetchAccessToken(apiInstance, requestParam);
		if (accessTokenResponse.status === 200 && accessTokenResponse?.data) {
			let userInfo = {};
			const { accessToken, idToken, expiresIn } = accessTokenResponse?.data;
			if (accessToken && idToken && expiresIn) {
				userInfo = jwt(idToken);
				localStorage.setItem("id_token", idToken);
				const mdTokenApiUrl = `${Constants.URLS.MD_TOKEN}?userid=${userInfo.sub}&authtoken=${accessToken}`;
				const response = await apiInstance.get(mdTokenApiUrl);
				if (response.status === 200 && response?.data) {
					const { access_token: privateToken } = response?.data;
					localStorage.setItem("mdtokenPrivate", privateToken);
					localStorage.setItem("access_token", accessToken);
					localStorage.setItem(
						"expires_in",
						Utils.getTokenExpiredTimeInUnix(expiresIn, "seconds")
					);
					localStorage.setItem("userInfo", JSON.stringify(userInfo));
					setAuthenticatedUserDetails();
					redirectUserToPrivate();
					setIsEntitled(true);
				} else {
					setIsEntitled(false);
					console.log("Error: MD token endpoint failed");
					throw response;
				}
			}
		}
	}

	const getToken = useCallback(async () => {
		let lastUsedTime = localStorage.getItem("lastUsedTime");
		let currentDate = moment(new Date());
		let lastActivityTime = Utils.getCurrentTimeFromUnixFormat(lastUsedTime);
		// Get new token if and only if user is active on the application in last 60 minutes
		if (currentDate.diff(lastActivityTime, "minutes") < 60) {
			await getRefreshToken();
		}
	}, []);

	const setAuthenticatedUserDetails = () => {
		const userInfoValue = JSON.parse(localStorage.getItem("userInfo"));
		setUserName(userInfoValue.name);
		setAuthenticated(true);
	};

	const redirectUserToPrivate = () => {
		let loggedInFromPublic = sessionStorage.getItem("loggedInFromPublic");
		let venue = sessionStorage.getItem("venueXid");
		if (loggedInFromPublic === "true" && venue) {
			sessionStorage.removeItem("loggedInFromPublic");
			sessionStorage.removeItem("intermediator");
			window.open(`${SAM_REDIRECT_URI}/#/profile/${venue}`, "_self");
		}
	};

	return {
		getToken,
		getRefreshToken,
		getAccessToken,
		setAuthenticatedUserDetails,
		isEntitled,
	};
};

export default useToken;

const INFO = [
	{
		id: "sma",
		info: "Moving Averages are trend indicators that smooth stock price movements. Comparing the moving averages from multiple time frames can help investors spot changes in trend.",
	},
	{
		id: "ema",
		info: "Moving Averages are trend indicators that smooth stock price movements. Comparing the moving averages from multiple time frames can help investors spot changes in trend. Exponential Moving Averages add greater weight to more recent prices.",
	},
	{
		id: "mae",
		info: "Moving Average Envelopes provide context around a security's recent price range.",
	},
	{
		id: "bollinger",
		info: "Bollinger Bands® are created by plotting the standard deviation around a moving average and are expected to capture approximately 95% of all price action. They are also used to highlight periods of high and low volatility.",
	},
	{
		id: "rsi",
		info: "The Relative Strength Index is a momentum indicator which measures the strength of price changes relative to prior moves.",
	},
	{
		id: "stochastics",
		info: "Stochastics show periods when a stock is overbought or oversold.",
	},
	{
		id: "macd",
		info: "The MACD is the difference between 2 exponential moving averages and is a trend and momentum indicator. It is normally considered bullish when the MACD line is above the signal line and more so when both are above the 0-line.",
	},
];

const INDICATOR_INPUT_CONFIG = {
	sma: {
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "20",
			},
		},
	},
	ema: {
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "20",
			},
		},
	},
	mae: {
		inputs: {
			number0: {
				name: "Period",
				type: "text",
				default: "20",
			},
			number1: {
				name: "Lowfactor",
				type: "text",
				default: "0.95",
			},
			number2: {
				name: "Highfactor",
				type: "text",
				default: "1.05",
			},
			toggle: {
				name: "Averagetype",
				type: "toggle",
				default: "sma",
			},
		},
	},
	bollinger: {
		inputs: {
			number0: {
				name: "Period",
				type: "text",
				default: "20",
			},
			number1: {
				name: "Standard Deviations",
				type: "text",
				default: "2",
			},
		},
	},
	rsi: {
		inputs: {
			number: {
				name: "Period",
				type: "text",
				default: "14",
			},
		},
	},
	stochastics: {
		inputs: {
			number0: {
				name: "Kperiod",
				type: "text",
				default: "5",
			},
			number1: {
				name: "Smoothing",
				type: "text",
				default: "3",
			},
			number2: {
				name: "Dperiod",
				type: "text",
				default: "3",
			},
		},
	},
	macd: {
		inputs: {
			number0: {
				name: "Fastperiod",
				type: "text",
				default: "12",
			},
			number1: {
				name: "Slowperiod",
				type: "text",
				default: "26",
			},
			number2: {
				name: "Smoothing",
				type: "text",
				default: "9",
			},
		},
	},
};

const TEXT_DICT = {
	sma: {
		Period: "20",
	},
	ema: {
		Period: "20",
	},
	mae: {
		Period: "20",
		Lowfactor: "0.95",
		Highfactor: "1.05",
		Averagetype: "sma",
	},
	bollinger: {
		Period: "20",
		StandardDeviations: "2",
	},
	rsi: {
		Period: "14",
	},
	stochastics: {
		Kperiod: "5",
		Smoothing: "3",
		Dperiod: "3",
	},
	macd: {
		Fastperiod: "12",
		Slowperiod: "26",
		Smoothing: "9",
	},
};

export { INFO, INDICATOR_INPUT_CONFIG, TEXT_DICT };

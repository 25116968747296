import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import GeoChart from "components/InstitutionRegion/GeoChart";
import { Col, Container, Row } from "react-bootstrap";
import InstitutionStyle from "components/InstitutionStyle/InstitutionStyle";
import { SymbolContext } from "components/hooks/useSymbol";
import CompanyAnnouncements from "components/Announcements/Company/CompanyAnnouncements";
import Header from "components/Header/Header";
import Charting from "components/Charting/Charting";
import Fundamentals from "components/Fundamentals/Fundamentals";
import useQuote from "components/hooks/useQuote";
import HoldingAnalysis from "components/HoldingAnalysis/HoldingAnalysis";
import PeerComparisons from "components/PeerComparisons/PeerComparisons";
import RecentNews from "components/RecentNews/RecentNews";
import CorporateOwnership from "components/CorporateOwnership/CorporateOwnership";
import PeerShareholders from "components/PeerShareholders/PeerShareholders";
import "./home.scss";
import FEATURE_FLAGS from "utils/feature-flags";
import PageProvider from "components/contexts/PageContext";
import AnchorMenu from "components/Header/AnchorMenu";
import ResearchRatings from "components/ResearchRatings/ResearchRatings";
import { useLocation, useHistory } from "react-router";
import { LanguageContext } from "components/hooks/useLanguage";
import Utils from "utils/utils";
import usePeers from "components/hooks/FactSet/usePeers";
import useToken from "components/hooks/useToken";
import D3EarningsCard from "components/Earnings/D3EarningCard";
import Footer from "components/Footer/Footer";
import Disclaimers from "components/Disclaimers/Disclaimers";
import CorporateCalender from "components/CorporateCalender/CorporateCalender";
import { PeersContext } from "components/contexts/usePeersContext";
import { deviceType } from "utils/utils";

export default function PrivateUser() {
	const { venueXid } = useParams();
	const { symbol, instrumentXid, wsodCompany } = useContext(SymbolContext);
	const { quote, error, loading, fetchedAt } = useQuote(venueXid);

	const [width, setWidth] = useState(window.innerWidth <= 1024 ? true : false);
	useEffect(() => {
		window.addEventListener("resize", () => resize(setWidth));
	}, []);
	function resize(setWidth) {
		if (window.innerWidth <= 1024) {
			setWidth(true);
		} else {
			setWidth(false);
		}
	}

	const mobileOverlayRef = useRef(null);
	const [showAnchorMenu, setShowAnchorMenu] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const { setLanguage, selectedLangParam } = useContext(LanguageContext);

	const { search } = location;
	let lang = new URLSearchParams(search).get("lang");
	const [firstTime, setFirstTime] = useState(true);

	const { getToken } = useToken();

	usePeers(venueXid);

	const { peers, peersError, peersLoading, peerXIDs } = useContext(PeersContext);

	useEffect(() => {
		history.listen((locate, action) => {
			let patharray = locate.pathname.split("/");
			if (action === "POP" && patharray.length > 2 && patharray.includes("profile")) {
				if (
					patharray[patharray.findIndex((x) => x === "profile") + 1] !==
					localStorage.getItem("venue").toString()
				) {
					history.replace("/");
				}
			}
		});
	}, []);

	// Trigger API to get a new token before token gets expired.
	useEffect(() => {
		const interval = setInterval(() => getToken(), 100 * 60 * 58); // 58 minutes interval as our token will expire after 60 minutes.
		return () => clearInterval(interval);
	}, [getToken]);
	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if (selectedLangParam !== currentLang || lang === null || lang === "") {
			setLanguage(currentLang);
			if (lang === null || lang === "")
				history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		}
		setFirstTime(false);
	}, [lang]);

	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if ((currentLang !== "en" && lang === null) || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		} else if (!firstTime || lang === null || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${selectedLangParam}` });
		}
	}, [selectedLangParam]);

	useEffect(() => {
		const div = mobileOverlayRef.current;
		let className = "mobile-overlay";
		if (showAnchorMenu) {
			className = "mobile-overlay mobile-overlay-active";
		}
		div.className = className;
	}, [showAnchorMenu]);

	return (
		<PageProvider>
			<AnchorMenu
				showAnchorMenu={showAnchorMenu}
				setShowAnchorMenu={setShowAnchorMenu}
			></AnchorMenu>
			{FEATURE_FLAGS.HEADER_SECTION && <Header></Header>}
			<section className="bg-white chartSection" data-testid="chartSection">
				<div
					className="container card-wrapper"
					data-testid="performance"
					id="performance"
				>
					<div className="private-chart-row">
						{FEATURE_FLAGS.CHARTING_SECTION && (
							<Charting
								key={venueXid}
								venueXid={venueXid}
								loggedin={true}
								symbol={symbol}
								peers={peers}
								peersError={peersError}
								peersLoading={peersLoading}
								currency={quote?.currency}
							/>
						)}
					</div>
					<div className="pr-0 pt-xs-40 pl-xs-0 fundamental-card-row">
						{FEATURE_FLAGS.FUNDAMENTALS_SECTION && (
							<Fundamentals
								venueXid={venueXid}
								quote={quote}
								error={error}
								loading={loading}
								fetchedAt={fetchedAt}
							/>
						)}
					</div>
				</div>
				<Container id="holding-analysis">
					<Row className="card-wrapper">
						{FEATURE_FLAGS.HOLDING_ANALYSIS && (
							<Col>
								<HoldingAnalysis
									key={instrumentXid}
									instrumentXid={instrumentXid}
								></HoldingAnalysis>
							</Col>
						)}
					</Row>
				</Container>
				<div className="container card-wrapper card-wrapper-geo">
					<div className="pb-xs-40 geo-chart-row" id="institution-region">
						{FEATURE_FLAGS.GEOCHART_SECTION && (
							<GeoChart key={instrumentXid} instrumentXid={instrumentXid}></GeoChart>
						)}
					</div>
					<div className="pt-xs-40 institution-style-row" id="institution-style">
						{FEATURE_FLAGS.INSTITUTION_STYLE && (
							<InstitutionStyle instrumentXid={instrumentXid} />
						)}
					</div>
				</div>
			</section>
			<section className={width ? "bg-white" : "bg-gray"}>
				<Container>
					<Row className={width ? "card-wrapper bg-white" : "card-wrapper bg-gray"}>
						{FEATURE_FLAGS.CORPORATE_OWNERSHIP && (
							<Col
								xs={12}
								md={6}
								lg={6}
								xl={6}
								className={width ? "pb-xs-40" : "pr-20 pb-xs-40"}
								id="corporate-ownership"
							>
								<CorporateOwnership instrumentXid={instrumentXid}></CorporateOwnership>
							</Col>
						)}
						{FEATURE_FLAGS.PEER_SHARE_HOLDERS && (
							<Col
								xs={12}
								md={6}
								lg={6}
								xl={6}
								className="pr-0 pl-20 pt-xs-40"
								id="peer-shareholders"
							>
								<PeerShareholders
									instrumentXid={instrumentXid}
									peerXIDs={peerXIDs}
									peersLoading={peersLoading}
								></PeerShareholders>
							</Col>
						)}
					</Row>
				</Container>
			</section>
			<section className={width ? "bg-white" : "bg-gray"}>
				<Container>
					<Row
						className={
							width
								? "card-wrapper bg-white peer-comparisons-wrapper"
								: "card-wrapper bg-gray peer-comparisons-wrapper"
						}
					>
						{FEATURE_FLAGS.PEER_COMPARISON && (
							<Col id="peer-comparison">
								<PeerComparisons
									venueXid={venueXid}
									deviceWidth={width}
								></PeerComparisons>
							</Col>
						)}
					</Row>
				</Container>
			</section>

			<section className="bg-white earningsSection">
				<Container id="earnings-section">
					<Row className="card-wrapper earnings-section">
						<Col id="earnings" className="">
							{FEATURE_FLAGS.EARNINGS_SECTION && (
								<D3EarningsCard key={venueXid} venueXid={venueXid}></D3EarningsCard>
							)}
						</Col>
					</Row>
				</Container>
			</section>

			<section className="bg-white chartSection">
				<Container id="corporate-calender-section">
					<Row className="card-wrapper corporate-calender-card-wrapper">
						<Col xs={12} className="research-ratings-card-row" id="research-ratings">
							{FEATURE_FLAGS.RESEARCH_RATINGS_SECTION && (
								<ResearchRatings fetchedAt={fetchedAt} venueXid={venueXid} />
							)}
						</Col>
						<Col className="pt-xs-40 corporate-calender-section" id="corporate-calender">
							<CorporateCalender venueXid={venueXid}></CorporateCalender>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="bg-white">
				<Container>
					<Row className="card-wrapper  company-announcements-card">
						<Col
							xs={12}
							md={6}
							lg={6}
							xl={6}
							className="pb-xs-40"
							id="company-announcements"
						>
							{FEATURE_FLAGS.COMPANY_ANNOUNCEMENTS && (
								<CompanyAnnouncements symbol={symbol} />
							)}
						</Col>
						{FEATURE_FLAGS.RECENT_NEWS && (
							<Col xs={12} md={6} lg={6} xl={6} className="pt-xs-40" id="recent-news">
								<RecentNews wsodCompany={wsodCompany} />
							</Col>
						)}
					</Row>
				</Container>
			</section>
			<div ref={mobileOverlayRef}></div>
			<section>
				<Container className="bg-gray disclaimerContainer">
					<Row className="card-wrapper bg-gray gy-0 gx-0">
						<Col>
							<Disclaimers />
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Footer></Footer>
			</section>
		</PageProvider>
	);
}

import formatter from "utils/formatUtils";
import { useTranslation } from "react-i18next";
import "./QuoteCard.scss";
import { Card, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "components/Lib/CustomSkeleton/CustomSkeletonTheme";
import moment from "moment";
import InfoIconToolTip from "../../../assets/img/InfoIconGray.svg";
import InfoIcon from "../../../assets/img/InfoIconBlue.svg";
import "locale/CommonLocale";

const QuoteCard = ({ data, error, loading, fetchedAt }) => {
	const { close, last, open } = data.lastTrade ? data.lastTrade : {};
	const { volume, marketCap, currency } = data ? data : {};
	const { t } = useTranslation();
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	const renderOverlay = (
		<Popover className="market-cap-popover-container">
			<Popover.Body className="hk-peer-popover">
				<div className="error-popover" data-testid="error-popover">
					<div>
						<div className="">
							<img src={InfoIconToolTip} alt="Alert" />
						</div>
					</div>
					<div className="market-cap-info-text">{t("market-cap-info")}</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	const getChangeColor = (lastprice, closeprice) => {
		if (lastprice > closeprice) return { color: "green" };
		else if (lastprice === closeprice) return { color: "gray" };
		else return { color: "#e72742" };
	};
	const getPriceElem = (price) => {
		return price > 0 ? (
			<h3>
				{currency.symbol}
				<b>{formatter.priceWithRoundingAndSymbol(price, 3)}</b>
			</h3>
		) : (
			<h3>-</h3>
		);
	};
	const getNonPriceLargeElem = (val) => {
		return <b>{formatter.getCompactFormattedLargeValue(val, 2, false)}</b>;
	};

	if (loading || data.length < 1)
		return (
			<CustomSkeletonTheme>
				<Card id="quoteCard" className="container-quote-card">
					<Card.Body className="card-body-quote">
						<Row className="ml-3 batch-quote">
							<Col xs={4}>
								<Row className="last-price-div mr-5">
									<Skeleton width={"155px"} height={"17px"} />
									<div className="last-price">
										<Skeleton className="lastprice-skeleton" />
										<Skeleton className="lastchange-skeleton" />
									</div>
								</Row>
							</Col>
							<Col>
								<Row className="batch-quote-right">
									<Col className="detail">
										<Skeleton className="label-skeleton" />
										<Skeleton className="value-skeleton" />
									</Col>
									<Col className="detail">
										<Skeleton className="label-skeleton" />
										<Skeleton className="value-skeleton" />
									</Col>
									<Col className="detail">
										<Skeleton className="label-skeleton" />
										<Skeleton className="value-skeleton" />
									</Col>
									<Col className="detail">
										<Skeleton className="label-skeleton" />
										<Skeleton className="value-skeleton" />
									</Col>
								</Row>
								<Row>
									<Skeleton className="quote-asof-skeleton" />
								</Row>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</CustomSkeletonTheme>
		);

	if (error) throw error;

	if (!data && !loading)
		return (
			<Card className="p-30">
				<Card.Body className="card-body-quote">
					<h5>No data found</h5>
				</Card.Body>
			</Card>
		);

	return (
		<Card id="quoteCard" className="container-quote-card">
			<Card.Body className="card-body-quote">
				<Row className="ml-3 batch-quote">
					<Col xs={4}>
						<Row className="last-price-div mr-5">
							<span data-testid="last-price-label" className="colname">
								{t("last-price")}
							</span>
							<div className="last-price">
								{getPriceElem(last)}
								<span className="today-change" style={getChangeColor(last, close)}>
									{last > close ? "+" : ""}
									{formatter.number(last - close, 3)} ({last > close ? "+" : ""}
									{formatter.percent(((last - close) * 100) / close, 2)})
								</span>
							</div>
						</Row>
					</Col>
					<Col>
						<Row className="batch-quote-right">
							<Col className="detail">
								<span data-testid="prev-close-value" className="colname">
									{t("prev-close")}
								</span>
								{getPriceElem(close)}
							</Col>
							<Col className="detail">
								<span data-testid="open-price-label" className="colname">
									{t("open")}
								</span>
								{getPriceElem(open)}
							</Col>
							<Col className="detail">
								<span data-testid="market-cap-value" className="colname">
									{t("market-cap")}
									<OverlayTrigger placement="top" overlay={renderOverlay}>
										<img
											src={`${cdnDomain}${InfoIcon}`}
											alt="Market Cap Info"
											data-testid="Market-cap-info"
											className="marketCapInfoIconBlue"
										/>
									</OverlayTrigger>
								</span>
								<h3>
									{currency.symbol}
									{getNonPriceLargeElem(marketCap)}
								</h3>
							</Col>
							<Col className="detail">
								<span data-testid="volume-label" className="colname">
									{t("volume")}
								</span>
								<h3>{volume.last > 0 ? getNonPriceLargeElem(volume.last) : "-"}</h3>
							</Col>
						</Row>
						<Row>
							<span className="region-map-time as-of">
								{t("As of")} {moment(fetchedAt).locale(t("lang")).format(t("format"))}{" "}
								{t("HKT")}
							</span>
						</Row>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
export default QuoteCard;

import { useState, useEffect, useContext, useRef } from "react";
import { Card } from "react-bootstrap";
import { SymbolContext } from "components/hooks/useSymbol";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
	PointerEvents,
	ArrowColor,
	ClassList,
} from "../../MarketToday/MarketTodayConstant";
import { deviceType, getDeviceType } from "utils/utils";
import {
	getDeviceTypeCorporateCalender,
	handleWindowResizeCorporateCalender,
} from "components/hooks/useResizeObserverCorporateCalender";
import Loader from "components/Lib/Loader/Loader";
import Error from "components/Error/Error";
import CorporateCalenderEvent from "./CorporateCalenderEvent";
import "./CorporateCalenderEvents.scss";
import Carousel from "react-multi-carousel";
import Skeleton from "react-loading-skeleton";

const CorporateCalenderEvents = ({
	isBlurTest,
	eventsData,
	carouselTab,
	isPublicPage,
	isPastEventTabSelected,
	error,
	loading,
}) => {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1440 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		desktop: {
			breakpoint: { max: 1439, min: 1025 },
			items: 2,
			partialVisibilityGutter: 0,
		},
		tablet: {
			breakpoint: { max: 955, min: 768 },
			items: 2,
			partialVisibilityGutter: 0,
		},
		tabletLarge: {
			breakpoint: { max: 1024, min: 956 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		mobileSmall: {
			breakpoint: { max: 390, min: 0 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileMedium: {
			breakpoint: { max: 550, min: 391 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileLarge: {
			breakpoint: { max: 650, min: 551 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileXLarge: {
			breakpoint: { max: 767, min: 651 },
			items: 2,
			partialVisibilityGutter: 0,
		},
	};

	const { symbol } = useContext(SymbolContext);
	const { t } = useTranslation();
	const [device, setDevice] = useState(getDeviceTypeCorporateCalender());
	const leftMoveRef = useRef(null);
	const rightMoveRef = useRef(null);
	const [isBlur, setIsBlur] = useState(isBlurTest ? isBlurTest : true);
	const [deviceResponsive, setDeviceResponsive] = useState(responsive);
	const [currentSlide, setCurrentSlide] = useState(null);
	let carousel = { state: null };

	useEffect(() => {
		handleWindowResizeCorporateCalender(resizeHandler);
		setBackAndFarwardArrow(0);
		if (carousel && carousel.state) {
			carousel.state.currentSlide = 0;
		}
	}, [device, eventsData]);

	useEffect(() => {
		setBackAndFarwardArrow(0);
		if (carousel && carousel.state) {
			carousel.state.currentSlide = 0;
		}
		if (!isBlur) {
			setIsBlur(true);
		}
	}, [carouselTab]);

	const resizeHandler = (response) => {
		setDevice(response);
	};

	const shouldBlurHideForLessThanOrEqualtoThreeCard = () => {
		return device && eventsData
			? (eventsData.length < 3 &&
					[
						deviceType.LargeDesktop,
						deviceType.SmallDesktop,
						deviceType.Desktop,
						deviceType.Tablet,
						deviceType.Mobile,
					].indexOf(device) > -1) ||
					(device === deviceType.LargeDesktop && eventsData.length === 3) ||
					(device === deviceType.SmallMobile && eventsData?.length === 1) ||
					(device === deviceType.Tablet &&
						document.documentElement.clientWidth > 955 &&
						eventsData?.length === 3)
			: false;
	};

	const moveLeft = (e) => {
		if (shouldBlurHideForLessThanOrEqualtoThreeCard()) {
			setIsBlur(false);
			return false;
		}
		if (rightMoveRef.current && carousel) {
			if (!isBlur) {
				setIsBlur(true);
			}
			let { currentSlide } = carousel.state;
			setCurrentSlide(currentSlide);
			carousel.state.itemWidth = 290;
			if (currentSlide === 0) {
				return false;
			}
			setColorAndPointer(rightMoveRef.current);
			rightMoveRef.current.classList.remove("hide");

			const nextSlide = currentSlide - 1;
			if (nextSlide === 0) {
				setColorAndPointer(leftMoveRef.current, false);
				setColorAndPointer(rightMoveRef.current);
			}
			// this.Carousel.next()
			carousel.goToSlide(nextSlide);
		}
	};

	const moveRight = (e) => {
		if (shouldBlurHideForLessThanOrEqualtoThreeCard()) {
			setIsBlur(false);
			return false;
		}
		if (leftMoveRef.current && carousel) {
			carousel.state.itemWidth = 290;
			let { currentSlide, totalItems, slidesToShow } = carousel.state;
			setCurrentSlide(currentSlide);
			if (currentSlide === totalItems - slidesToShow) {
				return false;
			}
			setColorAndPointer(leftMoveRef.current);
			leftMoveRef.current.classList.add(ClassList.SHOW);

			const nextSlide = currentSlide + 1;

			if (nextSlide === totalItems - slidesToShow) {
				setColorAndPointer(leftMoveRef.current, true, false);
				setColorAndPointer(rightMoveRef.current, false, false);
				rightMoveRef.current.classList.add(ClassList.HIDE);
				setIsBlur(false);
			}
			// this.Carousel.next()
			carousel.goToSlide(nextSlide);
		}
	};

	const setColorAndPointer = (instance, isShow = true, noClassRequired = true) => {
		if (instance) {
			instance.childNodes[0].style.color = isShow
				? ArrowColor.RED
				: ArrowColor.LIGHT_GREY;
			instance.childNodes[0].style.pointerEvents = isShow
				? PointerEvents.INITIAL
				: PointerEvents.NONE;

			if (noClassRequired) {
				if (isShow) instance.classList.add(ClassList.SHOW);
				else instance.classList.remove(ClassList.HIDE);
			}
		}
	};

	const beforeChangeCarouselHandler = (nextSlide, { currentSlide, onMove }) => {
		carousel.state.itemWidth = 290;
	};

	const afterChangeCarouselHandler = (
		previousSlide,
		{ currentSlide, totalItems, slidesToShow }
	) => {
		setBackAndFarwardArrow(currentSlide, totalItems, slidesToShow);
		setCurrentSlide(currentSlide);
	};

	const setBackAndFarwardArrow = (currentSlide, totalItems, slidesToShow) => {
		setColorAndPointer(rightMoveRef.current);
		setColorAndPointer(leftMoveRef.current);
		setIsBlur(true);

		if (device === deviceType.LargeDesktop && eventsData?.length <= 3) {
			setColorAndPointer(leftMoveRef.current, false);
			setColorAndPointer(rightMoveRef.current, false);
			setIsBlur(false);
			return false;
		}

		if (device === deviceType.SmallMobile && eventsData?.length === 1) {
			setColorAndPointer(leftMoveRef.current, false);
			setColorAndPointer(rightMoveRef.current, false);
			setIsBlur(false);
			return false;
		}

		if (
			device === deviceType.Tablet &&
			document.documentElement.clientWidth > 955 &&
			eventsData?.length === 3
		) {
			setColorAndPointer(leftMoveRef.current, false);
			setColorAndPointer(rightMoveRef.current, false);
			setIsBlur(false);
			return false;
		}

		if (
			currentSlide === 0 &&
			[
				deviceType.LargeDesktop,
				deviceType.SmallDesktop,
				"DESKTOP",
				deviceType.Tablet,
				deviceType.Mobile,
			].indexOf(device) > -1 &&
			eventsData?.length < 3
		) {
			setColorAndPointer(leftMoveRef.current, false);
			setColorAndPointer(rightMoveRef.current, false);
			let newDeviceResposive = { ...deviceResponsive };
			newDeviceResposive.superLargeDesktop.items = 1;
			newDeviceResposive.desktop.items = 1;
			setDeviceResponsive(newDeviceResposive);
			return false;
		} else if (currentSlide === 0) {
			setColorAndPointer(leftMoveRef.current, false);
			setColorAndPointer(rightMoveRef.current);
			setDeviceResponsive(responsive);
			return false;
		} else if (currentSlide === totalItems - slidesToShow) {
			setColorAndPointer(leftMoveRef.current, true, false);
			setColorAndPointer(rightMoveRef.current, false, false);
			rightMoveRef.current.classList.add(ClassList.HIDE);
			setIsBlur(false);
			setDeviceResponsive(responsive);
			return false;
		}
	};

	const renderErrorMessage = (error) => {
		let eventType = isPastEventTabSelected ? "Past" : "Future";
		let message =
			error && error.response?.status === 404
				? t(`There is currently no ${eventType} events data available.`)
				: t(`There was a problem loading ${eventType} events.`);

		return <Error errorMessge={t(message)}></Error>;
	};

	const showSkeleton = (wdth = "280px", hght = "10px") => {
		return (
			<div>
				<Skeleton width={wdth} height={hght} />
			</div>
		);
	};

	const renderLoadingSkeleton = () => {
		return [1, 2, 3, 4].map((_x) => {
			return (
				<>
					<div className="event-card-body">
						<div>
							<div className="event-event">{showSkeleton("55.66px", "15px")}</div>
						</div>
						<div className="card-event-type">
							<div className="event-type">{showSkeleton("220px", "15px")}</div>
						</div>
						<div className="card-event-desc">
							<div className="event-desc">{showSkeleton("220px", "56px")}</div>
						</div>
						<div className="card-event-date">
							<div className="event-date">{showSkeleton("220px", "15px")}</div>
						</div>
					</div>
					<div className="event-card-body">
						<div className="event-card-schedule-body">
							<div className="card-event-period">
								<div className="event-schedule-label">{showSkeleton("40px", "15px")}</div>
								<div className="event-schedule">{showSkeleton("101px", "38px")}</div>
							</div>
							<div className="card-event-schedule">
								<div className="event-schedule-label">{showSkeleton("40px", "15px")}</div>
								<div className="event-schedule">{showSkeleton("101px", "38px")}</div>
							</div>
						</div>
						{showSkeleton("138.6px", "17px")}
					</div>
				</>
			);
		});
	};

	const renderEventsCards = () => {
		return !loading
			? eventsData &&
					!error &&
					!loading &&
					eventsData.map((event, index) => {
						return (
							<CorporateCalenderEvent
								eventData={event}
								key={index}
								isPastEventTabSelected={isPastEventTabSelected}
								carouselCurrentSlide={currentSlide}
							/>
						);
					})
			: renderLoadingSkeleton();
	};

	return (
		<Card
			className={
				isPublicPage ? "calender-events public-page-calender" : "calender-events"
			}
		>
			<Card.Body className="card-body">
				{(eventsData && eventsData.length > 0) || loading ? (
					<>
						<Carousel
							responsive={deviceResponsive}
							className="carousel-inner"
							autoPlay={false}
							showDots={false}
							infinite={false}
							additionalTransfrom={0}
							containerClass="calender-events"
							shouldResetAutoplay={false}
							arrows={false}
							autoPlaySpeed={0}
							centerMode={false}
							draggable={!isPublicPage}
							focusOnSelect={false}
							partialVisible={true}
							swipeable={
								getDeviceType() === "SMALLDESKTOP" ||
								getDeviceType() === "LARGEDESKTOP" ||
								getDeviceType() === "DESKTOP"
									? false
									: true
							}
							renderButtonGroupOutside={true}
							ref={(el) => (carousel = el)}
							itemClass="event-card"
							beforeChange={beforeChangeCarouselHandler}
							afterChange={afterChangeCarouselHandler}
						>
							{renderEventsCards()}
						</Carousel>
						<div
							data-testid="right-blur"
							className={isBlur ? "right-blur" : "no-blur"}
						></div>
					</>
				) : (
					<div className="calender-events">{renderErrorMessage(error)}</div>
				)}
			</Card.Body>
			<Card.Footer>
				{eventsData && eventsData.length > 0 ? (
					<div className="car-control">
						<div className="nav buttons">
							<button
								data-testid="scrollLeftButton"
								ref={leftMoveRef}
								className="prev"
								onClick={(e) => moveLeft(e)}
								aria-label="Scroll Left"
							>
								<FontAwesomeIcon icon={faChevronLeft} color={ArrowColor.LIGHT_GREY} />
							</button>
							<div className="bar" />
							<button
								data-testid="scrollRightButton"
								ref={rightMoveRef}
								className="next"
								onClick={(e) => moveRight(e)}
								aria-label="Scroll Right"
							>
								<FontAwesomeIcon icon={faChevronRight} color={ArrowColor.RED} />
							</button>
						</div>
					</div>
				) : (
					<></>
				)}
			</Card.Footer>
		</Card>
	);
};

export default CorporateCalenderEvents;

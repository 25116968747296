import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { SymbolContext } from "components/hooks/useSymbol";
import Utils from "../utils/utils";
function ProtectedRoute({ component: Component, ...restOfProps }) {
	const { venueXid } = useContext(SymbolContext);
	return (
		<Route
			{...restOfProps}
			render={(props) =>
				Utils.IsAuthTokenValid() ? (
					<Component {...props} />
				) : venueXid ? (
					<Redirect to={`/public/${venueXid}`} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
}

export default ProtectedRoute;

// Please note that the news url path does not include the version number in
// order to accomodate the pattern that document links are returned. Please do
// not add a version without updating news API calls in the project
const CONSTANTS = {
	URLS: {
		CHARTWORKS_SERIES_PATH: "/apiman-gateway/MOD/chartworks-data/1.0/",
		HKEX_NEWS_BASE_URL: "https://www1.hkexnews.hk",
		XREF_PREDICTIVE_SEARCH_PATH: "/xref/v1/venues",
		XREF_VENUES_IDS_PATH: "/xref/v1/venue-xids",
		XREF_XID_PATH: "/xref/v1/venue-xids",
		QUOTE: "quote/v1/details",
		MARKET_RANKINGS: "market-rankings/v1/detail",
		BIGDOUGH_INSTITUTIONSAGGREGATES:
			"ownership-big-dough-institutional/v1/institutionsAggregates/",
		FACTSET_GENERAL_INFORMATION: "equities-factset-generalinformation/v1/details/",
		FACTSET_FUNDAMENTALS: "equities-factset-basic-fundamentals/v1/details/",
		FACTSET_FUNDAMENTALS_ADVANCE: "equities-factset-advanced-fundamentals/v1/details/",
		FACTSET_RESEARCH_CONSENSUS: "equities-factset-research-consensus/v1/details/",
		FACTSET_COMPANY_INFORMATION: "equities-factset-companyinformation/v1/companyProfile/",
		FACTSET_PEERS_COMPARISONS: "equities-factset-peers/v1/details/",
		FACT_SET_ESTIMATE: "equities-factset-estimates/v1/trends/",
		BIG_DOUGH_INSIDERS: "ownership-big-dough-insiders/v1/insidersList/",
		BIG_DOUGH_INSTITUTIONS_LIST: "ownership-big-dough-institutional/v1/institutionsList/",
		BIG_DOUGH_FUND_LIST: "ownership-big-dough-institutional/v1/fundList/",
		BIGDOUGH_INSTITUTIONSSUMMARY:
			"ownership-big-dough-institutional/v1/institutions-summary/",
		BIG_DOUGH_INSTITUTIONS_LIST_PEERS_SHAREHOLDER:
			"big-dough-peers-shareholder/v1/institutionsList",
		NEWS: `news/v1/3323/articles/`,
		COMPANY_ANNOUNCEMENTS: "/hkex-ir-connect/v1/getCompanyAnnouncement",
		MD_TOKEN: "/hkex-ir-connect/v1/getMDToken",
		Access_TOKEN: "/hkex-ir-connect/v1/getAccessToken",
		USER_MANAGEMENT_PREFERENCE: "/user-management/v1/preferences",
		FACTSET_CALENDAR_FUTURE_EVENTS: "/equities-factset-calendarevents/v1/future-events/",
		FACTSET_CALENDAR_HISTORICAL_EVENTS:
			"/equities-factset-calendarevents/v1/historical-events/",
	},
	RESPONSIVE_BREAKPOINTS: {
		MEDIA_BREAKPOINTS_MIN: {
			MEDIA_BREAKPOINT_MIN_XS: 361,
			MEDIA_BREAKPOINT_MIN_SM: 376,
			MEDIA_BREAKPOINT_MIN_MD: 578,
			MEDIA_BREAKPOINT_MIN_LG: 770,
			MEDIA_BREAKPOINT_MIN_XL: 994,
			MEDIA_BREAKPOINT_MIN_XXL: 1120,
		},
		MEDIA_BREAKPOINTS_MAX: {
			MEDIA_BREAKPOINT_MAX_XXS: 360,
			MEDIA_BREAKPOINT_MAX_XS: 375,
			MEDIA_BREAKPOINT_MAX_SM: 577,
			MEDIA_BREAKPOINT_MAX_MD: 769,
			MEDIA_BREAKPOINT_MAX_LG: 769,
			MEDIA_BREAKPOINT_MAX_XL: 1119,
			MEDIA_BREAKPOINT_MAX_XXL: 1140,
		},
	},
	CLIENTS: {
		HKEX: "HKEX",
	},
	MARKETS_AND_RESEARCH_ROUTE_NAMES: {
		OVERVIEW: "Overview",
		CHARTING: "Charting",
		NEWS: "News",
		ANALYST_RESEARCH: "AnalystResearch",
		EARNINGS: "Earnings",
		DIVIDENDS: "Dividends",
		FINANCIALS: "Financials",
		OPTIONS: "Options",
		SECFILING: "SecFiling",
	},
	PERIODICITY_OPTIONS: {
		ANNUAL: "annual",
		QUARTERLY: "quarterly",
	},
	SEARCH_VALUE_VALIDATION: {
		REGEX: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
		SECURITY_TYPE: "Equity",
	},
};

export default CONSTANTS;

const CHART_COLORS = {
	LINE: "#13426B",
	MOUNTAIN_FILL: "rgba(5, 173, 251, 0.28)",
	HISTOGRAM: "#13426B",
	DOT: "#13426B",
};

const LINE_STYLES = [
	{
		key: 1,
		label: "Line",
		markerType: "line",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 2,
		label: "Shape",
		markerType: "shape",
		lineColor: CHART_COLORS.LINE,
		color: CHART_COLORS.DOT,
	},
	{
		key: 3,
		label: "Line with Shapes",
		markerType: "lineWithShapes",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 4,
		label: "Bar",
		markerType: "bar",
		lineColor: CHART_COLORS.HISTOGRAM,
	},
	{
		key: 5,
		label: "Fill",
		markerType: "fill",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 6,
		label: "Pos/Neg",
		markerType: "posneg",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 7,
		label: "Stepped",
		markerType: "stepped",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 8,
		label: "Candlestick",
		markerType: "candlestick",
		lineColor: CHART_COLORS.LINE,
	},
	{
		key: 9,
		label: "HLC",
		markerType: "hlc",
		lineColor: CHART_COLORS.LINE,
	},
];

const OHLC_LINE_STYLES = [
	{
		key: 10,
		label: "Pos/Neg",
		markerType: "ohlc",
		lineColor: CHART_COLORS.LINE,
		ohlcType: "posneg",
	},
	{
		key: 11,
		label: "New Highs",
		markerType: "ohlc",
		lineColor: CHART_COLORS.LINE,
		ohlcType: "newhighs",
	},
	{
		key: 12,
		label: "New Lows",
		markerType: "ohlc",
		lineColor: CHART_COLORS.LINE,
		ohlcType: "newlows",
	},
	{
		key: 13,
		label: "Default",
		markerType: "ohlc",
		lineColor: CHART_COLORS.LINE,
		ohlcType: "mono",
	},
];

const CHART_PANEL_PARAMS = {
	primaryOhlcType: "posneg",
	style: {
		panel: {
			padding: { right: 60 },
			border: {
				default: {
					color: "transparent",
					lineDash: "",
					padding: 0,
					width: 1,
				},
				left: {
					color: "#E3E3E3",
					lineDash: null,
					padding: null,
					width: 1,
				},
				top: {
					color: "#E3E3E3",
					lineDash: null,
					padding: null,
					width: 1,
				},
				bottom: {
					color: "#E3E3E3",
					lineDash: null,
					padding: null,
					width: 1,
				},
			},
			grid: {
				color: "#E3E3E3",
				horizontal: {
					color: "#E3E3E3",
					lineDash: "",
					width: "0.5",
				},
				vertical: {
					color: "#E3E3E3",
					width: "0.5",
					alt: { color: "transparent" },
				},
			},
			xAxis: {
				tick: {
					alt: {
						color: "transparent",
					},
					color: "transparent",
				},
				font: {
					size: 12,
					weight: "normal",
					height: 15,
				},
			},
			yAxis: {
				tick: {
					alt: {
						color: "transparent",
					},
					color: "transparent",
				},
				padding: {},
				label: {
					format: {
						default: {
							format: "0,.2f",
						},
						small: {
							format: "0.3f",
						},
						micro: {
							format: "0.4f",
						},
					},
				},
			},
		},
		panelLower: {
			yAxis: {
				label: {
					format: {
						default: {
							format: "0,.2f",
						},
						small: {
							format: "0.3f",
						},
						micro: {
							format: "0.4f",
						},
					},
				},
			},
		},
	},
};

const DEFAULT_PANEL_PARAMS = {
	primaryOhlcType: "posneg",
	style: {
		panel: JSON.parse(JSON.stringify(CHART_PANEL_PARAMS.style.panel)),
		indicator: {
			price: {
				color: "#13426B",
				fillColor: "#13426B",
				background: {
					color: "#13426B",
					colorStop: "rgba(255, 255, 255, 0.4)",
				},
				bar: {
					color: "#13426B",
				},
				candlestick: {
					negative: {
						color: "#E72742",
					},
					neutral: {
						color: "#999999",
					},
					positive: {
						color: "#24803E",
					},
				},
				ohlc: {
					negative: {
						color: "#E72742",
					},
					neutral: {
						color: "#999999",
					},
					positive: {
						color: "#24803E",
					},
				},
				posneg: {
					lineDash: null,
					negative: {
						background: {
							color: "rgba(178, 0, 0, 0.1)",
							colorStop: "rgba(231, 39, 66, 0.7)",
						},
						color: "#E72742",
					},
					positive: {
						background: {
							color: "rgba(17, 182, 0, 0.1)",
							colorStop: "rgba(36, 128, 62, 0.7)",
						},
						color: "#24803E",
					},
					width: 1.5,
				},
				sessionBreak: {
					background: {
						color: "transparent",
					},
					color: "#13426B",
					lineDash: "2,2",
					width: 1.5,
				},
				shape: {
					background: {
						color: "#13426B",
					},
					color: "#13426B",
					length: 1,
					offset: 2,
					type: "circle",
					width: 1.5,
				},
				width: 2.5,
			},
			rsi: {
				color: "#13426B",
				lineDash: "",
				overbought: {
					background: {
						color: "#F3F6F9",
					},
					color: "#F3F6F9",
					label: {
						color: "#71747D",
						font: {
							size: 12,
							weight: "normal",
						},
						padding: {
							top: 0,
							left: 0,
						},
						textAlign: "left",
					},
					lineDash: "",
					width: 1,
				},
				oversold: {
					background: {
						color: "#F3F6F9",
					},
					color: "#F3F6F9",
					label: {
						color: "#71747D",
						font: {
							size: 12,
							weight: "normal",
						},
						padding: {
							bottom: 0,
							left: 0,
						},
						textAlign: "left",
					},
					lineDash: "",
					width: 1,
				},
				yAxisPadding: {
					bottom: 0,
					top: 0,
				},
				width: 2,
			},
			stochastics: {
				color: "#13426B",
				lineDash: "",
				d: {
					color: "#71747D",
					lineDash: "",
					width: 1,
					sessionBreak: {
						color: "#13426B",
						lineDash: "2,2",
						width: 1,
					},
				},
				overbought: {
					background: {
						color: "#F3F6F9",
					},
					color: "#F3F6F9",
					label: {
						color: "#71747D",
						font: {
							size: 12,
							weight: "normal",
						},
						padding: {
							top: 0,
							left: 0,
						},
						textAlign: "left",
					},
					lineDash: "",
					width: 1,
				},
				oversold: {
					background: {
						color: "#F3F6F9",
					},
					color: "#F3F6F9",
					label: {
						color: "#71747D",
						font: {
							size: 12,
							weight: "normal",
						},
						padding: {
							bottom: 0,
							left: 0,
						},
						textAlign: "left",
					},
					lineDash: "",
					width: 1,
				},
				yAxisPadding: {
					bottom: 0,
					top: 0,
				},
				width: 2,
			},
			macd: {
				color: "#13426B",
				ema: {
					color: "#71747D",
					lineDash: null,
					width: 1,
					sessionBreak: {
						color: "#71747D",
						lineDash: "2,2",
						width: 1,
					},
				},
				lineDash: null,
				negative: {
					color: "#E72742",
				},
				positive: {
					color: "#24803E",
				},
				width: 2,
				zero: {
					color: "#888",
					lineDash: null,
					width: 1.5,
				},
				sessionBreak: {
					color: "#13426B",
					lineDash: "2,2",
					width: 2,
				},
			},
			defaults: {
				sessionBreak: {
					lineDash: "2,2",
				},
			},
		},
	},
};

const CHART_CONFIG = {
	params: {
		showFlags: true,
		xAxisShortMonths: true,
		zoomEnabled: false,
		style: JSON.parse(JSON.stringify(DEFAULT_PANEL_PARAMS.style)),
	},
	panels: [
		{
			indicators: [
				{
					id: "price",
				},
				{
					id: "volume",
					style: { color: "rgba(185,185,185, 0.4)" },
				},
			],
		},
	],
};

// crosshair and event constants
const EVENT_TYPES = {
	EARNINGS: "Earnings",
	CUSTOM: "custom",
	EARNINGS_CUSTOM: "earnings-custom",
};
const PRICE_FORMATS = {
	PERCENT: {
		label: "%",
		value: "percent",
		disable: false,
	},
	ABSOLUTE: {
		label: "$",
		value: "absolute",
		disable: false,
	},
};
const SORT_RANK = {};
SORT_RANK[EVENT_TYPES.EARNINGS] = 1;

export {
	CHART_CONFIG,
	LINE_STYLES,
	OHLC_LINE_STYLES,
	EVENT_TYPES,
	SORT_RANK,
	PRICE_FORMATS,
};

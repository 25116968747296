import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import Search from "../components/Search/Search";
import { Container, Row, Col } from "react-bootstrap";
import { SymbolContext } from "../components/hooks/useSymbol";
import Navbar from "components/Header/Navbar";
import EquityIndexSection from "components/EquityIndexPerformance/EquityIndexSection";
import styled from "styled-components";
import header_bg from "assets/img/market_landing.jpg";
import Arc_Vector from "assets/img/Arc_Vector.svg";
import PageProvider from "components/contexts/PageContext";
import FEATURE_FLAGS from "utils/feature-flags";
import MarketToday from "components/MarketToday/MarketToday";
import Top10Movers from "components/Top10Movers/Top10Movers";
import useBenchmarks from "components/hooks/useBenchmarks";
import { useHistory, useLocation } from "react-router";
import { LanguageContext } from "components/hooks/useLanguage";
import Utils from "../utils/utils";
import useToken from "components/hooks/useToken";
import Banner from "components/Banner/Banner";
import Footer from "components/Footer/Footer";
import Disclaimers from "components/Disclaimers/Disclaimers";

export default function Home() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const { selectedLangParam, setLanguage } = useContext(LanguageContext);
	const { search } = location;
	let lang = new URLSearchParams(search).get("lang");
	const [firstTime, setFirstTime] = useState(true);
	const {
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
	} = useContext(SymbolContext);
	const { benchmarks, loading, error } = useBenchmarks();

	const { getToken } = useToken();
	const intermediator = sessionStorage.getItem("intermediator");

	// Trigger API to get a new token before token gets expired.
	useEffect(() => {
		const interval = setInterval(() => getToken(), 100 * 60 * 58); // 58 minutes interval as our token will expire after 60 minutes.
		return () => clearInterval(interval);
	}, [getToken]);

	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if (selectedLangParam !== currentLang || lang === null || lang === "") {
			setLanguage(currentLang);
			if (lang === null || lang === "")
				history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		}
		setFirstTime(false);
	}, [lang]);

	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if ((currentLang !== "en" && lang === null) || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		} else if (!firstTime || lang === null || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${selectedLangParam}` });
		}
	}, [selectedLangParam]);
	useEffect(() => {
		let arr = document.getElementsByClassName("chartist-tooltip");
		for (let i of arr) {
			i.style.display = "none";
		}
	}, []);

	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const HeaderSection = styled.section`
		background: linear-gradient(180deg, rgba(53, 98, 165, 1), rgba(19, 66, 107, 0)) top
				left no-repeat,
			url(${cdnDomain}${Arc_Vector}) top left no-repeat,
			url(${cdnDomain}${header_bg}) left top no-repeat;

		@media (min-width: 1441px) {
			background-size: 100% 103px, 100% 100%, 100% 572px;
		}
		@media (min-width: 1440px) {
			background-size: 100% 103px, 1606px 100%, 100% 572px;
			background-position: left top, calc(50% + 54.91px) top, left top;
		}
		@media (min-width: 1025px) and (max-width: 1439px) {
			background-size: 100% 103px, 1606px 100%, 100% 572px;
			background-position: left top, calc(50% + 20px) top, left top;
		}
		@media (min-width: 768px) and (max-width: 1024px) {
			background-size: 100% 103px, 1404px 100%, 100% 394px;
			background-position: left top, calc(50% + 90px) top, left top;
		}
		@media only screen and (max-width: 767px) {
			background-size: 100% 103px, 740px 100%, 100% 272px;
			background-position: left top, calc(50% - 60px) top, left top;
		}
	`;

	return intermediator === "true" ? (
		<div className="intermediator">
			<h2>{t("Processing... Please wait.")}</h2>
		</div>
	) : (
		<PageProvider>
			<header>
				<Banner />
				<HeaderSection className="header-background header-background-home">
					<Container>
						<Navbar></Navbar>
					</Container>
					<Container className="header-welcomeText">
						<Row>
							<Col className="welcomeText">
								<h1 className="text-white mb-0">{t("Welcome to IR Connect!")}</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<h4 className="text-white">
									{t("A platform where your IR activities begin.")}
								</h4>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col>
								<Search
									isProfile={false}
									setSymbol={setSymbol}
									setVenueXid={setVenueXid}
									setInstrumentXid={setInstrumentXid}
									setEquityShortName={setEquityShortName}
									setEquityName={setEquityName}
									setWSODCompany={setWSODCompany}
									loading={loading}
								/>
							</Col>
						</Row>
					</Container>
				</HeaderSection>
			</header>
			{FEATURE_FLAGS.MARKET_TODAY && (
				<section className="marketplace-background-color">
					<Container>
						<Row>
							<Col>
								<MarketToday benchmarks={benchmarks} error={error} loading={loading} />
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section className="bg-white">
				<Container>
					{FEATURE_FLAGS.EQUITY_INDEX_PERFORMANCE && (
						<Row className="card-wrapper">
							<EquityIndexSection />
						</Row>
					)}
				</Container>
			</section>
			<section className="bg-white">
				<Container>
					<Row className="card-wrapper">
						<Top10Movers></Top10Movers>
					</Row>
				</Container>
			</section>
			<section>
				<Container className="bg-gray disclaimerContainer">
					<Row className="card-wrapper bg-gray gy-0 gx-0">
						<Col>
							<Disclaimers />
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Footer></Footer>
			</section>
		</PageProvider>
	);
}

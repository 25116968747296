import RequestService from "../../RequestService";
import Constants from "utils/constants";

const EMPTY_XREFS = [];

/**
 * Utility for fetching XREF API data for venueXid passed
 * @param {Object} apiInstance - api instance
 */
const getXrefDataByVenueXid = async (venueXids) => {
	if (!venueXids) {
		return EMPTY_XREFS;
	}
	try {
		const apiInstance = await RequestService.init();
		const xrefResponse = await apiInstance.get(
			`${Constants.URLS.XREF_XID_PATH}?venueXids=${venueXids}`
		);
		if (xrefResponse) {
			return xrefResponse;
		}
		return EMPTY_XREFS;
	} catch (error) {
		return EMPTY_XREFS;
	}
};

export default { getXrefDataByVenueXid };

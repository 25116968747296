import { ModchartsInteractive, Crosshair } from "@markit/modcharts";
import { useEffect, useState, useRef, useContext } from "react";
import { CHART_CONFIG, EVENT_TYPES, LINE_STYLES } from "../Constants/ChartConstants";
import TimeFrame from "../TimeFrame/TimeFrame";
import "./ChartingCard.scss";
import RequestService from "services/RequestService";
import CONSTANTS from "utils/constants";
import Events from "../Events/Events";
import { Row, Col } from "react-bootstrap";
import LineStyle from "../LineStyle/LineStyle";
import Indicators from "../Indicators/Indicators";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Benchmark from "../Benchmarks/Benchmark";
import { BENCHMARKS, PEERS } from "../Constants/Benchmarks";
import closeIcon from "assets/img/Vectorclose-icon.png";
import ChartEventsOverlay from "../ChartEventsOverlay/ChartEventsOverlay";
import CrosshairBoard from "../CrosshairBoard/CrosshairBoard";
import IndicatorPopUp from "../IndicatorPopUp/IndicatorPopUp";
import "../IndicatorPopUp/IndicatorPopUp.scss";
import YAxisFlag from "../AxisFlags/YAxisFlag";
import XAxisFlag from "../AxisFlags/XAxisFlag";
import { INFO, INDICATOR_INPUT_CONFIG, TEXT_DICT } from "../IndicatorPopUp/IndicatorInfo";
import CustomLocale from "../CustomLocale";
import ErrorPopover from "../Popover/ErrorPopover";
import COLOR_PALETTE from "../Constants/ColorPalette";
import { UPPER_INDICATORS } from "../Constants/IndicatorsConstants";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "../../Lib/CustomSkeleton/CustomSkeletonTheme";
import Utils from "../../../utils/utils";
import TimeFrameConstants from "../Constants/TimeFrameConstants";
import { PeersContext } from "components/contexts/usePeersContext";

const ChartingCard = ({
	venueXid,
	loggedin,
	symbol,
	peersError,
	peersLoading,
	currency,
}) => {
	let api = null;
	const getApi = async () => {
		const apiInstance = await RequestService.init();
		api = apiInstance;
		await initNewChart();
	};
	const { t } = useTranslation();
	const chartLoaderRef = useRef({});
	const chartRef = useRef({});
	const [chart, setChart] = useState(null);
	const [eventsData, setEventsData] = useState({});
	const [legends, setLegends] = useState(null);
	const [lowerLegend, setLowerLegend] = useState(null);
	let [lowerAdded, setLowerAdded] = useState(false);
	const [removeLegend, setRemoveLegend] = useState();
	const [loading, setLoading] = useState(false);
	const [inceptiondate, setInceptiondate] = useState();
	const [comparisonEquities, setComparisonEquities] = useState([]);
	const [dollarFormat, setDollarFormat] = useState(true);
	const DEFAULT_LINE_STYLE = LINE_STYLES[0];
	const selectedTimeFrame =
		TimeFrameConstants.TIME_FRAMES[TimeFrameConstants.DEFAULT_TIME_FRAME_INDEX];
	const [indicatorInputConfig, setIndicatorInputConfig] = useState({
		isOpen: false,
		config: null,
		name: null,
		uid: null,
	});
	const [lowerInputConfig, setLowerInputConfig] = useState({
		isOpen: false,
		isEdit: false,
		config: null,
		name: null,
		uid: null,
	});
	const [info, setInfo] = useState();
	let pointer = null;
	let [textDict] = useState(null);
	let [indicatorCount] = useState({
		sma: 0,
		ema: 0,
	});
	const [showPopover, setShowPopover] = useState(false);
	const [target, setTarget] = useState(null);
	const [dataLoadError, setDataLoadError] = useState(false);
	const { peers } = useContext(PeersContext);

	//COLOR PALETTE for benchmark and Indicator
	let [availableStyle, setAvailableStyle] = useState([]);
	let [indexHolder, setIndexHolder] = useState([]);
	let [defaultStyle, setDefaultStyle] = useState(
		JSON.parse(JSON.stringify(COLOR_PALETTE))
	);
	let colorPaletteLegends = null;

	const getIndexOfAvailableStyle = () => {
		return defaultStyle.findIndex((item) => item.available === true);
	};
	useEffect(() => {
		if (chart && document.getElementById("chartingCard")) {
			registerChartEvents();
			return () => unregisterChartEvents();
		}
	}, [chart]);

	const checkStylesAdd = () => {
		let index = getIndexOfAvailableStyle();
		defaultStyle[index].available = false;
		availableStyle.push(defaultStyle[index]);
		availableStyle.forEach((element, index) => {
			availableStyle[index].index = 0;
		});
		setIndexHolder([]);
		setDefaultStyle([...defaultStyle]);
	};

	const checkStylesRemove = (e) => {
		if (e) {
			let index = defaultStyle.findIndex(
				(item) => item.available === false && item.color === e.params.style.color
			);

			//compare style that is to be removed from comparison styles array
			if (index >= 0) {
				let i = availableStyle.findIndex(
					(item) =>
						item.available === false &&
						item.color === defaultStyle[index].color &&
						item.lineDash === defaultStyle[index].lineDash
				);
				availableStyle.splice(i, 1);
				availableStyle.forEach((element, index) => {
					availableStyle[index].index = 0;
				});
				setIndexHolder([]);
				defaultStyle[index].available = true;
				setDefaultStyle([...defaultStyle]);
			}
		}
	};

	const closePopUpOnChartClick = () => {
		document.getElementsByClassName("legend-card").forEach((element) => {
			element.click();
		});
	};

	const chartNotAvailable = () => {
		setDataLoadError(true);
	};

	const load_start = () => {
		setLoading(true);
	};
	const load_stop = () => {
		setLoading(false);
	};

	const registerChartEvents = () => {
		if (chartLoaderRef.current) {
			chart.eventEmitter.on("DATA_LOAD_START", load_start);
			chart.eventEmitter.on("DATA_LOAD_STOP", load_stop);
			chart.eventEmitter.on("EVENT_RENDER", eventRenderDefault);
			chart.eventEmitter.on("EVENT_REMOVE", eventRenderDefault);
			chart.eventEmitter.on("MOUSE_DOWN", closePopUpOnChartClick);
			chart.eventEmitter.on("DATA_LOAD_ERROR", chartNotAvailable);
			setDataLoadError(false);
		}
	};

	const unregisterChartEvents = () => {
		if (chartLoaderRef.current) {
			chart.eventEmitter.off("DATA_LOAD_START", load_start);
			chart.eventEmitter.off("DATA_LOAD_STOP", load_stop);
			chart.eventEmitter.off("EVENT_RENDER", eventRenderDefault);
			chart.eventEmitter.off("EVENT_REMOVE", eventRenderDefault);
			chart.eventEmitter.off("MOUSE_DOWN", closePopUpOnChartClick);
			chart.eventEmitter.off("DATA_LOAD_ERROR", chartNotAvailable);
		}
	};

	// Event Callbacks
	const eventRenderDefault = (params) => {
		if (params.eventType) {
			if (params.eventType === EVENT_TYPES.CUSTOM) {
				eventsData[EVENT_TYPES.EARNINGS_CUSTOM] = [];
			} else {
				eventsData[params.eventType] = [];
			}
			setEventsData({ ...eventsData });
			return;
		}

		const coords = params.coords;
		const eventData = [];
		if (Array.isArray(coords)) {
			for (let elem of coords) {
				eventData.push(elem);
			}
		}
		eventsData[params.name] = eventData;
		if (Array.isArray(coords)) setEventsData({ ...eventsData });
		else setTimeout(() => setEventsData({}), 1);
	};

	const initNewChart = async () => {
		setLoading(true);
		const newChart = new ModchartsInteractive(chartRef.current);
		const authToken = await Utils.getValidMDToken();
		newChart.setAuthToken(authToken);
		if (api) {
			await api
				.get(
					`${api.baseUrl}${CONSTANTS.URLS.CHARTWORKS_SERIES_PATH}/chartapi/inceptiondate?symbol=${venueXid}&access_token=${authToken}`
				)
				.then((res) => {
					if (res.data) {
						var oaDate = res.data.InceptionDate;
						var date = new moment("1899-12-30", "YYYY-MM-DD").add(oaDate, "days");
						setInceptiondate(date);
					}
				});
		}

		const chartConfig = CHART_CONFIG;
		chartConfig.params.apiPath = `${api.baseUrl}${CONSTANTS.URLS.CHARTWORKS_SERIES_PATH}`;
		chartConfig.params.symbol = venueXid;
		chartConfig.params.days = selectedTimeFrame.numDays;
		chartConfig.params.dataInterval = selectedTimeFrame.defaultPeriod.interval;
		chartConfig.params.dataPeriod = selectedTimeFrame.defaultPeriod.value;
		chartConfig.params.style.panel.yAxis.label.format = {
			default: {
				format: "0,.2f",
				formatPercent: "0,.2f",
			},
			small: {
				format: "0.3f",
				formatPercent: "0,.3f",
			},
			micro: {
				format: "0.4f",
				formatPercent: "0,.4f",
			},
		};

		newChart.crosshair = new Crosshair(newChart, {
			enabled: true,
			flagEnabled: true,
			persist: false,
			primaryOnly: false,
			showCircle: false,
		});
		const style = {
			color: DEFAULT_LINE_STYLE.lineColor,
			width: 2.5,
		};
		chartConfig.panels[0].indicators[0].style = style;
		chartConfig.params.locale = getLocale();

		newChart.load(chartConfig);

		newChart.params.prependNormalizedPoint = false;
		newChart.params.returnPreviousPoint = false;

		setChart(newChart);
		setLegends(newChart.panels[0].indicators);
		setLoading(false);
	};
	const getLocale = () => {
		switch (t("CustomLocale")) {
			case "ZH_LOCALE":
				return CustomLocale.ZH_LOCALE;
			case "HK_LOCALE":
				return CustomLocale.HK_LOCALE;
			default:
				return CustomLocale.EN_LOCALE;
		}
	};
	useEffect(() => {
		if (lowerLegend) {
			let el = document.getElementById("lower-legend-text");
			let inputel = createCommonInputSpan();
			let legendText =
				lowerLegend.params.id === "rsi"
					? t("Relative Strength Index")
					: t(lowerLegend.params.name);
			el.innerText = `${legendText} `;
			inputel.innerHTML = `${getIndicatorInputs(lowerLegend)}`;
			el.appendChild(inputel);
		}
		if (chart && chart.params.locale) {
			chart.setLocale(getLocale());
			chart.render();
		}
	}, [t]);

	useEffect(() => {
		if (venueXid && !chart) {
			if (!api) {
				getApi();
			} else {
				initNewChart();
			}
		}
	}, [venueXid]);

	useEffect(() => {
		if (chart) {
			setLegends(chart.panels[0].indicators);
		}
	}, [legends]);

	useEffect(() => {
		if (chart && chart.panels.length > 1) {
			setLowerLegend(chart.panels[1].indicators[0]);
			if (lowerInputConfig.isEdit && lowerAdded) {
				document.getElementById("lower-legend").remove();
				setLowerAdded((lowerAdded = false));
			}
			if (lowerLegend && !lowerAdded) {
				let ul = document.getElementsByClassName("modcharts-legend")[1];
				ul.style.visibility = "hidden";
				let Span = document.createElement("span");
				Span.id = "lower-legend";
				var span = document.createElement("span");
				span.tabIndex = "0";
				span.id = "lower-legend-text";
				if (lowerLegend.params.id === "rsi")
					lowerLegend.params.name = t("Relative Strength Index");
				span.innerText = `${t(lowerLegend.params.name)} `;
				span.style.height = "20px";
				span.style.fontWeight = "700";
				span.style.fontSize = "14px";
				Span.style.zIndex = "10";
				Span.style.position = "relative";
				span.style.cursor = "pointer";
				var inputSpan = createCommonInputSpan();
				var i = document.createElement("img");
				i.tabIndex = "0";
				i.src = closeIcon;
				i.className = "close-icon";
				i.alt = "close";
				i.style.marginLeft = "9px";
				i.onclick = () => setRemoveLegend(lowerLegend.params.id);
				i.onkeydown = (e) => {
					if (e.code === "Enter" || e.code === "Space")
						setRemoveLegend(lowerLegend.params.id);
				};
				span.onclick = () =>
					setLowerInputConfig({
						isOpen: true,
						isEdit: false,
						config: lowerLegend.params,
						name: lowerLegend.params.id,
						uid: lowerLegend.params.uid,
					});
				span.onkeydown = (e) => {
					if (e.code === "Enter" || e.code === "Space")
						setLowerInputConfig({
							isOpen: true,
							isEdit: false,
							config: lowerLegend.params,
							name: lowerLegend.params.id,
							uid: lowerLegend.params.uid,
						});
				};
				span.appendChild(inputSpan);
				Span.appendChild(span);
				Span.appendChild(i);
				ul.appendChild(Span);
				setLowerAdded(true);
				setInfo(INFO.find((ele) => ele.id === lowerLegend.params.id).info);
			}
		}
	}, [lowerLegend, lowerInputConfig.isEdit]);

	const createCommonInputSpan = () => {
		var inputSpan = document.createElement("span");
		inputSpan.style.fontWeight = "400";
		inputSpan.id = "lower-legend-input";
		inputSpan.innerText = `${getIndicatorInputs(lowerLegend)}`;
		return inputSpan;
	};

	useEffect(() => {
		if (lowerLegend) {
			let popup = document.getElementById("popup");
			if (lowerInputConfig.isOpen && popup === null) {
				let ul = document.getElementsByClassName("modcharts-legend")[1];
				popup = document.createElement("div");
				popup.id = "popup";
				let ins = getLowerInputs();

				let ip = document.createElement("div");
				ip.className = "indicator-popup-lower";
				ip.tabIndex = 0;
				ip.addEventListener("focusout", () => onBlurCustom());
				ip.onfocus = () => clearTimeout(pointer);
				let content = document.createElement("div");
				content.className = "content";
				content.textContent = t(info);
				let inpts = document.createElement("div");
				inpts.className = "inputs";
				inpts.append(ins);
				let ctrls = document.createElement("div");
				ctrls.className = "controls d-flex justify-content-end";
				let cancel = document.createElement("div");
				cancel.className = "cancel";
				let btn1 = document.createElement("button");
				let btn2 = document.createElement("button");
				btn1.innerText = t("Cancel");
				cancel.appendChild(btn1);
				let apply = document.createElement("div");
				apply.className = "apply";
				btn2.innerText = t("APPLY");
				apply.appendChild(btn2);
				btn1.onfocus = () => clearTimeout(pointer);
				btn1.onclick = () =>
					setLowerInputConfig({
						isOpen: false,
						config: null,
						name: null,
						uid: null,
					});
				btn2.onfocus = () => clearTimeout(pointer);
				btn2.onclick = () => {
					if (lowerLegend.params.id === "rsi") {
						if (document.getElementById("errorPeriod").hidden === true)
							onApply(textDict, "lower");
					} else onApply(textDict, "lower");
				};

				ctrls.appendChild(cancel);
				ctrls.appendChild(apply);
				content.appendChild(inpts);
				content.appendChild(ctrls);
				ip.appendChild(content);
				popup.appendChild(ip);

				ul.append(popup);
				ip.focus();
			} else {
				popup?.parentNode.removeChild(popup);
			}
		}
	}, [lowerInputConfig]);

	const onBlurCustom = () => {
		pointer = setTimeout(() => {
			setLowerInputConfig({
				isOpen: false,
				name: lowerLegend.params.id,
				uid: lowerLegend.params.uid,
			});
		}, 200);
	};

	const checkInput = (e) => {
		let len = e.target.value.length + 1;
		let deciPlaces = e.target.value.indexOf(".") + 3;

		//Restricting upto 2 decimal places
		if (
			len > deciPlaces &&
			e.target.value.includes(".") &&
			e.keyCode !== 8 &&
			e.keyCode !== 46
		)
			e.preventDefault();

		//Only numbers , - at first position , one . , no ctrl+v ,
		if (
			(e.key === "." && e.target.value.includes(".")) ||
			(e.key === "-" && e.target.value.length > 0) ||
			((e.keyCode < 48 || e.keyCode > 57) &&
				(e.keyCode < 96 || e.keyCode > 105) &&
				e.keyCode !== 8 &&
				e.keyCode !== 46 &&
				e.key !== "." &&
				e.key !== "-") ||
			e.key === "!" ||
			e.key === "@" ||
			e.key === "#" ||
			e.key === "$" ||
			e.key === "%" ||
			e.key === "^" ||
			e.key === "&" ||
			e.key === "*" ||
			e.key === "(" ||
			e.key === ")" ||
			(e.keyCode === 86 && !!e.ctrlKey)
		) {
			e.preventDefault();
		}
	};

	const setDefault = (params, input) => {
		params.inputs.forEach((ele) => {
			if (ele.name === input.name.toLowerCase() && ele.value) {
				input.default = ele.value;
				textDict[input.name] = ele.value;
			}
		});
	};

	const onTextNumberChange = (e, input) => {
		Object.keys(textDict).forEach((key) => {
			if (key === e.target.name) {
				e.target.value !== ""
					? (textDict[key] = e.target.value)
					: (textDict[key] = input.default);
			}
		});

		//Validation on values
		let el = document.getElementById("errorPeriod");
		if ((e.target.value < 0 && e.target.value !== "") || e.target.value === "-")
			el.removeAttribute("hidden");
		else el.setAttribute("hidden", true);
	};

	const getLowerInputs = () => {
		const { inputs } = INDICATOR_INPUT_CONFIG[lowerInputConfig.name];
		textDict = JSON.parse(JSON.stringify(TEXT_DICT[lowerInputConfig.name]));
		const params = lowerInputConfig.config;
		let ins = document.createElement("div");

		switch (lowerInputConfig.name) {
			case "rsi":
				let input = inputs["number"];
				setDefault(params, input);

				let row = document.createElement("div");
				row.className = "input row";
				let inlabels = document.createElement("div");
				inlabels.className = "inputLabels col-6";
				let inboxes = document.createElement("div");
				inboxes.className = "inputBoxes col-6";
				let lbl = document.createElement("label");
				lbl.innerText = t(input.name);
				let inpt = document.createElement("input");
				inpt.type = "text";
				inpt.defaultValue = input.default;
				inpt.name = input.name;
				inpt.onfocus = () => clearTimeout(pointer);
				inpt.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt.addEventListener("keyup", (e) => onTextNumberChange(e, input));
				inlabels.appendChild(lbl);
				inboxes.appendChild(inpt);
				let error = document.createElement("label");
				error.className = "error";
				error.id = "errorPeriod";
				error.hidden = true;
				error.innerText = `* ${t("Period must be greater than zero")}`;
				row.appendChild(inlabels);
				row.appendChild(inboxes);
				row.appendChild(error);
				ins.appendChild(row);
				break;
			case "stochastics":
				let input1 = inputs["number0"];
				setDefault(params, input1);

				let row1 = document.createElement("div");
				row1.className = "input row";
				let inlabels1 = document.createElement("div");
				inlabels1.className = "inputLabels col-6";
				let inboxes1 = document.createElement("div");
				inboxes1.className = "inputBoxes col-6";
				let lbl1 = document.createElement("label");
				lbl1.innerText = t(input1.name);
				let inpt1 = document.createElement("input");
				inpt1.onfocus = () => clearTimeout(pointer);
				inpt1.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt1.addEventListener("keyup", (e) => onTextNumberChange(e, input1));
				inpt1.type = "text";
				inpt1.defaultValue = input1.default;
				inpt1.name = input1.name;
				inlabels1.appendChild(lbl1);
				inboxes1.appendChild(inpt1);
				row1.appendChild(inlabels1);
				row1.appendChild(inboxes1);

				let input2 = inputs["number1"];
				setDefault(params, input2);

				let row2 = document.createElement("div");
				row2.className = "input row";
				let inlabels2 = document.createElement("div");
				inlabels2.className = "inputLabels col-6";
				let inboxes2 = document.createElement("div");
				inboxes2.className = "inputBoxes col-6";
				let lbl2 = document.createElement("label");
				lbl2.innerText = t(input2.name);
				let inpt2 = document.createElement("input");
				inpt2.onfocus = () => clearTimeout(pointer);
				inpt2.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt2.addEventListener("keyup", (e) => onTextNumberChange(e, input2));
				inpt2.type = "text";
				inpt2.defaultValue = input2.default;
				inpt2.name = input2.name;
				inlabels2.appendChild(lbl2);
				inboxes2.appendChild(inpt2);
				row2.appendChild(inlabels2);
				row2.appendChild(inboxes2);

				let input3 = inputs["number2"];
				setDefault(params, input3);

				let row3 = document.createElement("div");
				row3.className = "input row";
				let inlabels3 = document.createElement("div");
				inlabels3.className = "inputLabels col-6";
				let inboxes3 = document.createElement("div");
				inboxes3.className = "inputBoxes col-6";
				let lbl3 = document.createElement("label");
				lbl3.innerText = t(input3.name);
				let inpt3 = document.createElement("input");
				inpt3.onfocus = () => clearTimeout(pointer);
				inpt3.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt3.addEventListener("keyup", (e) => onTextNumberChange(e, input3));
				inpt3.type = "text";
				inpt3.defaultValue = input3.default;
				inpt3.name = input3.name;
				inlabels3.appendChild(lbl3);
				inboxes3.appendChild(inpt3);
				row3.appendChild(inlabels3);
				row3.appendChild(inboxes3);

				ins.appendChild(row1);
				ins.appendChild(row2);
				ins.appendChild(row3);
				break;
			case "macd":
				let input4 = inputs["number0"];
				setDefault(params, input4);

				let row4 = document.createElement("div");
				row4.className = "input row";
				let inlabels4 = document.createElement("div");
				inlabels4.className = "inputLabels col-6";
				let inboxes4 = document.createElement("div");
				inboxes4.className = "inputBoxes col-6";
				let lbl4 = document.createElement("label");
				lbl4.innerText = t(input4.name);
				let inpt4 = document.createElement("input");
				inpt4.onfocus = () => clearTimeout(pointer);
				inpt4.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt4.addEventListener("keyup", (e) => onTextNumberChange(e, input4));
				inpt4.type = "text";
				inpt4.defaultValue = input4.default;
				inpt4.name = input4.name;
				inlabels4.appendChild(lbl4);
				inboxes4.appendChild(inpt4);
				row4.appendChild(inlabels4);
				row4.appendChild(inboxes4);

				let input5 = inputs["number1"];
				setDefault(params, input5);

				let row5 = document.createElement("div");
				row5.className = "input row";
				let inlabels5 = document.createElement("div");
				inlabels5.className = "inputLabels col-6";
				let inboxes5 = document.createElement("div");
				inboxes5.className = "inputBoxes col-6";
				let lbl5 = document.createElement("label");
				lbl5.innerText = t(input5.name);
				let inpt5 = document.createElement("input");
				inpt5.onfocus = () => clearTimeout(pointer);
				inpt5.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt5.addEventListener("keyup", (e) => onTextNumberChange(e, input5));
				inpt5.type = "text";
				inpt5.defaultValue = input5.default;
				inpt5.name = input5.name;
				inlabels5.appendChild(lbl5);
				inboxes5.appendChild(inpt5);
				row5.appendChild(inlabels5);
				row5.appendChild(inboxes5);

				let input6 = inputs["number2"];
				setDefault(params, input6);

				let row6 = document.createElement("div");
				row6.className = "input row";
				let inlabels6 = document.createElement("div");
				inlabels6.className = "inputLabels col-6";
				let inboxes6 = document.createElement("div");
				inboxes6.className = "inputBoxes col-6";
				let lbl6 = document.createElement("label");
				lbl6.innerText = t(input6.name);
				let inpt6 = document.createElement("input");
				inpt6.onfocus = () => clearTimeout(pointer);
				inpt6.addEventListener("keydown", (e) => {
					if (e.code !== "Tab") checkInput(e);
				});
				inpt6.addEventListener("keyup", (e) => onTextNumberChange(e, input6));
				inpt6.type = "text";
				inpt6.defaultValue = input6.default;
				inpt6.name = input6.name;
				inlabels6.appendChild(lbl6);
				inboxes6.appendChild(inpt6);
				row6.appendChild(inlabels6);
				row6.appendChild(inboxes6);

				ins.appendChild(row4);
				ins.appendChild(row5);
				ins.appendChild(row6);
				break;
			default:
				break;
		}
		return ins;
	};

	const getIndicatorInputs = (indicator) => {
		if (!indicator) return;
		let inputs = indicator.params.inputs;
		switch (inputs.length) {
			case 1:
				return `(${inputs[0].value})`;
			case 2:
				return `(${inputs[0].value}, ${inputs[1].value})`;
			case 3:
				return `(${inputs[0].value}, ${inputs[1].value}, ${inputs[2].value})`;
			case 4:
				return `( ${inputs[0].value}, ${inputs[1].value}, ${inputs[2].value}, ${t(
					inputs[3].value
				)})`;
			default:
				return `(${inputs[0].value})`;
		}
	};

	const getBenchmarkSymbol = (xid) => {
		let index = BENCHMARKS.filter((element) => {
			return element.id === xid;
		});
		// temporary code below. once peers api is integrated it will be removed.
		if (index.length === 0) {
			index = PEERS.filter((el) => {
				return el.id === xid;
			});
		}
		if (index.length > 0) {
			return index;
		} else {
			let equities = comparisonEquities;
			if (Array.isArray(comparisonEquities))
				return equities.filter((element) => {
					return element.id.toString() === xid.toString();
				});
		}
	};

	const setEquities = (equity) => {
		comparisonEquities.push(equity);
		setComparisonEquities(comparisonEquities);
	};

	const onApply = (newConfig, panel) => {
		let indicator;
		if (panel === "upper")
			indicator = chart.panels[0].indicators.find(
				(ele) => ele.params.uid === indicatorInputConfig.uid
			);
		else {
			indicator = chart.panels[1].indicators.find(
				(ele) => ele.params.uid === lowerInputConfig.uid
			);
		}

		indicator.params.inputs.forEach((ele) => {
			Object.keys(newConfig).forEach((conf) => {
				if (ele.name.toLowerCase() === conf.toLowerCase()) ele.value = newConfig[conf];
			});
		});

		chart.loadData();
		if (panel === "upper") {
			setLegends(chart.panels[0].indicators);
			setIndicatorInputConfig({
				isOpen: false,
				config: null,
				name: null,
				uid: null,
			});
		} else {
			setLowerLegend(chart.panels[1].indicators[0]);
			setLowerInputConfig({
				isOpen: false,
				isEdit: true,
				config: null,
				name: null,
				uid: null,
			});
		}
	};

	const addAnother = (id) => {
		if (chart.panels[0].indicators.length < 11) {
			let indicators = chart.panels[0].indicators.filter((e) => e.params.id === id);
			checkStylesAdd();
			if (indicators.length < 2) {
				chart.panels[0].addIndicator(id);
				setLegends(chart.panels[0].indicators);
				Object.keys(indicatorCount).forEach((key) => {
					if (key === id) indicatorCount[key]++;
				});
				chart.loadData();
			}
		} else {
			setShowPopover(true);
			let parent = document.getElementsByClassName("indicators-button");
			setTarget(parent[0]);
		}
	};

	const getIdsforColorPalette = () => {
		if (colorPaletteLegends === null) {
			const getBenchmarkIds = BENCHMARKS.map((benchmark) => {
				return benchmark.id;
			});

			const getPeersId = peers
				? peers
						.filter((x) => x && x.isVisible)
						.map((peer) => {
							return peer.venueXid;
						})
				: [];

			const getUpperIndicatorsId = UPPER_INDICATORS.map((indicator) => {
				return indicator.id;
			});

			colorPaletteLegends = [...getBenchmarkIds, ...getPeersId, ...getUpperIndicatorsId];
		}
	};

	const getPriceHeaderLabel = () => {
		let currencyCode = "HK$";
		if (currency && currency.symbol) {
			currencyCode = currency.symbol === "$" ? "US$" : currency.symbol;
		}
		return dollarFormat ? `(${currencyCode})` : "(%)";
	};

	const getDynamicColor = (indicator, benchmarks, i) => {
		if (benchmarks && !colorPaletteLegends.includes(benchmarks.id)) {
			colorPaletteLegends.push(benchmarks.id);
		}
		getIdsforColorPalette();
		if (
			colorPaletteLegends.includes(indicator.params.id) ||
			(benchmarks && colorPaletteLegends.includes(benchmarks.id))
		) {
			if (availableStyle.length > 0) {
				let index = availableStyle.findIndex(
					(item) => item.available === false && item.index === 0
				);

				if (index > -1 && !indexHolder.includes(i)) {
					let getAvailablecolor = availableStyle[index];
					indexHolder.push(i);
					availableStyle[index].index = i;
					indicator.params.style.color = getAvailablecolor.color;
					return indicator.params.style.color;
				} else {
					let indexWithValue = availableStyle.findIndex(
						(item) => item.available === false && item.index === i
					);
					let getAvailablecolor = availableStyle[indexWithValue];
					availableStyle[indexWithValue].index = i;
					indicator.params.style.color = getAvailablecolor.color;
					return indicator.params.style.color;
				}
			}
		}
		return indicator.params.style.color;
	};

	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-expand-md">
				<Row id="chartingMenus">
					<Col className="nav-item" data-testid="timeFrameButton" id="timeFramedropDown">
						<TimeFrame
							chart={chart}
							chartRef={chartRef}
							inceptiondate={inceptiondate}
							registerChartEvents={registerChartEvents}
							unregisterChartEvents={unregisterChartEvents}
						/>
					</Col>
					{loggedin && (
						<>
							<Col className="nav-item " data-testid="eventsButton" id="eventsdropDown">
								<LineStyle
									chart={chart}
									chartRef={chartRef}
									registerChartEvents={registerChartEvents}
									unregisterChartEvents={unregisterChartEvents}
									setDollarFormat={setDollarFormat}
								/>
							</Col>
							<Col
								className="nav-item"
								data-testid="benchmarkButton"
								id="benchmarkdropDown"
							>
								<Benchmark
									chart={chart}
									registerChartEvents={registerChartEvents}
									unregisterChartEvents={unregisterChartEvents}
									setLegends={() => setLegends()}
									removeLegend={removeLegend}
									setRemoveLegend={() => setRemoveLegend()}
									setEquities={setEquities}
									selectedBenchmarks={[]}
									setDollarFormat={setDollarFormat}
									checkStylesAdd={checkStylesAdd}
									checkStylesRemove={checkStylesRemove}
									venueXid={venueXid}
									peers={peers}
									peersError={peersError}
									peersLoading={peersLoading}
								/>
							</Col>
							<Col
								className="nav-item"
								data-testid="indicatorsButton"
								id="indicatorsdropDown"
							>
								<Indicators
									chart={chart}
									newPanel={null}
									INDI={null}
									setLegends={() => setLegends()}
									setLowerLegend={() => setLowerLegend()}
									removeLegend={removeLegend}
									setLowerAdded={() => setLowerAdded()}
									setRemoveLegend={() => setRemoveLegend()}
									registerChartEvents={registerChartEvents}
									unregisterChartEvents={unregisterChartEvents}
									indicatorCount={indicatorCount}
									checkStylesAdd={checkStylesAdd}
									checkStylesRemove={checkStylesRemove}
								/>
							</Col>
							<Col className="nav-item" data-testid="eventsButton" id="eventsdropDown">
								<Events
									venueXid={venueXid}
									chart={chart}
									registerChartEvents={registerChartEvents}
									unregisterChartEvents={unregisterChartEvents}
								/>
							</Col>
						</>
					)}
				</Row>
			</nav>
			<Row>
				<Col>
					{legends && loggedin && (
						<Row id="legend-panel" className="legendPanel" data-testid="legendPanel">
							{legends.map((indicator, i) => {
								let benchmarks = null;
								if (indicator.params.id === "volume") {
									return;
								} else if (indicator.params.id === "mae") {
									indicator.params.name = t("MA Envelope");
								} else if (indicator.params.id === "price") {
									if (legends[0].params.uid === indicator.params.uid) {
										indicator.params.name = symbol;
										indicator.params.inputs[0].value = null;
									} else {
										// For adding legends for Comparions (Index & Equities)
										// indicator.params.style.color = availableStyle[0].color;
										benchmarks = getBenchmarkSymbol(indicator.params.symbol).at(0);
										indicator.params.name = benchmarks.symbol;
										indicator.params.inputs[0].value = null;
									}
								}
								return (
									<div className="legend-card" key={indicator.params.uid}>
										<i
											style={{
												margin: "4px 8px",
												color: `${getDynamicColor(indicator, benchmarks, i)}`,
											}}
											className="fa fa-circle"
										></i>
										<span
											tabIndex={indicator.params.id !== "price" ? "0" : ""}
											onKeyDown={(e) => {
												if (e.code === "Enter" || e.code === "Space") {
													if (indicatorInputConfig.isOpen)
														setIndicatorInputConfig({
															isOpen: false,
															config: null,
															name: null,
															uid: null,
														});
													else
														setIndicatorInputConfig({
															isOpen: true,
															config: indicator.params,
															name: indicator.params.id,
															uid: indicator.params.uid,
														});
												}
											}}
											onClick={() => {
												if (indicatorInputConfig.isOpen)
													setIndicatorInputConfig({
														isOpen: false,
														config: null,
														name: null,
														uid: null,
													});
												else
													setIndicatorInputConfig({
														isOpen: true,
														config: indicator.params,
														name: indicator.params.id,
														uid: indicator.params.uid,
													});
											}}
											style={{ cursor: "pointer", fontWeight: "700", fontSize: "14px" }}
										>
											{t(indicator.params.name)}
											{"  "}
											<span style={{ fontWeight: "400" }}>
												{indicator.params.inputs[0].value != null
													? getIndicatorInputs(indicator)
													: " "}
											</span>
										</span>
										{legends[0].params.uid !== indicator.params.uid && (
											<img
												tabIndex="0"
												src={closeIcon}
												alt="close"
												className="close-icon"
												onClick={() => setRemoveLegend(indicator.params.uid)}
												onKeyDown={(e) => {
													if (e.code === "Enter" || e.code === "Space") {
														setRemoveLegend(indicator.params.uid);
													}
												}}
											/>
										)}
										{indicatorInputConfig.isOpen &&
											indicator.params.id !== "price" &&
											indicatorInputConfig.uid === indicator.params.uid && (
												<IndicatorPopUp
													onClose={() =>
														setIndicatorInputConfig({
															isOpen: false,
															config: null,
															name: null,
															uid: null,
														})
													}
													config={indicatorInputConfig}
													onApply={(newConfig, panel) => onApply(newConfig, panel)}
													addAnother={(id) => addAnother(id)}
													indicatorCount={indicatorCount}
												></IndicatorPopUp>
											)}
									</div>
								);
							})}
						</Row>
					)}
					<div className="crosshairBoardContainer">
						<CrosshairBoard chart={chart} activeValue={"absolute"} />
					</div>
					{!dataLoadError && !loading && (
						<div
							className={`${
								getPriceHeaderLabel() === "(%)" ? "price-format-percent" : ""
							} text-end price-format`}
						>
							{getPriceHeaderLabel()}
						</div>
					)}
					{dataLoadError && (
						<Error
							errorMessge={t("There is currently no performance data available.")}
						></Error>
					)}
					<div
						ref={chartRef}
						className={!dataLoadError ? "chartContainer" : "chartContainerForError"}
						id="chartingCard"
						data-testid="chartingCard"
					>
						{loading && (
							<CustomSkeletonTheme>
								<Skeleton
									style={{ zIndex: "999" }}
									className={
										!dataLoadError
											? "chartContainer chart-skeleton"
											: "chartContainerForError"
									}
								/>
							</CustomSkeletonTheme>
						)}
						<ChartEventsOverlay
							className="visible"
							eventsData={eventsData}
						></ChartEventsOverlay>
						<YAxisFlag chart={chart} priceFormat={dollarFormat ? "dollar" : "percent"} />
						<XAxisFlag chart={chart} />
					</div>
				</Col>
			</Row>
			<ErrorPopover
				showPopover={showPopover}
				target={target}
				setShowPopover={setShowPopover}
			></ErrorPopover>
		</>
	);
};

export default ChartingCard;

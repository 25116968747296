import "./polyfills";
import React from "react";
import { I18nextProvider } from "react-i18next";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/_utilities.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import i18n from "config/i18n";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Bold.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-BoldItalic.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Heavy.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-HeavyItalic.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Italic.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Light.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-LightItalic.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Regular.woff";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-Thin.otf";
import "./assets/fonts/FSElliotPro/FS Elliot Pro-ThinItalic.otf";

ReactDOM.render(
	<ErrorBoundary>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</ErrorBoundary>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const UPPER_INDICATORS = [
	{ id: "sma", label: "Simple Moving Average", added: false },
	{ id: "ema", label: "Exponential Moving Average", added: false },
	{ id: "mae", label: "Moving Average Envelope", added: false },
	{ id: "bollinger", label: "Bollinger Bands", added: false },
];

const LOWER_INDICATORS = [
	{ id: "rsi", label: "Relative Strength Index", added: false },
	{ id: "stochastics", label: "Stochastics", added: false },
	{ id: "macd", label: "MACD", added: false },
];

const UPPER = "upper";
const LOWER = "Lower";

const INDICATORS = [...UPPER_INDICATORS, ...LOWER_INDICATORS];
export { INDICATORS, LOWER_INDICATORS, UPPER_INDICATORS, UPPER, LOWER };

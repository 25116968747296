import React, { useState, useEffect } from "react";
import { find, forEach, sortBy } from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as EarningIcon } from "assets/icons/earningpin.svg";

import formatter from "utils/formatUtils";
import styles from "./ChartEventsOverlay.scss";
import { EVENT_TYPES, SORT_RANK } from "../Constants/ChartConstants";
import utils from "utils/utils";
import { t } from "i18next";
import moment from "moment";

import "locale/CommonLocale";

/**
 * Renders event icons on a chart with tooltips.
 * @param {Array} props.eventsData - Events from a Modcharts chartseries data call
 */
const ChartEventsOverlay = (props) => {
	// A transformed array of events data for rendering on the chart;
	// this is used in cases where multiple events have to be stacked on a single
	// point in time
	const [eventsRenderData, setEventsRenderData] = useState([]);

	// Update the events render data if and only if events data changes
	useEffect(() => {
		if (
			eventsRenderData.length &&
			(!props.eventsData || !Object.keys(props.eventsData).length)
		) {
			setEventsRenderData([]);
			return;
		}

		// zipper the events together so that we can properly stack multiple events on a single point
		const xCoords = [];
		const zipped = {};

		//eslint-disable-next-line
		for (const eventType in props.eventsData) {
			props.eventsData[eventType].forEach((datapoint) => {
				// Round x coords to the nearest integer for grouping
				const x = Math.round(datapoint.x);

				if (xCoords.indexOf(x) === -1) {
					xCoords.push(x);
					zipped[x] = {
						meta: {
							coords: { x, y: datapoint.y },
							dates: [],
						},
					};
				}

				// Choose the highest y-axis position, since we are doing our
				// own stacking and this puts us closest to the line
				if (zipped[x].meta.coords.y < datapoint.y) {
					zipped[x].meta.coords.y = datapoint.y;
				}

				// Create a list of dates, if there are multiple ones on a given
				// x position
				zipped[x].meta.dates.push(datapoint.event.date);

				let eventsAtPoint = zipped[x].events;
				// Group all event types into an array
				if (!eventsAtPoint) {
					zipped[x].events = [];
					eventsAtPoint = zipped[x].events;
				}

				const eventTypeAtPoint = find(eventsAtPoint, (e) => {
					return e.type === eventType;
				});

				// Add to existing event type if it exists
				if (eventTypeAtPoint) {
					eventTypeAtPoint.events.push(datapoint.event);
					// Otherwise, create it and push the first datapoint
				} else {
					eventsAtPoint.push({
						type: eventType,
						events: [datapoint.event],
					});

					// Sort events for rendering
					zipped[x].events = sortBy(eventsAtPoint, (e) => {
						return SORT_RANK[e.type];
					});
				}
			});
		}

		let eventsArray = [];

		forEach(zipped, (point) => {
			eventsArray.push(point);
		});

		eventsArray = sortBy(eventsArray, (point) => {
			return point.meta.coords.x;
		});

		setEventsRenderData([...eventsArray]);
	}, [eventsRenderData.length, props.eventsData]);

	const eventOverlay = (metaEvent, event, elementKey) => {
		let content = <></>;
		switch (metaEvent.type) {
			case EVENT_TYPES.EARNINGS_CUSTOM:
				content = (
					<>
						<div className="col-field">
							<b>{t("Date")} </b>
							<b>{t("Earnings")} </b>
						</div>
						<div className="col-value">
							<p>
								{moment(formatter.date(event.date, "DD MMM YYYY"))
									.locale(t("lang"))
									.format(t("formatHolding"))}
							</p>
							<p>{formatter.number(event.actual, 2)}</p>
						</div>
					</>
				);
				break;
			default:
				break;
		}

		return (
			<Tooltip key={`event-overlay-${elementKey}`} id={`event-overlay-${elementKey}`}>
				{content}
			</Tooltip>
		);
	};

	const renderEventIcon = (point) => {
		const VERTICAL_OFFSET = 5;
		const HORIZONTAL_OFFSET = 9;
		const style = {
			left: point.meta.coords.x - HORIZONTAL_OFFSET,
			top: point.meta.coords.y - VERTICAL_OFFSET,
			position: "absolute",
			zIndex: 500,
		};

		return point.events.map((metaEvent, metaEventIndex) => {
			return metaEvent.events.map((event, eventIndex) => {
				style.top -= 22;
				let type;
				let additionalStyles = [];
				switch (metaEvent.type) {
					case EVENT_TYPES.EARNINGS_CUSTOM:
						type = <EarningIcon style={{ height: 14, width: 12 }} />;
						break;
					default:
						type = "";
						// eslint-disable-next-line array-callback-return
						return; // to avoid creation of overlay trigger for events like highlow
				}
				var eventType = metaEvent.type;

				const elementKey = `${metaEventIndex}-${eventIndex}`;
				let overlay = eventOverlay(metaEvent, event, elementKey);
				return (
					<OverlayTrigger
						rootClose={true}
						key={`event-${elementKey}`}
						trigger={utils.toolTipTrigger()}
						placement="top"
						overlay={overlay}
					>
						<div
							style={{ ...style }}
							className={`
								${styles.eventIcon}
								${props.className}
							`}
							data-testid={metaEvent.type}
							data-eventtype={eventType}
						>
							{type}
						</div>
					</OverlayTrigger>
				);
			});
		});
	};

	return (
		<div className={styles.eventsOverlay}>
			{eventsRenderData.map((point) => {
				return renderEventIcon(point);
			})}
			{props.eventsData && Object.keys(props.eventsData).length > 0 && (
				<table className="sr-only">
					<caption>Event data</caption>
					<thead>
						<tr>
							<th>Date</th>
							<th>Event Type</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(props.eventsData).map((eventType, index) => {
							return props.eventsData[eventType].map((event, eventIndex) => {
								switch (eventType) {
									case EVENT_TYPES.EARNINGS_CUSTOM:
										return (
											<tr key={`events-data-table-${index}-${eventIndex}`}>
												<td>
													{formatter.moment(event.event.date).format("MMM DD YYYY")}
												</td>
												<td>Earnings</td>
												<td>{event.event.actual}</td>
											</tr>
										);
									default:
										return "";
								}
							});
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default ChartEventsOverlay;

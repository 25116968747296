import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
import "./InstitutionsChart.scss";
import "./ChartColors.scss";
import "chartist-plugin-fill-donut";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import SubscriptionModal from "components/Common/SubscriptionModal";
import SubscriptionLink from "components/Common/SubscriptionLink";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Institutions = (props) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//Filter percentage data from object
	const institutionPercentData = props.data
		? props.data.map(({ percentHeld }) => percentHeld)
		: null;
	// Calculate the remaining percentage
	const sum =
		100 -
		(institutionPercentData
			? institutionPercentData.reduce((total, allocatedValue) => total + allocatedValue)
			: 0);
	const totalAllocationPercent = (100 - sum).toFixed(2);
	institutionPercentData?.push(sum);
	//Set chart series data
	const chartData = {
		series: institutionPercentData ? institutionPercentData : [100],
	};
	const tabName = props.tabName;

	const showSkeleton = () => {
		return '<div class="skeleton-div-pie"/>';
	};

	const subscriptionBox = () => {
		return (
			<>
				<Button className="nextButton" onClick={handleShow}>
					Open Modal
				</Button>

				<Modal show={show} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Modal heading</Modal.Title>
					</Modal.Header>
					<Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={handleClose}>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	};

	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	//Chart options
	const options = institutionPercentData
		? {
				donut: true,
				donutWidth: 25,
				startAngle: 0,
				total: 100,
				plugins: [
					Chartist.plugins.fillDonut({
						items: [
							{
								class: "middle-text-hdr",
								content: totalAllocationPercent + "%",
								position: "center",
								offsetY: 20,
							},
							{
								class: "middle-txt",
								content: t(`${tabName} Ownership `),
								position: "center",
								offsetY: 0,
							},
							{
								class: "middle-txt",
								content: t("in the Top 10"),
								position: "center",
								offsetY: 20,
							},
						],
					}),
				],
		  }
		: {
				donut: true,
				donutWidth: 25,
				startAngle: 0,
				total: 100,
				plugins: [
					Chartist.plugins.fillDonut({
						items: [
							{
								content: showSkeleton(),
								position: "center",
							},
							{
								content: showSkeleton(),
								position: "center",
								offsetY: 30,
							},
						],
					}),
				],
		  };
	const [chartClass, setChartClass] = useState("colored");

	useEffect(() => {
		if (props.loading) setChartClass("gray-loading");
		else setChartClass("colored");
	}, [props.loading]);

	return (
		<React.Fragment>
			<div className="donut-chart">
				{((chartData && chartData.series && options) ||
					chartClass === "gray-loading") && (
					<ChartistGraph
						key={t("As of")} // updating chart when lang is changed
						data={chartData}
						type={"Pie"}
						options={options}
						className={`donutchart ${chartClass}`}
					></ChartistGraph>
				)}

				<div className="txt-below-chart">
					{props.loading ? (
						<Skeleton width={"165px"} height={"18px"} />
					) : (
						<>{t("Data source: S&P Global")}</>
					)}
				</div>
				<div className="txt-subscribe">
					{
						<SubscriptionLink
							cdnDomain={cdnDomain}
							handleShow={handleShow}
							GAEventsTracker={{
								action: t("HoldingsAnalysis"),
								label: t(`${props.tabName}`),
							}}
						/>
					}
					{
						<SubscriptionModal
							show={show}
							handleShow={handleShow}
							handleClose={handleClose}
						/>
					}
				</div>
			</div>
		</React.Fragment>
	);
};

export default React.memo(Institutions);

import React from "react";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import arrow from "assets/img/Arrow-down.png";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { t } from "i18next";

const AutoComplete = React.forwardRef(
	(
		{
			options,
			placeholder,
			name,
			className,
			onSelect,
			onBlur,
			selected,
			noResultMessage,
		},
		ref
	) => {
		const ToggleButton = ({ onClick }) => (
			<img
				alt="arrow-icon"
				src={arrow}
				className="arrow-autocomplete"
				onClick={onClick}
				onMouseDown={(e) => {
					e.preventDefault();
				}}
			/>
		);
		const onFilter = (option, props) => {
			if (props.selected.length) {
				// Display all the options if there's a selection.
				return true;
			}
			return option.label.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
		};
		return (
			<Typeahead
				filterBy={onFilter}
				maxResults={options.length}
				highlightOnlyResult={true}
				options={options}
				ref={ref}
				id={`ddl_${name}`}
				className={`${className ? className : ""} autocomplete`}
				placeholder={placeholder}
				defaultSelected={selected && [selected]}
				renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
					<input
						id={name}
						name={name}
						className="search-input"
						{...inputProps}
						ref={(input) => {
							inputRef(input);
							referenceElementRef(input);
						}}
						onBlur={(e) => onBlur(e, name)}
					/>
				)}
				renderMenu={(results, menuProps) => (
					<Menu
						{...menuProps}
						emptyLabel={
							<div className="no-result-option">
								{t(noResultMessage || "No results found")}
							</div>
						}
						className="ddlOptionMenu"
					>
						{results.map((result, index) => (
							<MenuItem
								key={index}
								className="ddlOptionItem"
								onClick={() => onSelect(result.code, name)}
								option={result}
								position={index}
							>
								{result.label}
							</MenuItem>
						))}
					</Menu>
				)}
			>
				{({ isMenuShown, toggleMenu }) => (
					<ToggleButton isOpen={isMenuShown} onClick={() => toggleMenu()} />
				)}
			</Typeahead>
		);
	}
);

export default AutoComplete;

const fetchPublicAuthToken = async () => {
	try {
		const publicConsumerKey = window.MD && window.MD.PUBLIC_CONSUMER_KEY;
		const publicConsumerSecret = window.MD && window.MD.PUBLIC_CONSUMER_SECRET;
		const pingAuthTokenUrl = window.MD && window.MD.PING_AUTH_TOKEN;
		const authToken = btoa(`${publicConsumerKey}:${publicConsumerSecret}`);

		const response = await fetch(pingAuthTokenUrl, {
			method: "post",
			headers: {
				Accept: "application/json",
				Authorization: `Basic ${authToken}`,
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: "grant_type=client_credentials",
		});

		const result = await response.json();
		return result;
	} catch (err) {
		return;
	}
};

export default {
	fetchPublicAuthToken,
};

import React, { useEffect, useRef, useState } from "react";
import { updateFlag, hideFlag, showFlag } from "./YAxisFlagUtility";

const YAxisFlag = React.memo((props) => {
	let chart = props && props.chart ? props.chart : null;
	let [val, setVal] = useState(null);
	let [position, setPosition] = useState({ top: null, left: null });
	let divRef = useRef(null);

	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("CROSSHAIR_MOVE", (args) =>
				updateFlag(args, setVal, setPosition, val)
			);
			chart.eventEmitter.on("FLAG_HIDE_CROSSHAIR", () => hideFlag(divRef));
			chart.eventEmitter.on("FLAG_SHOW_CROSSHAIR", () => showFlag(divRef));
		}
	}, [chart]);

	return (
		<div
			ref={divRef}
			className={"flagCrosshair"}
			style={{ left: position.left, top: position.top }}
		>
			<div className={"arrowleft"}></div> <div>{val}</div>
		</div>
	);
});

export default YAxisFlag;

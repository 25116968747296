import { useState, useEffect } from "react";
import Loader from "components/Lib/Loader/Loader";
import RequestService from "services/RequestService";
import Error from "components/Error/Error";
import { useTranslation } from "react-i18next";

const ArticleContent = ({ articleContentApi }) => {
	const [articleBody, setArticleBody] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		async function fetchArticleContent() {
			try {
				setError(null);
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(`news/${articleContentApi}`, {
					headers: {
						Accept: "application/xml",
					},
				});
				if (response.status === 200 && response?.data) {
					let bodyContent = new window.DOMParser().parseFromString(
						response.data,
						"application/xml"
					);
					let body = bodyContent.querySelector("Body").innerHTML;

					const reg = /\d{2}:\d{2} (AM|PM) EDT, \d{2}\/\d{2}\/\d{4} \(MT Newswires\)/;
					let matchingtexts = reg.exec(body);
					let changedText = matchingtexts
						? matchingtexts[0].replace(/\(MT Newswires\)/, "")
						: "";
					setArticleBody(body.replace(reg, changedText));
				} else {
					setArticleBody(null);
				}
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
			}
		}
		fetchArticleContent();
	}, [articleContentApi]);

	if (loading) {
		return (
			<div className="centered-loader">
				<Loader />
			</div>
		);
	} else if (error) {
		return <Error errorMessge={t("There is currently no data available.")} />;
	} else {
		return (
			<div
				className="news-content"
				data-testid="modal-content"
				dangerouslySetInnerHTML={{ __html: articleBody }}
			></div>
		);
	}
};

export default ArticleContent;

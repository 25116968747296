import { useState, createContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from "config/i18n";

const LANGUAGES = {
	ENGLISH: "en",
	ENGLISH_DISPLAY_CODE: "EN", //public to be used in Company Announcements API (private page)
	ENGLISH_SHORTCODE: "e", //public to be used in Company Announcements API
	ENGLISH_DESCRIPTION: "English",
	CHINESE: "cn", // public page announcement
	CHINESE_PUBLIC_ANNOUCEMENT: "zh",
	CHINESE_URLPARAM: "cn",
	CHINESE_SHORTCODE: "c", //to be used in Company Announcements API (private page)
	CHINESE_DISPLAY_CODE: "CS",
	CHINESE_NEWS_SIMPLIFIED: "CS", //public to be used in News API (private page)
	CHINESE_SIMPLIFIED_DESCRIPTION: "Simplified Chinese",
	//Traditional Chinese
	CHINESE_TRADITIONAL_URLPARAM: "hk",
	CHINESE_TRADITIONAL_DISPLAY_CODE: "HK",
	CHINESE_TRADITIONAL: "hk",
	CHINESE_NEWS_TRADITIONAL: "TC", // To be used in NEWS api(traditional chinese)
	CHINESE_TRADITIONAL_DESCRIPTION: "Traditional Chinese",
};
const CS_LANG = {
	lang: LANGUAGES.CHINESE_URLPARAM,
	displayCode: LANGUAGES.CHINESE_DISPLAY_CODE,
	description: LANGUAGES.CHINESE_SIMPLIFIED_DESCRIPTION,
	languageCode: LANGUAGES.CHINESE_DISPLAY_CODE,
	symbol: "简",
};
const TC_LANG = {
	lang: LANGUAGES.CHINESE_TRADITIONAL_URLPARAM,
	displayCode: LANGUAGES.CHINESE_TRADITIONAL_DISPLAY_CODE,
	description: LANGUAGES.CHINESE_TRADITIONAL_DESCRIPTION,
	languageCode: LANGUAGES.CHINESE_TRADITIONAL_DISPLAY_CODE,
	symbol: "繁",
};
const EN_LANG = {
	lang: LANGUAGES.ENGLISH,
	displayCode: LANGUAGES.ENGLISH_DISPLAY_CODE,
	description: LANGUAGES.ENGLISH_DESCRIPTION,
	languageCode: LANGUAGES.ENGLISH_DISPLAY_CODE,
	symbol: "EN",
};
const useLanguage = () => {
	const { t } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES.ENGLISH);
	const [selectedLanguageInternal, setSelectedLanguageInternal] = useState(
		LANGUAGES.ENGLISH
	);
	const [announcemtnLang, setAnnouncement] = useState(LANGUAGES.ENGLISH);
	const [selectedLangParam, setSelectedLangParam] = useState(LANGUAGES.ENGLISH);

	const [languageDescription, setLanguageDescription] = useState(
		t(LANGUAGES.CHINESE_DESCRIPTION)
	);
	const [availableLang, setAvailableLang] = useState([CS_LANG, TC_LANG]);

	function changeLanguage(languageValue) {
		switch (languageValue) {
			case LANGUAGES.ENGLISH:
				setSelectedLanguage(LANGUAGES.ENGLISH);
				setSelectedLanguageInternal(LANGUAGES.ENGLISH);
				setLanguageDescription(t(LANGUAGES.ENGLISH_DESCRIPTION));
				setSelectedLangParam(t(LANGUAGES.ENGLISH));
				setAnnouncement(LANGUAGES.ENGLISH);
				setAvailableLang([CS_LANG, TC_LANG]);
				updateSelectedLanguage(LANGUAGES.ENGLISH, LANGUAGES.ENGLISH);
				break;
			case LANGUAGES.CHINESE:
				setSelectedLanguage(LANGUAGES.CHINESE);
				setSelectedLanguageInternal(LANGUAGES.CHINESE_NEWS_SIMPLIFIED);
				setLanguageDescription(t(LANGUAGES.CHINESE_SIMPLIFIED_DESCRIPTION));
				setSelectedLangParam(t(LANGUAGES.CHINESE_URLPARAM));
				setAnnouncement(LANGUAGES.CHINESE_PUBLIC_ANNOUCEMENT);
				setAvailableLang([EN_LANG, TC_LANG]);
				updateSelectedLanguage(LANGUAGES.CHINESE, LANGUAGES.CHINESE_URLPARAM);
				break;
			case LANGUAGES.CHINESE_TRADITIONAL:
				setSelectedLanguage(LANGUAGES.CHINESE_TRADITIONAL);
				setSelectedLanguageInternal(LANGUAGES.CHINESE_NEWS_TRADITIONAL);
				setLanguageDescription(t(LANGUAGES.CHINESE_TRADITIONAL_DESCRIPTION));
				setSelectedLangParam(t(LANGUAGES.CHINESE_TRADITIONAL_URLPARAM));
				setAnnouncement(LANGUAGES.CHINESE_PUBLIC_ANNOUCEMENT);
				setAvailableLang([EN_LANG, CS_LANG]);
				updateSelectedLanguage(
					LANGUAGES.CHINESE_TRADITIONAL,
					LANGUAGES.CHINESE_TRADITIONAL_URLPARAM
				);
				break;
			default:
		}
	}

	function updateSelectedLanguage(language, urlParam) {
		i18n.changeLanguage(language);
		localStorage.setItem("language", urlParam);
	}

	return {
		selectedLanguage,
		selectedLanguageInternal,
		languageDescription,
		selectedLangParam,
		availableLang,
		setLanguage: changeLanguage,
		announcemtnLang,
	};
};

export const LanguageContext = createContext(null);

const LanguageProvider = ({ children }) => {
	const {
		selectedLanguage,
		selectedLanguageInternal,
		languageDescription,
		setLanguage,
		selectedLangParam,
		availableLang,
		announcemtnLang,
	} = useLanguage();

	return (
		<LanguageContext.Provider
			value={{
				selectedLanguage,
				selectedLanguageInternal,
				languageDescription,
				selectedLangParam,
				announcemtnLang,
				availableLang,
				setLanguage,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

export { LanguageProvider };

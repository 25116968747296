import { useEffect, useState } from "react";
import useToken from "components/hooks/useToken";
import "./App.scss";
import { HashRouter, Route, Switch } from "react-router-dom";
import PublicUser from "../pages/PublicUser";
import PrivateUser from "../pages/PrivateUser";
import Home from "../pages/Home";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./Footer/Footer";
import { SymbolProvider } from "./hooks/useSymbol";
import Disclaimers from "components/Disclaimers/Disclaimers";
import { AuthProvider } from "./hooks/useAuthentication";
import { LanguageProvider } from "./hooks/useLanguage";
import ReactGA from "react-ga";
import RequestService from "services/RequestService";
import ProtectedRoute from "./ProtectedRoute.js";
import Utils from "utils/utils";
import { PeersProvider } from "./contexts/usePeersContext";

const GTM_TRACKING_CODE = window.MD && window.MD.GTM_TRACKING_CODE;
ReactGA.initialize(GTM_TRACKING_CODE);

function App() {
	const [loading, setLoading] = useState(true);

	const { getToken } = useToken();
	const updateLastUsedTime = () => {
		localStorage.setItem("lastUsedTime", Utils.getCurrentTimeInUnixFormat());
	};

	// Trigger API to get a new token before token gets expired.
	useEffect(() => {
		const interval = setInterval(() => getToken(), 1000 * 60 * 58); // 58 minutes interval as our token will expire after 60 minutes.
		return () => clearInterval(interval);
	}, [getToken]);

	useEffect(() => {
		window.addEventListener("focusin", updateLastUsedTime);
		window.addEventListener("scroll", updateLastUsedTime);
		return () => {
			//clean up
			window.removeEventListener("focusin", updateLastUsedTime);
			window.removeEventListener("scroll", updateLastUsedTime);
		};
	}, []);

	// useEffect with an empty dependency array works the same way as componentDidMount
	useEffect(async () => {
		try {
			// set loading to true before calling API
			setLoading(true);
			await RequestService.init();
			setLoading(false);
		} catch (error) {
			// add error handling here
			setLoading(false);
			console.log(error);
		}
	}, []);

	const [savedLanguge, setSavedLanguge] = useState(
		window.localStorage.getItem("language")
			? window.localStorage.getItem("language")
			: "en"
	);
	useEffect(() => {
		ReactGA.pageview(`Landing Page/${savedLanguge}`);
	}, []);

	return (
		!loading && (
			<SymbolProvider>
				<LanguageProvider>
					<AuthProvider>
						<PeersProvider>
							<HashRouter>
								<Switch>
									<Route exact path="/" component={Home} />
									<ProtectedRoute
										exact
										path="/profile/:venueXid"
										component={PrivateUser}
									/>
									<Route exact path="/public/:venueXid" component={PublicUser} />
								</Switch>
							</HashRouter>
						</PeersProvider>
					</AuthProvider>
				</LanguageProvider>
			</SymbolProvider>
		)
	);
}

export default App;

import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import Error from "components/Error/Error";

const ArticleErrorModal = ({ modalShow, setModalShow }) => {
	const { t } = useTranslation();
	return (
		<Modal
			show={modalShow}
			backdrop="static"
			keyboard={false}
			size="lg"
			centered
			data-testid="article_modal"
		>
			<Modal.Body>
				<div className="d-flex">
					<Error errorMessge={t("There is currently no data available.")} />
					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={() => setModalShow(false)}
					></button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ArticleErrorModal;

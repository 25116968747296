import React, { useState, useRef, useEffect } from "react";
import { Table, Card, Overlay, Popover } from "react-bootstrap";
import "./EquityIndexTable.scss";
import Rectangle_logo from "../../../assets/img/Rectangle.svg";
import EyeIcon from "../../../assets/img/EyeIcon.svg";
import EyeIconSlash from "../../../assets/img/EyeIconSlash.svg";
import AlertIcon from "../../../assets/img/AlertIcon.svg";
import { useTranslation } from "react-i18next";
import COLOR_PALETTE from "../LegendColorsConstants";
import formatter from "../../../utils/formatUtils";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "../../Lib/CustomSkeleton/CustomSkeletonTheme";
import moment from "moment";
import "locale/CommonLocale";

const EquityIndexTable = ({
	data,
	type,
	error,
	loading,
	fetchedAt,
	setVenueXidsForChart,
	initstyles,
	benchmarks,
	selectedTimeframe,
}) => {
	const width = window.innerWidth < 768 ? true : false;
	const [selectedIndexes, setSelectedIndexes] = useState([0, 1, 2]);
	const [tableRowClicked, setTableRowClicked] = useState(false);
	const { t } = useTranslation();
	//For Popover
	const [showPopover, setShowPopover] = useState(false);
	const [target, setTarget] = useState(null);
	const [timeoutVal, setTimeoutVal] = useState();
	const [indexes, setIndexes] = useState(data);
	const [noindexData, setNoIndexData] = useState(false);
	const ref = useRef(null);
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	// Set available styles for first three rows on Page Load
	let [availableStyle, setAvailableStyle] = useState(initstyles);
	let [defaultStyle, setDefaultStyle] = useState(
		JSON.parse(JSON.stringify(COLOR_PALETTE))
	);
	let availableStyleArrayForChart = [...initstyles];

	// Create selected Venue XIDs array for chart
	const setVenueXIDSForChartArray = () => {
		//Pass the color and XID
		let chartDataArray = [];
		availableStyleArrayForChart.forEach((item) => {
			let venueXid = benchmarks[item.tableRowIndex].id;
			let color = item.color;
			chartDataArray.push({ venueXid: venueXid, color: color });
		});
		setVenueXidsForChart(chartDataArray, type);
	};
	useEffect(() => {
		setIndexes(data);
		setVenueXIDSForChartArray();
		setSelectedIndexes([0, 1, 2]);
		setAvailableStyle(initstyles);
		setDefaultStyle(JSON.parse(JSON.stringify(COLOR_PALETTE)));
	}, [data]);

	// Class for red/green values of change
	const getClassForCol = (val) => {
		if (val.toString().indexOf("-") >= 0) {
			return "change-ngtv";
		} else if (parseFloat(val) === 0) {
			return "card-text-grey";
		} else {
			return "change-pstv";
		}
	};

	// Method to return the parent element as table so that target is set to table element always
	// to make the error message overlay being shown  in same position
	function hasSomeParentTheClass(element) {
		if (element.className.split(" ").indexOf("table-row-el") >= 0) {
			return element;
		} else {
			return hasSomeParentTheClass(element.parentNode);
		}
	}

	// On Table Row click
	const tableRowClick = (e, xid) => {
		const index = e.currentTarget.rowIndex - 1;
		// If user tries to remove last available index, dont remove and return
		if (selectedIndexes.length < 2 && selectedIndexes.includes(index)) {
			return;
		}

		// End If user tries to remove last available index, dont remove and return

		// Show error message if user adding 11th Index and check if that index already exists in list
		// if not exist it means user is trying to remove that index and hence don't show error
		if (selectedIndexes.length > 9 && !selectedIndexes.includes(index)) {
			let parent = e.target.parentElement;
			parent = hasSomeParentTheClass(parent);
			if (!showPopover) {
				setTarget(parent);
				setShowPopover(true);
			}
			// Close the error popover after 6sec
			clearTimeout(timeoutVal);
			setTimeoutVal(
				setTimeout(() => {
					setShowPopover(false);
				}, 6000)
			);
			return;
		}

		//To handle special case for VenueXid 475604063 as no intraday data is present for it.
		if (xid === 475604063 && selectedTimeframe < 30) {
			setNoIndexData(true);
			let parent = e.target.parentElement;
			parent = hasSomeParentTheClass(parent);
			if (!showPopover) {
				setTarget(parent);
				setShowPopover(true);
			}
			// Close the error popover after 6sec
			clearTimeout(timeoutVal);
			setTimeoutVal(
				setTimeout(() => {
					setShowPopover(false);
				}, 6000)
			);
			return;
		} else setNoIndexData(false);

		setTableRowClicked(true);

		// Set selectedIndex array to show eye/slash eye button
		setSelectedTableRowIndexArray(index);
		// End Set selectedIndex array to show eye/slash eye button

		if (selectedIndexes.includes(index)) {
			checkStylesRemove(index);
		} else {
			checkStylesAdd(index);
		}

		// Create selected Venue XIDs array for chart
		setVenueXIDSForChartArray();
		// End Create selected Venue XIDs array for chart
	};

	const setSelectedTableRowIndexArray = (index) => {
		let selectedIndices = JSON.parse(JSON.stringify(selectedIndexes));
		if (!selectedIndexes.includes(index)) {
			selectedIndices = [...selectedIndexes, index];
			setSelectedIndexes(selectedIndices);
		} else {
			const indexOf = selectedIndexes.indexOf(index);
			selectedIndices.splice(indexOf, 1);
			setSelectedIndexes(selectedIndices);
		}
	};
	//
	const getIndexOfAvailableStyle = () => {
		return defaultStyle.findIndex((item) => item.available === true);
	};

	// Set Available styles
	const checkStylesAdd = (tableRowIndex) => {
		let index = getIndexOfAvailableStyle();
		// Set state value of defaultStyle using below code
		let defaultStyleArray = [...defaultStyle];
		defaultStyleArray[index].available = false;
		defaultStyleArray[index].tableRowIndex = tableRowIndex;
		setAvailableStyle([...availableStyle, defaultStyleArray[index]]);
		setDefaultStyle([...defaultStyleArray]);
		//For setting the chart Object to have Venue XID with color mapping
		availableStyleArrayForChart = [...availableStyle, defaultStyleArray[index]];
	};

	const checkStylesRemove = (tableRowIndex) => {
		let index = defaultStyle.findIndex(
			(item) => item.available === false && item.tableRowIndex === tableRowIndex
		);

		//compare style that is to be removed from comparison styles array
		let i = availableStyle.findIndex(
			(item) => item.available === false && item.color === defaultStyle[index].color
		);
		// Below code to remove element from state
		let availableStyleArray = [...availableStyle];
		availableStyleArray.splice(i, 1);
		setAvailableStyle(availableStyleArray);
		//For setting the chart Object to have Venue XID with color mapping
		availableStyleArrayForChart = availableStyleArray;

		if (index >= 0) {
			// Set state value of defaultStyle using below code
			let defaultStyleArray = [...defaultStyle];
			defaultStyleArray[index].available = true;
			defaultStyleArray[index].tableRowIndex = null;
			setDefaultStyle([...defaultStyleArray]);
		}
	};

	// Legend Methods
	const getBackgroundForLegend = (index) => {
		const style = availableStyle.find((item) => item.tableRowIndex === index);
		return style.color;
	};

	// Render legend for each row
	const renderLegend = (index, name) => {
		if (!selectedIndexes.includes(index)) {
			return (
				<div className="col-one">
					<div className="new-cls">
						<span className="square">
							<img src={`${cdnDomain}${Rectangle_logo}`} alt="R" />
						</span>
					</div>
					<div className="name-sqr"> {name} </div>
				</div>
			);
		} else {
			return (
				<div className="col-one">
					<div className="new-cls">
						<span
							style={{
								background: getBackgroundForLegend(index),
							}}
							className="circle"
						></span>
					</div>
					<div className="name-crcl"> {name} </div>
				</div>
			);
		}
	};

	// Render Show/Hide icon HTML
	const renderElement = (index) => {
		if (index > 2) {
			if (!selectedIndexes.includes(index)) {
				return <img src={`${cdnDomain}${EyeIconSlash}`} alt="Hide" />;
			} else {
				return <img src={`${cdnDomain}${EyeIcon}`} alt="Show" />;
			}
		} else {
			if (tableRowClicked && !selectedIndexes.includes(index)) {
				return <img src={`${cdnDomain}${EyeIconSlash}`} alt="Hide" />;
			} else {
				return <img src={`${cdnDomain}${EyeIcon}`} alt="Show" />;
			}
		}
	};

	const onScroll = () => {
		setShowPopover(false);
	};

	const getMessage = () => {
		if (noindexData) return t("Index performance not available for selected range");
		return t("Max index comparison reached. Please remove prior selections to add more.");
	};

	const getIconClass = () => {
		if (noindexData) return "alertIcon";
		return "";
	};

	const getClass = () => {
		if (noindexData) return "error-txt-no-data";
		return "error-txt";
	};
	const showSkeleton = () => {
		return Array.from(Array(10).keys()).map((indx) => {
			return (
				<CustomSkeletonTheme key={indx}>
					<tr data-testid="tableRowEl" className="table-row-el">
						<td aria-hidden="true" className="first-col-wdth column-first">
							<div className="col-one">
								<div className="new-cls">
									{indx < 3 ? (
										<Skeleton circle className="circle" />
									) : (
										<Skeleton className="square" width={"4px"} height={"16px"} />
									)}
								</div>
								<div className="name-crcl" style={{ width: "100%" }}>
									<Skeleton />
								</div>
							</div>
						</td>
						<td colSpan="2" className="combine-col-wdth column-second">
							<Skeleton />
						</td>
						<td className={"third-col-wdth column-second "}>
							<Skeleton />
						</td>
						<td className="fourth-col-wdth">
							{indx < 3 ? (
								<img src={`${cdnDomain}${EyeIcon}`} alt="Show" />
							) : (
								<img src={`${cdnDomain}${EyeIconSlash}`} alt="Hide" />
							)}
						</td>
					</tr>
				</CustomSkeletonTheme>
			);
		});
	};

	// Commenting below code and we will handle errors separately
	// if (error) throw error;

	return (
		<React.Fragment>
			<div>
				<Table
					striped
					responsive="sm"
					borderless
					id="equityIndexTable"
					className="equity-index-tbl"
				>
					<thead>
						<tr>
							<th className="first-col-wdth">{t("Index")}</th>
							{width ? (
								<th colSpan="2" className="combine-col-wdth">
									<div>{t("Price(HK$)")}</div>
									<div>{t("Todays_Change")}</div>
								</th>
							) : (
								<>
									<th className="second-col-wdth">{t("Price(HK$)")}</th>
									<th className="third-col-wdth">{t("Todays_Change")}</th>
								</>
							)}
							<th className="fourth-col-wdth">
								<span style={{ display: "none" }}>EyeIcon</span>
							</th>
						</tr>
					</thead>
					{!loading && data.length === 0 && (
						<Error
							bgClass={"equity-table-icon"}
							errorMessge={t("There is currently no performance data available.")}
						></Error>
					)}
					{!error && (
						<tbody data-testid="tableBody" onScroll={onScroll}>
							{loading
								? showSkeleton()
								: indexes.map((item, index) => {
										let values = item.data;
										let result = benchmarks.find((obj) => {
											return obj.id === values.venueXid.toString();
										});
										return (
											result && (
												<tr
													key={index}
													onClick={(e) => tableRowClick(e, values.venueXid)}
													data-testid="tableRowEl"
													className="table-row-el"
												>
													<td aria-hidden="true" className="first-col-wdth column-first">
														{renderLegend(index, result.name)}
													</td>
													{width ? (
														<td colSpan="2" className="combine-col-wdth column-second">
															<div>
																{formatter.numberWithoutTrailingZero(
																	values.lastTrade.last,
																	2,
																	2
																)}
															</div>
															<div className={getClassForCol(values.lastTrade.change)}>
																{formatter.formatPosNegVal(
																	values.lastTrade.change.toFixed(2),
																	2
																) +
																	" (" +
																	formatter.formatPosNegValForChangePercent(
																		values.changePercent.today.toFixed(2)
																	) +
																	"%)"}
															</div>
														</td>
													) : (
														<>
															<td className="second-col-wdth column-second">
																{formatter.numberWithoutTrailingZero(
																	values.lastTrade.last,
																	2,
																	2
																)}
															</td>
															<td
																className={`third-col-wdth column-second ${getClassForCol(
																	values.lastTrade.change
																)}`}
															>
																{formatter.formatPosNegVal(
																	values.lastTrade.change.toFixed(2),
																	2
																) +
																	" (" +
																	formatter.formatPosNegValForChangePercent(
																		values.changePercent.today.toFixed(2)
																	) +
																	"%)"}
															</td>
														</>
													)}
													<td className="fourth-col-wdth">{renderElement(index)}</td>
												</tr>
											)
										);
								  })}
						</tbody>
					)}
				</Table>
				<div className="timestamp-txt">
					{t("As of")} {moment(fetchedAt).locale(t("lang")).format(t("format"))}{" "}
					{t("HKT")}
				</div>
				{
					<div ref={ref}>
						<Overlay
							show={showPopover}
							target={target}
							placement="top"
							container={ref}
							containerPadding={20}
							rootClose
							onHide={() => setShowPopover(false)}
						>
							<Popover id="popover-contained">
								<Popover.Body>
									<div className="error-popover">
										<div>
											<div className={getIconClass()}>
												<img src={AlertIcon} alt="Alert" />
											</div>
										</div>
										<div className={getClass()}>{getMessage()}</div>
									</div>
								</Popover.Body>
							</Popover>
						</Overlay>
					</div>
				}
			</div>
		</React.Fragment>
	);
};

export default EquityIndexTable;

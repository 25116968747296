import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";
const useInstitutionsAggregates = (instrumentXid, aggregateType) => {
	const [institutionsAggregates, setInstitutionsAggregates] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const apiUrl = `${Constants.URLS.BIGDOUGH_INSTITUTIONSAGGREGATES}${instrumentXid}?aggregateType=${aggregateType}`;

	useEffect(() => {
		if (!instrumentXid || !aggregateType) return;
		setLoading(true);
		setError(null);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.aggregates) {
					let sortedList = response.data.data.aggregates.sort(sort);
					setInstitutionsAggregates(sortedList);
				} else {
					setInstitutionsAggregates(null);
					throw response;
				}
			} catch (e) {
				setInstitutionsAggregates(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [instrumentXid, aggregateType]);

	const sort = (a, b) => {
		if (a.percentHeldInstitutional < b.c) {
			return 1;
		}
		if (a.percentHeldInstitutional > b.percentHeldInstitutional) {
			return -1;
		}
		return 0;
	};

	return { institutionsAggregates, error, loading, fetchedAt, sort };
};

export default useInstitutionsAggregates;

import { useState, useEffect } from "react";
import Constants from "../../utils/constants";
import RequestService from "../../services/RequestService";
import Utils from "../../utils/utils";
import moment from "moment";
import formatter from "utils/formatUtils";

const useEstimateEarning = (venueXid, period = "QTR") => {
	const [chartData, setChartData] = useState(null);
	const [earningData, setEarningData] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(null);
	const [currency, setCurrency] = useState("HK$");
	const apiUrl = `${Constants.URLS.FACT_SET_ESTIMATE}${venueXid}?period=${period}&numRowsEst=1&numRowsAct=8`;
	useEffect(() => {
		if (!venueXid) return;
		setLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.items.length > 0) {
					mapChatData(response?.data?.data?.items);
				} else {
					throw response;
				}
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}

		init();
	}, [venueXid]);
	const isLast2yr = (date) => {
		return moment().diff(date, "years", true) <= 2;
	};

	const mapChatData = (responseData) => {
		if (responseData && responseData.length > 0) {
			let actuals = [];
			let estimates = [];
			let surprises = [];
			let earnings = [];
			let updatedcurrency = currency;
			responseData.map((data) => {
				let { periods } = data;
				periods = periods.sort((a, b) =>
					parseInt(a.period.fiscalYear) > parseInt(b.period.fiscalYear) ? 1 : -1
				);
				let latest = 0.0;
				if (periods && periods.length > 0) {
					periods.map((earningValues) => {
						let { actual, estimate, surprise, period } = earningValues;
						let fiscalYear = period.fiscalYear;
						let publicationDate = period.publicationDate;
						let reportDate = period.reportDate;
						let fiscalQuarter = `${period.fiscalPeriod}${period.fiscalQuarter}`;
						if (isLast2yr(reportDate))
							earnings.push({
								date: new Date(formatter.date(reportDate, "YYYY-MM-DD")),
								quarter: fiscalQuarter,
								year: fiscalYear,
								calendarMonth: period.calendarMonth,
								calendarYear: period.calendarYear,
								actual: actual.actualValue,
								estimate: estimate.mean,
								surpriseAmount: surprise.surpriseAmount,
								surprisePercent: surprise.surprisePercent,
								isSurprise: !!surprise.surpriseAmount,
								xLabel: `${fiscalQuarter} ${fiscalYear}`,
							});
						actuals.push({
							date: formatter.date(publicationDate, "YYYY-MM-DD"),
							quarter: fiscalQuarter,
							year: fiscalYear,
							actual: actual.actualValue,
						});

						estimates.push({
							quarter: fiscalQuarter,
							year: fiscalYear,
							estimateMean: estimate.mean,
						});

						surprises.push({
							quarter: fiscalQuarter,
							year: fiscalYear,
							surpriseAmount: surprise.surpriseAmount,
							surprisePercent: surprise.surprisePercent,
							isSurprise: !!surprise.surpriseAmount,
						});
						let temp = period.calendarYear + period.calendarMonth / 12;
						if (latest < temp) {
							latest = temp;
							updatedcurrency = actual.actualCurrency;
						}
					});
				}
			});
			const earningsData = {
				actuals,
				estimates,
				surprises,
			};
			setError(null);
			if (updatedcurrency !== "HKD") setCurrency(updatedcurrency);
			setEarningData(earnings);
			setChartData(earningsData);
		}
	};

	return {
		chartData,
		error,
		loading,
		fetchedAt,
		currency,
		earningData,
	};
};

export default useEstimateEarning;

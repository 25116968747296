const RankingSets = [
	{
		key: "AllEquities",
		label: "All Equities",
		rankingFilter: "HongKong Exchange Equities",
	},
	{
		key: "HSIConstituents",
		label: "HSI Constituents",
		rankingFilter: "Hang Seng Index (Markit Digital)",
	},
];
export default RankingSets;

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useCompanyInformation = (venueXid) => {
	const [companyInformation, setCompanyInformation] = useState([]);
	const [errorCompanyInformation, setErrorCompanyInformation] = useState(null);
	const [loadingCompanyInformation, setLoadingCompanyInformation] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(null);
	const apiUrl = `${Constants.URLS.FACTSET_COMPANY_INFORMATION}${venueXid}`;

	useEffect(() => {
		if (!venueXid) return;
		setLoadingCompanyInformation(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data) {
					let companyProfileData = response.data.data;
					setCompanyInformation(companyProfileData);
				} else {
					setCompanyInformation(null);
					throw response;
				}
				setErrorCompanyInformation(null);
			} catch (e) {
				setCompanyInformation(null);
				setErrorCompanyInformation(e);
			} finally {
				setLoadingCompanyInformation(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [venueXid, apiUrl]);

	return {
		companyInformation,
		errorCompanyInformation,
		loadingCompanyInformation,
		fetchedAt,
	};
};

export default useCompanyInformation;

import * as d3 from "d3";
import "./D3EarningsChart.scss";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "utils/chartist/Tooltip.styles.scss";
import D3EarningsChartUtils from "./D3EarningsChartUtils";
import formatter from "utils/formatUtils";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Switch } from "@mui/material";
import moment from "moment";
import { deviceType } from "utils/utils";

const D3EarningChart = ({
	earnings,
	prices,
	earningCurrency,
	priceCurrency,
	chartWidth,
	device,
}) => {
	const { t } = useTranslation();
	const chartRef = useRef(null);
	const [showPricePerformance, setPricePerformance] = useState(true);
	const legendNames = [t("Estimate EPS"), t("Actual EPS"), t("Price")];
	const margin = {
		top: 30,
		right: D3EarningsChartUtils.getChartRightMargin(earnings, earningCurrency),
		bottom: 30,
		left: D3EarningsChartUtils.getChartLeftMargin(
			prices,
			showPricePerformance,
			priceCurrency
		),
	};
	const width = chartWidth - margin.left - margin.right;
	let height = 384;
	let xAxisTopMargin = 20;
	if (device === deviceType.Mobile) {
		xAxisTopMargin = 12;
	}
	height = height - margin.top - margin.bottom;
	let { startdate, enddate } = D3EarningsChartUtils.getEarningsDateRange(earnings);
	startdate = moment(startdate).subtract(25, "days");
	enddate = moment(enddate).add(45, "days");
	const x = d3.scaleTime().range([0, width]);
	const y0 = d3.scaleLinear().range([height, 0]);
	const y1 = d3.scaleLinear().range([height, 0]);

	useEffect(() => {
		if (prices.length > 0 && earnings.length > 0) mapPriceAnd24hChange();
	}, [prices, earnings]);

	useEffect(() => {
		if (chartRef.current && device === deviceType.Mobile) {
			chartRef.current.scrollTo(chartWidth - margin.right, 0);
		}
	}, [chartRef.current, device]);

	const mapPriceAnd24hChange = useCallback(() => {
		earnings.forEach((earning) => {
			prices.every((price, i) => {
				if (formatter.moment(price.date).isSame(earning.date, "day")) {
					let currValue = price?.value;
					let prevValue = prices[i - 1]?.value;
					earning.price = currValue;
					let priceChange = currValue - prevValue;
					earning.change24h = (priceChange * 100) / Math.abs(prevValue);
					return false;
				}
				return true;
			});
		});
	}, [prices, earnings]);

	const quarter = function (d, i) {
		const m = d.getMonth();
		if (i === 0 || m === 0) {
			return `${d.toLocaleString("default", { month: "short" })} ${d.getFullYear()}`;
		} else {
			return d.toLocaleString("default", { month: "short" });
		}
	};
	let monthsDiff = moment(enddate).diff(startdate, "months");
	const xAxis = d3
		.axisBottom(x)
		.tickSize(0)
		.ticks(d3.timeMonth.every(monthsDiff <= 5 ? 1 : 2))
		.tickFormat(quarter);
	const yAxisLeft = d3
		.axisLeft(y0)
		.ticks(5)
		.tickSize(0)
		.tickFormat((x) => x.toFixed(2));

	const yAxisRight = d3
		.axisRight(y1)
		.ticks(5)
		.tickSize(0)
		.tickFormat((x) => x.toFixed(2));

	function makeGridLineOnYaxis() {
		return d3.axisLeft(y1).ticks(5);
	}

	const valueline = d3
		.line()
		.x(function (d) {
			return x(d.date);
		})
		.y(function (d) {
			return y0(d.value);
		});

	x.domain([startdate, enddate]);
	y0.domain(D3EarningsChartUtils.priceDataPointRange(prices));
	y1.domain(D3EarningsChartUtils.earningsDataPointRange(earnings));
	const renderTooltip = (e, d) => {
		let chartInnerWidth = width;
		if (device === deviceType.Mobile) {
			chartInnerWidth = window.innerWidth - margin.left - margin.right;
		}
		D3EarningsChartUtils.renderEarningTooltips(
			e,
			d,
			chartInnerWidth,
			t,
			showPricePerformance,
			device
		);
	};

	// render svg in document Body
	const leftYAxis = d3.select("#left-YAxis").html("");
	const chart = d3
		.select("#chart-container")
		.html("")
		.classed("chart", true)
		.append("svg")
		.attr("width", width)
		.attr("height", height + margin.top + margin.bottom + 20)
		.append("g")
		.attr("transform", "translate(" + 0 + "," + margin.top + ")");

	//Add partial background color  (use for highlight background for future quarter)
	if (earnings.length > 0) {
		let lastQuarter = earnings.reduce((curr, next) => {
			return curr.date > next.date ? curr : next;
		});
		let lastQuarterDataPointWithoutActuals = earnings.find(
			(x) => !x.actual && x.date === lastQuarter.date
		);

		let divider = monthsDiff * 0.35;
		if (lastQuarterDataPointWithoutActuals) {
			chart
				.append("rect")
				.attr("x", width - width / divider)
				.attr("y", 0)
				.style("float", "right")
				.attr("width", `${width / divider}px`)
				.attr("height", height)
				.style("opacity", 0.2)
				.attr("z-index", -1)
				.attr("fill", "#E3E3E3");
		}
	}

	// Draw line chart
	if (showPricePerformance) {
		chart
			.append("path") // Add the valueline path.
			.style("stroke", "#26CAD3")
			.style("stroke-width", "2px")
			.attr("d", valueline(prices));
	}
	// Define the div for the tooltip
	if (d3.select("#tooltips-chart").size()) {
		d3.select("#tooltips-chart").remove();
	}
	d3.select("body")
		.append("div")
		.attr("id", "tooltips-chart")
		.attr("class", "chartist-tooltip");

	chart
		.append("g")
		.attr("class", "grid")
		.call(makeGridLineOnYaxis().tickSize(-width, 0, 0).tickFormat(""));

	chart
		.append("g") // Add the X Axis
		.attr("class", "x axis")
		.attr("class", "axisLabel")
		.attr("transform", "translate(0," + height + ")")
		.style("text-anchor", "start")
		.call(xAxis)
		.selectAll("text")
		.call(function (text) {
			text.each(function () {
				const self = d3.select(this);
				const s = self.text().split(" "); // get the text and split it
				self.text(""); // clear it out
				self
					.append("tspan") // insert two tspans
					.attr("x", 0)
					.attr("dy", `${xAxisTopMargin}px`)
					.style("text-anchor", "start")
					.text(s[0]);
				self
					.append("tspan")
					.style("text-anchor", "start")
					.attr("x", 0)
					.attr("dy", "15px")
					.text(s[1]);
			});
		});

	chart
		.append("g")
		.selectAll("dot")
		.data(earnings.filter((dd) => !isNaN(dd.estimate)))
		.enter()
		.append("circle")
		.attr("role", "estimatePoint")
		.attr("cx", function (d) {
			return x(d.date);
		})
		.attr("cy", function (d) {
			return y1(d.estimate);
		})
		.attr("r", 6)
		.on("touchstart", renderTooltip)
		.on("mouseover", renderTooltip)
		.on("mousemove", renderTooltip)
		.on("mouseout", D3EarningsChartUtils.hideTooltipPopover)
		.on("mouseleave", D3EarningsChartUtils.hideTooltipPopover)
		.attr("class", "ct-series-1 estimated");

	chart
		.append("g")
		.selectAll("dot")
		.data(earnings.filter((el) => !isNaN(el.actual)))
		.enter()
		.append("circle")
		.attr("role", "actualPoint")
		.attr("cx", function (d) {
			return x(d.date);
		})
		.attr("cy", function (d) {
			return y1(d.actual);
		})
		.attr("r", 6)
		.on("touchstart", renderTooltip)
		.on("mouseover", renderTooltip)
		.on("mousemove", renderTooltip)
		.on("mouseout", D3EarningsChartUtils.hideTooltipPopover)
		.on("mouseleave", D3EarningsChartUtils.hideTooltipPopover)
		.attr("class", "ct-series-0 actual");

	if (showPricePerformance && prices && prices.length > 0) {
		// render left Y axis
		let leftYAxisValue = leftYAxis
			.append("svg")
			.attr("width", margin.left)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("class", "y axis")
			.attr("class", "yaxisLabel")
			.attr("transform", "translate( 0," + margin.top + ")")
			.style("stroke-width", "0px")
			.style("fill", "#71747D")
			.call(yAxisLeft);

		leftYAxisValue
			.append("text")
			.attr("transform", "rotate(0)")
			.attr("y", -27)
			.attr("dy", "8px")
			.attr("x", `${margin.left - 10}px`)
			.text(`(${priceCurrency})`);

		leftYAxisValue.selectAll(".tick text").attr("x", margin.left - 10);
	}

	let rightYAxisValue = d3
		.select("#right-YAxis")
		.html("")
		.append("svg")
		.attr("width", margin.right)
		.attr("height", height + margin.top + margin.bottom)
		.append("g")
		.attr("class", "y axis")
		.attr("class", "yaxisLabel")
		.attr("transform", "translate( 0," + margin.top + ")")
		.style("stroke-width", "0px")
		.style("fill", "#71747D")
		.call(yAxisRight);

	rightYAxisValue
		.append("text")
		.attr("transform", "rotate(0)")
		.attr("y", -27)
		.attr("dy", "8px")
		.attr("x", "12px")
		.text(`(${earningCurrency})`);
	rightYAxisValue.selectAll(".tick text").attr("x", "12px");
	const handleToggle = () => {
		setPricePerformance(!showPricePerformance);
	};
	const onChartScroll = () => {
		if (d3.select("#tooltips-chart").size()) {
			D3EarningsChartUtils.hideTooltipPopover();
		}
	};
	return (
		<div id="d3earningsChartContainer">
			<Container id="chart-header">
				<Row className="priceToggle">
					<span className={`toggle-txt-label ${showPricePerformance && "checked"}`}>
						{t("Compare with price performance")}
					</span>
					<Switch
						checked={showPricePerformance}
						onChange={handleToggle}
						disableRipple
						defaultChecked
						className="switch-comp"
					/>
				</Row>
				<Row className="legend-container">
					<ul className="ct-legend">
						{legendNames.map((name, index) => {
							if (index === 2 && !showPricePerformance) return null;
							else
								return (
									<li key={index} class={`ct-series-${index}`}>
										{name}
									</li>
								);
						})}
					</ul>
				</Row>
			</Container>
			<div className="axes-labels">
				<span id="leftAxis">{!showPricePerformance ? "" : t("Price")}</span>
				<span id="rightAxis">{t("EPS")}</span>
			</div>
			<div id="earnings-chart">
				<div id="left-YAxis"></div>
				<div id="chart-container" ref={chartRef} onScroll={() => onChartScroll()}></div>
				<div id="right-YAxis"></div>
			</div>
		</div>
	);
};
export default React.memo(D3EarningChart);

import { memo, useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./header.scss";
import QuoteCard from "components/Header/Quote/QuoteCard";
import About from "components/About/About";
import Navbar from "components/Header/Navbar";
import useQuote from "components/hooks/useQuote";
import { SymbolContext } from "components/hooks/useSymbol";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import header_bg from "assets/img/profile.jpg";
import FEATURE_FLAGS from "utils/feature-flags";
import { AuthContext } from "components/hooks/useAuthentication";
import Search from "components/Search/Search";
import { handleWindowResize, getDeviceType, deviceType } from "utils/utils";
import MobileSearch from "./MobileSearch/MobileSearch";
import Banner from "components/Banner/Banner";

const HeaderSection = memo(styled.section`
	background: url(${window.MD && window.MD.CDN_DOMAIN}${header_bg}) left top no-repeat;
	@media (min-width: 1441px) {
		background-size: 100% ${(props) => (props.isAuthenticated ? "581px" : "391px")};
	}
	@media (min-width: 1025px) and (max-width: 1440px) {
		background-size: 100% ${(props) => (props.isAuthenticated ? "581px" : "454px")};
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		background-size: 100% ${(props) => (props.isAuthenticated ? "581px" : "326px")};
	}
	@media only screen and (max-width: 767px) {
		background-size: 100% ${(props) => (props.isAuthenticated ? "581px" : "267px")};
	}
`);
const Header = () => {
	const { t } = useTranslation();
	const {
		symbol,
		venueXid,
		equityName,
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
		loading: loadingSymbol,
	} = useContext(SymbolContext);
	const { quote, error, loading, fetchedAt } = useQuote(venueXid);
	const { isAuthenticated } = useContext(AuthContext);
	const history = useHistory();
	const [device, setDevice] = useState(getDeviceType());

	useEffect(() => {
		handleWindowResize(resizeHandler);
	}, []);

	const resizeHandler = (deviceName) => {
		setDevice(deviceName);
	};

	const onBackToMarketOverview = () => {
		sessionStorage.removeItem("venueXid");
		history.push(`/`);
	};

	return (
		<header>
			<Banner />
			<HeaderSection
				isAuthenticated={isAuthenticated}
				data-testid="headerSection"
				className="header-background header-profile"
			>
				<Container>
					<Navbar></Navbar>
				</Container>
				<Container className="mt-44 mt-32pt5-tab mt-31pt5-mob">
					<nav className="nav searchAdjust">
						<span
							className="nav-link cursor-pointer"
							aria-label={t("Back to Market Overview")}
							data-testid="back-to-market-overview-btn"
							onClick={onBackToMarketOverview}
						>
							<span
								className="nav-link mr-2 d-lg-inline text-gray-600 small back-to-overview"
								data-testid="back-to-market-overview"
							>
								<i className="fa fa-solid fa-arrow-left marketoverview"></i>
								{t("Back to Market Overview")}
							</span>
						</span>
						<span className="searchDiv">
							{device !== deviceType.Mobile ? (
								<Search
									setSymbol={setSymbol}
									isProfile={true}
									setVenueXid={setVenueXid}
									setInstrumentXid={setInstrumentXid}
									setEquityShortName={setEquityShortName}
									setEquityName={setEquityName}
									setWSODCompany={setWSODCompany}
									loading={loading}
								/>
							) : (
								<MobileSearch />
							)}
						</span>
					</nav>
				</Container>
				<Container className="mb-30 mt-62 mt-44-pt-5-tab mt-30-pt-5-mob mb-10-mob">
					<Row>
						<Col className="equityShortName">
							<h4 className="text-white mb-0 text-uppercase">
								{!loadingSymbol && <div className="header-symbol">{symbol}</div>}
							</h4>
						</Col>
					</Row>
					<Row className="mt-12 mt-4-tab mt-2-mob">
						<Col>
							<h1 className="text-white text-uppercase">
								{!loadingSymbol && equityName}
							</h1>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<QuoteCard
								data={quote}
								error={error}
								loading={loading}
								fetchedAt={fetchedAt}
							/>
						</Col>
					</Row>
				</Container>
				{FEATURE_FLAGS.ABOUT_SECTION && (
					<Container className="pt-20" id="about">
						<Row>
							<Col>
								<About venueXid={venueXid} />
							</Col>
						</Row>
					</Container>
				)}
			</HeaderSection>
		</header>
	);
};

export default Header;

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useInstitutionsSummary = (instrumentXid) => {
	const [percentShare, setPercentShare] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const apiUrl = `${Constants.URLS.BIGDOUGH_INSTITUTIONSSUMMARY}${instrumentXid}`;

	useEffect(() => {
		if (!instrumentXid) return;
		setLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.insiders) {
					let percent = response.data.data;
					setPercentShare(percent);
				} else {
					setPercentShare(null);
					throw response;
				}
			} catch (e) {
				setPercentShare(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [instrumentXid]);

	return {
		percentShare,
		error,
		loading,
		fetchedAt,
	};
};

export default useInstitutionsSummary;

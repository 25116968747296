import { useState, useEffect } from "react";
import Constants from "../../utils/constants";
import RequestService from "../../services/RequestService";
import Utils from "../../utils/utils";

const useQuote = (venueXid) => {
	const [quote, setQuote] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(null);
	const [asofDate, setAsofDate] = useState();
	const apiUrl = `${Constants.URLS.QUOTE}?venueXids=${venueXid}&marketHoursTradeType=normal`;

	useEffect(() => {
		if (venueXid === null) {
			setLoading(false);
		}
		if (!venueXid) return;
		setLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl, {
					headers: {
						realtime: false,
					},
				});

				if (response.status === 200 && response?.data?.data?.quotes?.length > 0) {
					if (response.data.data.quotes.length > 1) {
						setQuote(response.data.data.quotes);
						setAsofDate(response.data.data.quotes[0].data.ask.date);
					} else {
						setQuote(response.data.data.quotes.find((_f) => true).data);
					}
					let { date } = response.data.data.quotes.find((_f) => true)?.data.lastTrade;
					setFetchedAt(Utils.getHKTTime(date));
				} else {
					setQuote(null);
					throw response;
				}
			} catch (e) {
				setQuote(null);
				setError(e);
			} finally {
				setLoading(false);
			}
		}
		init();
	}, [venueXid]);

	return { quote, error, loading, fetchedAt, asofDate };
};

export default useQuote;

import React, { useEffect, useState, useRef } from "react";
import "../ChartingCard/ChartingCard.scss";
import formatter from "utils/formatUtils";
import { PRICE_FORMATS } from "components/Charting/Constants/ChartConstants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "locale/CommonLocale";

const CrosshairBoard = React.memo((props) => {
	const chart = props.chart;
	let activeState = props.activeValue;
	const { t } = useTranslation();
	let divRef = useRef(null);
	let [isVisible, setIsVisible] = useState(false);
	let [hoverValues, setHoverValues] = useState({
		date: null,
		time: null,
		high: null,
		low: null,
		open: null,
		close: null,
		volume: null,
	});
	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("CROSSHAIR_MOVE", updateBoard);
			chart.eventEmitter.on("FLAG_HIDE_CROSSHAIR", hideFlag);
			chart.eventEmitter.on("FLAG_SHOW_CROSSHAIR", showFlag);
		}
		return () => unregisterChartEvents();
	});

	const unregisterChartEvents = () => {
		if (chart) {
			chart.eventEmitter.off("CROSSHAIR_MOVE", updateBoard);
			chart.eventEmitter.off("FLAG_HIDE_CROSSHAIR", hideFlag);
			chart.eventEmitter.off("FLAG_SHOW_CROSSHAIR", showFlag);
		}
	};
	const hideFlag = () => {
		const el = divRef;
		if (el && el.current) {
			el.current.style.display = "none";
		}
	};
	const showFlag = () => {
		const el = divRef;
		if (el && el.current) {
			el.current.style.display = "block";
		}
	};

	const updateBoard = (args) => {
		if (!isVisible) setIsVisible(true);
		if (chart.panels[0].isNormalized) activeState = PRICE_FORMATS.PERCENT.value;
		else activeState = PRICE_FORMATS.ABSOLUTE.value;

		if (args.activePanel.isUpper) {
			setHoverValues({
				date: moment(formatter.date(args.date, "DD MMM YYYY"))
					.locale(t("lang"))
					.format(t("formatHolding")),
				time: moment(args.date).format("HH:mm") + " (" + t("HKT") + ")",
				open:
					activeState === PRICE_FORMATS.ABSOLUTE.value
						? formatter.number(args.open, 3)
						: formatValue(args.open),
				high:
					activeState === PRICE_FORMATS.ABSOLUTE.value
						? formatter.number(args.high, 3)
						: formatValue(args.high),
				low:
					activeState === PRICE_FORMATS.ABSOLUTE.value
						? formatter.number(args.low, 3)
						: formatValue(args.low),
				close:
					activeState === PRICE_FORMATS.ABSOLUTE.value
						? formatter.number(args.close, 3)
						: formatValue(args.close),
				volume: formatter.number(args.volume),
			});
		}
	};

	const formatValue = (val) => {
		if (chart.panels[0].isNormalized) {
			return formatter.number(chart.getActualFromNormalized(val), 2);
		} else {
			return formatter.number(val, 2);
		}
	};

	return (
		isVisible && (
			<div ref={divRef} id="tooltip">
				<div tabIndex="0" className={"crosshairBoard"}>
					<div className="detail">
						<p align="left">
							<b>{t("Date")}:</b>
						</p>
						<p align="left">{hoverValues.date}</p>
					</div>
					{hoverValues.time && hoverValues.time !== "00:00 (HKT)" && (
						<div className="detail">
							<p align="left">
								<b>{t("Time")}:</b>
							</p>
							<p align="left">{hoverValues.time}</p>
						</div>
					)}
					<div className="detail">
						<p align="left">
							<b>{t("open")}:</b>
						</p>
						<p align="left">{hoverValues.open}</p>
					</div>
					<div className="detail">
						<p align="left">
							<b>{t("High")}:</b>
						</p>
						<p align="left">{hoverValues.high}</p>
					</div>
					<div className="detail">
						<p align="left">
							<b>{t("Low")}:</b>
						</p>
						<p align="left">{hoverValues.low}</p>
					</div>
					<div className="detail">
						<p align="left">
							<b>{t("Close")}:</b>
						</p>
						<p align="left">{hoverValues.close}</p>
					</div>
					<div className="detail">
						<p align="left">
							<b>{t("volume")}:</b>
						</p>
						<p align="left">{hoverValues.volume}</p>
					</div>
				</div>
			</div>
		)
	);
});

export default CrosshairBoard;

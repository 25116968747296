import { useEffect, useContext, createContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import XRefDataByVenueXid from "services/RequestUtils/XRef/XRefDataByVenueXid";
import { SymbolContext } from "components/hooks/useSymbol";
import ShellUtils from "../../utils/shell-utils";
import ReactGA from "react-ga";
import { LanguageContext } from "components/hooks/useLanguage";
import { useLocation } from "react-router";
import { AuthContext } from "components/hooks/useAuthentication";
import Utils from "utils/utils";
const PageContext = createContext();

const getSupplementalDataValue = (supplementalData) =>
	supplementalData && supplementalData.length > 0 ? supplementalData[0] : { value: null };

const PageProvider = ({ children }) => {
	const {
		symbol,
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
		setLoading,
	} = useContext(SymbolContext);
	const location = useLocation();
	const { selectedLangParam } = useContext(LanguageContext);
	const { setAuthenticatedUserDetails, logOut } = useContext(AuthContext);
	const { venueXid } = useParams();
	const history = useHistory();
	useEffect(() => {
		//Called on page refresh
		const privateToken = localStorage.getItem("mdtokenPrivate");
		if (privateToken) {
			if (Utils.IsAuthTokenValid()) {
				setAuthenticatedUserDetails();
			} else {
				logOut();
			}
		}
		async function init() {
			if (!venueXid) return;
			setLoading(true);
			const xrefResponse = await XRefDataByVenueXid.getXrefDataByVenueXid(venueXid);
			if (xrefResponse && xrefResponse.data && xrefResponse.data.data) {
				const { items } = xrefResponse.data.data;
				if (items) {
					let { symbol, xids, name, supplementalData } = items[0];
					let { value } = getSupplementalDataValue(supplementalData);
					let { venue, instrument, entity } = xids;
					setSymbol(symbol);
					setVenueXid(venue);
					setInstrumentXid(instrument);
					setEquityShortName(value);
					setEquityName(name);
					setWSODCompany(entity);
				}
			} else {
				history.push(`/`); //Invalid Venue Xid
			}
			setLoading(false);
		}
		if (!symbol) {
			initializeGlobals();
			init();
		}
	}, []);
	useEffect(() => {
		const handleLogoutFromMultipleTabs = (e) => {
			if (e.key === "logoutAt") {
				logOut();
			}
		};
		window.addEventListener("storage", handleLogoutFromMultipleTabs);
		return function cleanup() {
			window.removeEventListener("storage", handleLogoutFromMultipleTabs);
		};
	}, [logOut]);
	useEffect(() => {
		ReactGA.pageview(location.pathname + selectedLangParam);
	}, []);

	const initializeGlobals = async () => {
		if (!Utils.IsAuthTokenValid()) {
			if (!Utils.IsPublicTokenValid()) {
				await Utils.fetchPublicToken();
			}
		}
	};
	return <PageContext.Provider>{children}</PageContext.Provider>;
};

export default PageProvider;

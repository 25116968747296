import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useResearchConsensus = (venueXid) => {
	const [researchConsensus, setResearchConsensus] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const [asofDate, setAsofDate] = useState();
	const apiUrl = `${Constants.URLS.FACTSET_RESEARCH_CONSENSUS}${venueXid}`;

	useEffect(() => {
		if (!venueXid) return;
		setLoading(true);
		setError(null);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.recommendation) {
					let researchData = response.data.data.recommendation;
					let date = response.data.data.recommendation.consensusStartDate;
					setAsofDate(date);
					setResearchConsensus(researchData);
				} else {
					setResearchConsensus(null);
					throw response;
				}
			} catch (e) {
				setResearchConsensus(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [venueXid]);

	return { researchConsensus, error, loading, fetchedAt, asofDate };
};

export default useResearchConsensus;

import { useEffect, useRef, useState } from "react";
import { select } from "d3-selection";
import { geoPath, geoNaturalEarth1 } from "d3-geo";
import { scaleLinear } from "d3-scale";
import { min, max } from "d3-array";
import "./GeoChart.scss";
import useInstitutionsAggregates from "components/hooks/BigDough/useInstitutionsAggregates";
import useResizeObserver from "components/hooks/useResizeObserver";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import Utils from "../../utils/utils";
import AggregateTypes from "Constants/AggregateTypes";
import formatter from "utils/formatUtils";
import Error from "components/Error/Error";
import useWindowResize from "components/hooks/useWindowResize";
import Skeleton from "react-loading-skeleton";
let data = {};

if (!process.env.ENVIRONMENT) {
	data = require(`assets/geochart.geo.json`);
} else {
	data = require(`${window.MD && window.MD.CDN_DOMAIN}/geochart.geo.json`);
}

const geopoliticalRegionsMapping = [
	{
		contient: "Asia",
		continent_wb: "Middle East & North Africa",
		geopoliticalRegion: "Middle East",
	},
	{
		contient: "Oceania",
		continent_wb: "East Asia & Pacific",
		geopoliticalRegion: "Pacific",
	},
	{
		contient: "Asia",
		continent_wb: "Europe & Central Asia",
		geopoliticalRegion: "Europe",
	},
	{
		contient: "North America",
		continent_wb: "Latin America & Caribbean",
		geopoliticalRegion: "C. America/Caribbean",
	},
];

const GeoChart = ({ instrumentXid }) => {
	const [size, setSize] = useState(window.innerHeight, window.innerWidth);
	const svgRef = useRef();
	const wrapperRef = useRef();
	const dimensions = useResizeObserver(wrapperRef);
	useWindowResize(setSize);
	const [regionColors, setRegionColors] = useState();
	const { institutionsAggregates, loading, error } = useInstitutionsAggregates(
		instrumentXid,
		AggregateTypes.Region
	);

	const [currentDate, setCurrentDate] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		if (!loading && (!institutionsAggregates || institutionsAggregates.length === 0))
			return;
		const svg = select(svgRef.current);
		// create a first guess for the projection
		const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();
		//get min and Max
		const minProp = min(
			institutionsAggregates,
			(feature) => feature.percentHeldInstitutional
		);
		const maxProp = max(
			institutionsAggregates,
			(feature) => feature.percentHeldInstitutional
		);

		const range1 = loading ? "#E3E3E3" : "#D3E6F7";
		const range2 = loading ? "#E3E3E3" : "#13426B";

		const colorScale = scaleLinear().domain([minProp, maxProp]).range([range1, range2]);

		setRegionColors(
			institutionsAggregates.map(({ name, percentHeldInstitutional }) => ({
				name,
				color: colorScale(percentHeldInstitutional),
			}))
		);

		let scale = 120;
		let center = [30, 10];
		if (width < 400) scale = 60;
		else if (width < 500) scale = 68;
		else if (width > 500 && width < 600) {
			scale = 90;
		} else if (width > 500 && width < 768) {
			scale = 110;
			center = [40, 5];
		}

		//first make projection
		var projection = geoNaturalEarth1()
			.scale(scale)
			.center(center)
			.translate([width / 2, height / 2]);

		//takes geojson data transforms that into the d attribute of a path element
		const pathGenerator = geoPath().projection(projection);
		svg
			.selectAll(".country")
			.data(data.features)
			.attr("width", width)
			.attr("height", height)
			.join("path")
			.attr("class", "country")
			.attr("fill", (feature) => getColor(feature, colorScale))
			.attr("d", (feature) => pathGenerator(feature))
			.style("stroke", (feature) => getColor(feature));

		setCurrentDate(Utils.getHKTTime());
	}, [size, loading]);

	const getColor = (feature, colorScale) => {
		try {
			//check if the region is geo politicaly different
			let geoRegion = geopoliticalRegionsMapping.find(
				(f) =>
					f.contient === feature.properties["continent"] &&
					f.continent_wb === feature.properties["region_wb"]
			);

			let value = institutionsAggregates.find(
				(f) => f.name === feature.properties["continent"]
			);

			if (geoRegion) {
				value = institutionsAggregates.find(
					(f) => f.name === geoRegion.geopoliticalRegion
				);
			}

			if (value) {
				let selectedColor = colorScale(value.percentHeldInstitutional);
				return selectedColor;
			}
		} catch (e) {
			console.log(e);
		}
		if (loading) return "#E3E3E3";
		return "#D3E6F7";
	};

	const renderErrorMessage = (error) => {
		let errorMessage = "There was a problem loading institution region data.";
		if (error && error.response && error.response.status === 404)
			errorMessage = "There is currently no institution region data available.";

		return <Error errorMessge={t(errorMessage)}></Error>;
	};

	return (
		<>
			<Card>
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Institution Region")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				<Card.Body className="card-body-map">
					{error && renderErrorMessage(error)}
					{institutionsAggregates && (
						<>
							<div ref={wrapperRef} className="svg-container">
								<svg viewBox={`0 0`} ref={svgRef}></svg>
							</div>
							<div className="region-summary-cards">
								{loading &&
									[1, 2, 3, 4, 5].map((region) => {
										return (
											<div key={region} className="summary-card">
												<div className="summary-card-body">
													<div>
														<div className="region-summary-label">
															<Skeleton className="region-label-skeleton" />
														</div>
														<div className="region-summary-value">
															<Skeleton className="region-label-skeleton" />
														</div>
													</div>
												</div>
											</div>
										);
									})}
								{institutionsAggregates?.map((region) => {
									let progressBarValue = region.percentHeldInstitutional * 100;
									const convertedPercentValue = formatter.percent(progressBarValue, 2);
									const isPercentHeldZero = convertedPercentValue !== "0.00%";
									let regionColor =
										regionColors && regionColors.find((f) => f.name === region.name);
									return (
										isPercentHeldZero && (
											<div key={region.name} className="summary-card">
												<div className="summary-card-body">
													{!loading ? (
														<div
															className="region-summary-dot"
															style={{
																backgroundColor: regionColor && regionColor.color,
															}}
														></div>
													) : (
														""
													)}
													<div>
														<div className="region-summary-label">
															{region.name && region.name.replace(/\//g, " /")}
														</div>
														<div className="region-summary-value">
															{convertedPercentValue}
														</div>
													</div>
												</div>
											</div>
										)
									);
								})}
							</div>
						</>
					)}
					<Row className="mt-20 mt-xs-10">
						<Col>
							{loading && <Skeleton className="style-date-skeleton" />}
							{!loading && (
								<span className="hkt-time">{t("Data source: S&P Global")}</span>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default GeoChart;

// Find the a high point for the chartist graph
const setSmallChartValueHighPoint = (maxValue) => {
	let result = undefined;
	if (maxValue <= 0.03) {
		result = 0.04;
	} else if (maxValue > 0.03 && maxValue <= 0.04) {
		result = 0.05;
	} else if (maxValue > 0.04 && maxValue <= 0.07) {
		result = 0.08;
	} else if (maxValue > 0.07 && maxValue <= 0.09) {
		result = 0.1;
	} else if (maxValue > 0.09 && maxValue <= 0.11) {
		result = 0.12;
	} else if (maxValue > 0.11 && maxValue <= 0.14) {
		result = 0.15;
	} else if (maxValue > 0.14 && maxValue <= 0.18) {
		result = 0.2;
	} else if (maxValue > 0.18 && maxValue <= 0.22) {
		result = 0.25;
	} else if (maxValue > 0.22 && maxValue <= 0.27) {
		result = 0.3;
	} else if (maxValue > 0.27 && maxValue < 0.37) {
		result = 0.4;
	} else if (maxValue >= 0.37 && maxValue < 0.45) {
		result = 0.5;
	} else if (maxValue >= 0.45 && maxValue < 0.55) {
		result = 0.6;
	} else if (maxValue >= 0.55 && maxValue < 0.7) {
		result = 0.75;
	} else if (maxValue >= 0.7 && maxValue < 0.85) {
		result = 1;
	} else if (maxValue >= 0.85 && maxValue < 1.1) {
		result = 1.25;
	} else if (maxValue >= 1.1 && maxValue < 1.4) {
		result = 1.5;
	} else if (maxValue >= 1.4 && maxValue < 1.9) {
		result = 2;
	} else if (maxValue >= 1.9 && maxValue < 2.3) {
		result = 2.5;
	} else if (maxValue >= 2.3 && maxValue < 2.8) {
		result = 3;
	} else if (maxValue >= 2.8 && maxValue < 3.3) {
		result = 3.5;
	} else if (maxValue >= 3.3 && maxValue < 3.7) {
		result = 4;
	} else if (maxValue >= 3.7 && maxValue < 4.2) {
		result = 4.5;
	} else if (maxValue >= 4.2 && maxValue < 4.7) {
		result = 5;
	} else if (maxValue >= 4.7 && maxValue < 5.6) {
		result = 6;
	} else if (maxValue >= 5.6 && maxValue < 6.6) {
		result = 7;
	} else if (maxValue >= 6.6 && maxValue < 7.5) {
		result = 8;
	} else if (maxValue >= 7.5 && maxValue < 9.4) {
		result = 10;
	} else {
		result = maxValue;
	}
	return result;
};

const findYAxisHigh = (maxValue) => {
	const digitObj = {
		1: 0.01,
		2: 0.1,
		3: 1,
		4: 10,
		5: 100,
		6: 1000,
		7: 10000,
		8: 100000,
		9: 1000000,
		10: 10000000,
	};
	let result;
	if (maxValue < 9.4) {
		result = setSmallChartValueHighPoint(maxValue);
	} else {
		let newUnroundedHigh = maxValue * 1.2;
		let roundingHigh = maxValue.toFixed(2);
		let strippedNumber = roundingHigh.toString().replace(",", "").replace(".", "");
		let lengthOfNumber = parseInt(strippedNumber, 10).toString().length;
		let multiplier = digitObj[lengthOfNumber];
		let newHigh = Math.ceil(newUnroundedHigh / multiplier) * multiplier;
		let edgeCaseCheck = newHigh / multiplier;
		switch (edgeCaseCheck) {
			case 9:
			case 11:
			case 13:
				result = newHigh - multiplier;
				break;
			default:
				result = newHigh;
		}
	}
	return result.toFixed(2);
};

export default {
	findYAxisHigh,
};

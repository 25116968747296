import React from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InfoIconToolTip from "../../assets/img/InfoIconGray.svg";
import InfoIcon from "../../assets/img/InfoIconBlue.svg";

const FundamentalDetails = ({ loading, quote, label, children }) => {
	const { t } = useTranslation();
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	const renderOverlay = (
		<Popover className="market-cap-popover-container">
			<Popover.Body className="hk-peer-popover">
				<div className="error-popover" data-testid="error-popover">
					<div>
						<div className="">
							<img src={InfoIconToolTip} alt="Alert" />
						</div>
					</div>
					<div className="market-cap-info-text">{t("market-cap-info")}</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="fundamental-row">
			{loading || quote?.length < 1 ? (
				<Skeleton className="fundamental-detail-skeleton" />
			) : (
				<>
					<div className="text-gray-dark lable">
						{t(label)}
						{label == "market-cap" && (
							<OverlayTrigger placement="top" overlay={renderOverlay}>
								<img
									src={`${cdnDomain}${InfoIcon}`}
									alt="Market Cap Info"
									data-testid="Market-cap-info"
									className="marketCapInfoIconBlue"
								/>
							</OverlayTrigger>
						)}
					</div>
					<div className="text-gray-dark" data-testid="fundamental-value-testid">
						{children}
						{!children && <span>--</span>}
					</div>
				</>
			)}
		</div>
	);
};

export default FundamentalDetails;

import React, { useState, useRef, useEffect } from "react";
import SearchHelper from "../../../Search/Search-utils";
import clearSearchIcon from "assets/img/clear_search.svg";
import searchIcon from "assets/img/search_icon.svg";

const CompanySearch = React.forwardRef(
	({ companyCodeEntered, setCompanyCodeEntered, checkInput, inputUpdate, t }, ref) => {
		let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
		const [searchBoxExpanded, setSearchBoxExpanded] = useState(false);
		const [companySelected, setCompanySelected] = useState(false);
		const searchInputRef = useRef(null);
		const [nodata, setNodata] = useState(false);
		const [filteredCompany, setFilteredCompany] = useState([]);
		useEffect(() => {
			// To avoid race condition used activeCall flag to handle effect
			let activeCall = true;
			async function getXrefData(params) {
				const options = await SearchHelper.getXrefData(companyCodeEntered, 6);
				if (activeCall && !companySelected) {
					setSearchBoxExpanded(true);
					setNodata(false);
					options && options.length > 0
						? setFilteredCompany(options)
						: setFilteredCompany([]);
				}
				if (options?.length === 0 && companyCodeEntered !== "") {
					setNodata(true);
				}
			}
			setSearchBoxExpanded(false);
			getXrefData();
			return () => {
				activeCall = false;
				setCompanySelected(false);
			};
		}, [companyCodeEntered]);
		useEffect(() => {
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					setSearchBoxExpanded(false);
				}
			};
			document.addEventListener("click", handleClickOutside, true);

			return () => {
				document.removeEventListener("click", handleClickOutside, true);
				setCompanyCodeEntered({});
				setFilteredCompany({});
			};
		}, []);
		const clearInput = (e) => {
			if (nodata) {
				setCompanyCodeEntered("");
			}
			if (
				!companySelected &&
				e.relatedTarget &&
				e.relatedTarget.className !== "search-results-item"
			) {
				setCompanyCodeEntered("");
			}
		};
		const getHighlightedText = (text) => {
			let searchKey = companyCodeEntered?.replace(/[^\w\s]/gi, "");
			const parts = text?.split(new RegExp(`(${searchKey})`, "gi"));
			return (
				<span>
					{parts.map((part, index) =>
						part.toLowerCase() === searchKey.toLowerCase() ? (
							<b key={index}>{part}</b>
						) : (
							part
						)
					)}
				</span>
			);
		};

		const clearSearchInput = () => {
			setFilteredCompany([]);
			setCompanyCodeEntered("");
		};
		return (
			<>
				<div className="search">
					<img
						className="search-Icons-signup"
						aria-hidden="true"
						src={`${cdnDomain}${searchIcon}`}
						alt="search"
						onClick={() => {
							searchInputRef.current.focus();
						}}
					></img>
					<input
						autoComplete="off"
						ref={searchInputRef}
						type="text"
						id="company-signup"
						data-testid="SearchInput"
						name="companyCodeEntered"
						value={companyCodeEntered}
						onChange={(e) => {
							setSearchBoxExpanded(true);
							inputUpdate(e, "companyCodeEntered");
						}}
						placeholder={t("Company name or stock code*")}
						onBlur={(e) => {
							clearInput(e);
							checkInput(e);
						}}
					/>
					{searchBoxExpanded && companyCodeEntered != "" && (
						<img
							className="search-clearIcon"
							aria-hidden="true"
							src={`${cdnDomain}${clearSearchIcon}`}
							alt="clear search"
							onClick={clearSearchInput}
						></img>
					)}
				</div>

				{searchBoxExpanded && companyCodeEntered != "" && filteredCompany.length > 0 && (
					<div className="searchResult" role="list">
						{filteredCompany.map((value) => {
							return (
								<div
									key={value.xids.venue}
									className="search-results-item"
									aria-label={value.name}
									role="listitem"
									tabIndex="6"
									data-testid="search_Option"
									name="companyCodeSelected"
									onClick={() => {
										setSearchBoxExpanded(false);
										setCompanySelected(true);
										inputUpdate(value, "companyCodeSelected");
									}}
								>
									<div md={2} className="symbol">
										{getHighlightedText(value.symbol)}
									</div>
									<div className="equityName" md={10} key={value.id}>
										{getHighlightedText(value.name)}
									</div>
								</div>
							);
						})}
					</div>
				)}
				{nodata && (
					<div className="searchResult">
						<div className="search-results-item">{t("No results found")}</div>
					</div>
				)}
			</>
		);
	}
);

export default CompanySearch;

import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./Disclaimers.scss";
import { useTranslation } from "react-i18next";

const Disclaimers = () => {
	const { t } = useTranslation();
	return (
		<Card className="bg-gray disclaimerContainer inherit">
			<Card.Header className="bg-gray hk-sectionhdr disclaimer-header">
				<span>{t("Disclaimers")}</span>
			</Card.Header>
			<Card.Body className="disclaimers-cardbody">
				<Row>
					<Col dangerouslySetInnerHTML={{ __html: t("DisclaimersText") }} />
				</Row>
			</Card.Body>
		</Card>
	);
};

export default Disclaimers;

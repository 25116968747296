import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Error.scss";
import InfoIcon from "assets/img/InfoIcon.png";
const Error = ({ errorMessge, bgClass }) => {
	return (
		<Row className={`errorInfo ${bgClass}`}>
			<Col className="d-flex">
				<img src={InfoIcon} alt="" className="infoIcon" />
				<span className="color-message">{errorMessge}</span>
			</Col>
		</Row>
	);
};

export default Error;

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useInsidersList = (instrumentXid) => {
	const [insidersList, setInsidersList] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const apiUrl = `${Constants.URLS.BIG_DOUGH_INSIDERS}${instrumentXid}`;

	useEffect(() => {
		if (!instrumentXid) return;
		setLoading(true);
		setError(null);
		setInsidersList([]);
		setFetchedAt(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (
					response.status === 200 &&
					response?.data?.data?.insidersList &&
					response?.data?.data?.instrumentXid === instrumentXid
				) {
					let insidersListData = response.data.data.insidersList;
					setInsidersList(insidersListData);
				} else {
					setInsidersList(null);
					throw response;
				}
			} catch (e) {
				setInsidersList(null);
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [instrumentXid]);

	return { insidersList, error, loading, fetchedAt };
};

export default useInsidersList;

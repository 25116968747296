/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import "./CorporateCalender.scss";
import { useTranslation } from "react-i18next";
import Loader from "components/Lib/Loader/Loader";
import CorporateCalenderEvents from "./CorporateCalenderEvents/CorporateCalenderEvents";
import useCorporateCalenderEvents from "components/hooks/FactSet/useCorporateCalenderEvents";

const CorporateCalender = ({ venueXid, isPublicPage = false }) => {
	const { t } = useTranslation();
	const [carouselTab, setCarouselTab] = useState("upcoming-events");
	const { upcomingEvents, pastEvents, loading, errorUpcomingEvents, errorPastEvents } =
		useCorporateCalenderEvents(venueXid, isPublicPage);
	const [isPastEventTabSelected, setIsPastEventTabSelected] = useState(false);

	useEffect(() => {
		handleTabSelect("upcoming-events");
	}, [venueXid]);

	const handleTabSelect = async (tab, e) => {
		setCarouselTab(tab);
		switch (tab) {
			case "upcoming-events":
				setIsPastEventTabSelected(false);
				break;
			case "past-events":
				setIsPastEventTabSelected(true);
				break;
			default:
				setIsPastEventTabSelected(false);
				break;
		}
	};

	return (
		<>
			<Card className="corporate-calender">
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Corporate Calendar")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				<Card.Body className="card-body-corporate-calender">
					{!isPublicPage ? (
						<Tabs
							defaultActiveKey="upcoming-events"
							transition={false}
							className="events-tab"
							onSelect={handleTabSelect}
							activeKey={carouselTab}
						>
							<Tab eventKey="upcoming-events" title={t("Upcoming Events")}>
								{upcomingEvents && !isPastEventTabSelected && (
									<CorporateCalenderEvents
										eventsData={upcomingEvents}
										isPastEventTabSelected={false}
										handleTabSelect={handleTabSelect}
										carouselTab={carouselTab}
										error={errorUpcomingEvents}
										loading={loading}
									/>
								)}
							</Tab>
							<Tab eventKey="past-events" title={t("Past Events")}>
								{pastEvents && isPastEventTabSelected && (
									<CorporateCalenderEvents
										eventsData={pastEvents}
										isPastEventTabSelected={true}
										handleTabSelect={handleTabSelect}
										carouselTab={carouselTab}
										error={errorPastEvents}
										loading={loading}
									/>
								)}
							</Tab>
						</Tabs>
					) : (
						upcomingEvents && (
							<CorporateCalenderEvents
								eventsData={upcomingEvents.slice(0, 4)}
								isPublicPage={isPublicPage}
								error={errorUpcomingEvents}
								loading={loading}
							/>
						)
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default CorporateCalender;

import { Card } from "react-bootstrap";
import ChartingCard from "./ChartingCard/ChartingCard";
import "./Charting.scss";
import { useTranslation } from "react-i18next";
const Charting = ({
	venueXid,
	loggedin,
	symbol,
	peers,
	peersError,
	peersLoading,
	currency,
}) => {
	const { t } = useTranslation();
	return (
		<Card>
			<Card.Header className="bg-white hk-sectionhdr">
				<h2>{t("Performance")}</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			<Card.Body className="card-body-chart">
				<ChartingCard
					venueXid={venueXid}
					loggedin={loggedin}
					symbol={symbol}
					peers={peers}
					peersError={peersError}
					peersLoading={peersLoading}
					currency={currency}
				/>
			</Card.Body>
		</Card>
	);
};

export default Charting;

import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import "./ResearchRatingsTable.scss";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../ResearchRatingsConstant";
import Skeleton from "react-loading-skeleton";

const ResearchRatingsTable = ({
	data,
	sum,
	actionType,
	changeCssForIndex,
	setHoverRowName,
	loading,
}) => {
	const { t } = useTranslation();

	// Code to update css classes when mouseOver or mouseLeave on chart
	useEffect(() => {
		if (changeCssForIndex !== undefined && changeCssForIndex !== null) {
			var x = document
				.getElementById("researchRatingsTableBody")
				.getElementsByTagName("tr");
			// To remove the css from rest of the rows
			for (const element of x) {
				element.classList.remove("backgrnd");
			}
			// End To remove the css from rest of the rows
			x[changeCssForIndex].className = "backgrnd";
			if (actionType === "Leave") {
				x[changeCssForIndex].classList.remove("backgrnd");
			}
		}
	}, [actionType, changeCssForIndex]);
	// Code to update css classes when mouseOver or mouseLeave on chart

	const onRowEnter = (index) => {
		setHoverRowName(index, "Enter");
	};

	const onRowLeave = (index) => {
		setHoverRowName(index, "Leave");
	};

	return (
		<React.Fragment>
			<div>
				<Table
					striped
					responsive="sm"
					borderless
					id="researchRatingsTable"
					data-testid="researchRatingsTable"
					className="research-ratings-tbl"
				>
					<thead>
						<tr>
							<th className="first-col-wdth">{t("Rating")}</th>
							<th className="second-col-wdth">
								{t("Analysts")} ({sum})
							</th>
						</tr>
					</thead>
					<tbody id="researchRatingsTableBody">
						{loading &&
							[1, 2, 3, 4, 5].map(() => {
								return (
									<tr>
										<td aria-hidden="true" className="column-first">
											<div className="col-one">
												<div className="new-cls">
													<Skeleton
														circle
														width={"12px"}
														height={"12px"}
														style={{ marginRight: "12px" }}
													/>
												</div>

												<div className="name">
													<Skeleton className="research-table-skeleton" />
												</div>
											</div>
										</td>
										<td className="column-second">
											<Skeleton className="research-table-small-skeleton" />
										</td>
									</tr>
								);
							})}
						{data &&
							!loading &&
							data.map((item, index) => {
								return (
									<tr
										key={index}
										onMouseEnter={() => onRowEnter(index)}
										onMouseLeave={() => onRowLeave(index)}
										data-testid={"table-row" + (index + 1)}
									>
										<td aria-hidden="true" className="column-first">
											<div className="col-one">
												<div className="new-cls">
													<span
														style={{ background: CONSTANTS.tableColors[index] }}
														className="circle"
													></span>
												</div>

												<div className="name">{t(item.label)}</div>
											</div>
										</td>
										<td className="column-second">{item.value}</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</div>
		</React.Fragment>
	);
};

export default ResearchRatingsTable;

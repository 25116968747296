import { Card, Row, Col } from "react-bootstrap";
import FundamentalDetails from "./FundamentalDetails";
import "./Fundamentals.scss";
import FundamentalPriceSlider from "./FundamentalPriceSlider";
import formatter from "utils/formatUtils";
import useBasicFundamentals from "components/hooks/FactSet/useBasicFundamentals";
import useAdvancedFundamentals from "components/hooks/FactSet/useAdvancedFundamentals";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import utils from "utils/utils";
import moment from "moment";
import "locale/CommonLocale";

export default function Fundamentals({ venueXid, quote, error, loading, fetchedAt }) {
	const { basicFundamentals, basicLoading, basicError, revenueAsOfDate, adyAsofDate } =
		useBasicFundamentals(venueXid);
	const { advancedFundamentals, advanceLoading, advanceError, asOfDate } =
		useAdvancedFundamentals(venueXid);
	const { t } = useTranslation();

	if (error) return <div>error</div>;

	if (!quote && !loading && !basicLoading && !advanceLoading)
		return <div>{t("No data found")}</div>;

	const precisionFormatter = (val) => {
		switch (val) {
			case "0%":
				return "0.00%";
			case "HK$0":
				return "HK$0.00";
			case "0x":
				return "0.00x";
			default:
				return val;
		}
	};

	const dividendYieldVal = () => {
		return basicFundamentals.items.find((f) => f.measure === "DividendYield")
			? precisionFormatter(
					formatter.percent(
						basicFundamentals.items.find((f) => f.measure === "DividendYield").value,
						2
					)
			  )
			: "—";
	};

	const priceToEarningsVal = () => {
		return basicFundamentals.items.find((f) => f.measure === "PriceToEarnings")
			? precisionFormatter(
					formatter.priceWithRoundingAndSymbol(
						basicFundamentals.items.find((f) => f.measure === "PriceToEarnings").value,
						2
					)
			  )
			: "—";
	};

	return (
		<Card className="bg-gray fundamental-card">
			<Card.Header className="hk-sectionhdr hk-sectionhdr-fundamentals">
				<h4 data-testid="fundamentals">{t("Fundamentals")}</h4>
			</Card.Header>
			<Card.Body className="card-body card-body-tab">
				<div className="fundamental-container-col">
					<Row className="fundamental-row border-bottom-none fundamental-rangerow pb-10">
						<Col
							style={{
								paddingRight: "20px",
							}}
						>
							{loading || quote.length < 1 ? (
								<Skeleton className="fundamental-slider-skeleton" />
							) : (
								<FundamentalPriceSlider
									label={"Intra-Day"}
									value={quote.lastTrade.last}
									min={quote.lastTrade.low}
									max={quote.lastTrade.high}
								/>
							)}
						</Col>

						<Col className="pr-0">
							{loading || quote.length < 1 ? (
								<Skeleton className="fundamental-slider-skeleton" />
							) : (
								<FundamentalPriceSlider
									label={"52-Week"}
									value={quote.lastTrade.last}
									min={quote.price52Week.low}
									max={quote.price52Week.high}
								/>
							)}
						</Col>
					</Row>
					<FundamentalDetails loading={loading} quote={quote} label={"open"}>
						{formatter.priceWithRoundingAndSymbol(
							quote?.lastTrade?.open,
							3,
							quote?.currency?.symbol
						)}
					</FundamentalDetails>
					<FundamentalDetails loading={loading} quote={quote} label={"Bid"}>
						{formatter.priceWithRoundingAndSymbol(
							quote?.bid?.price,
							3,
							quote?.currency?.symbol
						)}
					</FundamentalDetails>
					<FundamentalDetails loading={loading} quote={quote} label={"Ask"}>
						{formatter.priceWithRoundingAndSymbol(
							quote?.ask?.price,
							3,
							quote?.currency?.symbol
						)}
					</FundamentalDetails>
				</div>
				<div className="fundamental-container-col">
					<FundamentalDetails loading={loading} quote={quote} label={"prev-close"}>
						{formatter.priceWithRoundingAndSymbol(
							quote?.lastTrade?.close,
							3,
							quote?.currency?.symbol
						)}
					</FundamentalDetails>
					<FundamentalDetails loading={loading} quote={quote} label={"volume"}>
						{formatter.getCompactFormattedLargeValue(quote?.volume?.last, 2)}
					</FundamentalDetails>
					<FundamentalDetails loading={loading} quote={quote} label={"market-cap"}>
						{formatter.priceWithRoundingAndSymbol(
							quote?.marketCap,
							2,
							quote?.currency?.symbol,
							false
						)}
					</FundamentalDetails>
					<FundamentalDetails loading={loading} quote={quote} label={"Lot Size"}>
						{formatter.getCompactFormattedLargeValue(quote?.contractSize, 0)}
					</FundamentalDetails>
					<div className="fundamental-row border-bottom border-gray">
						{advanceLoading ? (
							<Skeleton className="fundamental-detail-skeleton" />
						) : (
							<>
								<span>
									<strong>{t("EPS")}</strong>({t("TTM")})
								</span>
								<div>
									<span className="textData" data-testid="fundamental-eps-testid">
										{!advanceError &&
											advancedFundamentals &&
											precisionFormatter(
												formatter.priceWithRoundingAndSymbol(
													advancedFundamentals.value,
													4,
													advancedFundamentals.currency
												)
											)}
										{!advancedFundamentals && <span>--</span>}
									</span>
									<span className="asOfDate">
										{t("As of")}{" "}
										{moment(utils.getHKTTime(asOfDate))
											.locale(t("lang"))
											.format(t("format"))}
										{t("HKT")}
									</span>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="fundamental-container-col">
					<div className="fundamental-row">
						{basicLoading ? (
							<Skeleton className="fundamental-detail-skeleton" />
						) : (
							<>
								<div>
									<strong>{t("P/E")}</strong>({t("TTM")})
								</div>
								<div className="divData" data-testid="fundamental-pe-testid">
									<span className="textData">
										{!basicError &&
											basicFundamentals &&
											basicFundamentals.items &&
											priceToEarningsVal()}
										{!basicFundamentals && <span>--</span>}
									</span>
									<span className="asOfDate">
										{t("As of")}{" "}
										{moment(utils.getHKTTime(revenueAsOfDate))
											.locale(t("lang"))
											.format(t("format"))}{" "}
										{t("HKT")}
									</span>
								</div>
							</>
						)}
					</div>
					<div className="fundamental-row media-body border-bottom border-gray">
						{basicLoading ? (
							<Skeleton className="fundamental-detail-skeleton" />
						) : (
							<>
								<span className="label">
									<strong>{t("DIV Yield")}</strong>({t("ADY")})
								</span>
								<div className="divData">
									<span className="textData" data-testid="fundamental-dy-testid">
										{!basicError &&
											basicFundamentals &&
											basicFundamentals.items &&
											dividendYieldVal()}
										{!basicFundamentals && <span>--</span>}
									</span>
									<span className="asOfDate">
										{t("As of")}{" "}
										{moment(utils.getHKTTime(adyAsofDate))
											.locale(t("lang"))
											.format(t("format"))}{" "}
										{t("HKT")}
									</span>
								</div>
							</>
						)}
					</div>
				</div>
			</Card.Body>
			<Card.Footer>
				{loading ? (
					<Skeleton width={"250px"} height={"11px"} />
				) : (
					<div>
						<span className="hkt-time">{t("Data delayed at least 15 minutes.")}</span>
						<br />
						<span className="hkt-time">{t("Data source: FactSet")}</span>
						<br />
						<span className="hkt-time">
							{t("As of")} {moment(fetchedAt).locale(t("lang")).format(t("format"))}{" "}
							{t("HKT")}
						</span>
					</div>
				)}
			</Card.Footer>
		</Card>
	);
}

const CONSTANTS = {
	tableColors: [
		"#13426B",
		"#26CAD3",
		"#FFB81C",
		"#5E366E",
		"#FEDD00",
		"#445CBE",
		"#D25B73",
		"#B5E3D8",
		"#41748D",
		"#EF886F",
	],
};

export default CONSTANTS;

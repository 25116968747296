/* eslint-disable */
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./EarningsCard.module.scss";
import useEstimateEarning from "components/hooks/useEstimatesEarning";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import D3EarningChart from "./D3EarningChart";
import { useEffect, useState } from "react";
import { getDeviceType, handleWindowResize } from "utils/utils";
import moment from "moment";
import CONSTANTS from "utils/constants";
import D3EarningsChartUtils from "./D3EarningsChartUtils";
import RequestService from "services/RequestService";
import { deviceType } from "utils/utils";
const D3EarningsCard = ({ venueXid }) => {
	const { t } = useTranslation();
	const [width, setWidth] = useState(0);
	const [priceData, setPriceData] = useState([]);
	const [priceCurrency, setPriceCurrency] = useState("HK$");
	const [device, setDevice] = useState(getDeviceType());
	useEffect(() => {
		handleWindowResize(resizeHandler);
		resizeHandler(device);
	}, []);

	const { error, loading, fetchedAt, currency, earningData } = useEstimateEarning(
		venueXid,
		"QTR"
	);

	useEffect(() => {
		if (venueXid && earningData && earningData.length > 0) {
			let { startdate, enddate } = D3EarningsChartUtils.getEarningsDateRange(earningData);
			startdate = moment(startdate).subtract(45, "days");
			let updatedEndDate = moment(enddate).add(45, "days");
			enddate = new Date(Math.min(updatedEndDate, new Date()));

			fetchPriceSeriesData(startdate, enddate);
		}
	}, [venueXid, earningData]);

	const renderErrorMessage = (err) => {
		let errorMessage = "There was a problem loading earnings data.";
		if (
			(err && err.response && err.response.status === 404) ||
			earningData.length === 0
		) {
			errorMessage = "There is currently no earnings data available.";
		}

		return <Error errorMessge={t(errorMessage)}></Error>;
	};
	const fetchPriceSeriesData = async (fromDate, endDate) => {
		let requestParams = D3EarningsChartUtils.getSeriesPriceRequestParam(
			venueXid,
			fromDate,
			endDate
		);
		const apiInstance = await RequestService.init();
		try {
			const response = await apiInstance.post(
				`${CONSTANTS.URLS.CHARTWORKS_SERIES_PATH}chartapi/series`,
				requestParams
			);
			let prices = [];
			if (response && response.status == 200 && response.data) {
				const { Dates, Elements } = response.data;

				if (Elements && Elements.length > 0) {
					const priceElement = Elements[0];
					if (priceElement.Currency !== "HKD") setPriceCurrency(priceElement.Currency);
					const priceDataComponent = priceElement.ComponentSeries.find(
						(x) => x.Type === "Close"
					);
					if (
						Dates &&
						priceDataComponent &&
						priceDataComponent.Values &&
						Dates.length === priceDataComponent.Values.length
					) {
						Dates.map((seriesDate, index) => {
							let values = priceDataComponent.Values;
							prices.push({
								date: new Date(seriesDate),
								value: values[index],
							});
						});
						prices = prices.filter((x) => x.date >= fromDate && x.date <= endDate);
						setPriceData(prices);
					}
				}
			} else {
				throw response;
			}
		} catch (e) {
			setPriceData([]);
			setPriceCurrency("");
		}
	};

	const resizeHandler = (dev) => {
		if (dev === deviceType.Mobile) {
			setWidth(727);
		} else {
			let screenWidth = window.innerWidth;
			if (screenWidth >= 1440) setWidth(1300);
			else if (screenWidth >= 768) setWidth(screenWidth - 80);
		}
		setDevice(dev);
	};
	const getTime = () => {
		if (loading) {
			return <Skeleton width={"90%"} />;
		} else {
			return `${t("Data source: FactSet")}, ${t("As of")} ${moment(fetchedAt)
				.locale(t("lang"))
				.format(t("format"))} ${t("HKT")}`;
		}
	};

	return (
		<>
			<Card className="inherit">
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Earnings")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				<Card.Body className="card-body-earnings">
					<div className={"earningsChart"}>
						{loading ? (
							<Skeleton className="earnings-skeleton" />
						) : (
							<>
								{(error || earningData.length == 0) && (
									<div className="error-earnings">{renderErrorMessage(error)}</div>
								)}
								{!error && earningData && earningData.length > 0 && (
									<D3EarningChart
										earnings={earningData}
										prices={priceData}
										earningCurrency={currency}
										priceCurrency={priceCurrency}
										chartWidth={width}
										device={device}
									/>
								)}
							</>
						)}
					</div>
				</Card.Body>
				<Card.Footer>
					<span className="hkt-time">
						{loading ? <Skeleton width={"90%"} /> : t("Diluted earnings per share")}
					</span>
					<br />
					<span className="hkt-time">{getTime()}</span>
				</Card.Footer>
			</Card>
		</>
	);
};

export default D3EarningsCard;

import { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../hooks/useLanguage";
import { Container, Navbar as TopNav, Overlay, Popover } from "react-bootstrap";
import { AuthContext } from "components/hooks/useAuthentication";
import { SymbolContext } from "components/hooks/useSymbol";
import logo from "assets/img/logo_new.svg";
import short_logo from "assets/img/short_logo.svg";
import userIcon from "assets/img/userIcon.svg";
import "./Navbar.scss";
import "./UserProfile.scss";
import XRefAPIUtils from "services/RequestUtils/XRef/XRefAPIUtils";
import Login from "../Login/Login";
import Signup from "./Signup/Signup";
import faqicon from "../../assets/img/faq.svg";
import glossaryicon from "../../assets/img/glossary.svg";
import { getDeviceType, handleWindowResize, deviceType } from "utils/utils";

const Navbar = () => {
	const glossarydocEN = `${process.env.PUBLIC_URL}/documents/IR Connect Glossary.pdf`;
	const glossarydocCN = `${process.env.PUBLIC_URL}/documents/投资服务通词汇.pdf`;
	const glossarydocHK = `${process.env.PUBLIC_URL}/documents/投資服務通詞彙.pdf`;

	const faqdocEN = `${process.env.PUBLIC_URL}/documents/IR Connect FAQ.pdf`;
	const faqdocCN = `${process.env.PUBLIC_URL}/documents/投资服务通常见问题.pdf`;
	const faqdocHK = `${process.env.PUBLIC_URL}/documents/投資服務通常見問題.pdf`;

	const { isAuthenticated, userName, signInUrl, logOut } = useContext(AuthContext);
	const {
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
	} = useContext(SymbolContext);
	const [showUserInfo, setShowUserInfo] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [showSignup, setShowSignup] = useState(false);
	const [loginFlag, setLoginFlag] = useState(true);
	const { t } = useTranslation();
	const location = useLocation();
	const { search } = useLocation();
	const { venueXid } = useParams();
	const history = useHistory();
	const userNameRef = useRef(null);
	const [device, setDevice] = useState(getDeviceType());
	const lang = new URLSearchParams(search).get("lang");
	const { selectedLanguage, setLanguage, availableLang } = useContext(LanguageContext);
	const signUpRef = useRef();
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	async function getXrefData(venueXid) {
		const xrefResponse = await XRefAPIUtils.getXrefDataforVenueXids(venueXid);
		if (xrefResponse && xrefResponse.data && xrefResponse.data.data) {
			const data = xrefResponse.data.data;
			if (data) {
				const { symbol, xids, name, supplementalData } = data.items.find((x) => true);
				const { value } = supplementalData[0];
				const { venue, instrument, entity } = xids;
				setSymbol(symbol);
				setVenueXid(venue);
				setInstrumentXid(instrument);
				setEquityShortName(value);
				setEquityName(name);
				setWSODCompany(entity);
			}
		}
	}

	const navigateTo = async (urlPrefix, nextLanguage) => {
		let templocation = location;
		//get VenueXid From URL param

		if (venueXid) {
			templocation = `/${urlPrefix}/${venueXid}/?lang=${
				nextLanguage ? nextLanguage : selectedLanguage
			}`;
			await getXrefData(venueXid);
			history.push(templocation);
		}
	};

	useEffect(() => {
		//get username
		if (isAuthenticated) {
			navigateTo("profile", lang);
		}
		handleWindowResize(setDevice);
	}, []);

	const openLogin = () => {
		setLoginFlag(true);
		setShowLogin(true);
	};

	const closeLogin = () => {
		setShowLogin(false);
	};

	const closeSignup = () => {
		setShowSignup(false);
	};

	const logout = () => {
		logOut();
		navigateTo("public");
	};

	const handleChangeLanguage = (nextLanguage) => {
		setLanguage(nextLanguage);
		if (isAuthenticated) {
			navigateTo("profile", nextLanguage);
		} else {
			navigateTo("public", nextLanguage);
		}
	};

	const handleOnClick = (e) => {
		if (signUpRef.current) {
			e.preventDefault();
			signUpRef.current.onSubmit();
		}
	};
	const showIcon = () => {
		return device !== deviceType.LargeDesktop && device !== deviceType.SmallDesktop;
	};
	const getDocUrl = (docname) => {
		return `${window.MD && window.MD.CDN_DOMAIN}${docname}`;
	};

	const makeSignupVisible = () => {
		setShowSignup(true);
		setLoginFlag(false);
	};

	const makeLoginVisible = () => {
		setLoginFlag(true);
		openLogin();
	};

	useEffect(() => {
		if (device === deviceType.Mobile && showUserInfo) {
			document.body.style.overflow = "hidden";
		} else document.body.style.overflow = "auto";
	}, [showUserInfo, device]);
	const getFAQDoc = () => {
		switch (t("faqdoc")) {
			case "faqdocEN":
				return faqdocEN;
			case "faqdocCN":
				return faqdocCN;
			case "faqdocHK":
				return faqdocHK;
			default:
				return faqdocEN;
		}
	};
	const getGlossaryDoc = () => {
		switch (t("glossarydoc")) {
			case "glossarydocEN":
				return glossarydocEN;
			case "glossarydocCN":
				return glossarydocCN;
			case "glossarydocHK":
				return glossarydocHK;
			default:
				return glossarydocEN;
		}
	};
	return (
		<TopNav className={`navbar-dark ${isAuthenticated ? "loggedInNav" : ""}`}>
			<Container className="irconnectlogo">
				<span className="navbar-brand" tabIndex="1" id="logo" data-testid="logo">
					<img
						src={`${cdnDomain}${logo}`}
						alt="IR Connect Logo"
						aria-label="IR Connect Logo"
						className="d-inline-block align-text-top logo"
					/>
				</span>
			</Container>
			<Container>
				<div className="collapse navbar-collapse" id="navmenu">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item no-pointer">
							<a
								className="nav-link mr-2"
								role="button"
								aria-label="Glossary"
								tabIndex="2"
								href={getDocUrl(getGlossaryDoc())}
								target="_blank"
								aria-haspopup="false"
								aria-expanded="false"
								rel="noreferrer"
							>
								{showIcon() ? (
									<img
										src={glossaryicon}
										alt="no img"
										className="glossaryicon"
										data-testid="Glossary"
									/>
								) : (
									<span
										className="nav-link mr-2 d-lg-inline text-gray-600 small nav-bar-item-faq"
										data-testid="Glossary"
									>
										{t("Glossary")}
									</span>
								)}
							</a>
						</li>
						<li className="nav-item no-pointer">
							<a
								className="nav-link mr-2"
								role="button"
								aria-label="frequently asked questions"
								tabIndex="2"
								target="_blank"
								href={getDocUrl(getFAQDoc())}
								aria-haspopup="false"
								aria-expanded="false"
								rel="noreferrer"
							>
								{showIcon() ? (
									<img src={faqicon} alt="no img" className="faqicon" data-testid="faq" />
								) : (
									<span
										className="nav-link mr-2 d-lg-inline text-gray-600 small nav-bar-item-faq"
										data-testid="faq"
									>
										{t("FAQ")}
									</span>
								)}
							</a>
						</li>
						{availableLang &&
							availableLang.map((availablelang, i) => {
								return (
									<>
										<li
											className={`nav-item no-pointer ${
												i === 0 ? "padding-right10" : ""
											}`}
										>
											<button
												data-testid={`change-language${i + 1}`}
												className="nav-link mr-2 langBtn"
												aria-label={availablelang.description}
												tabIndex="2"
												aria-haspopup="false"
												aria-expanded="false"
												onClick={() => handleChangeLanguage(availablelang.lang)}
											>
												<span
													className="nav-link mr-2 d-lg-inline text-gray-600 small nav-bar-item-lang"
													data-testid="selected-Language"
												>
													{availablelang.symbol}
												</span>
											</button>
										</li>
										{i === 0 && (
											<li className="nav-item no-pointer padding-right10">
												<span className="seprater-Bar"></span>
											</li>
										)}
									</>
								);
							})}

						{!isAuthenticated && (
							<>
								<li
									className="nav-item d-flex userWrapper"
									data-testid="login-btn"
									onClick={openLogin}
								>
									<img
										src={userIcon}
										alt=""
										className="userIcon userIconLoggedOut marginRemove"
									/>
									<span
										className="nav-link userIconLink userIconLinkLoggedOut"
										data-testid="login-link"
										aria-label={t("Login")}
										onKeyDown={(event) => {
											if (event.key == "Enter") {
												openLogin();
											}
										}}
										tabIndex="3"
									>
										{t("Login")}
									</span>
								</li>
							</>
						)}
						{isAuthenticated && (
							<>
								<li className="nav-item d-flex userWrapper no-pointer" ref={userNameRef}>
									<img src={userIcon} alt="" className="userIcon" />
									<span
										className="nav-link userNameDesktop"
										id="navbarDropdownMenuLink"
										role="button"
										aria-expanded="false"
										aria-label={userName}
										data-testid="showUserPopover-btn"
										tabIndex="3"
										onClick={() => setShowUserInfo(!showUserInfo)}
									>
										{userName}
									</span>
									<div
										className="nav-link userInfo userNameMobile"
										onClick={() => setShowUserInfo(!showUserInfo)}
										id="navbarDropdownMenuLink"
										role="button"
										aria-expanded="false"
										data-testid="showUserPopoverMobile-btn"
										aria-label={userName}
										tabIndex="3"
									>
										<div className="userInitials">{userName && userName[0]}</div>
									</div>
								</li>
								<Overlay
									target={userNameRef.current}
									show={showUserInfo}
									onHide={() => setShowUserInfo(false)}
									placement="bottom-end"
									rootClose={device === deviceType.Mobile}
								>
									<div
										id="backDrop"
										className="backDrop"
										onClick={() => setShowUserInfo(false)}
									>
										<Popover
											id="userInfoPopover-contained"
											onClick={(e) => e.stopPropagation()}
										>
											<Popover.Body>
												<div className="userInfo">
													<div className="userInitials">{userName && userName[0]}</div>
												</div>
												<div className="userName">{userName}</div>
											</Popover.Body>
											<div class="popover-footer">
												<button
													type="button"
													class="btn btn-default logOutBtn"
													data-testid="logout-btn"
													onClick={logout}
												>
													{t("Log out")}
												</button>
											</div>
										</Popover>
									</div>
								</Overlay>
							</>
						)}
					</ul>
				</div>
				{device !== deviceType.Mobile && (
					<span
						className="navbar-brand"
						href="/"
						aria-label="IR Connect Logo"
						tabIndex="5"
					>
						<img
							src={`${cdnDomain}${short_logo}`}
							alt="IR Connect Logo"
							aria-label="IR Connect Logo"
							className="d-inline-block align-text-top shortlogo"
						/>
					</span>
				)}
				{loginFlag ? (
					<Login
						show={showLogin}
						onHide={closeLogin}
						signInUrl={signInUrl}
						makeSignupVisible={makeSignupVisible}
					/>
				) : (
					<Signup
						show={showSignup}
						onHide={closeSignup}
						makeLoginVisible={makeLoginVisible}
					/>
				)}
			</Container>
		</TopNav>
	);
};

export default Navbar;

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row } from "react-bootstrap";
import { ReactComponent as Vector } from "assets/icons/vector.svg";
import "./PrivateAnnouncement.scss";
import { LanguageContext } from "components/hooks/useLanguage";

const PrivateAnnouncement = ({ selectedSymbol }) => {
	const { announcemtnLang } = useContext(LanguageContext);
	const { t } = useTranslation();
	let today = new Date();

	return (
		<>
			<Row className="pull-right view-announcemnt-row">
				<Form
					action={`https://www1.hkexnews.hk/search/titlesearch.xhtml?lang=${announcemtnLang}`}
					method="post"
					target="_blank"
				>
					<input type="hidden" name="current_page" value="1"></input>
					<input type="hidden" name="stock_market" value="HKEX"></input>
					<input type="hidden" name="rdo_SelectSortBy" value="DateTime"></input>
					<input type="hidden" name="txt_stock_code" value={selectedSymbol}></input>
					<input type="hidden" name="sel_DocTypePrior2006" value="-1"></input>
					<input type="hidden" name="sel_tier_1" value="-2"></input>
					<input type="hidden" name="sel_tier_1" value="-2"></input>
					<input type="hidden" name="sel_tier_2_group" value="-2"></input>
					<input type="hidden" name="IsFromNewList" value="false"></input>
					<input type="hidden" name="sel_DateOfReleaseFrom_d" value="01"></input>
					<input type="hidden" name="sel_DateOfReleaseFrom_m" value="04"></input>
					<input type="hidden" name="sel_DateOfReleaseFrom_y" value="1999"></input>
					<input
						type="hidden"
						name="sel_DateOfReleaseTo_d"
						value={today.getDate()}
					></input>
					<input
						type="hidden"
						name="sel_DateOfReleaseTo_m"
						value={("0" + (today.getMonth() + 1)).slice(-2)}
					></input>
					<input
						type="hidden"
						name="sel_DateOfReleaseTo_y"
						value={today.getFullYear()}
					></input>
					<span className="hkt-time">{t("Data source: HKEX News")}</span>
					<Button
						data-testid="viewAnnouncementButton"
						variant=""
						type="submit"
						className="b-announcemnt"
					>
						{t("View Announcements")}
						<Vector style={{ maxHeight: "11.7px" }} />
					</Button>
				</Form>
			</Row>
		</>
	);
};

export default PrivateAnnouncement;

// Default to all feature flags turned off for Production
let FEATURE_FLAGS = {
	MARKET_TODAY: true,
	HEADER_SECTION: true,
	ABOUT_SECTION: true,
	CHARTING_SECTION: true,
	FUNDAMENTALS_SECTION: true,
	HOLDING_ANALYSIS: true,
	GEOCHART_SECTION: true,
	INSTITUTION_STYLE: true,
	CORPORATE_OWNERSHIP: true,
	EARNINGS: true,
	RESEARCH_RATINGS: true,
	CORPORATE_CALENDAR: false,
	PEER_SHARE_HOLDERS: true,
	COMPANY_ANNOUNCEMENTS: true,
	RECENT_NEWS: true,
	PEER_COMPARISON: true,
	EQUITY_INDEX_PERFORMANCE: true,
	RESEARCH_RATINGS_SECTION: true,
	EARNINGS_SECTION: true,
	SIGN_UP: false,
};

// Conditionally turn on features per environment
// If flags are set to true for all environments, then we can look to decommission the flag
if (window.MD && window.MD.ENV) {
	switch (window.MD.ENV) {
		case "acceptance-2":
			FEATURE_FLAGS = {
				...FEATURE_FLAGS,
				MARKET_TODAY: true,
				HOLDING_ANALYSIS: true,
				GEOCHART_SECTION: true,
				INSTITUTION_STYLE: true,
				CORPORATE_OWNERSHIP: true,
				PEER_SHARE_HOLDERS: true,
				COMPANY_ANNOUNCEMENTS: true,
				RECENT_NEWS: true,
				PEER_COMPARISON: true,
				EQUITY_INDEX_PERFORMANCE: true,
				RESEARCH_RATINGS_SECTION: true,
				EARNINGS: true,
				CORPORATE_CALENDAR: true,
				SIGN_UP: false,
			};
			break;
		case "acceptance":
			FEATURE_FLAGS = {
				...FEATURE_FLAGS,
				EARNINGS: true,
				CORPORATE_CALENDAR: false,
			};
			break;
		case "development":
			FEATURE_FLAGS = { ...FEATURE_FLAGS };
			break;
		case "localhost":
			FEATURE_FLAGS = {
				MARKET_TODAY: true,
				HEADER_SECTION: true,
				ABOUT_SECTION: true,
				CHARTING_SECTION: true,
				FUNDAMENTALS_SECTION: true,
				HOLDING_ANALYSIS: true,
				GEOCHART_SECTION: true,
				INSTITUTION_STYLE: true,
				CORPORATE_OWNERSHIP: true,
				EARNINGS: true,
				RESEARCH_RATINGS: true,
				CORPORATE_CALENDAR: true,
				PEER_SHARE_HOLDERS: true,
				COMPANY_ANNOUNCEMENTS: true,
				RECENT_NEWS: true,
				PEER_COMPARISON: true,
				EQUITY_INDEX_PERFORMANCE: true,
				RESEARCH_RATINGS_SECTION: true,
				EARNINGS_SECTION: true,
				SIGN_UP: false,
			};
			break;
		case "production":
		default:
			FEATURE_FLAGS = {
				...FEATURE_FLAGS,
				CORPORATE_CALENDAR: true,
			};
			break;
	}
}

export default FEATURE_FLAGS;

import axios from "axios";
import Utils from "../utils/utils";
import Constants from "../utils/constants";
import moment from "moment-timezone";
window.MD = window.MD || {};
// Set up auth and initialize axios
// Only call on initial App load or when we explicitly need to set a new auth token
const init = async () => {
	let internalAuthToken = await Utils.getValidMDToken();

	let apiUrl;
	let basePath;

	switch (window.MD.CLIENT) {
		case Constants.CLIENTS.HKEX:
		case undefined && window.location.hostname === "localhost":
			apiUrl = window.MD.API_URL;
			basePath = `${apiUrl}`;
			break;
		default:
			break;
	}

	const config = {
		baseURL: basePath,
		headers: {
			Accept: "application/json",
		},
	};

	if (internalAuthToken) {
		config.headers = {
			Accept: "application/json",
			authorization: `Bearer ${internalAuthToken}`,
		};
	} else {
		config.withCredentials = true;
	}

	const instance = axios.create(config);
	let CancelToken = axios.CancelToken;
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			const isAuthError = error && error.response && error.response.status === 401;
			const isNetworkError =
				error && error.message && error.message.toLowerCase() === "network error";
			if (isNetworkError || isAuthError) {
				//fetch new token and reload page
				if (!Utils.IsAuthTokenValid()) {
					if (!Utils.IsPublicTokenValid()) {
						await Utils.fetchPublicToken();
					}
				}
				return window.location.href;
			}
			return Promise.reject({ ...error });
		}
	);

	const get = (url, parameters) => instance.get(url, parameters);
	const post = (url, parameters) => instance.post(url, parameters);
	const deleteAction = (url, parameters) => instance.delete(url, parameters);

	return {
		instance,
		get,
		post,
		deleteAction,
		config: {
			CancelToken,
		},
		baseUrl: config.baseURL,
	};
};

export default { init };

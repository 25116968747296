import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useGeneralInformation = (venueXid) => {
	const [generalInformation, setGeneralInformation] = useState([]);
	const [errorGeneralInformation, setErrorGeneralInformation] = useState(null);
	const [loadingGeneralInformation, setLoadingGeneralInformation] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(null);
	const apiUrl = `${Constants.URLS.FACTSET_GENERAL_INFORMATION}${venueXid}`;

	useEffect(() => {
		if (!venueXid) return;
		setLoadingGeneralInformation(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data) {
					let basicData = response.data.data;
					setGeneralInformation(basicData);
				} else {
					setGeneralInformation(null);
					throw response;
				}
				setErrorGeneralInformation(null);
			} catch (e) {
				setGeneralInformation(null);
				setErrorGeneralInformation(e);
			} finally {
				setLoadingGeneralInformation(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [venueXid]);

	return {
		generalInformation,
		errorGeneralInformation,
		loadingGeneralInformation,
		fetchedAt,
	};
};

export default useGeneralInformation;

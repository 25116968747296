import { useState, useEffect, useContext } from "react";
import Constants from "../../utils/constants";
import RequestService from "../../services/RequestService";
import { LanguageContext } from "../hooks/useLanguage";
import Utils from "../../utils/utils";

const useNewsArticle = (wsodCompany) => {
	const [newsData, setNewsData] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const apiUrl = `${Constants.URLS.NEWS}`;
	const { selectedLanguageInternal } = useContext(LanguageContext);

	useEffect(() => {
		if (!wsodCompany) return;
		setLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.post(apiUrl, getPostPayload());
				if (response.status === 200 && response?.data?.data?.items?.length > 0) {
					if (response.data.data.items.length > 0) {
						await mapArticleData(response.data.data.items, apiInstance).then(
							(updatedData) => {
								setNewsData(updatedData);
							}
						);
					}
				} else if (response.status === 404) {
					setNewsData([]);
				} else {
					throw response;
				}
				setError(null);
			} catch (e) {
				setError(e);
				setNewsData([]);
			} finally {
				setLoading(false);
			}
		}

		init();
	}, [wsodCompany, selectedLanguageInternal]);

	const mapArticleData = async (updatedData, apiInstance) => {
		return await Promise.all(
			updatedData.map(async (news) => {
				const articleData = await fetchArticle(news.articleId, apiInstance);
				if (articleData) {
					return {
						...news,
						DocumentDate: Utils.getUTCtoHKTTime(news.DocumentDate),
						articleHeadline: articleData.headline,
						articleSource: articleData.source,
						articleDatetime: articleData.datetime,
						articleContentURI: articleData.links[0]?.href,
					};
				} else {
					return {
						...news,
						DocumentDate: Utils.getUTCtoHKTTime(news.DocumentDate),
					};
				}
			})
		);
	};

	const fetchArticle = async (selectedArticleId, apiInstance) => {
		try {
			const response = await apiInstance.get(
				`${Constants.URLS.NEWS}/${selectedArticleId}`
			);
			if (response.status === 200 && response?.data?.data) {
				return response.data.data;
			}
		} catch (e) {
			return null;
		}
	};

	const getPostPayload = () => {
		return {
			argsOperator: "AND",
			arguments: [
				{
					field: "WSODCompany",
					type: "string",
					conditions: [{ operator: "EqualTo", values: [`${wsodCompany}`] }],
				},
				{
					field: "Language",
					conditions: [
						{
							operator: "EqualTo",
							values: [selectedLanguageInternal.toUpperCase() || "EN"],
						},
					],
				},
			],
			sortArguments: [
				{
					direction: "desc",
					field: "DocumentDate",
				},
			],
			limit: 4,
			offset: 0,
		};
	};

	return { newsData, error, loading };
};

export default useNewsArticle;

import { useState, useEffect } from "react";

const useWindowResize = (setSize) => {
	useEffect(() => {
		const handleResize = () => {
			setSize([window.innerHeight, window.innerWidth]);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
};

export default useWindowResize;

const DATE_OPTIONS = {
	ONEDAY: {
		key: "1D",
		label: "1 Day",
	},
	FIVEDAY: {
		key: "5D",
		label: "5 Day",
	},
	TENDAY: {
		key: "10D",
		label: "10 Day",
	},
	ONEMONTH: {
		key: "1M",
		label: "1 Month",
	},
	THREEMONTH: {
		key: "3M",
		label: "3 Month",
	},
	SIXMONTH: {
		key: "6M",
		label: "6 Month",
	},
	YTD: {
		key: "YTD",
		label: "YTD",
	},
	ONEYEAR: {
		key: "1Y",
		label: "1 Year",
	},
	THREEYEAR: {
		key: "3Y",
		label: "3 Year",
	},
	FIVEYEAR: {
		key: "5Y",
		label: "5 Year",
	},
	TENYEAR: {
		key: "10Y",
		label: "10 Year",
	},
	ALLTIME: {
		key: "MAX",
		label: "All Time",
	},
};

const PERIODS = {
	ONEMINUTE: {
		interval: 1,
		label: "1 Minute",
		value: "Minute",
	},
	FIVEMINUTE: {
		interval: 5,
		label: "5 Minute",
		value: "Minute",
	},
	DAY: {
		interval: 1,
		label: "Daily",
		value: "Day",
	},
	WEEK: {
		interval: 1,
		label: "Weekly",
		value: "Week",
	},
	MONTH: {
		interval: 1,
		label: "Monthly",
		value: "Month",
	},
};

const TIME_FRAMES = [
	{
		key: 1,
		keyLabel: DATE_OPTIONS.ONEDAY.key,
		label: DATE_OPTIONS.ONEDAY.label,
		numDays: "1",
		defaultPeriod: PERIODS.ONEMINUTE,
		periods: [PERIODS.ONEMINUTE],
		enabled: true,
	},
	{
		key: 2,
		keyLabel: DATE_OPTIONS.FIVEDAY.key,
		label: DATE_OPTIONS.FIVEDAY.label,
		numDays: "5",
		defaultPeriod: PERIODS.FIVEMINUTE,
		periods: [PERIODS.FIVEMINUTE],
		enabled: true,
	},
	{
		key: 3,
		keyLabel: DATE_OPTIONS.TENDAY.key,
		label: DATE_OPTIONS.TENDAY.label,
		numDays: "10",
		defaultPeriod: PERIODS.FIVEMINUTE,
		periods: [PERIODS.FIVEMINUTE, PERIODS.DAY],
		enabled: true,
	},
	{
		key: 4,
		keyLabel: DATE_OPTIONS.ONEMONTH.key,
		label: DATE_OPTIONS.ONEMONTH.label,
		numDays: "30",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK],
		enabled: true,
	},
	{
		key: 5,
		keyLabel: DATE_OPTIONS.THREEMONTH.key,
		label: DATE_OPTIONS.THREEMONTH.label,
		numDays: "90",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK],
		enabled: true,
	},
	{
		key: 6,
		keyLabel: DATE_OPTIONS.SIXMONTH.key,
		label: DATE_OPTIONS.SIXMONTH.label,
		numDays: "180",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK],
		enabled: true,
	},
	{
		key: 7,
		keyLabel: DATE_OPTIONS.YTD.key,
		label: DATE_OPTIONS.YTD.label,
		numDays: "365",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
	{
		key: 8,
		keyLabel: DATE_OPTIONS.ONEYEAR.key,
		label: DATE_OPTIONS.ONEYEAR.label,
		numDays: "365",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
	{
		key: 9,
		keyLabel: DATE_OPTIONS.FIVEYEAR.key,
		label: DATE_OPTIONS.FIVEYEAR.label,
		numDays: "1825",
		defaultPeriod: PERIODS.WEEK,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
	{
		key: 10,
		keyLabel: DATE_OPTIONS.TENYEAR.key,
		label: DATE_OPTIONS.TENYEAR.label,
		numDays: "3650",
		defaultPeriod: PERIODS.MONTH,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
	{
		key: 11,
		keyLabel: DATE_OPTIONS.ALLTIME.key,
		label: DATE_OPTIONS.ALLTIME.label,
		numDays: "3650",
		defaultPeriod: PERIODS.MONTH,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
];

const DEFAULT_TIME_FRAME_INDEX = 0;

const TIME_FRAME_TEST = [
	{
		key: 1,
		keyLabel: DATE_OPTIONS.ONEYEAR.key,
		label: DATE_OPTIONS.ONEYEAR.label,
		numDays: "365",
		defaultPeriod: PERIODS.DAY,
		periods: [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH],
		enabled: true,
	},
];

export default {
	PERIODS,
	DATE_OPTIONS,
	TIME_FRAMES,
	DEFAULT_TIME_FRAME_INDEX,
	TIME_FRAME_TEST,
};

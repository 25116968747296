import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Card, Row } from "react-bootstrap";
import PrivateAnnouncement from "components/Announcements/Company/PrivateAnnouncement";
import "./CompanyAnnouncements.scss";
import useAnnouncements from "components/hooks/useAnnouncements";
import CONSTANTS from "utils/constants";
import moment from "moment";
import Error from "components/Error/Error";
import { addLeadingZeros } from "utils/utils";
import Skeleton from "react-loading-skeleton";
import "locale/CommonLocale";

const CompanyAnnouncements = ({ symbol }) => {
	const { t } = useTranslation();
	const symbolNum = parseInt(symbol || 0);
	const market = symbolNum > 7999 && symbolNum < 9000 ? "gem" : "sehk";
	symbol = addLeadingZeros(symbol, 5);

	const { announcements, error, loading } = useAnnouncements(symbol, market, 5);

	const renderErrorMessage = (error) => {
		let errorMessage = "ERROR_COMPANY_ANNOUNCEMENTS";
		if (error && error.response && error.response.status === 404)
			errorMessage = "NO_COMPANY_ANNOUNCEMENTS";

		return (
			<Error
				errorMessge={t(errorMessage, { symbol })}
				bgClass={"errorInfo-nobg-desktop"}
			></Error>
		);
	};

	const showSkeleton = (cls, hght, displayData) => {
		if (loading)
			return (
				<div>
					<Skeleton className={cls} height={hght} />
				</div>
			);
		else return displayData;
	};

	return (
		<Container fluid className="company-announcements">
			<Card>
				<Card.Header className="bg-white hk-sectionhdr">
					<h2 data-testid="viewAnnouncement">{t("Company Announcements")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
			</Card>
			<Card className="border-0 ml-0"></Card>
			{error && !loading && renderErrorMessage(error)}
			{loading &&
				[1, 2, 3, 4, 5].map((elem, key) => {
					return (
						<Card.Body className="border-bottom border-dark pb-0">
							<Row className="mt-16">
								<h6
									className={key === 0 ? "first-announcement" : "following-announcement"}
								>
									<Skeleton
										className="title-skeleton"
										height={key === 0 ? "48px" : "20px"}
									/>
								</h6>
							</Row>
							<Row>
								<span className="colname">
									<Skeleton className="date-ca-skeleton" height={"15px"} />
								</span>
							</Row>
							<a className="a-display-flex" target="_blank" rel="noreferrer">
								<Skeleton className="view-doc-skeleton" height={"24px"} />
							</a>
						</Card.Body>
					);
				})}
			{announcements &&
				!loading &&
				Array.isArray(announcements) &&
				announcements.map((elem, index) => {
					return (
						<Card.Body key={index} className="border-bottom border-dark pb-0">
							<Row className="mt-16">
								<h6
									className={
										index === 0 ? "first-announcement" : "following-announcement"
									}
								>
									{showSkeleton(
										"title-skeleton",
										index === 0 ? "48px" : "20px",
										elem.title
									)}
								</h6>
							</Row>
							<Row>
								<span className="colname">
									{showSkeleton(
										"date-ca-skeleton",
										"15px",
										`${moment(elem.dateTime, "DD/MM/YYYY HH:mm")
											.locale(t("lang"))
											.format(t("formatRecentNews"))} ${t("HKT")}`
									)}
								</span>
							</Row>
							<a
								className="a-display-flex"
								href={CONSTANTS.URLS.HKEX_NEWS_BASE_URL + elem.fileLink}
								target="_blank"
								rel="noreferrer"
							>
								{showSkeleton(
									"view-doc-skeleton",
									"24px",
									<>
										<i className="i-item fa fa-file-text-o"></i>
										<p className="a-item">
											<b className="b-text">{t("View Document")}</b>
										</p>
									</>
								)}
							</a>
						</Card.Body>
					);
				})}
			{!error && !loading && announcements && announcements.length === 0 && (
				<Error
					errorMessge={t("NO_COMPANY_ANNOUNCEMENTS", {
						defaultValue: "There are no company announcements for {{symbol}}",
						symbol,
					})}
				/>
			)}
			<Card.Body className="pl-0 announcements-footer">
				<div className="pl-0"></div>
				<div className="view-announcement">
					<PrivateAnnouncement selectedSymbol={symbol}></PrivateAnnouncement>
				</div>
			</Card.Body>
		</Container>
	);
};
export default CompanyAnnouncements;

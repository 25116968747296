const scroller = () => {
	const headerText = document.getElementsByClassName("signup-text-header")[0];
	const signupText = document.getElementsByClassName("signup-text")[0];
	const classes = document.getElementsByClassName("header-signup")[0].classList;
	if (signupText.getBoundingClientRect().y > 35) {
		headerText.setAttribute("hidden", true);
		classes.replace("header-border", "no-header-border");
	} else {
		headerText.removeAttribute("hidden");
		classes.replace("no-header-border", "header-border");
	}
};

const formatPhoneValue = (e) => {
	if (e.target.value && !e.target.value.includes("(")) {
		let len = e.target.value.length;
		let i = 0;
		let formattedValue = "(";
		if (len < 5) {
			formattedValue += `${e.target.value})`;
		} else {
			while (i < len) {
				if (i === 4) formattedValue += ") ";
				else if (i === 8) formattedValue += " ";
				else if (i === 12) formattedValue += " ";
				formattedValue += e.target.value[i];
				i++;
			}
		}
		e.target.value = formattedValue;
	}
};

const deFormatterPhoneValue = (e) => {
	if (e.target.value) {
		let deformattedValue = "";
		let splits = e.target.value.split(/[(,), ]/);
		let len = splits.length;
		let i = 0;
		if (len > 3) {
			while (i < len) {
				if (i !== 0 && i !== 2) {
					deformattedValue += splits[i];
				}
				i++;
			}
		} else deformattedValue = splits[1];
		e.target.value = deformattedValue;
	}
};

const checkInputPhone = (e) => {
	if (
		((e.keyCode < 48 || e.keyCode > 57) &&
			(e.keyCode < 96 || e.keyCode > 105) &&
			![8, 37, 39, 46, 9].includes(e.keyCode)) ||
		(e.target.value.length > 14 && e.keyCode >= 48 && e.keyCode <= 57) ||
		(e.target.value.length > 14 && e.keyCode >= 96 && e.keyCode <= 105) ||
		(e.shiftKey && e.keyCode !== 9)
	)
		e.preventDefault();
};

const getRequestParam = (
	mail,
	fname,
	lname,
	companyNameEntered,
	title,
	country,
	city,
	phoneNum,
	phoneCode,
	agree,
	wechat,
	website,
	agreeOptional,
	companyCodeEntered
) => {
	const date = new Date();
	const elqProspectID = `${mail}.${
		date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
	}${
		date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
	}${date.getFullYear()}${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}${
		date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
	}${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
	const params = new URLSearchParams();
	params.append("elqFormName", "frm_HK-IRConnect_Repost");
	params.append("elqProspectID", elqProspectID);
	params.append("source_url", window.location.origin); //"https://hkex-irconnect.markitqa.com/"
	params.append("elqSiteID", "1770935282");
	params.append("MPC_CampaignID", "PC022172");
	params.append("primary_interest", "38050");
	params.append("form_title", "IRConnect");

	params.append("FirstName", fname);
	params.append("LastName", lname);
	params.append("Emailaddress1", mail);
	params.append("AccountId", companyNameEntered);
	params.append("JobTitle", title);
	params.append("address1_country", country);
	params.append("elqCookieWrite", "0");
	params.append("address1_city", city);
	params.append("Telephone1", phoneNum);
	params.append("telephone_country_code", phoneCode);
	params.append("privacy_policy", agree ? "on" : "off");
	params.append("WeChatID", wechat);
	params.append("CompanyURL", website);
	params.append("ysnContactMeNow", agreeOptional ? "Yes" : "");
	params.append("MPC_ActionRecommendation", "CRM");
	params.append("txtTickerSymbol", companyCodeEntered);
	return params;
};

const setError = (name, showMsg) => {
	if (!["wechat", "website"].includes(name)) {
		let el = document.getElementById(`error${name}`);
		if (el) {
			if (showMsg) {
				el.removeAttribute("hidden");
			} else {
				el.setAttribute("hidden", true);
			}
		}
	}
};

export {
	scroller,
	formatPhoneValue,
	deFormatterPhoneValue,
	checkInputPhone,
	getRequestParam,
	setError,
};

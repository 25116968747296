import format from "@markit/format";

const updateFlag = (args, chart, setVal, setPosition) => {
	let value = formatValue(args.date, chart);
	setVal(value);
	positionFlag(args, chart, setPosition);
};
const hideFlag = (divRef) => {
	const el = divRef;
	if (el && el.current) {
		el.current.style.display = "none";
	}
};
const showFlag = (divRef) => {
	const el = divRef;
	if (el.current) {
		el.current.style.display = "block";
	}
};
const positionFlag = (args, chart, setPosition) => {
	let offset = parseInt(chart.params.days) < 11 ? -22 : -35;
	let left = args.coords.x + offset;
	setPosition({
		top: chart.panels[0].size.height + 7,
		left: left,
	});
};
const formatValue = (value, chart) => {
	if (parseInt(chart.params.days) < 11) return format().date(value, "hh:mm a");
	else {
		return format().date(value, "DD MMM YYYY");
	}
};

export { formatValue, hideFlag, positionFlag, showFlag, updateFlag };

const COLOR_PALETTE = [
	{
		color: "#13426B",
		available: false,
		tableRowIndex: 0,
	},
	{
		color: "#26CAD3",
		available: false,
		tableRowIndex: 1,
	},
	{
		color: "#FFB81C",
		available: false,
		tableRowIndex: 2,
	},
	{
		color: "#5E366E",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#FEDD00",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#445CBE",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#D25B73",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#B5E3D8",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#41748D",
		available: true,
		tableRowIndex: null,
	},
	{
		color: "#EF886F",
		available: true,
		tableRowIndex: null,
	},
];

export default COLOR_PALETTE;

import React, { useState, useEffect } from "react";
import "chartist-plugin-fill-donut";
import InstitutionsChart from "./Chart/InstitutionsChart";
import InstitutionsTable from "./Table/InstitutionsTable";
import { Row, Col } from "react-bootstrap";
import Error from "components/Error/Error";
import SubscriptionModal from "components/Common/SubscriptionModal";
import SubscriptionLink from "components/Common/SubscriptionLink";
import { useTranslation } from "react-i18next";
import Utils from "../../utils/utils";
import CaretIcon from "../../assets/img/Caret_icon.svg";
import "./HoldingAnalysis.scss";
import moment from "moment";
import "locale/CommonLocale";

const Holdings = ({ tabName, data, loading, error }) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { t } = useTranslation();
	const mapTabToMessage = {
		Institutional: t("institutions"),
		Buyers: t("institutional buyers"),
		Sellers: t("institutional sellers"),
		Funds: t("funds"),
	};
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const errorMessage = (type) => {
		switch (type) {
			case "Institutional":
				return t("There was a problem loading top 10 institutions data.");
			case "Buyers":
				return t("There was a problem loading top 10 institutional buyers data.");
			case "Sellers":
				return t("There was a problem loading top 10 institutional sellers data.");
			case "Funds":
				return t("There was a problem loading top 10 funds data.");
			case "nodata":
				let noDataMessage = `There is currently no top 10 ${mapTabToMessage[tabName]} data available.`;
				return t(noDataMessage);
			default:
		}
	};

	if ((!data || data.length < 1) && !loading) {
		let errorMsg = errorMessage(
			(data && data.length === 0) || (error && error.response.status === 404)
				? "nodata"
				: tabName
		);
		return (
			<Row data-testid="noDataText" className="pt-20">
				<Col>{!loading && <Error errorMessge={t(errorMsg)}></Error>}</Col>
				<div className="txt-below-chart-hold pt-20">
					{t("Data source: S&P Global") + " " + t("As of") + " "}
					{moment(Utils.getHKTTime()).locale(t("lang")).format(t("format"))} {t("HKT")}
				</div>
				<div
					className="txt-subscribe"
					style={{ textAlign: "left", paddingTop: "13.5px" }}
				>
					{<SubscriptionLink cdnDomain={cdnDomain} handleShow={handleShow} />}
					{
						<SubscriptionModal
							show={show}
							handleShow={handleShow}
							handleClose={handleClose}
						/>
					}
				</div>
			</Row>
		);
	}
	return (
		<React.Fragment>
			<Row>
				<Col xs={12} md={12} lg={4}>
					{loading && (
						<InstitutionsChart
							loading={loading}
							data={null}
							tabName={tabName}
						></InstitutionsChart>
					)}
					{data && data.length > 0 && !loading && (
						<InstitutionsChart
							loading={loading}
							data={data}
							tabName={tabName}
						></InstitutionsChart>
					)}
				</Col>
				<Col xs={12} md={12} lg={8}>
					<InstitutionsTable
						loading={loading}
						data={data}
						tabName={tabName}
					></InstitutionsTable>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default Holdings;

import format from "@markit/format";

export const options = {
	errorString: "\u2014",
};

const currencyMapping = [
	{
		key: "HKD",
		Value: "HK$",
	},
];

format.extend({
	daysFromCurrentDate(date) {
		const currentDate = this.moment(new Date()).startOf("day");
		const futureDate = this.moment(date).startOf("day");

		return Math.abs(futureDate.diff(currentDate, "days"));
	},

	getCompactFormattedLargeValue(volume, precision = 2, trillionflag = true) {
		const trillion = 1e12;
		const billion = 1e9;
		const million = 1e6;
		const thousand = 1e3;
		if (!volume) {
			return `0`;
		}

		if (volume >= trillion && trillionflag) {
			const formatValue = formatter.number(volume / trillion, precision);
			return `${formatValue}T`;
		}

		if (volume >= billion) {
			const formatValue = formatter.number(volume / billion, precision);
			return `${formatValue}B`;
		}

		if (volume >= million) {
			const formatValue = formatter.number(volume / million, precision);
			return `${formatValue}M`;
		}

		if (volume >= thousand) {
			const formatValue = formatter.number(volume / thousand, precision);
			return `${formatValue}K`;
		}
		return formatter.number(volume, precision);
	},
	priceMagnitude(val, precision) {
		// price with showMagnitude=true returns a string like '$13 b'
		const standardMagnitude = format(options).price(val, precision, {
			showMagnitude: true,
		});

		// transform something like '$13 b' to '$13B' to match our use case
		const customMagnitude = standardMagnitude.toUpperCase().replace(" ", "");

		return customMagnitude;
	},
	ratio(val, precision) {
		const formattedNum = format(options).number(val, precision);

		return formattedNum && formattedNum !== options.errorString
			? `${formattedNum}x`
			: formattedNum;
	},
	percent(val, precision) {
		const formattedNum = format(options).number(val, precision);

		return formattedNum && formattedNum !== options.errorString
			? `${formattedNum}%`
			: formattedNum;
	},
	removeProtocol(val) {
		if (!val) {
			return val;
		}

		const regex = /^https?:\/\//;
		return val.replace(regex, "");
	},
	removeDomainPrefix(val) {
		if (!val) {
			return val;
		}

		const regex = /^(www\.)/;
		return val.replace(regex, "");
	},
	priceWithRoundingAndSymbol(val, precision, symbol, trillionflag = true) {
		const thousand = 1e3;

		const priceFormatter = precision
			? format(Object.assign(options, { precision: { number: precision } }))
			: format(options);
		let formattedVal =
			val > thousand
				? this.getCompactFormattedLargeValue(val, precision, trillionflag)
				: priceFormatter.number(val);
		//Special handling when HKD is received from API Call
		let symbolCode = symbol;
		if (currencyMapping.find((f) => f.key === symbol)) {
			symbolCode = currencyMapping.find((f) => f.key === symbol).Value;
		}
		return formattedVal.charAt(0) === "-"
			? `-${symbolCode ? symbolCode : ""}${formattedVal.substring(1)}`
			: `${symbolCode ? symbolCode : ""}${formattedVal}`;
	},
	numberMagnitude(val, precision, magnitude, numberOptions) {
		if (!magnitude) {
			return val;
		}

		const formattedNum = format(options).number(val, precision, numberOptions);
		return formattedNum && formattedNum !== options.errorString
			? `${formattedNum}${magnitude}`
			: formattedNum;
	},
	text(val) {
		if (typeof val !== "undefined" && val !== null && val !== "") {
			return val.toString();
		}

		return options.errorString;
	},
	/**
	 * Show minimum to minPrecision, significant digits allowed upto maxPrecision and remove any trailing zeros beyond maxPrecision
	 * @param {string} val input value
	 * @param {number} minPrecision minimum precision
	 * @param {number} maxPrecision max. significant digits allowed
	 * @returns {string} Formatted price string
	 * priceWithoutTrailingZero(12345, 2, 2) // $12,345.00
	 * priceWithoutTrailingZero(12345.67895000, 2, 4) // $12,345.6789
	 * priceWithoutTrailingZero(12345.6780000, 2, 4) // $12,345.678
	 * priceWithoutTrailingZero(12345.6700000, 2, 4) // $12,345.67
	 * priceWithoutTrailingZero(12345.6000000, 2, 4) // $12,345.60
	 * priceWithoutTrailingZero(12345.6, 2, 4) // $12,345.60
	 */
	priceWithoutTrailingZero(val, minPrecision, maxPrecision) {
		// removes trailing zeros
		val = val.toString();
		const decimalIndex = val.indexOf(".");
		if (decimalIndex > -1) {
			const decimalPlaces = val.substring(decimalIndex + 1);
			if (decimalPlaces.length > maxPrecision) {
				val = val.substring(0, decimalIndex + maxPrecision + 1);
			} else if (decimalPlaces.length < minPrecision) {
				maxPrecision = minPrecision;
			} else {
				maxPrecision = decimalPlaces.length;
			}
			return formatter.price(val, maxPrecision);
		} else {
			return formatter.price(val, minPrecision);
		}
	},
	numberWithoutTrailingZero(val, minPrecision, maxPrecision) {
		// removes trailing zeros
		val = val.toString();
		const decimalIndex = val.indexOf(".");
		if (decimalIndex > -1) {
			const decimalPlaces = val.substring(decimalIndex + 1);
			if (decimalPlaces.length > maxPrecision) {
				val = val.substring(0, decimalIndex + maxPrecision + 1);
			} else if (decimalPlaces.length < minPrecision) {
				maxPrecision = minPrecision;
			} else {
				maxPrecision = decimalPlaces.length;
			}
			return formatter.number(val, maxPrecision);
		} else {
			return formatter.number(val, minPrecision);
		}
	},
	marketCapText(marketCap, styleBoxCode) {
		const styleBoxMap = {
			1: "Large",
			2: "Large",
			3: "Large",
			4: "Mid",
			5: "Mid",
			6: "Mid",
			7: "Small",
			8: "Small",
			9: "Small",
		};

		const capSizeText = styleBoxMap[styleBoxCode]
			? `${styleBoxMap[styleBoxCode]} Cap`
			: this.options.errorString;
		const marketCapValue = marketCap || this.options.errorString;

		const formattedMarketCap = `${capSizeText} (${marketCapValue})`;

		return formattedMarketCap;
	},
	formatPosNegVal(val, precision = 3) {
		const formattedNum = format(options).number(val, precision);
		if (val.toString().indexOf("-") >= 0) {
			return formattedNum;
		} else if (val.toString() === "0.000") {
			return "0.000";
		} else if (val.toString() === "0.00") {
			return "0.00";
		} else {
			return "+" + formattedNum;
		}
	},
	formatPosNegValForChangePercent(val, precision = 2) {
		const formattedNum = format(options).number(val, precision);
		if (val.toString().indexOf("-") >= 0) {
			return formattedNum;
		} else if (val.toString() === "0.00") {
			return "0.00";
		} else {
			return "+" + formattedNum;
		}
	},
	priceWithRounding(val, precision) {
		const priceFormatter = precision
			? format(Object.assign(options, { precision: { number: precision } }))
			: format(options);
		let formattedVal = priceFormatter.number(val);

		return formattedVal.charAt(0) === "-"
			? `-$${formattedVal.substring(1)}`
			: `$${formattedVal}`;
	},
});

const formatter = format(options);

export default formatter;

import { useContext, useState, useEffect } from "react";
import { SymbolContext } from "components/hooks/useSymbol";
import { AuthContext } from "components/hooks/useAuthentication";
import useGeneralInformation from "components/hooks/FactSet/useGeneralInformation";
import useBasicFundamentals from "components/hooks/FactSet/useBasicFundamentals";
import useAdvancedFundamentals from "components/hooks/FactSet/useAdvancedFundamentals";
import useCompanyInformation from "components/hooks/FactSet/useCompanyInformation";
import PublicAnnouncement from "components/Announcements/PublicAnnouncement";
import "./About.scss";
import { Row, Col, Card } from "react-bootstrap";
import { getDeviceType, handleWindowResize, deviceType } from "utils/utils";
import { useTranslation } from "react-i18next";
import formatter from "utils/formatUtils";
import Utils from "../../utils/utils";
import squareUpRightArrowRed from "assets/icons/squareUpRightArrowRed.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Login from "components/Login/Login";
import Signup from "components/Header/Signup/Signup";
import CaretIcon from "../../assets/img/Caret_icon.svg";

function About({ venueXid }) {
	const { t } = useTranslation();
	const { symbol } = useContext(SymbolContext);
	const { isAuthenticated, signInUrl } = useContext(AuthContext);
	const { generalInformation, errorGeneralInformation, loadingGeneralInformation } =
		useGeneralInformation(venueXid);
	const { revenueSales, basicError, basicLoading, revenueAsOfDate } =
		useBasicFundamentals(venueXid);
	const { salesGrowth, employeeNo, advanceError, advanceLoading, asOfDate, empAsOfDate } =
		useAdvancedFundamentals(venueXid);
	const { companyInformation, loadingCompanyInformation, errorCompanyInformation } =
		useCompanyInformation(venueXid);
	const [device, setDevice] = useState(getDeviceType());
	const [loginFlag, setLoginFlag] = useState(true);
	const [showLogin, setShowLogin] = useState(false);
	const [showSignup, setShowSignup] = useState(false);
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	let card_bg = `bg-white about-card-auth`;
	if (!isAuthenticated) card_bg = `bg-gray about-card`;
	useEffect(() => {
		handleWindowResize(resizeHandler);
	}, [device]);
	const openLogin = () => {
		setLoginFlag(true);
		setShowLogin(true);
	};
	const closeLogin = () => {
		setShowLogin(false);
	};
	const makeSignupVisible = () => {
		setShowSignup(true);
		setLoginFlag(false);
	};
	const closeSignup = () => {
		setShowSignup(false);
	};
	const makeLoginVisible = () => {
		setLoginFlag(true);
		openLogin();
	};
	const resizeHandler = (response) => {
		setDevice(response);
	};

	const replaceSpecialCharector = (str) => {
		return str
			.replace(/\uFFFD/g, " ")
			.replace(/[\u00e0-\u00ef][\u0080-\u00bf][\u00c0-\u00df]/g, " ");
	};

	const getAddress = (address) => {
		let adrs = `${address.street1 ? replaceSpecialCharector(address.street1) : "/"}, ${
			address.street2 ? replaceSpecialCharector(address.street2) : "/"
		}, ${address.street3 ? replaceSpecialCharector(address.street3) : "/"}, ${
			address.city ? address.city : "/"
		}, ${address.state ? address.state : "/"}, ${address.country ? address.country : ""}`;
		return adrs.toString().replaceAll("/,", "");
	};

	const getTelephone = (telephoneNumber) => {
		if (telephoneNumber) {
			return telephoneNumber.toString().replaceAll(".", "-");
		}
	};

	const getLoader = (loading, cls) => {
		if (loading) {
			if (cls === "address-skeleton")
				return <Skeleton className={`about-value-skeleton ${cls}`} count={2} />;
			else if (cls === "description-skeleton")
				return <Skeleton className={`about-value-skeleton ${cls}`} count={4} />;
			return <Skeleton className={`about-value-skeleton ${cls}`} />;
		} else return false;
	};
	const getClassCol = (op1, op2) => {
		if (device !== deviceType.Mobile) return op1;
		else return op2;
	};

	const getClassCompanyAddress = () => {
		if (isAuthenticated) return "company-address";
		else return "company-address-public";
	};

	const showCompanyAnnoucement = () => {
		if (!isAuthenticated)
			return (
				<Row className="about-compnayannouncement-row align-text-bottom">
					<Col className="about-compnayannouncement-col">
						<PublicAnnouncement selectedSymbol={symbol}></PublicAnnouncement>
					</Col>
					<Col className="link-to-login-col">
						<button
							className="btn-link"
							onClick={openLogin}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									openLogin();
								}
							}}
						>
							{t("Login to view Advanced Profile") + " "}
							<img src={`${cdnDomain}${CaretIcon}`} alt="Right" />
						</button>
					</Col>
				</Row>
			);
	};

	const getDataToRender = (op1, op2) => {
		if (op1) return op1;
		else return op2;
	};

	const getAboutLabel = (loading, label) => {
		if (loading) return <Skeleton className="about-label-skeleton" />;
		else return <small className="about-label">{t(label)}</small>;
	};

	return (
		<Card className={card_bg}>
			<Card.Header className="hk-sectionhdr">
				<h2>{t("About")}</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			<Card.Body className="about-cardbody">
				<Row className="about-cardbody-row">
					<Col>
						<Row className="mt-0">
							<Col className={getClassCol("about-col3", "about-col")}>
								{getAboutLabel(basicLoading, "Revenue")}
								<div>
									{getDataToRender(
										getLoader(basicLoading),
										revenueSales?.mrfy?.value && !basicLoading && !basicError ? (
											<p>
												<p style={{ fontWeight: 400, display: "inline" }}>{t("HK$")}</p>
												{formatter.priceWithRoundingAndSymbol(
													revenueSales.mrfy.value * 1000 * 1000,
													2
												)}
											</p>
										) : (basicError && !basicLoading) || !revenueSales?.mrfy?.value ? (
											<p>--</p>
										) : (
											getLoader(basicLoading)
										)
									)}
								</div>
							</Col>
							<Col className={getClassCol("about-col4", "about-col")}>
								{getAboutLabel(advanceLoading, "Sales Growth")}
								<div>
									{getDataToRender(
										getLoader(advanceLoading),
										salesGrowth?.mrfy?.value && !advanceLoading && !advanceError ? (
											<p>{formatter.percent(salesGrowth.mrfy.value, 2)}</p>
										) : (advanceError && !advanceLoading) || !salesGrowth?.mrfy?.value ? (
											<p>--</p>
										) : (
											getLoader(advanceLoading)
										)
									)}
								</div>
							</Col>
							<Col className={getClassCol("about-col5", "about-col")}>
								{getAboutLabel(advanceLoading, "Employees")}
								<div>
									{getDataToRender(
										getLoader(advanceLoading),
										employeeNo?.mrfy?.value && !advanceLoading && !advanceError ? (
											<p>{employeeNo.mrfy.value.toLocaleString("en-US")}</p>
										) : (advanceError && !advanceLoading) || !employeeNo?.mrfy?.value ? (
											<p>--</p>
										) : (
											getLoader(advanceLoading)
										)
									)}
								</div>
							</Col>
							<Col className={getClassCol("about-col6", "about-col")}>
								{getAboutLabel(loadingGeneralInformation, "Year Founded")}
								{getDataToRender(
									getLoader(loadingGeneralInformation),
									generalInformation?.yearFounded &&
										!loadingGeneralInformation &&
										!errorGeneralInformation ? (
										<p>{generalInformation?.yearFounded}</p>
									) : (errorGeneralInformation && !loadingGeneralInformation) ||
									  !generalInformation?.yearFounded ? (
										<p>--</p>
									) : (
										getLoader(loadingGeneralInformation)
									)
								)}
							</Col>
							<Col className={getClassCol("about-col6", "about-col")}>
								{getAboutLabel(loadingGeneralInformation, "Sector")}
								{getDataToRender(
									getLoader(loadingGeneralInformation),
									generalInformation?.taxonomy?.sectorName &&
										!loadingGeneralInformation &&
										!errorGeneralInformation ? (
										<p>{generalInformation.taxonomy.sectorName}</p>
									) : (errorGeneralInformation && !loadingGeneralInformation) ||
									  !generalInformation?.taxonomy?.sectorName ? (
										<p>--</p>
									) : (
										getLoader(loadingGeneralInformation)
									)
								)}
							</Col>
							<Col className={getClassCol("about-col6", "about-col")}>
								{getAboutLabel(loadingGeneralInformation, "Industry")}
								{getDataToRender(
									getLoader(loadingGeneralInformation),
									generalInformation?.taxonomy?.industryName &&
										!loadingGeneralInformation &&
										!errorGeneralInformation ? (
										<p>
											{generalInformation.taxonomy.industryName
												.toString()
												.replaceAll("/", " ")}
										</p>
									) : (errorGeneralInformation && !loadingGeneralInformation) ||
									  !generalInformation?.taxonomy?.industryName ? (
										<p>--</p>
									) : (
										getLoader(loadingGeneralInformation)
									)
								)}
							</Col>

							<span className="text-line"></span>
							<Col>
								{getDataToRender(
									getLoader(loadingCompanyInformation, "address-skeleton"),
									companyInformation?.address &&
										!loadingCompanyInformation &&
										!errorCompanyInformation ? (
										<p className={getClassCompanyAddress()}>
											{getAddress(companyInformation.address)}
											<br />
											{getTelephone(companyInformation.address.telephoneNumber)}
										</p>
									) : (errorCompanyInformation && !loadingCompanyInformation) ||
									  !companyInformation?.address ? (
										<p>--</p>
									) : (
										getLoader(loadingCompanyInformation, "address-skeleton")
									)
								)}
								<div className="websiteLink">
									<a
										href={generalInformation?.contact?.website}
										target="_blank"
										rel="noreferrer"
									>
										{loadingGeneralInformation ? (
											<Skeleton className="website-skeleton" />
										) : (
											<>
												<img
													alt="website"
													src={`${cdnDomain}${squareUpRightArrowRed}`}
													className="svg"
												/>
												<span className="website">{t("Website")}</span>
											</>
										)}
									</a>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col className="company-profile">
						{getDataToRender(
							getLoader(loadingCompanyInformation, "description-skeleton"),
							companyInformation?.longDescription &&
								!loadingCompanyInformation &&
								!errorCompanyInformation ? (
								<div>
									<h5>{replaceSpecialCharector(companyInformation.longDescription)}</h5>
									<span className="colname">
										{t(
											"Data source: FactSet. Revenue, Sales Growth and Employees based on latest annual report."
										)}
									</span>
								</div>
							) : (errorCompanyInformation && !loadingCompanyInformation) ||
							  !companyInformation?.longDescription ? (
								<p>--</p>
							) : (
								getLoader(loadingCompanyInformation, "description-skeleton")
							)
						)}
					</Col>
				</Row>
				{showCompanyAnnoucement()}
				{loginFlag ? (
					<Login
						show={showLogin}
						onHide={closeLogin}
						signInUrl={signInUrl}
						makeSignupVisible={makeSignupVisible}
					/>
				) : (
					<Signup
						show={showSignup}
						onHide={closeSignup}
						makeLoginVisible={makeLoginVisible}
					/>
				)}
			</Card.Body>
		</Card>
	);
}

export default About;

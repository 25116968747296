import formatter from "utils/formatUtils";

const updateFlag = (args, setVal, setPosition, val) => {
	let value = formatValue(args.val, val);
	setVal(value);
	positionFlag(args, setPosition);
};
const hideFlag = (divRef) => {
	const el = divRef;
	if (el && el.current) {
		el.current.style.display = "none";
	}
};
const showFlag = (divRef) => {
	const el = divRef;
	if (el.current) {
		el.current.style.display = "none";
	}
};
const positionFlag = (args, setPosition) => {
	const left = args.x + 5;
	const top = args.y - 14;
	setPosition({ top: top, left: left });
};
const formatValue = (value, val) => {
	return formatter.number(value, getPrecision(value, val));
};
const getPrecision = (value, val) => {
	return value < 1 && val > -1 ? 3 : 2;
};
export { formatValue, getPrecision, hideFlag, positionFlag, showFlag, updateFlag };

/* eslint-disable no-unused-expressions */
import React from "react";
import { useTranslation } from "react-i18next";
import CaretIcon from "../../assets/img/Caret_icon.svg";
import useGAEventsTracker from "components/hooks/useGAEventsTracker";

export default function SubscriptionLink(props) {
	const { t } = useTranslation();
	const GAEventsTracker = useGAEventsTracker(t("Subscribe"));

	return (
		<>
			<span
				onClick={() => {
					props.handleShow();
					GAEventsTracker(props.GAEventsTracker.action, props.GAEventsTracker.label);
				}}
				data-testid="subscribe-link"
			>
				{t("Subscribe for more data from S&P Global")}
			</span>
			<img
				onClick={() => {
					props.handleShow();
					GAEventsTracker(props.GAEventsTracker.action, props.GAEventsTracker.label);
				}}
				src={`${props.cdnDomain}${CaretIcon}`}
				alt="Right"
				data-testid="caret-link"
			/>
		</>
	);
}

import { useState, createContext, useEffect } from "react";
//https://atomizedobjects.com/blog/react/how-to-test-a-custom-react-hook/#:~:text=The%20easiest%20way%20to%20test,would%20in%20normal%20unit%20tests.

const useSymbol = () => {
	const [symbol, setSymbol] = useState(null);
	const [venueXid, setVenueXid] = useState(null);
	const [instrumentXid, setInstrumentXid] = useState(null);
	const [equityShortName, setEquityShortName] = useState("");
	const [equityName, setEquityName] = useState("");
	const [wsodCompany, setWSODCompany] = useState(null);
	const [loading, setLoading] = useState(false);

	return {
		symbol,
		setSymbol,
		venueXid,
		setVenueXid,
		equityShortName,
		setEquityShortName,
		equityName,
		setEquityName,
		instrumentXid,
		setInstrumentXid,
		wsodCompany,
		setWSODCompany,
		loading,
		setLoading,
	};
};

export const SymbolContext = createContext(null);

const SymbolProvider = ({ children }) => {
	const {
		symbol,
		setSymbol,
		venueXid,
		setVenueXid,
		equityShortName,
		setEquityShortName,
		equityName,
		setEquityName,
		instrumentXid,
		setInstrumentXid,
		wsodCompany,
		setWSODCompany,
		loading,
		setLoading,
	} = useSymbol();
	return (
		<SymbolContext.Provider
			value={{
				symbol,
				setSymbol,
				venueXid,
				setVenueXid,
				equityShortName,
				setEquityShortName,
				equityName,
				setEquityName,
				instrumentXid,
				setInstrumentXid,
				wsodCompany,
				setWSODCompany,
				loading,
				setLoading,
			}}
		>
			{children}
		</SymbolContext.Provider>
	);
};
export { SymbolProvider };

import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Tabs, Tab } from "react-bootstrap";
import "./EquityIndexSection.scss";
import EquityIndexTable from "./Table/EquityIndexTable";
import useQuote from "components/hooks/useQuote";
import {
	BENCHMARKS,
	FLAGSHIP,
	CORE,
	ESG,
	SMARTBETA,
	THEMATIC,
	STOCKCONNECT,
} from "../../components/Charting/Constants/Benchmarks";
import { useTranslation } from "react-i18next";
import LandingChart from "components/Charting/LandingChart/LandingChart";
import CONSTANTS from "utils/constants";
import RequestService from "services/RequestService";
import moment from "moment";
import Utils from "../../utils/utils";

const EquityIndexSection = () => {
	const { t } = useTranslation();
	let indexesArray = BENCHMARKS.map(({ id }) => id);
	const [selectedTab, setSelectedTab] = useState("all");
	const [activeTab, setActiveTab] = useState({
		all: true,
		flagship: false,
		core: false,
		esg: false,
		smartbeta: false,
		thematic: false,
		stockconnect: false,
	});
	const initstyles = [
		{
			color: "#13426B",
			available: false,
			tableRowIndex: 0,
		},
		{
			color: "#26CAD3",
			available: false,
			tableRowIndex: 1,
		},
		{
			color: "#FFB81C",
			available: false,
			tableRowIndex: 2,
		},
	];
	const [visibleIndexes, setVisibleIndexes] = useState({
		all: [],
		flagship: [],
		core: [],
		esg: [],
		smartbeta: [],
		thematic: [],
		stockconnect: [],
	});
	const [selectedtimeframe, setSelectedtimeframe] = useState(1);

	const { quote, error, loading, fetchedAt } = useQuote(indexesArray.toString());
	const [benchmarks, setBenchmarks] = useState({
		all: BENCHMARKS,
		flagship: [],
		core: [],
		esg: [],
		smartbeta: [],
		thematic: [],
		stockconnect: [],
	});
	const [data, setData] = useState({
		all: quote,
		flagship: [],
		core: [],
		esg: [],
		smartbeta: [],
		thematic: [],
		stockconnect: [],
	});

	useEffect(() => {
		if (!loading && quote) {
			let flagshipData = quote.filter((e) =>
				FLAGSHIP.includes(parseInt(e.data.venueXid))
			);
			let coreData = quote.filter((e) => CORE.includes(parseInt(e.data.venueXid)));
			let esgData = quote.filter((e) => ESG.includes(parseInt(e.data.venueXid)));
			let smartbetaData = quote.filter((e) =>
				SMARTBETA.includes(parseInt(e.data.venueXid))
			);
			let thematicData = quote.filter((e) =>
				THEMATIC.includes(parseInt(e.data.venueXid))
			);
			let stockConnectData = quote.filter((e) =>
				STOCKCONNECT.includes(parseInt(e.data.venueXid))
			);
			setData((prev) => ({
				...prev,
				all: quote,
				flagship: flagshipData,
				core: coreData,
				esg: esgData,
				smartbeta: smartbetaData,
				thematic: thematicData,
				stockconnect: stockConnectData,
			}));
		}
	}, [loading, quote]);

	const setVenueXidsForChart = (chartDataArray, selectedTab) => {
		let chartData = [];
		chartDataArray.forEach((element, index) => {
			chartData.push(BENCHMARKS.find((x) => x.id === element.venueXid));
			chartData[index].color = element.color;
		});
		fillDatainTab(chartData, selectedTab);
	};
	const fillDatainTab = (chartData, selectedTab) => {
		switch (selectedTab) {
			case "all":
				setVisibleIndexes((prevState) => ({ ...prevState, all: chartData }));
				break;
			case "flagship":
				setVisibleIndexes((prevState) => ({ ...prevState, flagship: chartData }));
				break;
			case "core":
				setVisibleIndexes((prevState) => ({ ...prevState, core: chartData }));
				break;
			case "esg":
				setVisibleIndexes((prevState) => ({ ...prevState, esg: chartData }));
				break;
			case "smartbeta":
				setVisibleIndexes((prevState) => ({ ...prevState, smartbeta: chartData }));
				break;
			case "thematic":
				setVisibleIndexes((prevState) => ({ ...prevState, thematic: chartData }));
				break;
			case "stockconnect":
				setVisibleIndexes((prevState) => ({ ...prevState, stockconnect: chartData }));
				break;
			default:
				break;
		}
	};

	const updateBenchmarks = (key, e) => {
		if (key === selectedTab) return;
		switch (key) {
			case "all":
				setBenchmarks((prev) => ({ ...prev, all: BENCHMARKS }));
				setSelectedTab("all");
				!activeTab.all && setActiveTab((st) => ({ ...st, all: true }));
				break;
			case "flagship":
				let core = BENCHMARKS.filter((e) => FLAGSHIP.includes(parseInt(e.id)));
				setBenchmarks((prev) => ({ ...prev, flagship: core }));
				setSelectedTab("flagship");
				!activeTab.flagship && setActiveTab((st) => ({ ...st, flagship: true }));
				break;
			case "core":
				let flagships = BENCHMARKS.filter((e) => CORE.includes(parseInt(e.id)));
				setBenchmarks((prev) => ({ ...prev, core: flagships }));
				setSelectedTab("core");
				!activeTab.core && setActiveTab((st) => ({ ...st, core: true }));
				break;
			case "esg":
				let esg = BENCHMARKS.filter((e) => ESG.includes(parseInt(e.id)));
				setBenchmarks((prev) => ({ ...prev, esg: esg }));
				setSelectedTab("esg");
				!activeTab.esg && setActiveTab((st) => ({ ...st, esg: true }));
				break;
			case "smartbeta":
				let smartbeta = BENCHMARKS.filter((e) => SMARTBETA.includes(parseInt(e.id)));
				setBenchmarks((prev) => ({ ...prev, smartbeta: smartbeta }));
				setSelectedTab("smartbeta");
				!activeTab.smartbeta && setActiveTab((st) => ({ ...st, smartbeta: true }));
				break;
			case "thematic":
				let thematic = BENCHMARKS.filter((e) => THEMATIC.includes(parseInt(e.id)));
				setBenchmarks((prev) => ({ ...prev, thematic: thematic }));
				setSelectedTab("thematic");
				!activeTab.thematic && setActiveTab((st) => ({ ...st, thematic: true }));
				break;
			case "stockconnect":
				let stockconnect = BENCHMARKS.filter((e) =>
					STOCKCONNECT.includes(parseInt(e.id))
				);
				setBenchmarks((prev) => ({ ...prev, stockconnect: stockconnect }));
				setSelectedTab("stockconnect");
				!activeTab.stockconnect && setActiveTab((st) => ({ ...st, stockconnect: true }));
				break;
			default:
				break;
		}
	};
	const [api, setAPI] = useState(null);
	const getApi = async () => {
		const apiInstance = await RequestService.init();
		setAPI(apiInstance);
	};
	const [inceptiondate, setInceptiondate] = useState();

	useEffect(() => {
		if (!api) {
			getApi();
		}
	}, []);

	useEffect(async () => {
		if (api) {
			const authToken = await Utils.getValidMDToken();
			await api
				.get(
					`${api.baseUrl}${CONSTANTS.URLS.CHARTWORKS_SERIES_PATH}/chartapi/inceptiondate?symbol=${benchmarks.all[0].id}&access_token=${authToken}`
				)
				.then((res) => {
					if (res.data) {
						var oaDate = res.data.InceptionDate;
						var date = new moment("1899-12-30", "YYYY-MM-DD").add(oaDate, "days");
						setInceptiondate(date);
					}
				});
		}
	}, [api]);

	return (
		<>
			<Card>
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Equity Index Performance")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				<Card.Body className="card-body-equity-index">
					<Tabs
						defaultActiveKey="all"
						transition={false}
						onSelect={(k) => updateBenchmarks(k)}
						className="equity-index-tab"
					>
						<Tab eventKey="all" title={t("All")}>
							{activeTab.all && (
								/* Separting all the data as per the tabs so that there should not be any dependency between these */
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.all}
												benchmarks={benchmarks.all}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.all}
											type="all"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.all}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="flagship" title={t("Most Viewed")}>
							{activeTab.flagship && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.flagship}
												benchmarks={benchmarks.flagship}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.flagship}
											type="flagship"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.flagship}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="core" title={t("Popular")}>
							{activeTab.core && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.core}
												benchmarks={benchmarks.core}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.core}
											type="core"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.core}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="esg" title={t("ESG")}>
							{activeTab.esg && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.esg}
												benchmarks={benchmarks.esg}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.esg}
											error={error}
											type="esg"
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.esg}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="smartbeta" title={t("Smart Beta")}>
							{activeTab.smartbeta && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.smartbeta}
												benchmarks={benchmarks.smartbeta}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.smartbeta}
											type="smartbeta"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.smartbeta}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="thematic" title={t("Thematic")}>
							{activeTab.thematic && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.thematic}
												benchmarks={benchmarks.thematic}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.thematic}
											type="thematic"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.thematic}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
						<Tab eventKey="stockconnect" title={t("Stock Connect")}>
							{activeTab.stockconnect && (
								<Row>
									<Col xs={12} md={12} lg={6} xl={6} className="pr-gt-md-20">
										<Card.Body className="card-body-chart pt-20">
											<LandingChart
												initstyles={initstyles}
												visibleIndexes={visibleIndexes.stockconnect}
												benchmarks={benchmarks.stockconnect}
												inceptiondate={inceptiondate}
												selectedTimeframe={setSelectedtimeframe}
											/>
										</Card.Body>
									</Col>
									<Col xs={12} md={12} lg={6} xl={6} className="pl-gt-md-20">
										<EquityIndexTable
											data={data.stockconnect}
											type="stockconnect"
											error={error}
											loading={loading}
											fetchedAt={fetchedAt}
											setVenueXidsForChart={setVenueXidsForChart}
											initstyles={initstyles}
											benchmarks={benchmarks.stockconnect}
											selectedTimeframe={selectedtimeframe}
										/>
									</Col>
								</Row>
							)}
						</Tab>
					</Tabs>
				</Card.Body>
			</Card>
		</>
	);
};

export default EquityIndexSection;

import { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import "./AnchorMenu.scss";
import anchorCloseBtn from "assets/img/anchorCloseBtn.png";
import anchorbtn from "assets/img/anchorbtn.png";
import FEATURE_FLAGS from "utils/feature-flags";
import { useTranslation } from "react-i18next";

const AnchorMenu = ({ showAnchorMenu, setShowAnchorMenu }) => {
	const { t } = useTranslation();
	const [selectedSection, setSelectedSection] = useState(null);
	const [menuTimeout, setMenuTimeout] = useState(null);

	useEffect(() => {
		window.addEventListener("mousewheel", removeSection);
		return () => {
			window.removeEventListener("mousewheel", removeSection); //clean up
		};
	}, []);

	const removeSection = () => {
		setSelectedSection(null);
	};

	const toggleAnchorMenu = (show) => {
		setShowAnchorMenu(show);
	};
	const handleSelection = (selection) => {
		setSelectedSection(selection);
	};
	const renderAnchorItems = (name, displayText) => {
		let itemcClass = "nav-item";
		if (selectedSection && selectedSection === name)
			itemcClass = "nav-item nav-item-selected";

		return (
			<div
				className={itemcClass}
				data-testid={`quick-menu-${name}`}
				onClick={() => handleSelection(name)}
			>
				<HashLink className="nav-link anchor-link" to={`#${name}`}>
					{t(displayText)}
				</HashLink>
			</div>
		);
	};

	const onMouseLeave = () => {
		let timer = setTimeout(() => {
			setShowAnchorMenu(false);
		}, 1500);
		setMenuTimeout(timer);
	};

	const onMouseEnter = () => {
		clearTimeout(menuTimeout);
	};

	return (
		<aside>
			<div
				className="quickMenu d-flex"
				data-testid="quick-menu"
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
			>
				{!showAnchorMenu && (
					<div className="quickMenuControlBox d-flex">
						<img
							src={anchorbtn}
							alt={t("Quick menu")}
							className="anchorIcon"
							data-testid="open-quick-menu"
							onClick={() => toggleAnchorMenu(true)}
						/>
					</div>
				)}
				{showAnchorMenu && (
					<>
						<div className="quickMenuControlBox d-flex">
							<img
								src={anchorCloseBtn}
								alt={t("Close quick menu")}
								className="anchorIcon"
								data-testid="close-quick-menu"
								onClick={() => toggleAnchorMenu(false)}
							/>
						</div>
						<div className="anchor d-flex">
							{FEATURE_FLAGS.ABOUT_SECTION && renderAnchorItems("about", "About")}
							{(FEATURE_FLAGS.CHARTING_SECTION || FEATURE_FLAGS.FUNDAMENTALS_SECTION) &&
								renderAnchorItems("performance", "Performance")}
							{FEATURE_FLAGS.HOLDING_ANALYSIS &&
								renderAnchorItems("holding-analysis", "Holdings Analysis")}
							{FEATURE_FLAGS.GEOCHART_SECTION &&
								renderAnchorItems("institution-region", "Institution Region")}
							{FEATURE_FLAGS.INSTITUTION_STYLE &&
								renderAnchorItems("institution-style", "Institution Style")}
							{FEATURE_FLAGS.CORPORATE_OWNERSHIP &&
								renderAnchorItems("corporate-ownership", "Corporate Ownership")}
							{FEATURE_FLAGS.PEER_SHARE_HOLDERS &&
								renderAnchorItems("peer-shareholders", "Peer Shareholders")}
							{FEATURE_FLAGS.PEER_COMPARISON &&
								renderAnchorItems("peer-comparison", "Peer Comparison")}
							{FEATURE_FLAGS.EARNINGS && renderAnchorItems("earnings", "Earnings")}
							{FEATURE_FLAGS.RESEARCH_RATINGS &&
								renderAnchorItems("research-ratings", "Research Ratings")}
							{FEATURE_FLAGS.CORPORATE_CALENDAR &&
								renderAnchorItems("corporate-calender", "Corporate Calendar")}
							{FEATURE_FLAGS.COMPANY_ANNOUNCEMENTS &&
								renderAnchorItems("company-announcements", "Company Announcements")}
							{FEATURE_FLAGS.RECENT_NEWS &&
								renderAnchorItems("recent-news", "Recent News")}

							<div className="nav-item">
								<HashLink
									className="nav-link anchor-link"
									data-testid="quick-menu-go-to-top"
									to="#root"
								>
									{t("Go to Top")}
								</HashLink>
							</div>
						</div>
					</>
				)}
			</div>
		</aside>
	);
};

export default AnchorMenu;

import { useEffect, useState } from "react";
import RequestService from "services/RequestService";
import Constants from "utils/constants";
import utils from "utils/utils";

const useCorporateCalenderEvents = (venueXid, isPublicPage = false) => {
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const [pastEvents, setPastEvents] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetchedAt, setFetchedAt] = useState(null);
	const [errorUpcomingEvents, setErrorUpcomingEvents] = useState(null);
	const [errorPastEvents, setErrorPastEvents] = useState(null);

	const getApiInstance = async () => {
		return RequestService.init();
	};

	const loadFutureEvents = async () => {
		let limit = isPublicPage ? 4 : 9;
		let Endpoint = `${Constants.URLS.FACTSET_CALENDAR_FUTURE_EVENTS}${venueXid}?limit=${limit}&offset=0`;
		let api = await getApiInstance();
		return api.get(Endpoint);
	};

	const loadPastEvents = async (limit, offset = 0) => {
		let Endpoint = `${Constants.URLS.FACTSET_CALENDAR_HISTORICAL_EVENTS}${venueXid}?limit=${limit}&offset=${offset}`;
		let api = await getApiInstance();
		return api.get(Endpoint);
	};

	const loadPastEventData = async () => {
		await setErrorPastEvents(null);
		let pastEventResponse = null;
		let limit = 100;
		let offset = 0;
		try {
			pastEventResponse = await loadPastEvents(limit, offset);
			if (pastEventResponse?.status === 200) {
				let totalCount = pastEventResponse?.data?.data?.totalCount;
				let pastEventsLocal = pastEventResponse?.data?.data?.events;
				offset += 100;

				while (offset < totalCount) {
					pastEventResponse = await loadPastEvents(limit, offset);
					if (pastEventResponse?.status === 200) {
						let events = pastEventResponse?.data?.data?.events;
						pastEventsLocal = [...pastEventsLocal, ...events];
						offset += 100;
					}
				}

				// load only past 90 days data.
				let threeMonthBeforeDate = new Date();
				threeMonthBeforeDate.setDate(threeMonthBeforeDate.getDate() - 90);

				pastEventsLocal = pastEventsLocal.filter(
					(x) => new Date(x.date) >= threeMonthBeforeDate
				);

				if (pastEventsLocal) {
					setPastEvents(pastEventsLocal);
				}
			}
		} catch (ex) {
			setErrorPastEvents(ex);
			setPastEvents([]);
			console.log(ex);
		}
	};

	const loadUpcomingEventData = async () => {
		await setErrorUpcomingEvents(null);
		let upcomingEventResponse = null;
		try {
			upcomingEventResponse = await loadFutureEvents();
			if (upcomingEventResponse?.status === 200) {
				let upcomingEventsLocal = upcomingEventResponse?.data?.data?.events;

				if (upcomingEventsLocal) {
					setUpcomingEvents(upcomingEventsLocal);
				}
			}
		} catch (ex) {
			setErrorUpcomingEvents(ex);
			setUpcomingEvents([]);
			console.log(ex);
		}
	};

	const init = async () => {
		await setLoading(true);
		await loadUpcomingEventData();
		if (!isPublicPage) {
			await loadPastEventData();
		}
		setFetchedAt(utils.getHKTTime());
		await setLoading(false);
	};

	useEffect(() => {
		init();
	}, [venueXid, isPublicPage]);

	return {
		upcomingEvents,
		pastEvents,
		fetchedAt,
		loading,
		errorPastEvents,
		errorUpcomingEvents,
	};
};

export default useCorporateCalenderEvents;

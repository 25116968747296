import { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./MarketToday.scss";
import { useTranslation } from "react-i18next";
import useQuote from "components/hooks/useQuote";
import MarketCard from "./MarketCard/MarketCard";
import { getDeviceType, handleWindowResize, deviceType } from "utils/utils";
import { TimeDetails, MARKET_TODAY } from "./MarketTodayConstant";
import moment from "moment";
import "locale/CommonLocale";

const MarketToday = ({ benchmarks, loading, error }) => {
	const { t } = useTranslation();
	const trackRef = useRef(null);
	const [device, setDevice] = useState(getDeviceType());
	let indexesArray = benchmarks
		.filter((x) => MARKET_TODAY.includes(x.id))
		.map(({ id }) => id);

	const {
		quote,
		error: quoteError,
		loading: quoteLoading,
		fetchedAt,
	} = useQuote(indexesArray.toString());
	useEffect(() => {
		handleWindowResize(resizeHandler);
	}, [device]);

	const resizeHandler = (response) => {
		setDevice(response);
	};

	return (
		<div className="carousel-container">
			<Row>
				<Col className="carousel-nav">
					<h2>{t("Equity Market Overview")}</h2>
				</Col>
			</Row>
			<Row className="carousel-inner multi-carousel">
				<div
					ref={trackRef}
					data-testid="marketCardData"
					className={device !== deviceType.Mobile ? "track" : "track scrolling-wrapper"}
				>
					<MarketCard
						data={quote}
						error={error || quoteError}
						loading={loading || quoteLoading}
						benchmarks={benchmarks}
					/>
				</div>
			</Row>
			<Row>
				<div data-testid="marketTodayAsOfDate-testid" className="timeDetails">
					{TimeDetails(t, moment(fetchedAt).locale(t("lang")).format(t("format")))}
				</div>
			</Row>
		</div>
	);
};
export default MarketToday;

import { useState, useEffect, useContext } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";
import { PeersContext } from "components/contexts/usePeersContext";

const useInstrumentList = (setPeerInstrumentXidList) => {
	const { peersDataXREF, userPrefPeers } = useContext(PeersContext);

	const [instrumentListError, setInstrumentListError] = useState(null);
	const [instrumentListLoading, setInstrumentListLoading] = useState(false);
	const [instrumentListFetchedAt, setInstrumentListFetchedAt] = useState(true);

	useEffect(() => {
		// Check if user pref is there than execute effect only when peerDataXREF is equal to userPref
		if (userPrefPeers) {
			let commaSepratedUserPrefPeers = userPrefPeers
				.filter((x) => x && x.venueXid && x.isVisible)
				.map((x) => x.venueXid.toString());
			let commaSepratedPeers = peersDataXREF
				? peersDataXREF
						.filter((x) => x && x.venueXid && x.isVisible)
						.map((x) => x.venueXid.toString())
				: [];

			if (String(commaSepratedUserPrefPeers) !== String(commaSepratedPeers)) {
				return;
			}
		}

		let peerXids =
			peersDataXREF && peersDataXREF.length > 0
				? peersDataXREF
						.filter((data) => data && data.isVisible && data.name)
						.map((x) => x.venueXid)
						.join(",")
				: "";

		if (!peerXids) {
			setInstrumentListLoading(false);
			setPeerInstrumentXidList(null);
			return;
		}
		const apiUrl = `${Constants.URLS.XREF_VENUES_IDS_PATH}?venueXids=${peerXids}`;
		setInstrumentListLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (
					(response.status === 200 || response.status === 206) &&
					response?.data?.data?.items
				) {
					let instrumentListData = response.data.data.items;
					instrumentListData = instrumentListData.filter((x) => !x.error);
					let instrumentxidList = [];
					instrumentListData.forEach((element) => {
						instrumentxidList.push(element.xids.instrument);
					});
					instrumentxidList = String(instrumentxidList);
					setPeerInstrumentXidList(instrumentxidList);
				} else {
					setPeerInstrumentXidList(null);
					throw response;
				}
			} catch (e) {
				setPeerInstrumentXidList(null);
				setInstrumentListError(e);
			} finally {
				setInstrumentListLoading(false);
				setInstrumentListFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [peersDataXREF]);

	return {
		instrumentListError,
		instrumentListLoading,
		instrumentListFetchedAt,
	};
};

export default useInstrumentList;

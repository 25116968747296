import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";
import RankingSets from "Constants/RankingSets";

const useRankingSet = (ranking, includeMicroCapStocks, rankingType) => {
	const [rankingSet, setRankingSet] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [fetchedAt, setFetchedAt] = useState(true);

	const getAPIUrl = (key, includeMicroCapStocks) => {
		let rankSet = RankingSets.find((f) => f.key === key);
		let apiUrl = "";
		apiUrl = `${Constants.URLS.MARKET_RANKINGS}?rankingSet=${rankSet.rankingFilter}&rankingType=${rankingType}`;

		if (rankSet.key === "AllEquities") {
			if (includeMicroCapStocks) {
				apiUrl = `${apiUrl}&rankingMinPrice=0.001`;
			} else {
				apiUrl = `${apiUrl}&rankingMinPrice=4`;
			}
		}
		apiUrl = `${apiUrl}&count=10`;
		return apiUrl;
	};

	useEffect(() => {
		if (!ranking || !rankingType) return;
		let apiUrl = getAPIUrl(ranking.key, includeMicroCapStocks);
		setLoading(true);

		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.data?.marketRankings) {
					let symbols = response.data.data.marketRankings.map((m) => m.venueXid);
					const apiXRefUrl = `${Constants.URLS.XREF_VENUES_IDS_PATH}?venueXids=${symbols}`;
					const responseXRef = await apiInstance.get(apiXRefUrl);
					if (responseXRef.status === 200 && responseXRef?.data?.data?.items) {
						const xRefMapping = responseXRef.data.data.items;
						let rankings = response.data.data.marketRankings.map(function (item) {
							let { symbol, name } = xRefMapping.find(
								(f) => f.xids.venue === item.venueXid
							);
							return {
								symbol: symbol,
								name: name,
								lastTradePrice: item.lastTrade.last,
								lastTradeDate: item.lastTrade.dateUtc,
								change: item.lastTrade.change,
								changePercent: item.changePercent.today,
							};
						});
						setRankingSet(rankings);
					}
				} else {
					throw response;
				}
				setError(null);
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [ranking, rankingType, includeMicroCapStocks]);

	return { rankingSet, error, loading, fetchedAt };
};

export default useRankingSet;

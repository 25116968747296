import React from "react";
import errorIcon from "assets/img/ErrorIconSignup.png";

const ValidationLabel = ({ id, message }) => {
	return (
		<label id={id} className="error" hidden={true}>
			<img src={errorIcon} alt="errorIcon" /> {message}
		</label>
	);
};

export default ValidationLabel;

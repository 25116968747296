import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ArrowColor } from ".././MarketToday/MarketTodayConstant";
import { useTranslation } from "react-i18next";
import hkexLogo from "assets/img/HKEX_Logo.svg";
import spgLogo from "assets/img/SPG_Logo.svg";
import "./Login.scss";

const Login = (props) => {
	const { t } = useTranslation();
	const [agree, setAgree] = useState(false);
	const { venueXid } = useParams();
	const onLoginClick = () => {
		if (venueXid) {
			sessionStorage.setItem("venueXid", venueXid);
			sessionStorage.setItem("loggedInFromPublic", "true");
			sessionStorage.setItem("intermediator", "true");
		}
		window.open(props.signInUrl, "_self");
	};
	const onAgree = () => {
		setAgree(!agree);
	};
	const onClosePopup = () => {
		setAgree(false);
		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="md"
			backdrop="static"
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName={"modalContainer hk-sectionhdr mb-full-screen"}
			centered
		>
			<Modal.Header className={"header"}>
				<button
					data-testid="close-btn"
					type="button"
					class="btn-close"
					aria-label="Close"
					onClick={onClosePopup}
				></button>
			</Modal.Header>
			<Modal.Body className={"body"}>
				<div className="login-title">
					<h5 className="login-text">{t("Login Title")}</h5>
					<div className="hk-sectionhdr-line login-text-bar"></div>
				</div>
				<div className="login-msg">{t("Login Message")}</div>
				<div className="agree-msg">
					<div>
						<input type="checkbox" className="agree-checkbox" onClick={onAgree} />
						<span>{t("Login Agreement Message")}</span>
					</div>
					<button
						className="btn-primary agree-btn"
						disabled={!agree}
						onClick={onLoginClick}
					>
						{t("Continue to Login")}
					</button>
				</div>
				<div className="signup-msg">
					<span>{t("Don't have an account?")}</span>
					<button
						data-testid="toggle-btn"
						id="signup-button"
						onClick={() => {
							props.makeSignupVisible();
						}}
					>
						{t("Signup")}
					</button>
					<button data-testid="scrollRightButton" aria-label={t("Scroll Right")}>
						<FontAwesomeIcon icon={faChevronRight} color={ArrowColor.RED} />
					</button>
				</div>
			</Modal.Body>
			<Modal.Footer className="footer">
				<img src={hkexLogo} alt="HKEX Logo" className="hkex-logo"></img>
				<div className="spg-footer">
					<span className="managed-by">{t("Managed by:")}</span>
					<img src={spgLogo} alt="SPG Logo" className="spg-logo"></img>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default Login;

import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SymbolContext } from "../components/hooks/useSymbol";
import { AuthContext } from "components/hooks/useAuthentication";
import Charting from "components/Charting/Charting";
import useQuote from "components/hooks/useQuote";
import Header from "components/Header/Header";
import FEATURE_FLAGS from "utils/feature-flags";
import PageProvider from "components/contexts/PageContext";
import { useHistory, useLocation } from "react-router";
import { LanguageContext } from "components/hooks/useLanguage";
import Utils from "../utils/utils";
import Footer from "components/Footer/Footer";
import Disclaimers from "components/Disclaimers/Disclaimers";
import CorporateCalender from "components/CorporateCalender/CorporateCalender";
import { useParams } from "react-router-dom";
import { PeersProvider } from "components/contexts/usePeersContext";

export default function PublicUser() {
	const { venueXid } = useContext(SymbolContext);
	const { quote } = useQuote(venueXid);
	const location = useLocation();
	const history = useHistory();
	const { setLanguage, selectedLangParam } = useContext(LanguageContext);
	const { search } = location;
	let lang = new URLSearchParams(search).get("lang");
	const [firstTime, setFirstTime] = useState(true);
	const { isAuthenticated } = useContext(AuthContext);

	const params = useParams();
	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if (selectedLangParam !== currentLang || lang === null || lang === "") {
			setLanguage(currentLang);
			if (lang === null || lang === "")
				history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		}
		setFirstTime(false);
	}, [lang]);

	useEffect(() => {
		let currentLang = Utils.GetCurrentLang(lang);
		if ((currentLang !== "en" && lang === null) || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${currentLang}` });
		} else if (!firstTime || lang === null || lang === "") {
			history.push({ pathname: location.pathname, search: `lang=${selectedLangParam}` });
		}
	}, [selectedLangParam]);
	useEffect(() => {
		history.listen((locate, action) => {
			let patharray = locate.pathname.split("/");
			if (action === "POP" && patharray.length > 2 && patharray.includes("public")) {
				if (
					patharray[patharray.findIndex((x) => x === "public") + 1] !==
					localStorage.getItem("venue").toString()
				) {
					history.replace("/");
				}
			}
		});
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			let venue = history.location.pathname.split("/")[2];
			if (venue) {
				history.replace(`/profile/${venue}`);
			}
		}
	}, [isAuthenticated]);

	useEffect(() => {
		let arr = document.getElementsByClassName("chartist-tooltip");
		for (let i of arr) {
			i.style.display = "none";
		}
	}, []);

	return (
		<PageProvider>
			{FEATURE_FLAGS.HEADER_SECTION && <Header></Header>}
			<section className="bg-white pt-40">
				<Container>
					<Row className="card-wrapper">
						{FEATURE_FLAGS.CHARTING_SECTION && (
							<Col>
								<Charting
									key={venueXid}
									venueXid={venueXid}
									loggedin={false}
									quote={quote}
								/>
							</Col>
						)}
					</Row>
					<Row>
						<Col className="card-wrapper public-corporate-calender">
							<CorporateCalender
								isPublicPage={true}
								venueXid={venueXid || params.venueXid}
							></CorporateCalender>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className="bg-gray disclaimerContainer">
					<Row className="card-wrapper bg-gray gy-0 gx-0">
						<Col>
							<Disclaimers />
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Footer></Footer>
			</section>
		</PageProvider>
	);
}

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import "./IndicatorPopUp.scss";
import { INFO, INDICATOR_INPUT_CONFIG, TEXT_DICT } from "./IndicatorInfo";
import addIcon from "assets/img/add-icon.png";

const IndicatorPopUp = ({ onClose, config, onApply, addAnother, indicatorCount }) => {
	const [info, setInfo] = useState();
	const { inputs } = INDICATOR_INPUT_CONFIG[config.name];
	const [textDict] = useState(JSON.parse(JSON.stringify(TEXT_DICT[config.name])));
	const [params] = useState(config.config);
	const { t } = useTranslation();
	let pointer = null;

	useEffect(() => {
		document.getElementById("indicator-popup").focus();
	}, []);

	const onBlurCustom = () => {
		pointer = setTimeout(() => {
			onClose();
		}, 200);
	};

	const getInfo = () => setInfo(INFO.find((ele) => ele.id === config.name).info);

	useEffect(() => {
		getInfo();
	}, [config]);

	const checkInput = (e) => {
		let len;
		let deciPlaces;
		if (!e) return;
		if (e.target && e.target.value) {
			deciPlaces = e.target.value.indexOf(".") + 3;
			len = e.target.value.length + 1;
		}

		//Restricting upto 2 decimal places
		if (
			len > deciPlaces &&
			e.target &&
			e.target.value &&
			e.target.value.includes(".") &&
			e.keyCode !== 8 &&
			e.keyCode !== 46
		)
			e.preventDefault();

		//Only numbers , - at first position , one . , no ctrl+v ,
		if (
			(e.target &&
				e.target.value &&
				((e.key === "." && e.target.value.includes(".")) ||
					(e.key === "-" && e.target.value.length > 0))) ||
			((e.keyCode < 48 || e.keyCode > 57) &&
				(e.keyCode < 96 || e.keyCode > 105) &&
				e.keyCode !== 8 &&
				e.keyCode !== 46 &&
				e.key !== "." &&
				e.key !== "-") ||
			e.key === "!" ||
			e.key === "@" ||
			e.key === "#" ||
			e.key === "$" ||
			e.key === "%" ||
			e.key === "^" ||
			e.key === "&" ||
			e.key === "*" ||
			e.key === "(" ||
			e.key === ")" ||
			(e.keyCode === 86 && !!e.ctrlKey)
		) {
			e.preventDefault();
		}
	};

	const setDefault = (input) => {
		params.inputs.forEach((ele) => {
			if (ele.name === input.name.toLowerCase() && ele.value) {
				input.default = ele.value;
				textDict[input.name] = ele.value;
			}
			if (ele.name === "standardDeviations" && input.name === "Standard Deviations") {
				input.default = ele.value;
				textDict["StandardDeviations"] = ele.value;
			}
		});
	};

	let inputItems = Object.keys(inputs).map((key) => {
		let input = inputs[key];
		setDefault(input);
		switch (key.replace(/[0-9]/g, "")) {
			case "number":
				return (
					<div className="input row">
						<div className="inputLabels col-6">
							<label>{t(input.name)}</label>
						</div>
						<div className="inputBoxes col-6">
							<input
								tabIndex="0"
								type="text"
								name={input.name}
								defaultValue={input.default}
								aria-label={input.name}
								onFocus={() => clearTimeout(pointer)}
								onKeyDown={(e) => {
									if (e.code !== "Tab") checkInput(e);
								}}
								onChange={(e) => onTextNumberChange(e, input)}
							></input>
						</div>
						{input.name === "Period" && (
							<label id="errorPeriod" className="error" hidden={true}>
								* {t("Period must be greater than zero")}
							</label>
						)}
						{input.name === "Lowfactor" && (
							<label id="errorLowfactor" className="error" hidden={true}>
								* {t("Lowfactor must be less than highfactor")}
							</label>
						)}
						{input.name === "Highfactor" && (
							<label id="errorHighfactor" className="error" hidden={true}>
								* {t("Highfactor must be greater than lowfactor")}
							</label>
						)}
					</div>
				);
			case "toggle":
				return (
					<div className="input row">
						<div className="inputLabels col-6">
							<label>{t(input.name)}</label>
						</div>
						<div className="inputBoxes col-6">
							<button
								id="sma"
								tabIndex="0"
								onFocus={() => clearTimeout(pointer)}
								className={`first ${
									textDict["Averagetype"] === "sma" ? "selected" : "toggle"
								}`}
								onClick={() => {
									input.default = "sma";
									textDict["Averagetype"] = "sma";
									let el = document.getElementById("sma");
									let ele = document.getElementById("ema");
									el.classList.add("selected");
									el.classList.remove("toggle");
									ele.classList.add("toggle");
									ele.classList.remove("selected");
								}}
							>
								{"SMA"}
							</button>
							<button
								id="ema"
								tabIndex="0"
								onFocus={() => clearTimeout(pointer)}
								className={`second ${
									textDict["Averagetype"] === "ema" ? "selected" : "toggle"
								}`}
								onClick={() => {
									input.default = "ema";
									textDict["Averagetype"] = "ema";
									let el = document.getElementById("ema");
									let ele = document.getElementById("sma");
									el.classList.add("selected");
									el.classList.remove("toggle");
									ele.classList.add("toggle");
									ele.classList.remove("selected");
								}}
							>
								{"EMA"}
							</button>
						</div>
					</div>
				);
			default:
				break;
		}
	});

	const onTextNumberChange = (e, input) => {
		Object.keys(textDict).forEach((key) => {
			if (
				key === e.target.name ||
				(key === "StandardDeviations" && e.target.name === "Standard Deviations")
			) {
				e.target.value !== ""
					? (textDict[key] = e.target.value)
					: (textDict[key] = input.default);
			}
		});

		//Validation on values
		let el = document.getElementById(`error${input.name}`);

		switch (input.name) {
			case "Period":
				if ((e.target.value < 0 && e.target.value !== "") || e.target.value === "-")
					el.removeAttribute("hidden");
				else el.setAttribute("hidden", true);
				break;
			case "Lowfactor":
				if (
					(parseFloat(e.target.value) > parseFloat(textDict.Highfactor) &&
						e.target.value !== "") ||
					e.target.value === "-"
				)
					el.removeAttribute("hidden");
				else {
					document.getElementById("errorHighfactor").setAttribute("hidden", true);
					el.setAttribute("hidden", true);
				}
				break;
			case "Highfactor":
				if (
					(parseFloat(e.target.value) < parseFloat(textDict.Lowfactor) &&
						e.target.value !== "") ||
					e.target.value === "-"
				)
					el.removeAttribute("hidden");
				else {
					document.getElementById("errorLowfactor").setAttribute("hidden", true);
					el.setAttribute("hidden", true);
				}
				break;
			default:
				break;
		}
	};

	const apply = () => {
		onApply(textDict, "upper");
	};

	if (!config.isOpen) return null;
	else
		return (
			<div
				id="indicator-popup"
				className="indicator-popup"
				tabIndex="0"
				onBlur={() => onBlurCustom()}
				onFocus={() => clearTimeout(pointer)}
			>
				<div className="content">
					{t(info)}
					<div className="inputs">{inputItems}</div>
					<div className="controls d-flex justify-content-end">
						{["sma", "ema"].includes(config.name) && indicatorCount[config.name] < 2 && (
							<button
								tabIndex="0"
								className="add"
								onClick={() => {
									addAnother(config.name);
									onClose();
								}}
							>
								<img src={addIcon} className="addIcon" alt="add-icon" />
								{t("Add Another")}
							</button>
						)}
						<div className="cancel">
							<button tabIndex="0" onClick={onClose}>
								{t("Cancel")}
							</button>
						</div>
						<div className="apply">
							<button
								tabIndex="0"
								onClick={() => {
									if (
										(config.name === "mae" &&
											document.getElementById("errorPeriod").hidden === true &&
											document.getElementById("errorLowfactor").hidden === true &&
											document.getElementById("errorHighfactor").hidden === true) ||
										(config.name !== "mae" &&
											document.getElementById("errorPeriod").hidden === true)
									)
										apply();
								}}
							>
								{t("APPLY")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
};

export default IndicatorPopUp;

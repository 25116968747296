const COUNTRIES = [
	{ code: "HK", label: "Hong Kong SAR" },
	{ code: "CN", label: "People's Republic of China" },
	{ code: "US", label: "United States" },
	{ code: "GB", label: "United Kingdom" },
	{ code: "CA", label: "Canada" },
	{ code: "AF", label: "Afghanistan" },
	{ code: "AC", label: "Ascension Islands" },
	{ code: "AX", label: "Aland Islands" },
	{ code: "AL", label: "Albania" },
	{ code: "DZ", label: "Algeria" },
	{ code: "AS", label: "America Samoa" },
	{ code: "AD", label: "Andorra" },
	{ code: "AO", label: "Angola" },
	{ code: "AI", label: "Anguilla" },
	{ code: "AQ", label: "Antarctica" },
	{ code: "AG", label: "Antigua and Barbuda" },
	{ code: "AR", label: "Argentina" },
	{ code: "AM", label: "Armenia" },
	{ code: "AW", label: "Aruba" },
	{ code: "AU", label: "Australia" },
	{ code: "AT", label: "Austria" },
	{ code: "AZ", label: "Azerbaijan" },
	{ code: "BS", label: "Bahamas" },
	{ code: "BH", label: "Bahrain" },
	{ code: "BD", label: "Bangladesh" },
	{ code: "BB", label: "Barbados" },
	{ code: "BY", label: "Belarus" },
	{ code: "BE", label: "Belgium" },
	{ code: "BZ", label: "Belize" },
	{ code: "BJ", label: "Benin" },
	{ code: "BM", label: "Bermuda" },
	{ code: "BT", label: "Bhutan" },
	{ code: "BO", label: "Bolivia" },
	{ code: "BQ", label: "Sint Eustatius and Saba Bonaire" },
	{ code: "BA", label: "Bosnia and Herzegovina" },
	{ code: "BW", label: "Botswana" },
	{ code: "BR", label: "Brazil" },
	{ code: "IO", label: "British Indian Ocean Territory" },
	{ code: "BN", label: "Brunei Darussalam" },
	{ code: "BG", label: "Bulgaria" },
	{ code: "BF", label: "Burkina Faso" },
	{ code: "BU", label: "Burma" },
	{ code: "BI", label: "Burundi" },
	{ code: "KH", label: "Cambodia" },
	{ code: "CM", label: "Cameroon" },
	{ code: "CV", label: "Cape Verde" },
	{ code: "KY", label: "Cayman Islands" },
	{ code: "CF", label: "Central African Republic" },
	{ code: "TD", label: "Chad" },
	{ code: "CL", label: "Chile" },
	{ code: "CX", label: "Christmas Island" },
	{ code: "CC", label: "Cocos (Keeling) Islands" },
	{ code: "CO", label: "Colombia" },
	{ code: "KM", label: "Comoros" },
	{ code: "CG", label: "Congo" },
	{ code: "CD", label: "The Democratic Republic of The Congo" },
	{ code: "CK", label: "Cook Islands" },
	{ code: "CR", label: "Costa Rica" },
	{ code: "CI", label: "Cote D'Ivoire" },
	{ code: "HR", label: "Croatia" },
	{ code: "CW", label: "Curacao" },
	{ code: "CY", label: "Cyprus" },
	{ code: "CZ", label: "Czech Republic" },
	{ code: "DK", label: "Denmark" },
	{ code: "DJ", label: "Djibouti" },
	{ code: "DG", label: "Diego Garcia" },
	{ code: "DM", label: "Dominica" },
	{ code: "DO", label: "Dominican Republic" },
	{ code: "EC", label: "Ecuador" },
	{ code: "EG", label: "Egypt" },
	{ code: "SV", label: "El Salvador" },
	{ code: "GQ", label: "Equatorial Guinea" },
	{ code: "ER", label: "Eritrea" },
	{ code: "EE", label: "Estonia" },
	{ code: "ET", label: "Ethiopia" },
	{ code: "FK", label: "Falkland Islands (Malvinas)" },
	{ code: "FO", label: "Faroe Islands" },
	{ code: "FJ", label: "Fiji" },
	{ code: "FI", label: "Finland" },
	{ code: "FR", label: "France" },
	{ code: "GF", label: "French Guiana" },
	{ code: "PF", label: "French Polynesia" },
	{ code: "TF", label: "French Southern Territories" },
	{ code: "GA", label: "Gabon" },
	{ code: "GM", label: "Gambia" },
	{ code: "GE", label: "Georgia" },
	{ code: "DE", label: "Germany" },
	{ code: "GH", label: "Ghana" },
	{ code: "GI", label: "Gibraltar" },
	{ code: "GR", label: "Greece" },
	{ code: "GL", label: "Greenland" },
	{ code: "GD", label: "Grenada" },
	{ code: "GP", label: "Guadeloupe" },
	{ code: "GU", label: "Guam" },
	{ code: "GT", label: "Guatemala" },
	{ code: "GG", label: "Guernsey" },
	{ code: "GN", label: "Guinea" },
	{ code: "GW", label: "Guinea-Bissau" },
	{ code: "GY", label: "Guyana" },
	{ code: "HT", label: "Haiti" },
	{ code: "HM", label: "Heard Island and McDonald Islands" },
	{ code: "VA", label: "Holy See (Vatican City State)" },
	{ code: "HN", label: "Honduras" },
	{ code: "HU", label: "Hungary" },
	{ code: "IS", label: "Iceland" },
	{ code: "IN", label: "India" },
	{ code: "ID", label: "Indonesia" },
	{ code: "IQ", label: "Iraq" },
	{ code: "IE", label: "Ireland" },
	{ code: "IM", label: "Isle of Man" },
	{ code: "IL", label: "Israel" },
	{ code: "IT", label: "Italy" },
	{ code: "JM", label: "Jamaica" },
	{ code: "JP", label: "Japan" },
	{ code: "JE", label: "Jersey" },
	{ code: "JO", label: "Jordan" },
	{ code: "KZ", label: "Kazakhstan" },
	{ code: "KE", label: "Kenya" },
	{ code: "KI", label: "Kiribati" },
	{ code: "KR", label: "Republic of Korea" },
	{ code: "KW", label: "Kuwait" },
	{ code: "KG", label: "Kyrgyzstan" },
	{ code: "LA", label: "Lao People's Democratic Republic" },
	{ code: "LV", label: "Latvia" },
	{ code: "LB", label: "Lebanon" },
	{ code: "LS", label: "Lesotho" },
	{ code: "LR", label: "Liberia" },
	{ code: "LY", label: "Libya" },
	{ code: "LI", label: "Liechtenstein" },
	{ code: "LT", label: "Lithuania" },
	{ code: "LU", label: "Luxembourg" },
	{ code: "MO", label: "Macao SAR" },
	{ code: "MK", label: "The Former Yugoslav Republic Of Macedonia" },
	{ code: "MG", label: "Madagascar" },
	{ code: "MW", label: "Malawi" },
	{ code: "MY", label: "Malaysia" },
	{ code: "MV", label: "Maldives" },
	{ code: "ML", label: "Mali" },
	{ code: "MT", label: "Malta" },
	{ code: "MH", label: "Marshall Islands" },
	{ code: "MQ", label: "Martinique" },
	{ code: "MR", label: "Mauritania" },
	{ code: "MU", label: "Mauritius" },
	{ code: "YT", label: "Mayotte" },
	{ code: "MX", label: "Mexico" },
	{ code: "FM", label: "Federated States of Micronesia" },
	{ code: "MD", label: "Republic Of Moldova" },
	{ code: "MC", label: "Monaco" },
	{ code: "MN", label: "Mongolia" },
	{ code: "ME", label: "Montenegro" },
	{ code: "MS", label: "Montserrat" },
	{ code: "MA", label: "Morocco" },
	{ code: "MZ", label: "Mozambique" },
	{ code: "MM", label: "Myanmar" },
	{ code: "NA", label: "Namibia" },
	{ code: "NR", label: "Nauru" },
	{ code: "NP", label: "Nepal" },
	{ code: "NL", label: "Netherlands" },
	{ code: "NC", label: "New Caledonia" },
	{ code: "NZ", label: "New Zealand" },
	{ code: "NI", label: "Nicaragua" },
	{ code: "NE", label: "Niger" },
	{ code: "NG", label: "Nigeria" },
	{ code: "NU", label: "Niue" },
	{ code: "NF", label: "Norfolk Island" },
	{ code: "MP", label: "Northern Mariana Islands" },
	{ code: "NO", label: "Norway" },
	{ code: "OM", label: "Oman" },
	{ code: "PK", label: "Pakistan" },
	{ code: "PW", label: "Palau" },
	{ code: "PS", label: "Occupied Palestinian Territory" },
	{ code: "PA", label: "Panama" },
	{ code: "PG", label: "Papua New Guinea" },
	{ code: "PY", label: "Paraguay" },
	{ code: "PE", label: "Peru" },
	{ code: "PH", label: "Philippines" },
	{ code: "PN", label: "Pitcairn" },
	{ code: "PL", label: "Poland" },
	{ code: "PT", label: "Portugal" },
	{ code: "PR", label: "Puerto Rico" },
	{ code: "QA", label: "Qatar" },
	{ code: "RE", label: "Reunion" },
	{ code: "RO", label: "Romania" },
	{ code: "RU", label: "Russian Federation" },
	{ code: "RW", label: "Rwanda" },
	{ code: "BL", label: "Saint Barthelemy" },
	{ code: "SH", label: "Saint Helena" },
	{ code: "KN", label: "Saint Kitts and Nevis" },
	{ code: "LC", label: "Saint Lucia" },
	{ code: "MF", label: "St. Martin (French)" },
	{ code: "SX", label: "St Maarten (Dutch)" },
	{ code: "PM", label: "Saint Pierre and Miquelon" },
	{ code: "VC", label: "Saint Vincent and The Grenadines" },
	{ code: "WS", label: "Samoa" },
	{ code: "SM", label: "San Marino" },
	{ code: "ST", label: "Sao Tome and Principe" },
	{ code: "SA", label: "Saudi Arabia" },
	{ code: "SN", label: "Senegal" },
	{ code: "RS", label: "Serbia" },
	{ code: "SC", label: "Seychelles" },
	{ code: "SL", label: "Sierra Leone" },
	{ code: "SG", label: "Singapore" },
	{ code: "SK", label: "Slovakia" },
	{ code: "SI", label: "Slovenia" },
	{ code: "SB", label: "Solomon Islands" },
	{ code: "SO", label: "Somalia" },
	{ code: "ZA", label: "South Africa" },
	{ code: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ code: "SD", label: "Sudan" },
	{ code: "SS", label: "South Sudan" },
	{ code: "ES", label: "Spain" },
	{ code: "LK", label: "Sri Lanka" },
	{ code: "SR", label: "Suriname" },
	{ code: "SZ", label: "Swaziland" },
	{ code: "SE", label: "Sweden" },
	{ code: "CH", label: "Switzerland" },
	{ code: "TW", label: "Taiwan" },
	{ code: "TJ", label: "Tajikistan" },
	{ code: "TZ", label: "United Republic Of Tanzania" },
	{ code: "TH", label: "Thailand" },
	{ code: "TL", label: "Timor-Leste" },
	{ code: "TG", label: "Togo" },
	{ code: "TK", label: "Tokelau" },
	{ code: "TO", label: "Tonga" },
	{ code: "TT", label: "Trinidad and Tobago" },
	{ code: "TN", label: "Tunisia" },
	{ code: "TR", label: "Turkey" },
	{ code: "TM", label: "Turkmenistan" },
	{ code: "TC", label: "Turks and Caicos Islands" },
	{ code: "TV", label: "Tuvalu" },
	{ code: "UG", label: "Uganda" },
	{ code: "UA", label: "Ukraine" },
	{ code: "AE", label: "United Arab Emirates" },
	{ code: "UY", label: "Uruguay" },
	{ code: "UZ", label: "Uzbekistan" },
	{ code: "VU", label: "Vanuatu" },
	{ code: "VE", label: "Venezuela" },
	{ code: "VN", label: "Vietnam" },
	{ code: "VG", label: "British Virgin Islands" },
	{ code: "VI", label: "US Virgin Islands" },
	{ code: "WF", label: "Wallis and Futuna" },
	{ code: "EH", label: "Western Sahara" },
	{ code: "YE", label: "Yemen" },
	{ code: "ZR", label: "Zaire" },
	{ code: "ZM", label: "Zambia" },
	{ code: "ZW", label: "Zimbabwe" },
];

const PHONE_CODES = [
	{ code: "+86", label: "+86" },
	{ code: "+852", label: "+852" },
	{ code: "+1", label: "+1" },
	{ code: "+1242", label: "+1242" },
	{ code: "+1246", label: "+1246" },
	{ code: "+1264", label: "+1264" },
	{ code: "+1268", label: "+1268" },
	{ code: "+1345", label: "+1345" },
	{ code: "+1441", label: "+1441" },
	{ code: "+1473", label: "+1473" },
	{ code: "+1649", label: "+1649" },
	{ code: "+1664", label: "+1664" },
	{ code: "+1670", label: "+1670" },
	{ code: "+1671", label: "+1671" },
	{ code: "+1684", label: "+1684" },
	{ code: "+1758", label: "+1758" },
	{ code: "+1767", label: "+1767" },
	{ code: "+1784", label: "+1784" },
	{ code: "+1787", label: "+1787" },
	{ code: "+1809", label: "+1809" },
	{ code: "+1868", label: "+1868" },
	{ code: "+1869", label: "+1869" },
	{ code: "+1876", label: "+1876" },
	{ code: "+20", label: "+20" },
	{ code: "+211", label: "+211" },
	{ code: "+212", label: "+212" },
	{ code: "+213", label: "+213" },
	{ code: "+216", label: "+216" },
	{ code: "+218", label: "+218" },
	{ code: "+220", label: "+220" },
	{ code: "+221", label: "+221" },
	{ code: "+222", label: "+222" },
	{ code: "+223", label: "+223" },
	{ code: "+224", label: "+224" },
	{ code: "+226", label: "+226" },
	{ code: "+227", label: "+227" },
	{ code: "+228", label: "+228" },
	{ code: "+229", label: "+229" },
	{ code: "+230", label: "+230" },
	{ code: "+231", label: "+231" },
	{ code: "+232", label: "+232" },
	{ code: "+233", label: "+233" },
	{ code: "+234", label: "+234" },
	{ code: "+235", label: "+235" },
	{ code: "+236", label: "+236" },
	{ code: "+237", label: "+237" },
	{ code: "+238", label: "+238" },
	{ code: "+239", label: "+239" },
	{ code: "+240", label: "+240" },
	{ code: "+241", label: "+241" },
	{ code: "+244", label: "+244" },
	{ code: "+245", label: "+245" },
	{ code: "+248", label: "+248" },
	{ code: "+250", label: "+250" },
	{ code: "+251", label: "+251" },
	{ code: "+252", label: "+252" },
	{ code: "+253", label: "+253" },
	{ code: "+254", label: "+254" },
	{ code: "+256", label: "+256" },
	{ code: "+257", label: "+257" },
	{ code: "+258", label: "+258" },
	{ code: "+260", label: "+260" },
	{ code: "+261", label: "+261" },
	{ code: "+262", label: "+262" },
	{ code: "+263", label: "+263" },
	{ code: "+264", label: "+264" },
	{ code: "+265", label: "+265" },
	{ code: "+266", label: "+266" },
	{ code: "+267", label: "+267" },
	{ code: "+268", label: "+268" },
	{ code: "+269", label: "+269" },
	{ code: "+27", label: "+27" },
	{ code: "+290", label: "+290" },
	{ code: "+291", label: "+291" },
	{ code: "+297", label: "+297" },
	{ code: "+298", label: "+298" },
	{ code: "+299", label: "+299" },
	{ code: "+30", label: "+30" },
	{ code: "+31", label: "+31" },
	{ code: "+32", label: "+32" },
	{ code: "+33", label: "+33" },
	{ code: "+34", label: "+34" },
	{ code: "+350", label: "+350" },
	{ code: "+351", label: "+351" },
	{ code: "+352", label: "+352" },
	{ code: "+353", label: "+353" },
	{ code: "+354", label: "+354" },
	{ code: "+355", label: "+355" },
	{ code: "+356", label: "+356" },
	{ code: "+357", label: "+357" },
	{ code: "+358", label: "+358" },
	{ code: "+359", label: "+359" },
	{ code: "+36", label: "+36" },
	{ code: "+370", label: "+370" },
	{ code: "+371", label: "+371" },
	{ code: "+372", label: "+372" },
	{ code: "+374", label: "+374" },
	{ code: "+375", label: "+375" },
	{ code: "+376", label: "+376" },
	{ code: "+377", label: "+377" },
	{ code: "+378", label: "+378" },
	{ code: "+380", label: "+380" },
	{ code: "+381", label: "+381" },
	{ code: "+382", label: "+382" },
	{ code: "+385", label: "+385" },
	{ code: "+386", label: "+386" },
	{ code: "+39", label: "+39" },
	{ code: "+40", label: "+40" },
	{ code: "+41", label: "+41" },
	{ code: "+420", label: "+420" },
	{ code: "+421", label: "+421" },
	{ code: "+423", label: "+423" },
	{ code: "+43", label: "+43" },
	{ code: "+44", label: "+44" },
	{ code: "+45", label: "+45" },
	{ code: "+46", label: "+46" },
	{ code: "+47", label: "+47" },
	{ code: "+48", label: "+48" },
	{ code: "+49", label: "+49" },
	{ code: "+501", label: "+501" },
	{ code: "+502", label: "+502" },
	{ code: "+503", label: "+503" },
	{ code: "+504", label: "+504" },
	{ code: "+505", label: "+505" },
	{ code: "+506", label: "+506" },
	{ code: "+507", label: "+507" },
	{ code: "+508", label: "+508" },
	{ code: "+509", label: "+509" },
	{ code: "+51", label: "+51" },
	{ code: "+52", label: "+52" },
	{ code: "+54", label: "+54" },
	{ code: "+55", label: "+55" },
	{ code: "+56", label: "+56" },
	{ code: "+57", label: "+57" },
	{ code: "+590", label: "+590" },
	{ code: "+592", label: "+592" },
	{ code: "+593", label: "+593" },
	{ code: "+594", label: "+594" },
	{ code: "+595", label: "+595" },
	{ code: "+596", label: "+596" },
	{ code: "+597", label: "+597" },
	{ code: "+598", label: "+598" },
	{ code: "+599", label: "+599" },
	{ code: "+60 ", label: "+60 " },
	{ code: "+61", label: "+61" },
	{ code: "+62", label: "+62" },
	{ code: "+63", label: "+63" },
	{ code: "+64", label: "+64" },
	{ code: "+65", label: "+65" },
	{ code: "+66", label: "+66" },
	{ code: "+670", label: "+670" },
	{ code: "+672", label: "+672" },
	{ code: "+673", label: "+673" },
	{ code: "+674", label: "+674" },
	{ code: "+675", label: "+675" },
	{ code: "+676", label: "+676" },
	{ code: "+677", label: "+677" },
	{ code: "+678", label: "+678" },
	{ code: "+679", label: "+679" },
	{ code: "+680", label: "+680" },
	{ code: "+682", label: "+682" },
	{ code: "+683", label: "+683" },
	{ code: "+685", label: "+685" },
	{ code: "+686", label: "+686" },
	{ code: "+687", label: "+687" },
	{ code: "+688", label: "+688" },
	{ code: "+689", label: "+689" },
	{ code: "+690", label: "+690" },
	{ code: "+7", label: "+7" },
	{ code: "+76", label: "+76" },
	{ code: "+81", label: "+81" },
	{ code: "+82", label: "+82" },
	{ code: "+84", label: "+84" },
	{ code: "+853", label: "+853" },
	{ code: "+855", label: "+855" },
	{ code: "+856", label: "+856" },
	{ code: "+870", label: "+870" },
	{ code: "+880", label: "+880" },
	{ code: "+886", label: "+886" },
	{ code: "+90", label: "+90" },
	{ code: "+91", label: "+91" },
	{ code: "+92", label: "+92" },
	{ code: "+93", label: "+93" },
	{ code: "+94", label: "+94" },
	{ code: "+95", label: "+95" },
	{ code: "+960", label: "+960" },
	{ code: "+961", label: "+961" },
	{ code: "+962", label: "+962" },
	{ code: "+964", label: "+964" },
	{ code: "+965", label: "+965" },
	{ code: "+966", label: "+966" },
	{ code: "+968", label: "+968" },
	{ code: "+971", label: "+971" },
	{ code: "+972", label: "+972" },
	{ code: "+973", label: "+973" },
	{ code: "+974", label: "+974" },
	{ code: "+975", label: "+975" },
	{ code: "+976", label: "+976" },
	{ code: "+977", label: "+977" },
	{ code: "+992", label: "+992" },
	{ code: "+993", label: "+993" },
	{ code: "+994", label: "+994" },
	{ code: "+995", label: "+995" },
	{ code: "+996", label: "+996" },
	{ code: "+998", label: "+998" },
];

export { COUNTRIES, PHONE_CODES };

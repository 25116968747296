import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from "../locale/en.json";
import translationZH from "../locale/zh.json";
import translationHK from "../locale/hk.json";

const resources = {
	en: { translations: translationEN },
	cn: { translations: translationZH },
	hk: { translations: translationHK },
};

i18n.use(detector).init({
	resources: resources,
	detection: {
		order: ["path", "querystring"],
		lookupFromPathIndex: 0,
	},
	fallbackLng: "en",
	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ",",
	},

	react: {
		useSuspense: true,
	},
});

export default i18n;

import Modal from "react-bootstrap/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import "./SubscriptionModal.scss";

export default function SubscriptionModal(props) {
	const { t } = useTranslation();

	const handleClick = () => {
		const url = t("BD corporate landing page");
		window.open(url, "_blank");
		props.handleClose();
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={props.handleClose}
				size="md"
				backdrop="static"
				aria-labelledby="contained-modal-title-vcenter"
				dialogClassName={"subscriptionContainer hk-sectionhdr mb-full-screen"}
				centered
				animation={false}
			>
				<Modal.Header className={"header"}>
					<button
						data-testid="close-btn"
						type="button"
						class="btn-close"
						aria-label="Close"
						onClick={props.handleClose}
					></button>
				</Modal.Header>
				<Modal.Body>
					<div className="subscription-modal-body">
						<div className="subscription-title">
							<h5 className="subscription-text">{t("Data Subscription")}</h5>
							<div className="hk-sectionhdr-line subscription-text-bar"></div>
						</div>
						<div className="subscription-info">{t("Data Subscription Info")}</div>
						<div className="subscription-disclaimer">
							{t("Data Subscription Disclaimer")}
						</div>
					</div>
					<div className="subscription-continue">
						<span className="subscription-msg">{t("Data Subscription Message")}</span>
						<button className="btn-primary continue-btn" onClick={handleClick}>
							{t("Continue")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

import { useEffect, useContext } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";
import { PeersContext } from "components/contexts/usePeersContext";
import usePeersMasterCopy from "components/hooks/FactSet/usePeersMasterCopy";

const setXidListData = (peersData, xidList) => {
	peersData.forEach((element) => {
		if (element.venueXid) {
			xidList.push(element.venueXid);
		}
	});
};

const getPeerDataFromUserPref = (userPreferencePeerXids, initialUserPrefData) => {
	return userPreferencePeerXids
		? [...userPreferencePeerXids]
		: [...initialUserPrefData.userPreferencePeer];
};

function setUserPrefrensePeersData(
	userPreferencePeerXids,
	initialUserPrefData,
	setPeers,
	setPeerXIDs
) {
	let peersData = getPeerDataFromUserPref(userPreferencePeerXids, initialUserPrefData);
	let xidList = [];
	setXidListData(peersData, xidList);
	xidList = String(xidList);
	setPeers(peersData);
	setPeerXIDs(xidList);
}

async function setDefaultPeersData(apiUrl, setPeers, setPeerXIDs, setPeersDataXREF) {
	try {
		const apiInstance = await RequestService.init();
		const response = await apiInstance.get(apiUrl);
		if (response.status === 200 && response?.data?.data?.peersList) {
			let peersData = response.data.data.peersList;
			let xidList = [];
			setXidListData(peersData, xidList);
			peersData = peersData.map((x) => {
				return {
					...x,
					isVisible: true,
				};
			});
			xidList = String(xidList);
			setPeers(peersData); // ranking / venueXID
			setPeerXIDs(xidList); // list of venueXIDs
		} else {
			setPeers(null);
			setPeerXIDs(null);
			throw response;
		}
	} catch (error) {
		setPeers(null);
		setPeerXIDs(null);
		setPeersDataXREF(null);
		throw error;
	}
}

const getApiUrl = (venueXid) => {
	// HKEX-804 use MarketCap as ranking type
	const rankingTypeName = ["", "?rankingTypeName=MarketCap&numberOfResults=4"];
	const defaultRankingType = false;
	const rankingTypeMarketCap = true;

	return `${Constants.URLS.FACTSET_PEERS_COMPARISONS}${venueXid}${
		!defaultRankingType && rankingTypeMarketCap ? rankingTypeName[1] : rankingTypeName[0]
	}`;
};

const usePeers = (
	venueXid,
	userPreferencePeerXids = null,
	resetDisabled = false,
	isFirstLoad = false
) => {
	const {
		peers,
		setPeers,
		setPeersError,
		setPeersLoading,
		setPeersFetchedAt,
		setPeersDataXREF,
		setPeerXIDs,
		peersDataXREF,
		peersError,
		peersLoading,
		peersFetchedAt,
	} = useContext(PeersContext);

	usePeersMasterCopy(resetDisabled);

	const loadPeers = async (initialUserPrefData) => {
		if (userPreferencePeerXids || initialUserPrefData?.userPreferencePeer) {
			setUserPrefrensePeersData(
				userPreferencePeerXids,
				initialUserPrefData,
				setPeers,
				setPeerXIDs
			);
		} else {
			let apiUrl = getApiUrl(venueXid);
			await setDefaultPeersData(apiUrl, setPeers, setPeerXIDs, setPeersDataXREF);
		}
	};

	useEffect(() => {
		if (!venueXid) return;
		setPeersLoading(true);
		setPeers([]);
		setPeersError(null);
		setPeersLoading(false);
		setPeersFetchedAt(true);
		setPeersDataXREF([]);
		async function init() {
			try {
				let initialUserPrefData = await loadInitialPerf();
				// try loading user pereference for first time.
				loadPeers(initialUserPrefData);
			} catch (e) {
				setPeers(null);
				setPeerXIDs(null);
				setPeersError(e);
			} finally {
				setPeersLoading(false);
				setPeersFetchedAt(Utils.getHKTTime());
			}
		}

		const loadInitialPerf = async () => {
			if (!venueXid || isFirstLoad) return;
			let initialUserPrefData = null;
			if (userPreferencePeerXids == null) {
				const apiInstance = await RequestService.init(null, false);

				const UserPreferenceKey = `PeerComparisons_${venueXid}`;
				let UserManagementApiUrl = `${Constants.URLS.USER_MANAGEMENT_PREFERENCE}/${UserPreferenceKey}`;

				let prefResponse = await apiInstance.get(UserManagementApiUrl);

				if (prefResponse.status === 200) {
					let pref = prefResponse.data.data?.value
						? JSON.parse(prefResponse.data.data?.value)
						: {};
					initialUserPrefData = pref;
				}
			}

			return initialUserPrefData;
		};

		init();
	}, [venueXid, userPreferencePeerXids]);

	return { peers, peersDataXREF, peersError, peersLoading, peersFetchedAt };
};

export default usePeers;

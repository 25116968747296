const BENCHMARKS = [
	{ id: "568838", InceptionDate: 23589 },
	{ id: "586439", InceptionDate: 34165 },
	{ id: "609430562", InceptionDate: 42004 },
	{ id: "691727508", InceptionDate: 44529 },
	{ id: "622594542", InceptionDate: 44123 },
	{ id: "603858", InceptionDate: 36528 },
	{ id: "565671903", InceptionDate: 43815 },
	{ id: "475604063", InceptionDate: 42003 },
	{ id: "20806338", InceptionDate: 38534 },
	{ id: "11959411", InceptionDate: 39629 },
	{ id: "691692841", InceptionDate: 44529 },
	{ id: "25391345", InceptionDate: 39449 },
	{ id: "608238529", InceptionDate: 44032 },
	{ id: "649082562", InceptionDate: 44284 },
	{ id: "649079171", InceptionDate: 44284 },
	{ id: "637957126", InceptionDate: 44214 },
	{ id: "637422472", InceptionDate: 44214 },
	{ id: "637423069", InceptionDate: 44214 },
	{ id: "638167957", InceptionDate: 44214 },
	{ id: "637416096", InceptionDate: 44214 },
	{ id: "637995939", InceptionDate: 44214 },
	{ id: "294592623", InceptionDate: 40242 },
	{ id: "445708041", InceptionDate: 42004 },
	{ id: "574136982", InceptionDate: 43878 },
	{ id: "675466722", InceptionDate: 44431 },
	{ id: "603481911", InceptionDate: 44004 },
	{ id: "667900168", InceptionDate: 44403 },
	{ id: "458788329", InceptionDate: 43241 },
	{ id: "372894565", InceptionDate: 42709 },
	{ id: "649130876", InceptionDate: 44284 },
	{ id: "30421549", InceptionDate: 36893 },
];
const FLAGSHIP = [568838, 586439, 609430562, 691727508];
const CORE = [603858, 565671903, 475604063, 20806338, 11959411, 30421549];
const ESG = [691727508, 691692841, 25391345, 608238529, 649082562, 649079171];
const SMARTBETA = [
	622594542, 637957126, 637422472, 637423069, 638167957, 637416096, 637995939,
];
const THEMATIC = [294592623, 445708041, 574136982, 675466722, 603481911, 667900168];
const STOCKCONNECT = [458788329, 372894565, 649130876];

const PEERS = [
	{ symbol: "2318", name: "PING AN", id: "19148" },
	{ symbol: "2628", name: "CHINA LIFE", id: "19847" },
	{ symbol: "2601", name: "CPIC", id: "22012847" },
	{ symbol: "2318", name: "PING AN", id: "19149" },
];
export { BENCHMARKS, PEERS, FLAGSHIP, CORE, ESG, SMARTBETA, THEMATIC, STOCKCONNECT };

const { SkeletonTheme } = require("react-loading-skeleton");

const CustomSkeletonTheme = ({ children }) => {
	return (
		<SkeletonTheme baseColor="#E3E3E3" borderRadius={0}>
			{children}
		</SkeletonTheme>
	);
};
export default CustomSkeletonTheme;

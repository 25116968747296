import { useState } from "react";
import { Card, Col, Row, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RisersTable from "./Tables/RisersTable";
import IndexFilters from "./IndexFilters";
import useRankingSet from "components/hooks/useRankingSet";
import Error from "components/Error/Error";
import "./Top10Movers.scss";
import Utils from "../../utils/utils";
import moment from "moment";
import "locale/CommonLocale";

const Top10Movers = () => {
	const [selectedFilterOption, setSelectedFilterOption] = useState();
	const [includeMicroCapStocks, setIncludeMicroCapStocks] = useState(false);
	const {
		rankingSet: topGainers,
		error: errorGainers,
		loading: loadingGainers,
		fetchedAt,
	} = useRankingSet(selectedFilterOption, includeMicroCapStocks, "PctChgGainers");

	const {
		rankingSet: topLoosers,
		error: errorLoosers,
		loading: loadingLoosers,
	} = useRankingSet(selectedFilterOption, includeMicroCapStocks, "PctChgLosers");

	const { t } = useTranslation();
	const renderGainersErrorMessage = (error) => {
		let errorMessage = t("There was a problem loading top 10 risers data.");
		if (error && error.response && error.response.status === 404)
			errorMessage = t("There is currently no top 10 risers data available.");

		return <Error errorMessge={t(errorMessage)}></Error>;
	};
	const renderLoosersErrorMessage = (error) => {
		let errorMessage = t("There was a problem loading top 10 fallers data.");
		if (error && error.response && error.response.status === 404)
			errorMessage = t("There is currently no top 10 fallers data available.");

		return <Error errorMessge={t(errorMessage)}></Error>;
	};
	const widthMob = window.innerWidth < 768 ? true : false;

	return (
		<>
			<Card>
				<Card.Header className="bg-white hk-sectionhdr">
					<Row>
						<Col xs={12} sm={12} md={6} lg={6} xl={6}>
							<h2>{t("Top 10 Movers")}</h2>
							<div className="hk-sectionhdr-line"></div>
						</Col>
						<Col xs={12} sm={12} md={6} lg={6} xl={6}>
							<IndexFilters
								selectedFilterOption={selectedFilterOption}
								setSelectedFilterOption={setSelectedFilterOption}
								includeMicroCapStocks={includeMicroCapStocks}
								setIncludeMicroCapStocks={setIncludeMicroCapStocks}
							></IndexFilters>
						</Col>
					</Row>
				</Card.Header>
				{!widthMob && (
					<Card.Body>
						<Row>
							<Col xs={12} md={6} lg={6} xl={6}>
								<RisersTable
									loading={loadingGainers}
									error={errorGainers}
									data={topGainers}
									renderErrorMessage={renderGainersErrorMessage}
									section={t("Risers")}
								></RisersTable>
							</Col>
							<Col xs={12} md={6} lg={6} xl={6}>
								<RisersTable
									loading={loadingLoosers}
									error={errorLoosers}
									data={topLoosers}
									renderErrorMessage={renderLoosersErrorMessage}
									section={t("Fallers")}
								></RisersTable>
							</Col>
						</Row>
						<div className="timestamp-txt">
							{t("As of")}{" "}
							{topGainers
								? moment(Utils.getHKTTime(topGainers[0].lastTradeDate))
										.locale(t("lang"))
										.format(t("format"))
								: ""}{" "}
							{t("HKT")}
						</div>
					</Card.Body>
				)}
				{widthMob && (
					<Card.Body>
						<Tabs defaultActiveKey="risers" transition={false} className="movers-tab">
							<Tab eventKey="risers" title={t("Risers")}>
								<RisersTable
									loading={loadingGainers}
									error={errorGainers}
									data={topGainers}
									renderErrorMessage={renderGainersErrorMessage}
									margin="none"
								></RisersTable>
							</Tab>
							<Tab eventKey="fallers" title={t("Fallers")}>
								<RisersTable
									loading={loadingLoosers}
									error={errorLoosers}
									data={topLoosers}
									renderErrorMessage={renderLoosersErrorMessage}
									margin="none"
								></RisersTable>
							</Tab>
						</Tabs>
						<div className="timestamp-txt-tbl">
							{t("As of")} {moment(fetchedAt).locale(t("lang")).format(t("format"))}{" "}
							{t("HKT")}
						</div>
					</Card.Body>
				)}
			</Card>
		</>
	);
};

export default Top10Movers;

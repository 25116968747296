import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./RisersTable.scss";
import formatter from "../../../utils/formatUtils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "../../Lib/CustomSkeleton/CustomSkeletonTheme";

const RisersTable = ({ data, section, margin, loading, error, renderErrorMessage }) => {
	// To be used for mobile implementation
	const widthMob = window.innerWidth < 768 ? true : false;
	const widthTab = window.innerWidth < 1025 && window.innerWidth > 768 ? true : false;
	const { t } = useTranslation();
	// Class for red/green values of change
	const getClassForCol = (val) => {
		if (val.toString().indexOf("-") >= 0) {
			return "change-ngtv";
		} else {
			return "change-pstv";
		}
	};

	const getMargin = () => {
		if (margin && margin !== undefined) {
			return "none";
		} else {
			return widthTab ? "10px" : "20px";
		}
	};
	const showSkeleton = () => {
		return Array.from(Array(10).keys()).map((index) => {
			return (
				<CustomSkeletonTheme key={index}>
					<tr>
						<td aria-hidden="true" className="first-col-wdth column-first">
							<Skeleton />
						</td>
						<td className="second-col-wdth">{<Skeleton />}</td>
						{widthMob ? (
							<td colSpan="2" className="combine-col-wdth column-second">
								<div>
									<Skeleton />
								</div>
								<div>
									<Skeleton />
								</div>
							</td>
						) : (
							<>
								<td className="third-col-wdth">
									<Skeleton />
								</td>
								<td className={`fourth-col-wdth`}>
									<Skeleton />
								</td>
							</>
						)}
					</tr>
				</CustomSkeletonTheme>
			);
		});
	};
	return (
		<React.Fragment>
			<div
				style={{
					marginRight: section === "Risers" || section === "上涨" ? getMargin() : "none",
					marginLeft: section === "Fallers" || section === "下跌" ? getMargin() : "none",
				}}
			>
				<div className="movers-top-txt">
					<span>{section}</span>
				</div>
				{!loading && error && renderErrorMessage(error)}
				{!error && (
					<Table
						striped
						responsive="sm"
						borderless
						id="risersTable"
						className="risers-tbl"
					>
						<thead>
							<tr>
								<th className="first-col-wdth code-col-top-movers">{t("Code")}</th>
								<th className="second-col-wdth">{t("Name")}</th>
								{widthMob ? (
									<th colSpan="2" className="combine-col-wdth">
										<div>{t("Price(HK$)")}</div>
										<div>{t("Todays_Change")}</div>
									</th>
								) : (
									<>
										<th className="third-col-wdth">{t("Price(HK$)")}</th>
										<th className="fourth-col-wdth">{t("Todays_Change")}</th>
									</>
								)}
							</tr>
						</thead>
						<tbody>
							{loading
								? showSkeleton()
								: data &&
								  data.map((item, index) => {
										return (
											<tr key={index}>
												<td aria-hidden="true" className="first-col-wdth column-first">
													{item.symbol}
												</td>
												<td className="second-col-wdth">{item.name}</td>
												{widthMob ? (
													<td colSpan="2" className="combine-col-wdth column-second">
														<div>
															{formatter.priceWithRoundingAndSymbol(
																item.lastTradePrice,
																3
															)}
														</div>
														<div className={getClassForCol(item.changePercent)}>
															{`${formatter.formatPosNegVal(
																item.change,
																3
															)} (${formatter.formatPosNegVal(item.changePercent, 2)}%)`}
														</div>
													</td>
												) : (
													<>
														<td className="third-col-wdth">
															{formatter.priceWithRoundingAndSymbol(
																item.lastTradePrice,
																3
															)}
														</td>
														<td
															className={`fourth-col-wdth ${getClassForCol(item.change)}`}
														>
															{`${formatter.formatPosNegVal(
																item.change,
																3
															)} (${formatter.formatPosNegVal(item.changePercent, 2)}%)`}
														</td>
													</>
												)}
											</tr>
										);
								  })}
						</tbody>
					</Table>
				)}
			</div>
		</React.Fragment>
	);
};

export default RisersTable;

import { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import "./ResearchRatings.scss";
import { useTranslation } from "react-i18next";
import ResearchBreakdownChart from "./Chart/ResearchBreakdownChart";
import ResearchRatingsTable from "./Table/ResearchRatingsTable";
import useResearchConsensus from "components/hooks/FactSet/useResearchConsensus";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import utils from "utils/utils";
import moment from "moment";
import "locale/CommonLocale";

export default function ResearchRatings({ venueXid }) {
	const { researchConsensus, error, loading, asofDate } = useResearchConsensus(venueXid);
	const { t } = useTranslation();
	const [hoverElementIndex, setHoverElementIndex] = useState();
	const [actionTypeVal, setActionTypeVal] = useState();
	const [hoverRowIndex, setHoverRowIndex] = useState();
	const [actionTypeRowVal, setActionTypeRowVal] = useState();

	const [researchRatings, setResearchRatings] = useState(null);
	const [sum, setSum] = useState();
	useEffect(() => {
		if (researchConsensus && researchConsensus.numberOfRecs) {
			let obj = [
				{
					label: "Buy",
					value: researchConsensus.numberOfRecs.buy
						? researchConsensus.numberOfRecs.buy
						: 0,
				},
				{
					label: "Overweight",
					value: researchConsensus.numberOfRecs.overweight
						? researchConsensus.numberOfRecs.overweight
						: 0,
				},
				{
					label: "Hold",
					value: researchConsensus.numberOfRecs.hold
						? researchConsensus.numberOfRecs.hold
						: 0,
				},
				{
					label: "Underweight",
					value: researchConsensus.numberOfRecs.underweight
						? researchConsensus.numberOfRecs.underweight
						: 0,
				},
				{
					label: "Sell",
					value: researchConsensus.numberOfRecs.sell
						? researchConsensus.numberOfRecs.sell
						: 0,
				},
			];
			setResearchRatings(obj);
			setSum(researchConsensus.numberOfRecs.total);
		} else {
			setResearchRatings(null);
		}
	}, [researchConsensus]);

	//From Chart to table
	const setHoverDivName = (index, type) => {
		setActionTypeVal(type);
		setHoverElementIndex(index);
	};

	//From table to chart
	const setHoverRowName = (index, type) => {
		setActionTypeRowVal(type);
		setHoverRowIndex(index);
	};

	const renderErrorMessage = () => {
		let errorMessage = "There is currently no Research Ratings data available.";
		return <Error bgClass="ratings-error" errorMessge={t(errorMessage)}></Error>;
	};

	const showSkeleton = (cls, displayData, type) => {
		if (loading)
			if (type === "circle")
				return (
					<Skeleton
						circle
						width={"12px"}
						height={"12px"}
						style={{ marginRight: "12px" }}
					/>
				);
			else
				return (
					<div style={{ display: "inline-block" }}>
						<Skeleton style={{ marginLeft: "1px" }} className={cls} />
					</div>
				);
		else return displayData;
	};

	return (
		<>
			<Card className="research-ratings">
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Research Ratings")}</h2>
					<div className="hk-sectionhdr-line research-section-line"></div>
				</Card.Header>
				<Card.Body className="card-body-ratings">
					{error && renderErrorMessage()}
					{loading && (
						<div>
							<Row>
								<Skeleton height={"30px"} />
								<span className="based-txt">
									{showSkeleton(
										"research-date-skeleton",
										t("Based on") + " " + sum + " " + t("analysts")
									)}
								</span>
							</Row>
							<Row>
								<ResearchRatingsTable
									data={researchRatings}
									sum={sum}
									changeCssForIndex={hoverElementIndex}
									actionType={actionTypeVal}
									setHoverRowName={setHoverRowName}
									loading={loading}
								></ResearchRatingsTable>
							</Row>
							<div className="timestamp-txt">
								{showSkeleton(
									"research-date-skeleton",
									t("As of") +
										" " +
										moment(utils.getHKTTime(asofDate))
											.locale(t("lang"))
											.format(t("format")) +
										" " +
										t("HKT")
								)}
							</div>
						</div>
					)}
					{researchRatings && !loading && (
						<div>
							<Row>
								<ResearchBreakdownChart
									researchRatings={researchRatings}
									sum={sum}
									setHoverDivName={setHoverDivName}
									changeCssForIndex={hoverRowIndex}
									actionType={actionTypeRowVal}
								></ResearchBreakdownChart>
								<span className="based-txt">
									{t("Based on") + " " + sum + " " + t("analysts")}
								</span>
							</Row>
							<Row>
								<ResearchRatingsTable
									data={researchRatings}
									sum={sum}
									changeCssForIndex={hoverElementIndex}
									actionType={actionTypeVal}
									setHoverRowName={setHoverRowName}
								></ResearchRatingsTable>
							</Row>
							<div className="timestamp-txt">
								{t("As of")}{" "}
								{moment(utils.getHKTTime(asofDate)).locale(t("lang")).format(t("format"))}{" "}
								{t("HKT")}
							</div>
						</div>
					)}
				</Card.Body>
			</Card>
		</>
	);
}

const COLOR_PALETTE = [
	{
		color: "#26CAD3",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#FFB81C",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#5E366E",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#FEDD00",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#445CBE",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#D25B73",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#B5E3D8",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#41748D",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#EF886F",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#A73B9C",
		width: 1.5,
		available: true,
		index: 0,
	},
	{
		color: "#B9B9B9",
		width: 1.5,
		available: true,
		index: 0,
	},
];

export default COLOR_PALETTE;

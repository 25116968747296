import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import { LINE_STYLES, OHLC_LINE_STYLES } from "../Constants/ChartConstants";
import "../Events/Events.scss";
import { useTranslation } from "react-i18next";
import useGAEventsTracker from "components/hooks/useGAEventsTracker";
const LineStyle = ({
	chart,
	unregisterChartEvents,
	registerChartEvents,
	setDollarFormat,
}) => {
	const { t } = useTranslation();
	const [selectedLineStyle, setSelectedLineStyle] = useState(LINE_STYLES[0]);
	const [title, setTitle] = useState(selectedLineStyle.label);
	const [arrow, setArrow] = useState("fa-angle-down");
	const [show, setShow] = useState(false);
	const GAEventsTracker = useGAEventsTracker(t("Performance"));
	const handleChartStyleOnSelect = (selectedLineStyleKey) => {
		registerChartEvents();
		let union = [...new Set([...LINE_STYLES, ...OHLC_LINE_STYLES])];
		setSelectedLineStyle(
			union.filter((lineStyle) => lineStyle.key === selectedLineStyleKey)[0]
		);
		GAEventsTracker(t(title), selectedLineStyle.label);
		return () => unregisterChartEvents();
	};

	const LineStyle = (mainPanel) => {
		// Update the markerType and lineColor of the main Price chart (first indicator)
		if (mainPanel.indicators && mainPanel.indicators.length) {
			const firstIndicator = mainPanel.indicators[0];
			if (selectedLineStyle.key === 6) {
				chart.params.dataNormalized = true;
				setDollarFormat(false);
			} else if (
				mainPanel.indicators.filter((ele) => ele.params.id === "price").length < 2
			) {
				chart.params.dataNormalized = false;
				setDollarFormat(true);
			}
			if (selectedLineStyle.key > 9)
				firstIndicator.params.ohlcType = selectedLineStyle.ohlcType;
			if (firstIndicator.params) {
				firstIndicator.params.markerType = selectedLineStyle.markerType;

				if (firstIndicator.params.style) {
					firstIndicator.params.style.color = selectedLineStyle.lineColor;
					firstIndicator.params.style.width = 2.5;
				}
			}
		}
	};

	useEffect(() => {
		if (chart) {
			if (chart.panels && chart.panels.length) {
				const mainPanel = chart.panels[0];
				LineStyle(mainPanel);
				setTitle(selectedLineStyle.label);
			}
			chart.loadData();
		}
	}, [selectedLineStyle]);

	const dropdownToggle = (isOpen) => {
		if (!isOpen) {
			setTitle("Style");
			setShow(true);
		} else {
			setTitle(selectedLineStyle.label);
			setShow(false);
		}
		if (arrow === "fa-angle-up") {
			setArrow("fa-angle-down");
			setShow(false);
		} else setArrow("fa-angle-up");
	};

	return (
		<>
			<div className="style-button" data-testid="styleDropdown">
				<Dropdown show={show} onToggle={() => dropdownToggle(show)}>
					<Dropdown.Toggle
						className={styles.dropdownToggle}
						data-testid="dropdownButton"
						id="dropdownButton"
					>
						<span data-testid="dropdown-title">{t(title)}</span>
						<i className={`fa ${arrow} ${styles.DropDownIcon}`}></i>{" "}
					</Dropdown.Toggle>
					<Dropdown.Menu className={`${styles.dropdownMenu} dropdownMenu`}>
						{LINE_STYLES.map((chartStyle) => {
							return (
								<Dropdown.Item
									key={chartStyle.label}
									id="dropdown-item"
									data-testid="dropdown-item"
									data-value={chartStyle.label}
									className={
										chartStyle === selectedLineStyle
											? styles.selected
											: styles.dropdownItem
									}
									onClick={() => handleChartStyleOnSelect(chartStyle.key)}
								>
									{t(chartStyle.label)}
								</Dropdown.Item>
							);
						})}
						<Dropdown.ItemText className={`${styles.dropdownItemText} border-top`}>
							{t("OHLC")}
						</Dropdown.ItemText>
						{OHLC_LINE_STYLES.map((chartStyle) => {
							return (
								<Dropdown.Item
									key={chartStyle.label}
									id="dropdown-item"
									data-testid="dropdown-item"
									data-value={chartStyle.label}
									className={
										chartStyle === selectedLineStyle
											? styles.selected
											: styles.dropdownItem
									}
									onClick={() => handleChartStyleOnSelect(chartStyle.key)}
								>
									{t(chartStyle.label)}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</>
	);
};

export default LineStyle;

import { ModchartsInteractive } from "@markit/modcharts";
import { useEffect, useState, useRef, useContext, memo } from "react";
import { CHART_CONFIG } from "../Constants/ChartConstants";
import TimeFrame from "../TimeFrame/TimeFrame";
import "../ChartingCard/ChartingCard";
import RequestService from "services/RequestService";
import CONSTANTS from "utils/constants";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../IndicatorPopUp/IndicatorPopUp.scss";
import YAxisFlag from "../AxisFlags/YAxisFlag";
import XAxisFlag from "../AxisFlags/XAxisFlag";
import CustomLocale from "../CustomLocale";
import "./LandingChart.scss";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "../../Lib/CustomSkeleton/CustomSkeletonTheme";
import Utils from "../../../utils/utils";
import TimeFrameConstants from "../Constants/TimeFrameConstants";

const LandingChart = ({
	initstyles,
	visibleIndexes,
	benchmarks,
	inceptiondate,
	selectedTimeframe,
}) => {
	const [api, setAPI] = useState(null);
	const [loading, setLoading] = useState(true);
	const [index, setindexes] = useState(null);
	const getApi = async () => {
		const apiInstance = await RequestService.init();
		setAPI(apiInstance);
	};
	const selectedTimeFrame =
		TimeFrameConstants.TIME_FRAMES[TimeFrameConstants.DEFAULT_TIME_FRAME_INDEX];

	const { t } = useTranslation();
	const chartRef = useRef({});

	const [chart, setChart] = useState(null);
	const [dataLoadError, setDataLoadError] = useState(false);
	useEffect(() => {
		if (chart && document.getElementById("landingchart")) {
			registerChartEvents();
			return () => unregisterChartEvents();
		}
	}, [chart]);
	const chartNotAvailable = (status) => {
		setDataLoadError(true);
	};

	const registerChartEvents = () => {
		chart.eventEmitter.on("DATA_LOAD_START", load_start);
		chart.eventEmitter.on("DATA_LOAD_STOP", load_stop);
		chart.eventEmitter.on("DATA_LOAD_ERROR", chartNotAvailable);
	};

	const load_start = () => {
		setLoading(true);
		setDataLoadError(false);
	};
	const load_stop = () => {
		setLoading(false);
	};
	const unregisterChartEvents = () => {
		chart.eventEmitter.off("DATA_LOAD_START", load_start);
		chart.eventEmitter.off("DATA_LOAD_STOP", load_stop);
		chart.eventEmitter.off("DATA_LOAD_ERROR", chartNotAvailable);
		setDataLoadError(false);
	};

	const initNewChart = async () => {
		const newChart = new ModchartsInteractive(chartRef.current);
		const authToken = await Utils.getValidMDToken();
		newChart.setAuthToken(authToken);
		let defaultIndexes = benchmarks.slice(0, 3);
		defaultIndexes = defaultIndexes.sort((a, b) =>
			a.InceptionDate > b.InceptionDate ? 1 : -1
		);
		const chartConfig = JSON.parse(JSON.stringify(CHART_CONFIG));
		chartConfig.params.apiPath = `${api.baseUrl}${CONSTANTS.URLS.CHARTWORKS_SERIES_PATH}`;
		chartConfig.params.symbol = defaultIndexes[0].id;
		chartConfig.params.showFlags = false;
		chartConfig.params.days = selectedTimeFrame.numDays;
		chartConfig.params.dataInterval = selectedTimeFrame.defaultPeriod.interval;
		chartConfig.params.dataPeriod = selectedTimeFrame.defaultPeriod.value;
		chartConfig.params.dataNormalized = false;

		chartConfig.params.style.panel.padding = { bottom: 54 };
		chartConfig.params.style.panel.border = {
			default: {
				color: "transparent",
				lineDash: "",
				padding: 0,
				width: 1,
			},
			top: {
				color: "#E3E3E3",
				lineDash: null,
				padding: null,
				width: 1,
			},
			right: {
				color: "#71747D",
				lineDash: null,
				padding: null,
				width: 1,
			},
			bottom: {
				color: "#71747D",
				lineDash: null,
				padding: null,
				width: 1,
			},
		};

		let symbols = benchmarks.slice(0, 3).map((e) => parseInt(e.id));
		chartConfig.params.symbolCompare = symbols.slice(1, 3);
		let styles = initstyles.slice(0, 3);
		let comparelist = symbols.map((item, ie) => {
			return {
				id: "price",
				symbol: item,
				style: {
					color: styles[ie].color,
					width: 2,
				},
			};
		});
		chartConfig.params.locale = CustomLocale.EN_LOCALE;
		chartConfig.panels[0].indicators = comparelist;
		newChart.load(chartConfig);
		setindexes(defaultIndexes);
		setChart(newChart);
	};
	useEffect(() => {
		if (!chart && api) {
			initNewChart();
		}
	}, [api]);

	useEffect(() => {
		if (chart) {
			if (index && index.length === visibleIndexes.length) {
				chart.resize();
				chart.render(); // rendering while changing tab
			} else {
				try {
					// using created flag to avoid loaddata just after initnewchart
					visibleIndexes = visibleIndexes.sort((a, b) =>
						a.InceptionDate > b.InceptionDate ? 1 : -1
					);
					let symbols = visibleIndexes.map((e) => parseInt(e.id));
					let styles = visibleIndexes.map((item) => {
						return {
							width: 2,
							color: item.color,
						};
					});
					// To handle special case for 475604063 as intraday data is not coming
					if (
						visibleIndexes[0].id === "475604063" &&
						chart.params?.dataPeriod === "Minute" &&
						visibleIndexes.length > 1
					) {
						chart.params.symbol = visibleIndexes[1].id;
						chart.panels[0].indicators[0].params.symbol = visibleIndexes[1].id;
					} else {
						chart.params.symbol = visibleIndexes[0].id;
						chart.panels[0].indicators[0].params.symbol = visibleIndexes[0].id;
					}
					chart.setSymbolCompare(symbols, styles);
					setindexes(visibleIndexes);
					chart.loadData();
				} catch (ex) {
					chartNotAvailable(true);
				}
				return () => unregisterChartEvents();
			}
		}
	}, [visibleIndexes]);

	useEffect(() => {
		if (!api) {
			getApi();
		}
	}, []);
	const selectedTimeframeCallback = (noOfDays) => {
		// To handle special case for 475604063 on timeframe change when data is not coming
		if (visibleIndexes[0].id === "475604063" && visibleIndexes.length > 1) {
			if (noOfDays <= 10) {
				chart.params.symbol = visibleIndexes[1].id;
				chart.panels[0].indicators[0].params.symbol = visibleIndexes[1].id;
			} else {
				chart.params.symbol = visibleIndexes[0].id;
				chart.panels[0].indicators[0].params.symbol = visibleIndexes[0].id;
			}
			chart.loadData();
		}
		selectedTimeframe(noOfDays);
	};
	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-expand-md pb-8">
				<Row id="chartingMenus">
					<Col className="nav-item" data-testid="timeFrameButton" id="timeFramedropDown">
						<TimeFrame
							chart={chart}
							chartRef={chartRef}
							registerChartEvents={registerChartEvents}
							unregisterChartEvents={unregisterChartEvents}
							inceptiondate={inceptiondate}
							selectedTimeframe={selectedTimeframeCallback}
						/>
					</Col>
				</Row>
			</nav>
			<Row>
				<Col className="mobil-dvc-ht">
					<div className="pt-16"></div>
					<div
						ref={chartRef}
						className={
							!dataLoadError
								? "chartContainer landing-chart"
								: "chartContainerForError landing-chartForError"
						}
						id="landingchart"
						data-testid="chartingCard"
					>
						{loading && (
							<CustomSkeletonTheme>
								<Skeleton
									className={
										!dataLoadError
											? "chartContainer landing-chart"
											: "chartContainerForError landing-chartForError"
									}
								/>
							</CustomSkeletonTheme>
						)}
						{/* Chart loader has been removed. not required anymore */}
						{dataLoadError && (
							<Error
								errorMessge={t("Index performance not available for selected range")}
							></Error>
						)}
						<YAxisFlag chart={chart} priceFormat={"percent"} />
						<XAxisFlag chart={chart} />
					</div>
				</Col>
			</Row>
		</>
	);
};
export default LandingChart;

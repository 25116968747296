import chartistUtils from "utils/chartistUtils";
import * as d3 from "d3";
import formatter from "utils/formatUtils";
import moment from "moment";
import { deviceType } from "utils/utils";

const earningsDataPointRange = (earnings) => {
	let actuals = earnings.map((x) => x.actual || 0);
	let estimates = earnings.map((x) => x.estimate || 0);
	let actualMin = Math.min(...actuals);
	let estimateMin = Math.min(...estimates);
	let actualMax = Math.max(...actuals);
	let estimateMax = Math.max(...estimates);
	let min = actualMin < estimateMin ? actualMin : estimateMin;
	let max = actualMax > estimateMax ? actualMax : estimateMax;
	max = chartistUtils.findYAxisHigh(max);
	min = min < 0 ? min / 0.83 : 0;
	return [min, max];
};

const priceDataPointRange = (prices) => {
	let priceValues = prices.map((x) => x.value || 0);
	let min = Math.min(...priceValues);
	let max = Math.max(...priceValues);
	min = min < 0 ? min / 0.83 : 0;
	max = chartistUtils.findYAxisHigh(max);
	return [min, max];
};

const getChartLeftMargin = (prices, showPricePerformance, currency) => {
	const max = priceDataPointRange(prices)[1];
	if (showPricePerformance && prices && prices.length > 0 && max && max.length > 0) {
		let maxChar = currency ? currency.length + 2 : 0;
		maxChar = max.length > maxChar ? max.length : maxChar;
		return maxChar < 5 ? maxChar * 10 : maxChar * 9;
	}
	return 0;
};
const getChartRightMargin = (earnings, currency) => {
	const [min, max] = earningsDataPointRange(earnings);
	if (earnings && earnings.length > 0) {
		return calculateMargin(min, max, currency);
	}
	return 0;
};

const calculateMargin = (min, max, currency) => {
	let maxValue = min < 0 && Math.abs(min) > max ? min : max;
	maxValue = formatter.number(maxValue, 2);
	let currLen = currency ? currency.length + 2 : 0;
	maxValue = maxValue.length > currLen ? maxValue.length : currLen;
	return maxValue < 5 ? maxValue * 10 : maxValue * 9;
};

const renderEarningTooltips = (event, d, width, t, showPricePerformance, device) => {
	const tooltipContainer = d3.select("#tooltips-chart");
	const tooltipBody = handleTooltipDataPoint(d, t, showPricePerformance);
	tooltipContainer.html(tooltipBody);
	const tooltipBox = tooltipContainer.node().getBoundingClientRect();
	let left = event.pageX;
	if (device === deviceType.Mobile) {
		const isTouchEvent = event.type === "touchstart";
		left = isTouchEvent ? event.touches[0]?.pageX : left;
	}
	const top = event.pageY - tooltipBox.height - 20;
	left = adjustToolTipPointer(left, width, tooltipBox.width);
	tooltipContainer.style("left", left + "px").style("top", top + "px");
	tooltipContainer.transition().duration(100).style("opacity", 1);
};

const adjustToolTipPointer = (left, chartWidth, width) => {
	if (left + width - 20 > chartWidth) {
		//render with right tip
		left = left - width + 30;
		d3.select("#tooltips-chart").style("--trianglepos", `${width - 30}px`);
	} else {
		//render with left tip
		left = left - 25;
		d3.select("#tooltips-chart").style("--trianglepos", "20px");
	}
	return left;
};

const handleTooltipDataPoint = (hoverEarningData, t, showPricePerformance) => {
	const getClassForSurpriseChange = (val) => {
		if (!val) {
			return "";
		} else if (val.toString().indexOf("-") >= 0) {
			return "text-red ";
		} else if (val === 0) {
			return "text-grey";
		} else return "text-green";
	};
	let estimateAmount = formatter.number(hoverEarningData.estimate, 2);
	let surprisePercentageValue = hoverEarningData.surprisePercent;
	let change24hpercent = hoverEarningData.change24h;
	const nonGappLabel = `${formatter.number(hoverEarningData.actual, 2)}`;
	const price = formatter.number(hoverEarningData.price, 2);
	const surprisePercentageLabel =
		surprisePercentageValue !== null && surprisePercentageValue !== undefined
			? `${formatter.formatPosNegValForChangePercent(
					surprisePercentageValue?.toFixed(2),
					2
			  )}%`
			: "—";
	// let price = hoverEarningData"—";
	const change24h =
		change24hpercent !== null && change24hpercent !== undefined
			? `${formatter.formatPosNegValForChangePercent(change24hpercent?.toFixed(2), 2)}%`
			: "—";
	return `
    <div class="bg-white hk-sectionhdr card-header">
	<h5 class="tooltip-header">${hoverEarningData.xLabel}</h5>
	<div class="hk-sectionhdr-line tooltip-hdrline"/>
	</div>
    <ul class="ct-legend tooltip-section-divider">
        <li class="ct-series-0">${t("Estimate")} <span>${
		estimateAmount !== "0" ? estimateAmount : "-"
	}</span></li>
        <li class="ct-series-1">${t("Actual")} <span>${nonGappLabel}</span></li>
		${
			showPricePerformance
				? `<li class="ct-series-2">
					${t("Price")} <span>${price}</span>
				</li>`
				: ""
		}
    </ul>
    <ul class="ct-legend">
	<li class="tooltip-label">${t("Surprise")} <span class="${getClassForSurpriseChange(
		surprisePercentageValue
	)}">${surprisePercentageLabel}</span></li> 
	${
		showPricePerformance
			? `<li class="tooltip-label">${t(
					"24h Change"
			  )} <span class="${getClassForSurpriseChange(
					change24hpercent
			  )}">${change24h}</span></li>`
			: ""
	} 	
	</ui>	
    `;
};

const hideTooltipPopover = () => {
	const tooltipContainer = d3.select("#tooltips-chart");
	tooltipContainer.transition().style("opacity", 0);
};

const getEarningsDateRange = (earnings) => {
	let firstQuarter = earnings.reduce((curr, next) => {
		return curr.date < next.date ? curr : next;
	});

	let lastQuarter = earnings.reduce((curr, next) => {
		return curr.date > next.date ? curr : next;
	});

	let startdate = firstQuarter.date;
	let enddate = lastQuarter.date;
	return { startdate, enddate };
};

const getSeriesPriceRequestParam = (venueXid, fromDate, endDate) => {
	let noOfDays = moment(endDate).diff(fromDate, "days") + 1;
	let offsetDays = moment().diff(endDate, "days");
	return {
		days: noOfDays,
		endOffsetDays: offsetDays > 0 ? offsetDays : 0,
		dataNormalized: false,
		dataPeriod: "Day",
		dataInterval: 1,
		realtime: false,
		yFormat: "0.###",
		timeServiceFormat: "JSON",
		rulerIntradayStart: 31,
		rulerIntradayStop: 3,
		rulerInterdayStart: 12783,
		rulerInterdayStop: 10,
		returnDateType: "ISO8601",
		showMajorCurrency: true,
		returnDateFormat: "interday",
		elements: [
			{
				Label: "4b4d87fd",
				Type: "price",
				Symbol: venueXid,
				OverlayIndicators: [],
				Params: {},
			},
		],
	};
};

export default {
	earningsDataPointRange,
	renderEarningTooltips,
	hideTooltipPopover,
	getEarningsDateRange,
	getSeriesPriceRequestParam,
	priceDataPointRange,
	getChartLeftMargin,
	getChartRightMargin,
};

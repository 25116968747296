// eslint-disable-next-line no-unused-vars
import ShellUtils from "../utils/shell-utils";
import moment from "moment-timezone";

const fetchPublicToken = async () => {
	const { access_token, expires_in } = await ShellUtils.fetchPublicAuthToken();
	const tokenExpireTimeInUnix = getTokenExpiredTimeInUnix(expires_in, "seconds");
	window.localStorage.setItem("expiresIn_mdtoken", tokenExpireTimeInUnix);
	window.localStorage.setItem("mdtoken", access_token);
};

const getCurrentTimeInUnixFormat = () => {
	return moment(new Date()).unix();
};

const getCurrentTimeFromUnixFormat = (value) => {
	return moment.unix(value); //.format('dddd, MMMM Do, YYYY h:mm:ss A')
};

const getHKTTime = (date) => {
	const format = "DD MMM YYYY HH:mm";
	moment.locale("en-au");
	if (date) {
		return moment(date).tz("Asia/Hong_Kong").format(format);
	}
	return moment(new Date()).tz("Asia/Hong_Kong").format(format);
};

const getTokenExpiredTimeInUnix = (expires_in, timeMeasurement) => {
	return moment(new Date())
		.add(expires_in, timeMeasurement || "seconds")
		.unix();
};

const getHKTTimeOnlyYear = (date) => {
	var format = "YYYY";
	if (date) {
		return moment(date).tz("Asia/Hong_Kong").format(format);
	}
	return moment(new Date()).tz("Asia/Hong_Kong").format(format);
};

const getHKTTimeWithDate = (date) => {
	var format = "DD MMM YYYY";
	if (date) {
		return moment(date).tz("Asia/Hong_Kong").format(format);
	}
	return moment(new Date()).tz("Asia/Hong_Kong").format(format);
};
const getUTCtoHKTTime = (date, format = "YYYY-MM-DD HH:mm") => {
	if (!format) {
		format = "YYYY-MM-DD HH:mm";
	}
	var utc = moment.utc(date, format);
	return utc.clone().tz("Asia/Hong_Kong").format(format);
};
const getUTCtoHKTDateTime = (date, time, format = "YYYY-MM-DD HH:mm") => {
	var DateTime = date + " " + time;
	if (!format) {
		format = "YYYY-MM-DD HH:mm";
	}
	var utc = moment.utc(DateTime, format);
	return utc.clone().tz("Asia/Hong_Kong").format(format);
};
const IsAuthTokenValid = () => {
	const tokenExpirationTime = localStorage.getItem("expires_in");
	const privateAuthToken = localStorage.getItem("mdtokenPrivate");
	const userInfoValue = localStorage.getItem("userInfo");
	const IdTokenValue = localStorage.getItem("id_token");

	let tokenExpireTime = getCurrentTimeFromUnixFormat(tokenExpirationTime);
	if (privateAuthToken && userInfoValue && IdTokenValue && tokenExpirationTime) {
		let currentDate = moment(new Date());
		return tokenExpireTime.diff(currentDate, "seconds") > 1;
	} else {
		return false;
	}
};
const getValidMDToken = async () => {
	if (IsAuthTokenValid()) {
		return window.localStorage.getItem("mdtokenPrivate");
	} else {
		if (!IsPublicTokenValid()) {
			await fetchPublicToken();
		}
		return window.localStorage.getItem("mdtoken");
	}
};

const IsPublicTokenValid = () => {
	const mdToken = window.localStorage.getItem("mdtoken");
	if (!mdToken) return false;
	const tokenExpirationTime = localStorage.getItem("expiresIn_mdtoken");
	let tokenExpireTime = getCurrentTimeFromUnixFormat(tokenExpirationTime);
	if (mdToken && tokenExpirationTime) {
		let currentDate = moment(new Date());
		return tokenExpireTime.diff(currentDate, "seconds") > 1;
	} else {
		return false;
	}
};

const toolTipTrigger = () => {
	const isMobile = window.matchMedia("(max-width: 1024px)").matches;
	return isMobile ? ["focus", "click"] : ["focus", "hover"];
};

export const deviceType = {
	LargeDesktop: "LARGEDESKTOP",
	SmallDesktop: "SMALLDESKTOP",
	Tablet: "TABLET",
	Mobile: "MOBILE",
	SmallMobile: "SMALLMOBILE",
	Desktop: "DESKTOP",
};

export const getDeviceType = () => {
	const screenWidth = document.documentElement.clientWidth;
	let device = deviceType.SmallDesktop;
	if (screenWidth >= 1441) {
		device = deviceType.LargeDesktop;
	} else if (screenWidth >= 1025 && screenWidth <= 1440) {
		device = deviceType.SmallDesktop;
	} else if (screenWidth >= 768 && screenWidth <= 1024) {
		device = deviceType.Tablet;
	} else if (screenWidth >= 375 && screenWidth <= 767) {
		device = deviceType.Mobile;
	}
	return device;
};

export const handleWindowResize = (callBackHandler) => {
	window.addEventListener("resize", () => {
		callBackHandler(getDeviceType());
	});
};

const getObjectPropertyValue = function (obj, propertyNames) {
	let currentObj;
	if (!obj || !propertyNames) {
		return currentObj;
	}
	let props = [];
	if (typeof propertyNames === "string") {
		props = propertyNames.split(".");
	}

	if (Array.isArray(propertyNames)) {
		props = propertyNames;
	}

	currentObj = { ...obj };
	let found = false;
	const propertySize = props.length - 1;
	props.forEach((prop, index) => {
		if (currentObj.hasOwnProperty(prop)) {
			currentObj = currentObj[prop];
			found = propertySize === index;
		}
	});

	return found ? currentObj : undefined;
};

export const generateRandomValue = () => {
	const crypto = window.crypto || window.msCrypto;
	let array = new Uint32Array(1);
	crypto.getRandomValues(array); // Compliant for security-sensitive use cases
	const FileId = array[0];
	return FileId;
};

export const addLeadingZeros = (num, totalLength) => {
	if (!num) return num;
	return String(num).padStart(totalLength, "0");
};
const getQuarterAndYear = (date) => {
	date = date || new Date();
	var quarter = Math.floor(date.getMonth() / 3) + 1;
	quarter -= quarter > 4 ? 4 : 0;
	var year = date.getFullYear() + (quarter == 1 ? 1 : 0);
	return { year, quarter };
};

const GetCurrentLang = (lang) => {
	lang = lang && lang.toLowerCase();
	let currentLang = localStorage.getItem("language");
	if (lang == null || lang === "" || (lang !== "en" && lang !== "cn" && lang !== "hk"))
		lang = currentLang || "en";
	if (lang === "cn") {
		return "cn";
	} else if (lang === "hk") {
		return "hk";
	} else return "en";
};
export const hideScroll = (isScroll) => {
	return isScroll
		? (document.body.style.overflow = "hidden")
		: (document.body.style.overflow = "auto");
};

export default {
	fetchPublicToken,
	getHKTTime,
	toolTipTrigger,
	getUTCtoHKTTime,
	getObjectPropertyValue,
	generateRandomValue,
	addLeadingZeros,
	getQuarterAndYear,
	IsAuthTokenValid,
	getValidMDToken,
	IsPublicTokenValid,
	GetCurrentLang,
	getHKTTimeOnlyYear,
	getHKTTimeWithDate,
	getCurrentTimeInUnixFormat,
	getCurrentTimeFromUnixFormat,
	hideScroll,
	getTokenExpiredTimeInUnix,
	getUTCtoHKTDateTime,
};

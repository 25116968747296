import RequestService from "../../RequestService";
import Constants from "utils/constants";

const EMPTY_XREFS = [];

/**
 * Utility for fetching XREF API data for symbol passed
 * @param {Object} apiInstance - api instance
 */
const getXrefData = async (symbol, limit, classificationId) => {
	if (!symbol) {
		return EMPTY_XREFS;
	}
	try {
		const apiInstance = await RequestService.init();
		const xrefResponse = await apiInstance.get(
			`${Constants.URLS.XREF_PREDICTIVE_SEARCH_PATH}?term=${symbol}&classificationIds=${classificationId}&limit=${limit}`
		);
		if (xrefResponse) {
			return xrefResponse;
		}
		return EMPTY_XREFS;
	} catch (error) {
		return EMPTY_XREFS;
	}
};

const getXrefDataforVenueXids = async (symbols) => {
	if (!symbols) {
		return EMPTY_XREFS;
	}
	try {
		const apiInstance = await RequestService.init();
		const xrefResponse = await apiInstance.get(
			`${Constants.URLS.XREF_VENUES_IDS_PATH}?venueXids=${symbols}`
		);
		if (xrefResponse) {
			return xrefResponse;
		}
		return EMPTY_XREFS;
	} catch (error) {
		return EMPTY_XREFS;
	}
};

export default { getXrefData, getXrefDataforVenueXids };

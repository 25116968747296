import React, { useState, useEffect, useRef } from "react";
import { hideFlag, showFlag, updateFlag } from "./XAxisFlagUtility";

const XAxisFlag = (props) => {
	let chart = props && props.chart ? props.chart : null;
	let [val, setVal] = useState();
	let [position, setPosition] = useState({ bottom: 0, left: 0 });
	let divRef = useRef(null);

	useEffect(() => {
		if (chart) {
			chart.eventEmitter.on("CROSSHAIR_MOVE", (args) =>
				updateFlag(args, chart, setVal, setPosition)
			);
			chart.eventEmitter.on("FLAG_HIDE_CROSSHAIR", () => hideFlag(divRef));
			chart.eventEmitter.on("FLAG_SHOW_CROSSHAIR", () => showFlag(divRef));
		}
	}, [chart]);

	return (
		<div ref={divRef} style={position} className={"xAxisFlag"} tabIndex="0">
			{val}
		</div>
	);
};
export default XAxisFlag;

import { useEffect, useContext, useMemo } from "react";
import XRefAPIUtils from "services/RequestUtils/XRef/XRefAPIUtils";
import { PeersContext } from "components/contexts/usePeersContext";

const usePeersMasterCopy = (resetDisabled) => {
	const { peers, peerXIDs, setPeersDataXREF, peersDataXREF } = useContext(PeersContext);

	const getXrefData = useMemo(async () => {
		return XRefAPIUtils.getXrefDataforVenueXids(peerXIDs);
	}, [peerXIDs]);

	const setXrefDataInPeerList = (xrefResponse, peersList) => {
		if (xrefResponse && xrefResponse.data && xrefResponse.data.data) {
			const data = xrefResponse.data.data;
			if (data) {
				if (Array.isArray(data.items)) {
					peersList.forEach((peer) => {
						let venue = data.items.find(
							(x) => x.xids?.venue && String(x.xids?.venue) === String(peer.venueXid)
						);
						if (venue) {
							peer.name = venue.name;
							peer.symbol = venue.symbol;
							peer.shortName = venue.supplementalData
								? venue.supplementalData[0].value
								: "";
						} else {
							peer = {};
						}
					});
				}
			}
		} else {
			peersList.forEach((peer) => {
				peer = {};
			});
		}
	};

	useEffect(() => {
		if ((peerXIDs && peerXIDs.length === 0) || !peers) return;
		async function init() {
			try {
				// try loading user pereference for first time.
				// Set XREF data
				if (peers && peers.length > 0) {
					let peersList = [...peers];
					const xrefResponse = await getXrefData;
					setXrefDataInPeerList(xrefResponse, peersList);
					setPeersDataXREF(peersList);
					// End Set XREF data
				} else {
					setPeersDataXREF(null);
					//throw xrefResponse;
				}
			} catch (e) {
				setPeersDataXREF(null);
			}
		}
		init();
	}, [peers, peerXIDs, resetDisabled]);

	return { peersDataXREF };
};

export default usePeersMasterCopy;

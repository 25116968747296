import { useEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { deviceType } from "utils/utils";

const useResizeObserverCorporateCalender = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		if (ref.current) {
			const observeTarget = ref.current;
			const resizeObserver = new ResizeObserver((entries) => {
				entries.forEach((entry) => {
					setDimensions(entry.contentRect);
				});
			});
			resizeObserver.observe(observeTarget);
			return () => {
				resizeObserver.unobserve(observeTarget);
			};
		}
	}, [ref]);
	return dimensions;
};

export const getDeviceTypeCorporateCalender = () => {
	const screenWidth = document.documentElement.clientWidth;
	let device = deviceType.SmallDesktop;
	if (screenWidth >= 1440) {
		device = deviceType.LargeDesktop;
	} else if (screenWidth >= 1150 && screenWidth < 1440) {
		device = "DESKTOP";
	} else if (screenWidth >= 1025 && screenWidth <= 1149) {
		device = deviceType.SmallDesktop;
	} else if (screenWidth >= 768 && screenWidth <= 1024) {
		device = deviceType.Tablet;
	} else if (screenWidth >= 600 && screenWidth <= 767) {
		device = deviceType.Mobile;
	} else if (screenWidth >= 375 && screenWidth <= 599) {
		device = deviceType.SmallMobile;
	}
	return device;
};

export const handleWindowResizeCorporateCalender = (callBackHandler) => {
	window.addEventListener("resize", () => {
		callBackHandler(getDeviceTypeCorporateCalender());
	});
};

export default useResizeObserverCorporateCalender;

const max = Number.MAX_SAFE_INTEGER;

const EN_LOCALE = {
	decimal: ".",
	thousands: ",",
	grouping: [3],
	currency: ["$", ""],
	dateTime: "%a %b %e %X %Y", // %c
	time: "%-I:%M%p", // 12-hour format
	periods: ["am", "pm"], // %p
	date: "%-m/%-d/%Y", // %x
	days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // %A
	shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // %a
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	], // %B
	shortMonths: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	], // %b
	translations: {
		band: {
			overbought: "Overbought",
			oversold: "Oversold",
		},
		chart: {
			unavailable: "  ",
			unavailableIndicator: "No data available for {0}",
		},
		indicator: {
			previousclose: {
				label: "Previous Close {0}",
			},
		},
	},
	labelTemplates: {
		xAxis: {
			doubleRow: {
				minutes: {
					default: "%X",
					first: "%x %A",
				},
				hours: {
					default: "%X",
					first: "",
				},
				days: {
					default: "%e",
					first: "%b %Y",
					firstAlt: "%x %A",
					intraday: "%x %A",
				},
				weeks: {
					default: "%-m/%-d", // Short date: 1/7
					first: "%b %Y",
					firstAlt: "%b %Y",
				},
				months: {
					default: "%b",
					first: "%Y",
					firstAlt: "%b %Y",
				},
				years: {
					default: "%Y",
					first: "%Y",
				},
			},
		},
	},
	rowTemplateThresholds: [
		// Small charts
		{
			width: 150,
			intraday: [
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 15,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 365,
					upper: "weeks",
					lower: "years",
				},
				{
					timeSpan: 365 * 10,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Medium charts
		{
			width: 900,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 24,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 60,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 17,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 150,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 3,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Large charts
		{
			width: max,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 50,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 130,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 30,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 80,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 5,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
	],
};

const ZH_LOCALE = {
	decimal: ".",
	thousands: ",",
	grouping: [3],
	currency: ["$", ""],
	dateTime: "%a %b %e %X %Y", // %c
	time: "%-I:%M%p", // 12-hour format
	periods: ["am", "pm"], // %p
	date: "%-m/%-d/%Y", // %x
	days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // %A
	shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // %a
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	], // %B
	shortMonths: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	], // %b
	translations: {
		band: {
			overbought: "过度购买（超买）",
			oversold: "过度销售（超卖）",
		},
		chart: {
			unavailable: "  ",
			unavailableIndicator: "No data available for {0}",
		},
		indicator: {
			previousclose: {
				label: "Previous Close {0}",
			},
		},
	},
	labelTemplates: {
		xAxis: {
			doubleRow: {
				minutes: {
					default: "%X",
					first: "%x %A",
				},
				hours: {
					default: "%X",
					first: "",
				},
				days: {
					default: "%e",
					first: "%b %Y",
					firstAlt: "%x %A",
					intraday: "%x %A",
				},
				weeks: {
					default: "%-m/%-d", // Short date: 1/7
					first: "%b %Y",
					firstAlt: "%b %Y",
				},
				months: {
					default: "%b",
					first: "%Y",
					firstAlt: "%b %Y",
				},
				years: {
					default: "%Y",
					first: "%Y",
				},
			},
		},
	},
	rowTemplateThresholds: [
		// Small charts
		{
			width: 150,
			intraday: [
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 15,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 365,
					upper: "weeks",
					lower: "years",
				},
				{
					timeSpan: 365 * 10,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Medium charts
		{
			width: 900,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 24,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 60,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 17,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 150,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 3,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Large charts
		{
			width: max,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 50,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 130,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 30,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 80,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 5,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
	],
};
const HK_LOCALE = {
	decimal: ".",
	thousands: ",",
	grouping: [3],
	currency: ["$", ""],
	dateTime: "%a %b %e %X %Y", // %c
	time: "%-I:%M%p", // 12-hour format
	periods: ["am", "pm"], // %p
	date: "%-m/%-d/%Y", // %x
	days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // %A
	shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // %a
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	], // %B
	shortMonths: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	], // %b
	translations: {
		band: {
			overbought: " 過度購買（超買)",
			oversold: " 過度銷售（超賣)",
		},
		chart: {
			unavailable: "  ",
			unavailableIndicator: "No data available for {0}",
		},
		indicator: {
			previousclose: {
				label: "Previous Close {0}",
			},
		},
	},
	labelTemplates: {
		xAxis: {
			doubleRow: {
				minutes: {
					default: "%X",
					first: "%x %A",
				},
				hours: {
					default: "%X",
					first: "",
				},
				days: {
					default: "%e",
					first: "%b %Y",
					firstAlt: "%x %A",
					intraday: "%x %A",
				},
				weeks: {
					default: "%-m/%-d", // Short date: 1/7
					first: "%b %Y",
					firstAlt: "%b %Y",
				},
				months: {
					default: "%b",
					first: "%Y",
					firstAlt: "%b %Y",
				},
				years: {
					default: "%Y",
					first: "%Y",
				},
			},
		},
	},
	rowTemplateThresholds: [
		// Small charts
		{
			width: 150,
			intraday: [
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 15,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 365,
					upper: "weeks",
					lower: "years",
				},
				{
					timeSpan: 365 * 10,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Medium charts
		{
			width: 900,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 24,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 60,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 17,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 150,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 3,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
		// Large charts
		{
			width: max,
			intraday: [
				{
					timeSpan: 4,
					upper: "minutes",
					lower: "days",
				},
				{
					timeSpan: 50,
					upper: "hours",
					lower: "days",
				},
				{
					timeSpan: 130,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: max,
					upper: "days",
					lower: "months",
				},
			],
			interday: [
				{
					timeSpan: 30,
					upper: "days",
					lower: "months",
				},
				{
					timeSpan: 80,
					upper: "weeks",
					lower: "months",
				},
				{
					timeSpan: 365 * 5,
					upper: "months",
					lower: "years",
				},
				{
					timeSpan: max,
					upper: "years",
					lower: null,
				},
			],
		},
	],
};

export default { EN_LOCALE, ZH_LOCALE, HK_LOCALE };

import { useState } from "react";
import formatter from "utils/formatUtils";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FundamentalPriceSlider = ({ label, value, min, max }) => {
	const { t } = useTranslation();
	const [step, setStep] = useState(() => {
		if (max - min < 1) {
			return "0.001";
		}
		return "1";
	});
	const formatValue = (value) => {
		return formatter.number(value, getPrecision(value));
	};
	const getPrecision = (value) => {
		return value < 1 && value > -1 ? 3 : 2;
	};

	return (
		<>
			<label
				className="fundamental-bar-title"
				id={`fundamental-bar-title-${label}`}
				htmlFor={`fundamental-bar-title-${label}`}
			>
				{t(label)}
			</label>
			<input
				type="range"
				className={max !== undefined ? "slider" : "slider no-thumb"}
				value={value}
				min={min}
				max={max}
				step={step}
				aria-labelledby={`fundamental-bar-title-${label}`}
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				aria-valuetext={`${value}`}
				readOnly
			/>
			<Row style={{ marginTop: "9px" }}>
				<Col className="d-flex justify-content-between fundamental-bar-min-max">
					<span className="text-gray-dark">{formatValue(min)}</span>
					<span className="text-gray-dark">{formatValue(max)}</span>
				</Col>
			</Row>
		</>
	);
};

export default FundamentalPriceSlider;

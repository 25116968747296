import { useState } from "react";
import { Container, Card, Row, Modal } from "react-bootstrap";
import Utils from "../../utils/utils";
import "./RecentNews.scss";
import { useTranslation } from "react-i18next";
import useNewsArticle from "components/hooks/useNewsArticle";
import squreUpRightArrow from "assets/icons/squreUpRightArrow.svg";
import moment from "moment-timezone";
import ArticleContent from "./ArticleContent/ArticleContent";
import Error from "components/Error/Error";
import ArticleErrorModal from "./ArticleContent/ArticleErrorModal";
import Skeleton from "react-loading-skeleton";
import "locale/CommonLocale";

const RecentNews = ({ wsodCompany }) => {
	const [selectedArticle, setArticle] = useState();
	const [modalShow, setModalShow] = useState(false);
	const { t } = useTranslation();
	const { newsData, error, loading } = useNewsArticle(wsodCompany);
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;

	async function viewArticle(event, selectedArticleId) {
		event.preventDefault();
		const articleData = newsData.find((x) => x.articleId === selectedArticleId);
		if (articleData && articleData.articleContentURI && articleData.articleHeadline) {
			setArticle(articleData);
		} else {
			setArticle(null);
		}
		setModalShow(true);
	}

	return (
		<Container fluid className="recent-news">
			<Card>
				<Card.Header className="bg-white hk-sectionhdr">
					<h2>{t("Recent News")}</h2>
					<div className="hk-sectionhdr-line"></div>
				</Card.Header>
				{(error || (newsData && newsData.length === 0)) && !loading && (
					<Error errorMessge={t("There is currently no news available.")} />
				)}
				{loading &&
					[1, 2, 3, 4, 5].map((ele, index) => {
						return (
							<Card.Body className="border-bottom border-dark pb-0 pt-1">
								<Row className="mt-16">
									<h6 className={index === 0 ? "first-headline" : "following-headline"}>
										<Skeleton
											className="headline-skeleton"
											height={index === 0 ? "48px" : "20px"}
										/>
									</h6>
								</Row>
								<Row>
									<span className="col_teaser">
										<Skeleton className="headline-skeleton" height={"30px"} />
									</span>
								</Row>
								<Row>
									<span className="col_date">
										<Skeleton className="date-recent-skeleton" height={"15px"} />
									</span>
								</Row>
								<a>
									<Skeleton className="date-recent-skeleton" height={"24px"} />
								</a>
							</Card.Body>
						);
					})}
				{newsData &&
					!loading &&
					Array.isArray(newsData) &&
					newsData.length > 0 &&
					newsData.map((news, index) => {
						return (
							<Card.Body
								key={news.articleId}
								className="border-bottom border-dark pb-0 pt-1"
							>
								<Row className="mt-16">
									<h6 className={index === 0 ? "first-headline" : "following-headline"}>
										{news.Headline}
									</h6>
								</Row>
								<Row>
									<span className="col_teaser">{news.Teaser}</span>
								</Row>
								<Row>
									<span className="col_date">
										{`${moment(news.DocumentDate)
											.locale(t("lang"))
											.format(t("formatRecentNews"))} ${t("HKT")}`}
									</span>
								</Row>
								<a
									className="a-view-more"
									data-testid="view_more"
									rel="noreferrer"
									href="#"
									onClick={(event) => viewArticle(event, news.articleId)}
								>
									<>
										<img src={`${cdnDomain}${squreUpRightArrow}`} className="i-item" />
										<p className="a-item">
											<b className="b-text">{t("View More")}</b>
										</p>
									</>
								</a>
							</Card.Body>
						);
					})}
				<Card.Footer className="col_date">
					<span>{t("Datasource: MTNewswire")}</span>
				</Card.Footer>
			</Card>
			{!selectedArticle && (
				<ArticleErrorModal modalShow={modalShow} setModalShow={setModalShow} />
			)}
			{selectedArticle && !error && (
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					backdrop="static"
					keyboard={false}
					size="lg"
					bsPrefix="news-modal modal"
					dialogClassName="news-modal"
					centered
					data-testid="article_modal"
				>
					<Modal.Header closeButton>
						<Modal.Title data-testid="article-modal">
							<Row>
								<span className="news-title">{selectedArticle.articleHeadline}</span>
							</Row>
							<Row>
								<span className="news-time">
									{`${moment(Utils.getUTCtoHKTTime(selectedArticle.articleDatetime))
										.locale(t("lang"))
										.format(t("format"))} ${t("HKT")}`}
								</span>
							</Row>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ArticleContent articleContentApi={selectedArticle.articleContentURI} />
					</Modal.Body>
				</Modal>
			)}
		</Container>
	);
};

export default RecentNews;

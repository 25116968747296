import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SearchHelper from "./Search-utils";
import searchIcon from "assets/img/search_icon.svg";
import clearSearchIcon from "assets/img/clear_search.svg";
import "./Search.scss";
import "./../PeerComparisons/PeerComparisons.scss";
import CONSTANTS from "utils/constants";

const KEYCODE = {
	UP: 38,
	DOWN: 40,
	TAB: 9,
	ENTER: 13,
};
//TODO : Mouse Navigation
const SearchPeer = ({
	setSymbol,
	setVenueXid,
	onSelectHandler,
	peerIndex,
	peersXidList,
	venueXid,
}) => {
	const [equityEntered, setEquityEntered] = useState("");
	const [filteredEquities, setFilteredEquities] = useState([]);
	const [searchBoxExpanded, setSearchBoxExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const ref = useRef(null);
	const searchInputRef = useRef(null);
	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setSearchBoxExpanded(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);

		return () => {
			document.removeEventListener("click", handleClickOutside, true);
			setEquityEntered({});
			setFilteredEquities({});
		};
	}, []);

	useEffect(() => {
		// To avoid race condition used activeCall flag to handle effect
		let activeCall = true;
		async function getXrefData(params) {
			setLoading(true);
			//check to validate the entered value as special characters are not present in VenueXid and name
			//so we can avoid making call to xref and return empty value
			const options = !CONSTANTS.SEARCH_VALUE_VALIDATION.REGEX.test(equityEntered)
				? await SearchHelper.getXrefData(equityEntered, 6)
				: "";
			if (activeCall) {
				setLoading(false);
				setSearchBoxExpanded(true);
				if (options && options.length > 0) {
					setFilteredEquities(
						options.filter((element) => {
							return (
								element.classification.name ===
									CONSTANTS.SEARCH_VALUE_VALIDATION.SECURITY_TYPE &&
								peersXidList.filter(
									(x) => x && x.toString() === element.xids.venue.toString()
								).length === 0 &&
								venueXid.toString() !== element.xids.venue.toString()
							);
						})
					);
				} else {
					setFilteredEquities([]);
				}
			}
		}
		getXrefData();
		return () => {
			activeCall = false;
		};
	}, [equityEntered]);

	// On selection , set the state for symbol selected
	const onSelected = (selectedItem) => {
		onSelectHandler(selectedItem, peerIndex);
	};

	const handleKeyDown = (event, value) => {
		let nextSibling = event.currentTarget.nextElementSibling;
		let previousSibling = event.currentTarget.previousElementSibling;

		switch (event.keyCode) {
			case KEYCODE.DOWN:
				event.preventDefault();
				if (nextSibling) {
					nextSibling.focus();
				}
				break;
			case KEYCODE.UP:
				event.preventDefault();
				if (previousSibling) {
					previousSibling.focus();
				}
				break;
			case KEYCODE.ENTER:
				event.preventDefault();
				onSelected(value);
				break;
			default:
		}
	};

	const clearSearchInput = () => {
		setFilteredEquities([]);
		setEquityEntered("");
	};

	const highlightMatchedText = (text) => {
		let index = text.toLowerCase().indexOf(equityEntered.toLowerCase());
		return index !== -1 ? (
			<span>
				{text.substring(0, index)}
				<b>{text.substring(index, index + equityEntered.length)}</b>
				{text.substring(index + equityEntered.length)}
			</span>
		) : (
			<span>{text}</span>
		);
	};

	const openSearchBox = () => {
		setSearchBoxExpanded(true);
	};

	return (
		<div ref={ref} className="search-peer-box" onFocus={openSearchBox}>
			<div className="search">
				<img
					className="search-peer-icon"
					aria-hidden="true"
					src={`${cdnDomain}${searchIcon}`}
					alt="search"
					onClick={() => {
						searchInputRef.current.focus();
					}}
				></img>
				<input
					ref={searchInputRef}
					type="text"
					className="peer-input"
					id="SearchInput"
					data-testid="SearchInput"
					aria-labelledby="SearchInput"
					placeholder={t("Stock code or Company")}
					value={equityEntered}
					onChange={(event) => {
						setEquityEntered(event.target.value);
					}}
					aria-label={t("Stock code or Company")}
					tabIndex="5"
					autoComplete="off"
				/>
				{searchBoxExpanded && equityEntered != "" && (
					<img
						className="search-clearIcon"
						aria-hidden="true"
						src={`${cdnDomain}${clearSearchIcon}`}
						alt="clear search"
						onClick={clearSearchInput}
					></img>
				)}
			</div>
			{searchBoxExpanded && equityEntered != "" && filteredEquities.length > 0 && (
				<div className="search-peer-results" role="list" tabIndex="5">
					{filteredEquities.map((value) => {
						return (
							<div
								key={value.xids.venue}
								className="search-results-item"
								aria-label={value.name}
								role="listitem"
								tabIndex="6"
								data-testid="search_Option"
								onKeyDown={(event) => handleKeyDown(event, value)}
								onClick={() => onSelected(value)}
							>
								<div md={2} className="symbol">
									{highlightMatchedText(value.symbol)}
								</div>
								<div className="equitytName peer-search-equity" md={10} key={value.id}>
									{highlightMatchedText(value.name)}
								</div>
							</div>
						);
					})}
				</div>
			)}
			{!loading && equityEntered != "" && filteredEquities.length === 0 && (
				<div className="searchResults" style={{ height: "100% !important" }}>
					<div className="search-results-item">{t("No results found")}</div>
				</div>
			)}
		</div>
	);
};
export default SearchPeer;

import { useState, useEffect } from "react";
import Constants from "utils/constants";
import Utils from "utils/utils";
import RequestService from "services/RequestService";

const useAdvancedFundamentals = (venueXid) => {
	const [advancedFundamentals, setAdvancedFundamentals] = useState([]);
	const [advanceError, setAdvanceError] = useState(null);
	const [advanceLoading, setAdvanceLoading] = useState(true);
	const [fetchedAt, setFetchedAt] = useState(true);
	const [salesGrowth, setSalesGrowth] = useState({});
	const [employeeNo, setEmployeeNo] = useState({});
	const [asOfDate, setAsOfDate] = useState(null);
	const [empAsOfDate, setEmpAsOfDate] = useState(null);
	const apiUrl = `${Constants.URLS.FACTSET_FUNDAMENTALS_ADVANCE}${venueXid}`;

	useEffect(() => {
		if (!venueXid) return;
		setAdvanceLoading(true);
		async function init() {
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);

				if (response.status === 200 && response?.data?.data?.fundamentals?.length > 0) {
					let advancedData = response.data.data.fundamentals.find(
						(f) => f.measure === "EPSFiscal"
					).ttm;
					let salesGrowthData = response.data.data.fundamentals.find(
						(f) => f.measure === "SalesGrowth1Yr"
					);
					let employeeNoData = response.data.data.fundamentals.find(
						(f) => f.measure === "EmployeeNo"
					);
					let Date = response.data.data.fundamentals.find((f) => f.ttm).ttm?.dateAsOf;
					let EmpDate = response.data.data.fundamentals.find((f) => f.mrfy).mrfy
						?.dateAsOf;
					setAdvancedFundamentals(advancedData);
					setSalesGrowth(salesGrowthData);
					setEmployeeNo(employeeNoData);
					setAsOfDate(Date);
					setEmpAsOfDate(EmpDate);
				} else {
					setSalesGrowth(null);
					setEmployeeNo(null);
					setAdvancedFundamentals(null);
					setAsOfDate(null);
					throw response;
				}
				setAdvanceError(null);
			} catch (e) {
				setSalesGrowth(null);
				setEmployeeNo(null);
				setAdvancedFundamentals(null);
				setAsOfDate(null);
				setAdvanceError(e);
			} finally {
				setAdvanceLoading(false);
				setFetchedAt(Utils.getHKTTime());
			}
		}
		init();
	}, [venueXid, apiUrl]);

	return {
		advancedFundamentals,
		salesGrowth,
		employeeNo,
		advanceError,
		advanceLoading,
		fetchedAt,
		asOfDate,
		empAsOfDate,
	};
};

export default useAdvancedFundamentals;

import { useEffect, useState, createContext } from "react";
import moment from "moment-timezone";
import useToken from "components/hooks/useToken";
import Utils from "utils/utils";
const useAuthentication = () => {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [userName, setUserName] = useState("");
	const code = new URLSearchParams(window.location.search).get("code");
	const SAM_CLIENT_ID = window.MD && window.MD.SAM_CLIENT_ID;
	const SAM_REDIRECT_URI = window.MD && window.MD.SAM_REDIRECT_URI;
	const SAM_URL_AUTHORIZE = window.MD && window.MD.SAM_URL_AUTHORIZE;
	const SAM_URL_END_SESSION = window.MD && window.MD.SAM_URL_END_SESSION;
	const signInUrl = `${SAM_URL_AUTHORIZE}?client_id=${SAM_CLIENT_ID}&response_type=code&scope=openid+profile+email&nonce=1234&state=abcd&
	redirect_uri=${encodeURIComponent(SAM_REDIRECT_URI)}`;

	const { getAccessToken, setAuthenticatedUserDetails, isEntitled } = useToken(
		setUserName,
		setAuthenticated
	);

	useEffect(() => {
		if (Utils.IsAuthTokenValid()) {
			setAuthenticatedUserDetails();
			return;
		}

		if (!code) return;
		try {
			getAccessToken();
		} catch (error) {
			console.log(error);
			logOut();
		}
	}, []);

	const logOut = () => {
		let id_token = localStorage.getItem("id_token");
		if (id_token) {
			let logOutUrl = `${SAM_URL_END_SESSION}${id_token}&post_logout_redirect_uri=${encodeURIComponent(
				SAM_REDIRECT_URI
			)}`;
			fetch(logOutUrl);
		}
		setAuthenticated(false);
		setUserName(null);
		localStorage.removeItem("access_token");
		localStorage.removeItem("expires_in");
		localStorage.removeItem("id_token");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("mdtokenPrivate");
		localStorage.setItem("logoutAt", moment(new Date()).unix());
		//Proper Logout
	};

	return {
		isAuthenticated,
		setAuthenticated,
		setAuthenticatedUserDetails,
		setUserName,
		userName,
		signInUrl,
		logOut,
		isEntitled,
	};
};

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	const {
		isAuthenticated,
		userName,
		signInUrl,
		navigateTo,
		logOut,
		logOutUrl,
		setAuthenticatedUserDetails,
		isEntitled,
	} = useAuthentication();
	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				userName,
				signInUrl,
				navigateTo,
				logOut,
				logOutUrl,
				setAuthenticatedUserDetails,
				isEntitled,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
export { AuthProvider };

import { Modal } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import "./DetailsSubmit.scss";

const DetailsSubmit = (props) => {
	const { t } = useTranslation();
	const onClosePopup = () => {
		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="md"
			backdrop="static"
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName={"submitModalContainer"}
			centered
		>
			<Modal.Header className={"header"}>
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={onClosePopup}
				></button>
			</Modal.Header>
			<Modal.Body className={"body details-submit-body"}>
				<div className="details-submit-title">
					<h5 className="details-submit-text">{t("Account details submitted")}</h5>
					<div className="hk-sectionhdr-line details-submit-bar"></div>
				</div>
				<div className="details-submit-msg">{t("Details Submitted Message")}</div>
				<div className="details-submit-footer">
					<button className="btn-primary details-submit-btn" onClick={onClosePopup}>
						{t("SubmitClose")}
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DetailsSubmit;

import { useState, useEffect, useContext, useRef, memo, useCallback } from "react";
import { Card } from "react-bootstrap";
import usePeers from "components/hooks/FactSet/usePeers";
import { SymbolContext } from "components/hooks/useSymbol";
import { useTranslation } from "react-i18next";
import "./PeerComparisons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ArrowColor } from "../MarketToday/MarketTodayConstant";
import utils, { deviceType } from "utils/utils";
import Constants from "utils/constants";
import {
	getDeviceTypePeerComparison,
	handleWindowResizePeerComparison,
} from "components/hooks/useResizeObserver";
import useQuote from "components/hooks/useQuote";
import formatter from "utils/formatUtils";
import Error from "components/Error/Error";
import SearchPeer from "../../components/Search/SearchPeer";
import RemovePeerIcon from "../../assets/img/RemoveIcon.png";
import ResetIconEnabled from "../../assets/img/Icon_blue.svg";
import ResetIconDisabled from "../../assets/img/Icon_grey.svg";
import RequestService from "services/RequestService";
import { PeersContext } from "components/contexts/usePeersContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import "locale/CommonLocale";

const PeerComparisons = ({ isBlurTest, deviceWidth }) => {
	const getApiInstance = async () => {
		return RequestService.init();
	};
	const isSmallDevice = deviceWidth;
	const {
		setSymbol,
		setVenueXid,
		setEquityShortName,
		setEquityName,
		setInstrumentXid,
		setWSODCompany,
		venueXid,
	} = useContext(SymbolContext);
	const [peersData, setPeersData] = useState([]);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const { symbol } = useContext(SymbolContext);
	const { peersDataXREF, peersError, peersLoading, peerXIDs, setUserPrefPeers } =
		useContext(PeersContext);
	const [userPreferencePeerXids, setUserPreferencePeerXids] = useState();
	const [resetDisabled, setResetDisabled] = useState(true);
	usePeers(venueXid, userPreferencePeerXids, resetDisabled, isFirstLoad);
	let { quote, error, loading, asofDate } = useQuote(peerXIDs);
	const { t } = useTranslation();
	const [device, setDevice] = useState(getDeviceTypePeerComparison());
	const leftMoveRef = useRef(null);
	const rightMoveRef = useRef(null);
	const [isBlur, setIsBlur] = useState(isBlurTest ? isBlurTest : true);
	const [updatedCardIndexs, setUpdatedCardIndexs] = useState(Array(5).fill(true));
	const [peerAdded, setPeerAdded] = useState(false);
	const [quoteError, setQuoteError] = useState(null);
	const [resetLoading, setResetLoading] = useState(false);
	const [reloadPeersData, setReloadPeersData] = useState(false);
	const UserPreferenceKey = venueXid ? `PeerComparisons_${venueXid}` : null;
	let UserManagementApiUrl = `${Constants.URLS.USER_MANAGEMENT_PREFERENCE}/${UserPreferenceKey}`;
	let carousel = null;

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1401 },
			items: 5,
			partialVisibilityGutter: 0,
		},
		desktop: {
			breakpoint: { max: 1400, min: 1025 },
			items: 4,
			partialVisibilityGutter: 0,
		},
		tabletSmall: {
			breakpoint: { max: 799, min: 768 },
			items: 2,
			partialVisibilityGutter: 0,
		},
		tabletLarge: {
			breakpoint: { max: 949, min: 800 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		tabletXLarge: {
			breakpoint: { max: 1024, min: 950 },
			items: 4,
			partialVisibilityGutter: 0,
		},
		mobileSmall: {
			breakpoint: { max: 474, min: 0 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileMedium: {
			breakpoint: { max: 524, min: 475 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileLarge: {
			breakpoint: { max: 574, min: 525 },
			items: 1,
			partialVisibilityGutter: 0,
		},
		mobileXLarge: {
			breakpoint: { max: 767, min: 575 },
			items: 2,
			partialVisibilityGutter: 0,
		},
	};

	const resizeHandler = (response) => {
		setDevice(response);
	};

	useEffect(() => {
		handleWindowResizePeerComparison(resizeHandler);
	}, [device]);

	useEffect(() => {
		if (!isFirstLoad) {
			setUpdatedCardIndexs(Array(5).fill(false));
		}
	}, [venueXid]);

	const ButtonGroup = ({ next, previous, _goToSlide, ...rest }) => {
		const {
			carouselState: { currentSlide, totalItems, slidesToShow },
		} = rest;
		const isLargeDesktop = totalItems <= slidesToShow;
		const shouldNotBlur = totalItems <= slidesToShow + currentSlide;
		useEffect(() => {
			shouldNotBlur ? setIsBlur(false) : setIsBlur(true);
		}, [shouldNotBlur]);
		return (
			<div className={isLargeDesktop ? "car-control hide" : "car-control"}>
				<div className="nav buttons multi-carousel-button">
					<div className="nav buttons">
						<button
							data-testid="scrollLeftButton"
							ref={leftMoveRef}
							className="prev"
							onClick={() => {
								if (carousel) carousel.state.itemWidth = 262;
								previous();
							}}
							aria-label="Scroll Left"
						>
							<FontAwesomeIcon
								icon={faChevronLeft}
								className={currentSlide === 0 ? "disable" : ""}
								color={ArrowColor.RED}
							/>
						</button>
						<div className="bar" />
						<button
							data-testid="scrollRightButton"
							ref={rightMoveRef}
							className="next"
							onClick={() => {
								if (carousel) carousel.state.itemWidth = 262;
								if (currentSlide < totalItems - slidesToShow) next();

								if (currentSlide === totalItems - slidesToShow) {
									setIsBlur(false);
								}
							}}
							aria-label="Scroll Right"
						>
							<FontAwesomeIcon
								icon={faChevronRight}
								className={currentSlide === totalItems - slidesToShow ? "disable" : ""}
								color={ArrowColor.RED}
							/>
						</button>
					</div>
				</div>
			</div>
		);
	};

	const showSkeleton = (wdth, hght) => {
		return (
			<div>
				<Skeleton width={wdth} height={hght} />
			</div>
		);
	};

	const setQuoteData = (peer, element) => {
		if (element && peer) {
			peer.marketCap = element.data?.marketCap ? element.data.marketCap : {};
			peer.lastTrade = element.data?.lastTrade ? element.data.lastTrade : {};
			peer.currency = element.data?.currency ? element.data.currency : {};
		}
		return peer;
	};

	const isStatesLoading = (key) => {
		return ((peersLoading || loading) && updatedCardIndexs[key]) || resetLoading;
	};

	const loadPeerData = () => {
		// to check if only one element is left in the peersDataXREF
		if (!Array.isArray(quote)) {
			quote = [{ data: quote }];
		}
		function modifyPeersList(peersList, localQuote) {
			peersList.forEach((peer) => {
				let element = localQuote.find(
					(x) => peer && String(x.data.venueXid) === String(peer?.venueXid)
				);
				peer = setQuoteData(peer, element);
			});
		}
		if (
			(quote && quote.length > 0 && peersDataXREF && peersDataXREF.length > 0) ||
			(peersDataXREF && peersDataXREF.length > 0)
		) {
			let peersList = [...peersDataXREF];
			let localQuote = [...quote];

			modifyPeersList(peersList, localQuote);

			let newPeerData = [...peersList];
			let currentLength = 5 - (newPeerData && newPeerData.length);
			for (let i = 0; i < currentLength; i++) {
				newPeerData.push({});
			}
			setPeersData(newPeerData);
		} else if (error?.status === 206) {
			// In case partial success
			let quotes = error?.data?.data?.quotes;
			if (quotes && quotes.length > 0 && peersDataXREF.length > 0) {
				let peersList = [...peersDataXREF];
				let localQuote = [...quote];
				modifyPeersList(peersList, localQuote);
				let newPeerData = [...peersList];
				setPeersData(newPeerData);
			}
		} else {
			if (!error) {
				setPeersData(Array(5).fill({}));
			}
			setQuoteError(error);
		}
		// }
	};

	const saveUserPreference = async (userPreference) => {
		let api = await getApiInstance();
		return api.post(UserManagementApiUrl, JSON.stringify(userPreference));
	};

	const getUserPreference = useCallback(async () => {
		if (!UserManagementApiUrl) return;
		let api = await getApiInstance();
		return api.get(UserManagementApiUrl);
	}, [venueXid]);

	const deleteUserPreference = async () => {
		let api = await getApiInstance();
		return api.deleteAction(UserManagementApiUrl);
	};

	useEffect(() => {
		loadPeerData();
	}, [quote, error, peersDataXREF]);

	useEffect(() => {
		fetchUserPreference();
	}, [reloadPeersData, venueXid]);

	useEffect(() => {
		if (carousel) carousel.goToSlide(0);
	}, [device]);

	async function fetchUserPreference() {
		let peerUserPrefData = await getUserPreference();
		if (peerUserPrefData?.data?.data?.value) {
			let perf = JSON.parse(peerUserPrefData?.data?.data?.value);
			setUserPreferencePeerXids(perf.userPreferencePeer);
			setUserPrefPeers(perf.userPreferencePeer);
			if (perf.userPreferencePeer) {
				setResetDisabled(false);
			}
		} else {
			setUserPrefPeers(null);
			setResetDisabled(true);
		}
		setIsFirstLoad(false);
	}

	const handleCloseComparison = async (_e, _symbol, index) => {
		await setPeerAdded(false);
		let userPreferencePeerXidList = peersData.map((item) => {
			return {
				venueXid: item.venueXid,
				ranking: item.ranking,
				isVisible: item.isVisible,
			};
		});

		userPreferencePeerXidList[index] = {
			isVisible: false,
			venueXid: null,
		};

		const peerComparisonsPreference = {
			userPreferencePeer: userPreferencePeerXidList,
		};

		let newUpdateCardIndex = [false, false, false, false, false];
		newUpdateCardIndex[index] = true;
		setUpdatedCardIndexs(newUpdateCardIndex);
		setReloadPeersData(!reloadPeersData);
		const response = await saveUserPreference(peerComparisonsPreference);
		if (response.status !== 200) {
			newUpdateCardIndex = [false, false, false, false, false];
			setUpdatedCardIndexs(newUpdateCardIndex);
		}
	};

	const handleResetFunctionality = async (_e) => {
		await setResetLoading(true);
		await setUpdatedCardIndexs(Array(5).fill(false));
		await setPeerAdded(false);

		let response = await deleteUserPreference();
		if (response.status === 200) {
			setUserPreferencePeerXids(null);
			setUserPrefPeers(null);
			setResetDisabled(true);
			setResetLoading(false);
			setReloadPeersData(!reloadPeersData);
		} else {
			console.log(response);
			setResetLoading(false);
		}
	};

	const onPeerSelectHandler = async (selectedItem, index) => {
		setPeerAdded(true);
		if (peersData) {
			let userPreferencePeerXidsList = peersData.map((item, _index) => {
				return {
					venueXid: item.venueXid,
					ranking: index + 1,
					isVisible: item.isVisible,
				};
			});

			userPreferencePeerXidsList[index].venueXid = selectedItem.xids.venue;
			userPreferencePeerXidsList[index].isVisible = true;

			let response = await saveUserPreference({
				userPreferencePeer: userPreferencePeerXidsList,
			});

			if (response.status === 200) {
				let newUpdateCardIndex = [false, false, false, false, false];
				newUpdateCardIndex[index] = true;
				setUpdatedCardIndexs(newUpdateCardIndex);
				setReloadPeersData(!reloadPeersData);
			} else {
				console.log(response);
			}
		}
	};

	const beforeChangeCarouselHandler = (nextSlide, { currentSlide, onMove }) => {
		carousel.state.itemWidth = 262;
	};

	const getChangeColor = (lastprice, closeprice) => {
		if (lastprice > closeprice) return { color: "green" };
		else if (lastprice === closeprice) return { color: "gray" };
		else return { color: "#e72742" };
	};

	const showPosNeg = (lastprice, closeprice) => {
		if (lastprice > closeprice) return "+";
		else return "";
	};

	const renderErrorMessage = (err, quoteErr) => {
		let errorMessage = "There was a problem loading peer comparison.";
		if (
			(err && err.response && err.response.status === 404) ||
			(quoteErr && quoteErr.response && quoteErr.response.status === 404)
		)
			errorMessage = "There is currently no peer comparison data available.";

		return <Error errorMessge={t(errorMessage)}></Error>;
	};

	const renderSkeleton = () => {
		return (
			<div className="comparison-cards skeleton">
				<div className="comparisons-card">
					<div className="comparisons-card-body">
						<div className="card-name-peer">
							{showSkeleton("101px", "19px")}
							<div className="peer-name">{showSkeleton("212px", "66px")}</div>
						</div>

						<div>
							<div className="peer-stats">
								<div className="peer-price-info">
									<div className="peer-stats-label">{showSkeleton("212px", "15px")}</div>
									<div className="peer-stats-value">{showSkeleton("212px", "38px")}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	let resetButtonClass = resetDisabled ? "reset-disabled" : "reset-enabled";
	let resetIcon = resetDisabled ? ResetIconDisabled : ResetIconEnabled;

	let renderPeerComparisonCard = (peer, key) => {
		let peerValue =
			peer?.lastTrade?.last && peer?.lastTrade?.last > 0
				? formatter.priceWithRoundingAndSymbol(peer.lastTrade.last, 3)
				: "-";
		let priceChange =
			peer?.lastTrade?.last && peer?.lastTrade?.close ? (
				<div
					className="price-change"
					style={getChangeColor(peer?.lastTrade?.last, peer?.lastTrade?.close)}
				>
					{showPosNeg(peer?.lastTrade?.last, peer?.lastTrade?.close)}
					{formatter.number(peer?.lastTrade?.last - peer?.lastTrade?.close, 3)}
					{" ("}
					{showPosNeg(peer?.lastTrade?.last, peer?.lastTrade?.close)}
					{formatter.percent(
						((peer?.lastTrade?.last - peer?.lastTrade?.close) * 100) /
							peer?.lastTrade?.close,
						2
					)}
					{")"}
				</div>
			) : (
				"- (-)"
			);

		let peerStatusValue = peer.marketCap
			? formatter.getCompactFormattedLargeValue(peer.marketCap, 2, false)
			: "-";

		return isStatesLoading(key) ? (
			renderSkeleton()
		) : (
			<>
				<div key={key} className="comparisons-card">
					<button
						className="remove-card"
						onClick={(e) => handleCloseComparison(e, peer?.symbol, key)}
					>
						<img
							className="remove-card-icon"
							alt="remove-card-icon"
							src={`${RemovePeerIcon}`}
						></img>
					</button>
					<div className="comparisons-card-body">
						<div className="card-name-peer">
							<div className="peer-short-desc">{peer.symbol}</div>
							<div className="peer-name">{peer.name}</div>
						</div>

						<div>
							<div className="peer-stats">
								<div className="peer-price-info">
									<div className="peer-stats-label">{t("Last Price")}</div>
									<div className="peer-stats-value">
										<>
											<div className="peer-currency">{peer?.currency?.symbol}</div>
											<div className="peer-value">{peerValue}</div>
										</>
									</div>
									{priceChange}
								</div>
								<div className="market-cap">
									<div className="peer-stats-label">{t("market-cap")}</div>
									<div className="peer-stats-value">
										<>
											<div className="peer-currency">{peer?.currency?.symbol}</div>
											<div className="peer-value">{peerStatusValue}</div>
										</>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	let renderAddComparisonsCard = (key) => {
		let addComparisonCard =
			peerAdded && updatedCardIndexs[key] ? (
				renderSkeleton()
			) : (
				<div className="comparisons-card addComparison">
					<div className="comparison-text">{t("Add a comparison")}</div>
					<SearchPeer
						setSymbol={setSymbol}
						setVenueXid={setVenueXid}
						setInstrumentXid={setInstrumentXid}
						setEquityShortName={setEquityShortName}
						setEquityName={setEquityName}
						setWSODCompany={setWSODCompany}
						onSelectHandler={onPeerSelectHandler}
						peerIndex={key}
						peersXidList={peersData.map((x) => x.venueXid)}
						venueXid={venueXid}
					/>
				</div>
			);

		return (peersLoading || loading) && updatedCardIndexs[key]
			? renderSkeleton()
			: addComparisonCard;
	};

	let renderAddComparisonsCardOrSkeleton = (key) => {
		return isStatesLoading(key) ? renderSkeleton() : renderAddComparisonsCard(key);
	};
	return (
		<Card className={isSmallDevice ? "bg-white hk-sectionhdr" : "bg-gray hk-sectionhdr"}>
			<Card.Header
				className={isSmallDevice ? "bg-white hk-sectionhdr" : "bg-gray hk-sectionhdr"}
			>
				<h2>{t("Peer Comparison")}</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			<Card.Body className="card-body">
				<div className="peer-desc">
					<span>
						{resetDisabled
							? t("Peer_Comparison_competitors_text")
							: t("Peer_Comparison_competitors_text_custom_peers")}
					</span>
					{device !== deviceType.Tablet && device !== deviceType.Mobile ? (
						<button
							className={`reset-button ${resetButtonClass}`}
							onClick={(e) => handleResetFunctionality(e)}
							disabled={resetDisabled}
						>
							<img className="reset-icon" alt="reset-icon" src={resetIcon}></img>
							<div className="reset-text">{t("Reset")}</div>
						</button>
					) : (
						""
					)}
				</div>
				{device === deviceType.Tablet || device === deviceType.Mobile ? (
					<button
						className={`reset-button ${resetButtonClass}`}
						onClick={(e) => handleResetFunctionality(e)}
						disabled={resetDisabled}
					>
						<img className="reset-icon" alt="reset-icon" src={resetIcon}></img>
						<div className="reset-text">{t("Reset")}</div>
					</button>
				) : (
					""
				)}
				{(peersError || quoteError) && renderErrorMessage(peersError, error)}
				{peersData && (
					<>
						<div className="comparison-cards">
							<Carousel
								responsive={responsive}
								className="carousel-inner overflow-content"
								autoPlay={false}
								showDots={false}
								infinite={false}
								additionalTransfrom={0}
								shouldResetAutoplay={false}
								arrows={false}
								autoPlaySpeed={0}
								centerMode={false}
								focusOnSelect={false}
								partialVisible={true}
								swipeable
								customButtonGroup={<ButtonGroup />}
								beforeChange={beforeChangeCarouselHandler}
								renderButtonGroupOutside={true}
								ref={(el) => (carousel = el)}
								itemClass={`peer-comparison-item-card`}
							>
								{peersData &&
									peersData.map((peer, key) => {
										return peer && peer.isVisible && peer?.name
											? renderPeerComparisonCard(peer, key)
											: renderAddComparisonsCardOrSkeleton(key);
									})}
							</Carousel>
							{
								<div
									data-testid="right-blur"
									className={isBlur ? "right-blur" : "no-blur"}
								></div>
							}
							<div className="timestamp">
								{t("As of")}{" "}
								{moment(utils.getHKTTime(asofDate))
									.locale(t("lang"))
									.format(t("format")) + " "}
								{t("HKT")}
							</div>
						</div>
					</>
				)}
			</Card.Body>
		</Card>
	);
};

export default memo(PeerComparisons);

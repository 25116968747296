import { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "components/hooks/useAuthentication";
import { useTranslation } from "react-i18next";
import SearchHelper from "./Search-utils";
import searchIcon from "assets/img/search_icon.svg";
import clearSearchIcon from "assets/img/clear_search.svg";
import "./Search.scss";
import { LanguageContext } from "components/hooks/useLanguage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSkeletonTheme from "components/Lib/CustomSkeleton/CustomSkeletonTheme";

const KEYCODE = {
	UP: 38,
	DOWN: 40,
	TAB: 9,
	ENTER: 13,
};
const Search = ({
	isProfile,
	setSymbol,
	setVenueXid,
	setEquityShortName,
	setEquityName,
	setInstrumentXid,
	setWSODCompany,
	closeOuterPopup,
	loading,
}) => {
	const [equityEntered, setEquityEntered] = useState("");
	const [filteredEquities, setFilteredEquities] = useState([]);
	const [searchBoxExpanded, setSearchBoxExpanded] = useState(false);
	const [isloading, setLoading] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const { isAuthenticated } = useContext(AuthContext);
	const ref = useRef(null);
	const searchInputRef = useRef(null);
	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const { selectedLangParam } = useContext(LanguageContext);
	var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setSearchBoxExpanded(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);

		return () => {
			document.removeEventListener("click", handleClickOutside, true);
			setEquityEntered({});
			setFilteredEquities({});
		};
	}, []);

	useEffect(() => {
		// To avoid race condition used activeCall flag to handle effect
		let activeCall = true;
		async function getXrefData(params) {
			setLoading(true);
			const options = !regex.test(equityEntered)
				? await SearchHelper.getXrefData(equityEntered, 6)
				: "";
			if (activeCall) {
				setLoading(false);
				setSearchBoxExpanded(true);
				options && options.length > 0
					? setFilteredEquities(options)
					: setFilteredEquities([]);
			}
		}
		getXrefData();
		return () => {
			activeCall = false;
		};
	}, [equityEntered]);

	// On selection , set the state for symbol selected
	const onSelected = (selectedItem) => {
		const { symbol, xids, name, supplementalData } = selectedItem;
		const { value } =
			supplementalData?.length > 0 ? supplementalData[0] : { value: null };
		const { venue, instrument, entity } = xids;

		if (symbol) {
			localStorage.setItem("venue", venue);
			setSymbol(symbol);
			setVenueXid(venue);
			setInstrumentXid(instrument);
			setEquityShortName(value);
			setEquityName(name);
			setWSODCompany(entity);
			setSearchBoxExpanded(false);
			setEquityEntered("");
			closeOuterPopup && closeOuterPopup();
			let path = `/public/${venue}`;

			if (isAuthenticated) {
				path = `/profile/${venue}`;
			}

			path = `${path}/?lang=${selectedLangParam}`;
			history.push(path);
		}
	};

	const handleKeyDown = (event, value) => {
		let nextSibling = event.currentTarget.nextElementSibling;
		let previousSibling = event.currentTarget.previousElementSibling;

		switch (event.keyCode) {
			case KEYCODE.DOWN:
				event.preventDefault();
				if (nextSibling) {
					nextSibling.focus();
				}
				break;
			case KEYCODE.UP:
				event.preventDefault();
				if (previousSibling) {
					previousSibling.focus();
				}
				break;
			case KEYCODE.ENTER:
				event.preventDefault();
				onSelected(value);
				break;
			default:
		}
	};

	const clearSearchInput = () => {
		setFilteredEquities([]);
		setEquityEntered("");
	};

	const getHighlightedText = (text) => {
		let searchKey = equityEntered?.replace(/[^\w\s]/gi, "");
		const parts = text?.split(new RegExp(`(${searchKey})`, "gi"));
		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === searchKey.toLowerCase() ? (
						<b key={index}>{part}</b>
					) : (
						part
					)
				)}
			</span>
		);
	};

	const openSearchBox = () => {
		setSearchBoxExpanded(true);
	};
	const showSkeleton = () => {
		return (
			<CustomSkeletonTheme>
				<div className={`${isProfile ? "searchBoxProfile" : "searchBox"}`}>
					{<Skeleton width={"100%"} height={"40px"} />}
				</div>
			</CustomSkeletonTheme>
		);
	};

	return (
		<div
			ref={ref}
			className={`${isProfile ? "searchBoxProfile" : "searchBox"}`}
			onFocus={openSearchBox}
		>
			{!isProfile ? (
				<label htmlFor="SearchInput" className="searchBox-lable">
					{t("Search for companies")}
				</label>
			) : null}
			{loading ? (
				showSkeleton()
			) : (
				<div className="search">
					<img
						className="search-Icons"
						aria-hidden="true"
						src={`${cdnDomain}${searchIcon}`}
						alt="search"
						onClick={() => {
							searchInputRef.current.focus();
						}}
					></img>
					<input
						ref={searchInputRef}
						type="text"
						id="SearchInput"
						data-testid="SearchInput"
						aria-labelledby="SearchInput"
						placeholder={t("Enter Stock code or Company name")}
						value={equityEntered}
						onChange={(event) => {
							setEquityEntered(event.target.value);
						}}
						aria-label={t("Enter Stock code or Company name")}
						tabIndex="5"
						autoComplete="off"
					/>
					{searchBoxExpanded && equityEntered != "" && (
						<img
							className="search-clearIcon"
							aria-hidden="true"
							src={`${cdnDomain}${clearSearchIcon}`}
							alt="clear search"
							onClick={clearSearchInput}
						></img>
					)}
				</div>
			)}
			{searchBoxExpanded && equityEntered != "" && filteredEquities.length > 0 && (
				<div
					className="searchResults"
					style={{ height: "100% !important" }}
					role="list"
					tabIndex="5"
				>
					<div className="search-results-item search-results-item-header">
						<div md={2} className="symbol">
							{t("Equities")}
						</div>
					</div>
					{filteredEquities.map((value) => {
						return (
							<div
								key={value.xids.venue}
								className="search-results-item"
								aria-label={value.name}
								role="listitem"
								tabIndex="6"
								data-testid="search_Option"
								onKeyDown={(event) => handleKeyDown(event, value)}
								onClick={() => onSelected(value)}
							>
								<div md={2} className="symbol">
									{getHighlightedText(value.symbol)}
								</div>
								<div className="equitytName" md={10} key={value.id}>
									{getHighlightedText(value.name)}
								</div>
							</div>
						);
					})}
				</div>
			)}
			{!isloading && equityEntered != "" && filteredEquities.length === 0 && (
				<div className="searchResults" style={{ height: "100% !important" }}>
					<div className="search-results-item">{t("No results found")}</div>
				</div>
			)}
		</div>
	);
};
export default Search;

import { useState, useEffect } from "react";
import { Dropdown, Row } from "react-bootstrap";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import "../IndexFilters.scss";
import CaretCloseIcon from "assets/img/caret-close-icon.svg";
import CaretOpenIcon from "assets/img/caret-open-Icon.svg";
import RankingSets from "Constants/RankingSets";
import { useTranslation } from "react-i18next";

const MoversDropdown = ({
	setShowSwitch,
	selectedFilterOption,
	setSelectedFilterOption,
}) => {
	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const [arrow, setArrow] = useState(`${cdnDomain}${CaretCloseIcon}`);
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState(RankingSets[0].label);
	const { t } = useTranslation();
	useEffect(() => {
		setSelectedFilterOption(RankingSets[0]);
	}, []);

	const handleFilterOptionSelect = (value) => {
		let getSelectedFilter = RankingSets.filter((option) => option.label === value)[0];
		setSelectedFilterOption(getSelectedFilter);
		setTitle(getSelectedFilter.label);
		if (t(value) === t("HSI Constituents")) {
			setShowSwitch(false);
		} else {
			setShowSwitch(true);
		}
	};

	const dropdownToggle = (isOpen) => {
		if (!isOpen) setShow(true);
		else setShow(false);
		if (arrow === `${cdnDomain}${CaretOpenIcon}`) {
			setArrow(`${cdnDomain}${CaretCloseIcon}`);
			setShow(false);
		} else setArrow(`${cdnDomain}${CaretOpenIcon}`);
	};

	return (
		<>
			<div className="filters-button" data-testid="filtersDropdown">
				<Row>
					<Dropdown show={show} onToggle={() => dropdownToggle(show)}>
						<Dropdown.Toggle
							className={styles.dropdownToggle}
							data-testid="dropdownButton"
							id="dropdownButton"
						>
							<span data-testid="dropdown-title">{t(title)}</span>{" "}
							<img
								className={styles.DropDownIcon + " align-caret"}
								src={arrow}
								alt="Right"
							/>
						</Dropdown.Toggle>
						<Dropdown.Menu className={`${styles.dropdownMenu} filtersMenu`}>
							{RankingSets.map((option) => {
								return (
									<Dropdown.Item
										key={option.key}
										id="dropdown-item"
										data-testid="dropdown-item"
										data-value={option.label}
										className={
											selectedFilterOption && selectedFilterOption.key === option.key
												? styles.selected
												: styles.dropdownItem
										}
										onClick={() => handleFilterOptionSelect(option.label)}
									>
										{t(option.label)}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</Row>
			</div>
		</>
	);
};

export default MoversDropdown;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loader = () => {
	return (
		<>
			<FontAwesomeIcon
				icon={faSpinner}
				pulse
				size="lg"
				style={{
					color: "lightblue",
					width: "35px",
				}}
			/>
		</>
	);
};

export default Loader;

import React from "react";

const CheckBox = ({ message, onClick }) => {
	return (
		<>
			<input type="checkbox" className="agree-checkbox" onClick={onClick} />
			<span dangerouslySetInnerHTML={{ __html: message }}></span>
		</>
	);
};

export default CheckBox;

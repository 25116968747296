import React, { useState } from "react";
import { Row, Form, Col } from "react-bootstrap";
import "./IndexFilters.scss";
import Switch from "@mui/material/Switch";
import MoversDropdown from "./MoversDropdown/MoversDropdown";
import { useTranslation } from "react-i18next";
const IndexFilters = ({
	selectedFilterOption,
	setSelectedFilterOption,
	includeMicroCapStocks,
	setIncludeMicroCapStocks,
}) => {
	const [showSwitch, setShowSwitch] = useState(true);
	const [textClass, setTextClass] = useState("toggle-txt");
	const widthMob = window.innerWidth < 768 ? true : false;
	const { t } = useTranslation();

	const handleRadioChange = (e) => {
		if (!includeMicroCapStocks) {
			setTextClass("toggle-txt");
		} else {
			setTextClass("toggle-txt-normal");
		}
		setIncludeMicroCapStocks(!includeMicroCapStocks);
	};

	return (
		<>
			{!widthMob && (
				<div className="filters-button" data-testid="filtersDropdown">
					<Row>
						<MoversDropdown
							selectedFilterOption={selectedFilterOption}
							setSelectedFilterOption={setSelectedFilterOption}
							setShowSwitch={setShowSwitch}
						></MoversDropdown>
					</Row>
					{showSwitch && (
						<Row className="toggle-btn">
							<Form className="inline-comp">
								<span className={textClass}>{t("Include micro cap equities")}</span>
								<Switch
									checked={includeMicroCapStocks}
									onChange={handleRadioChange}
									disableRipple
									defaultChecked
									className="switch-comp"
								/>
							</Form>
						</Row>
					)}
				</div>
			)}
			{widthMob && (
				<div className="filters-button" data-testid="filtersDropdown">
					<Row>
						<Col xs={6}>
							<MoversDropdown
								selectedFilterOption={selectedFilterOption}
								setSelectedFilterOption={setSelectedFilterOption}
								setShowSwitch={setShowSwitch}
							></MoversDropdown>
						</Col>
						<Col>
							{showSwitch && (
								<div className="toggle-btn">
									<Form className="inline-comp" data-testid="filters-toggle">
										<span className={textClass}>{t("Include micro cap")}</span>
										<Switch
											checked={includeMicroCapStocks}
											onChange={handleRadioChange}
											disableRipple
											defaultChecked
											className="switch-comp"
											data-testid="filters-toggle"
										/>
									</Form>
								</div>
							)}
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default IndexFilters;

import { useState, useEffect, useContext } from "react";
import Constants from "../../utils/constants";
import { LanguageContext } from "../hooks/useLanguage";
import RequestService from "../../services/RequestService";
const useAnnouncements = (symbol, market, limit) => {
	const [announcements, setAnnouncements] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const { announcemtnLang } = useContext(LanguageContext);
	const apiUrl = `${Constants.URLS.COMPANY_ANNOUNCEMENTS}?market=${market}&stockcode=${symbol}&limit=${limit}&lang=${announcemtnLang}`;

	useEffect(() => {
		setLoading(true);
		async function init() {
			if (!symbol) return;
			try {
				const apiInstance = await RequestService.init();
				const response = await apiInstance.get(apiUrl);
				if (response.status === 200 && response?.data?.length > 0) {
					setAnnouncements(response.data);
					setError(null);
				} else if (response.status === 404) {
					setAnnouncements([]);
					setError(null);
				} else {
					throw response;
				}
			} catch (err) {
				setError(err);
				setAnnouncements([]);
			} finally {
				setLoading(false);
			}
		}
		init();
	}, [symbol, market, limit, announcemtnLang]);

	return { announcements, error, loading };
};

export default useAnnouncements;

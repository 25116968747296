import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoIcon from "../../../assets/img/InfoIconBlue.svg";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const CorporateCalendarInfoPopUp = ({ keyName, children, carouselCurrentSlide }) => {
	const { t } = useTranslation();
	const [active, setActive] = useState(false);
	const tooltipOverlayRef = useRef(null);
	const moreInfoLinkRef = useRef(null);

	useEffect(() => {
		setActive(false);
	}, [carouselCurrentSlide]);

	useEffect(() => {
		//Assign click handler to listen the click to close the tooltip when clicked outside
		document.addEventListener("click", handleClickMoreInfoOverlay);

		return () => {
			//Remove the listener
			document.removeEventListener("click", handleClickMoreInfoOverlay);
		};
	}, []);

	const handleClickMoreInfo = async (event) => {
		await setActive(!active);
	};

	const handleClickMoreInfoOverlay = async (event) => {
		const path = event.path || (event.composedPath && event.composedPath());

		if (path.includes(moreInfoLinkRef.current)) {
			return false;
		}

		if (!path.includes(tooltipOverlayRef.current)) {
			await setActive(false);
		} else {
			await setActive(true);
		}
	};

	return (
		<div className="more-info-container" onClick={handleClickMoreInfo}>
			<OverlayTrigger
				key={keyName}
				placement={`top`}
				delay={500}
				show={active}
				className="overlay-pop-event-links"
				overlay={
					<Tooltip show={active} id={`tooltip-${keyName}`}>
						<div className="link-container" ref={tooltipOverlayRef}>
							{children}
						</div>
					</Tooltip>
				}
			>
				<span class="calender-event-more-info" ref={moreInfoLinkRef}>
					<img src={InfoIcon} alt="more-info"></img> <strong>{t("More_info_CC")}</strong>
				</span>
			</OverlayTrigger>
		</div>
	);
};

export default memo(CorporateCalendarInfoPopUp);

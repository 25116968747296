import { UPPER, LOWER } from "../Constants/IndicatorsConstants";

function addIndicator(props, indicator, panel, newPanel) {
	if (panel === UPPER) {
		if (["sma", "ema"].includes(indicator.id))
			Object.keys(props.indicatorCount).forEach((key) => {
				if (key === indicator.id) props.indicatorCount[key]++;
			});

		props.chart.panels[0].addIndicator(indicator.id);
	} else {
		if (props.chart.panels.length > 1) {
			//To remove previous lower indicator
			const currentPanel = props.chart.panels[1];
			props.chart.removePanel(currentPanel);
			props.chart.resize();
		}

		if (newPanel === null) {
			newPanel = props.chart.addPanel();
			newPanel.addIndicator(indicator.id);
		}

		props.setLowerAdded(true);
		props.setLowerLegend(indicator);
	}
}

const addIndicatorToPanel = (props, indicator, panel, newPanel) => {
	if (props.INDI != null) indicator = props.INDI; //To increase code coverage
	addIndicator(props, indicator, panel, newPanel);
	if (props.chart.panels.length > 2)
		props.setLowerLegend(props.chart.panels[2].indicators[0]);
	props.setLegends(props.chart.panels[0].indicators);
	if (props.chart.params) props.chart.params.panelHeightLower = 104;
	props.chart.loadData();
};

const addSelectedDropDownColorStyle = (
	indicatorId,
	panel,
	upperIndicatorRef,
	lowerIndicatorRef,
	styles
) => {
	if (upperIndicatorRef.current && panel === UPPER) {
		upperIndicatorRef.current.childNodes.forEach((element) => {
			let indicator = element.getAttribute("data-id");
			if (indicator && indicatorId === indicator) {
				element.classList.value = element.classList.value + " " + styles.selected;
			}
		});
	}
	if (lowerIndicatorRef.current && panel === LOWER) {
		lowerIndicatorRef.current.childNodes.forEach((element) => {
			let indicator = element.getAttribute("data-id");
			if (indicatorId !== indicator) {
				element.classList.value = element.classList.value.replace(
					element.classList[2],
					""
				);
			}

			if (indicator && indicatorId === indicator) {
				element.classList.value = element.classList.value + " " + styles.selected;
			}
		});
	}
};

//Code commented for now, will be used in legends integration ticket
function removeIndicator(props, indicator, panel) {
	if (panel === UPPER) {
		try {
			props.chart.panels[0].removeIndicator(indicator);
			props.setLegends(props.chart.panels[0].indicators);
			if (["sma", "ema"].includes(indicator.params.id))
				Object.keys(props.indicatorCount).forEach((key) => {
					if (key === indicator.params.id) props.indicatorCount[key]--;
				});
		} catch {
			console.log("No ChartData Available");
		}
	} else {
		let currentPanel = props.chart.panels[1];
		props.chart.removePanel(currentPanel);
		props.chart.resize();
		props.setLowerAdded(false);
	}
}

// To find the top parent element to set target for error message
function hasSomeParentTheClass(element) {
	if (element.className.split(" ").indexOf("indicators-button") >= 0) {
		return element;
	} else {
		return hasSomeParentTheClass(element.parentNode);
	}
}
const removeSelectedDropDownColorStyle = (
	indicatorId,
	upperIndicatorRef,
	lowerIndicatorRef
) => {
	if (upperIndicatorRef.current) {
		upperIndicatorRef.current.childNodes.forEach((element) => {
			let indicator = element.getAttribute("data-id");
			if (indicator && indicatorId === indicator) {
				element.classList.value = element.classList.value.replace(
					element.classList[2],
					""
				);
			}
		});
	}

	lowerIndicatorRef.current.childNodes.forEach((element) => {
		let indicator = element.getAttribute("data-id");
		if (indicator && indicatorId === indicator) {
			element.classList.value = element.classList.value.replace(element.classList[2], "");
		}
	});
};

export {
	addIndicator,
	removeIndicator,
	hasSomeParentTheClass,
	addSelectedDropDownColorStyle,
	removeSelectedDropDownColorStyle,
	addIndicatorToPanel,
};

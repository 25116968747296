import { useState, createContext } from "react";

const usePeersContext = () => {
	const [peers, setPeers] = useState([]);
	const [peersError, setPeersError] = useState(null);
	const [peersLoading, setPeersLoading] = useState(false);
	const [peersFetchedAt, setPeersFetchedAt] = useState(true);
	const [peersDataXREF, setPeersDataXREF] = useState([]);
	const [peerXIDs, setPeerXIDs] = useState();
	const [peersDataModified, setpeersDataModified] = useState();
	const [peersDataModifiedXIDs, setpeersDataModifiedXIDs] = useState();
	const [originalPeers, setOriginalPeers] = useState([]);
	const [userPrefPeers, setUserPrefPeers] = useState(null);

	return {
		originalPeers,
		setOriginalPeers,
		peers,
		peersDataXREF,
		peersError,
		peersLoading,
		peersFetchedAt,
		userPrefPeers,
		setPeers,
		setPeersError,
		setPeersLoading,
		setPeersFetchedAt,
		setPeersDataXREF,
		peerXIDs,
		setPeerXIDs,
		peersDataModified,
		setpeersDataModified,
		peersDataModifiedXIDs,
		setpeersDataModifiedXIDs,
		setUserPrefPeers,
	};
};

export const PeersContext = createContext(null);

const PeersProvider = ({ children }) => {
	const {
		originalPeers,
		setOriginalPeers,
		peers,
		peersDataXREF,
		peersError,
		peersLoading,
		peersFetchedAt,
		userPrefPeers,
		setPeers,
		setPeersError,
		setPeersLoading,
		setPeersFetchedAt,
		setPeersDataXREF,
		peerXIDs,
		setPeerXIDs,
		peersDataModified,
		setpeersDataModified,
		peersDataModifiedXIDs,
		setpeersDataModifiedXIDs,
		setUserPrefPeers,
	} = usePeersContext();

	return (
		<PeersContext.Provider
			value={{
				originalPeers,
				setOriginalPeers,
				peers,
				peersDataXREF,
				peersError,
				peersLoading,
				peersFetchedAt,
				userPrefPeers,
				setPeers,
				setPeersError,
				setPeersLoading,
				setPeersFetchedAt,
				setPeersDataXREF,
				peerXIDs,
				setPeerXIDs,
				peersDataModified,
				setpeersDataModified,
				peersDataModifiedXIDs,
				setpeersDataModifiedXIDs,
				setUserPrefPeers,
			}}
		>
			{children}
		</PeersContext.Provider>
	);
};
export { PeersProvider };

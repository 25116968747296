import styles from "./BreakDown.module.scss";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useInstitutionsSummary from "components/hooks/BigDough/useInstitutionsSummary";
import formatter from "utils/formatUtils";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";

const BreakDown = ({ instrumentXid }) => {
	const { t } = useTranslation();
	const { percentShare, error, loading } = useInstitutionsSummary(instrumentXid);
	let instituionsShare = null,
		instituionsSharePercent = null,
		strategicCorporates = null,
		strategicCorporatesPercent = null,
		managementOwnership = null,
		managementOwnershipPercent = null,
		impliedRetail = null,
		impliedRetailPercent = null;
	for (const key in percentShare) {
		if (key === "institutional") {
			instituionsShare = percentShare.institutional.percentSharesHeld * 100;
			if (isNaN(instituionsShare) || instituionsShare < 1) instituionsShare = 0.0;
			instituionsSharePercent =
				instituionsShare > 0 ? formatter.percent(instituionsShare, 2) : "0.00%";
		}
		if (key === "insiders") {
			managementOwnership = percentShare.insiders.percentSharesHeld * 100;
			if (isNaN(managementOwnership) || managementOwnership < 1) managementOwnership = 0;
			managementOwnershipPercent =
				managementOwnership > 0 ? formatter.percent(managementOwnership, 2) : "0.00%";
		}
		if (key === "strategic") {
			strategicCorporates = percentShare.strategic.percentSharesHeld * 100;
			if (isNaN(strategicCorporates) || strategicCorporates < 1) strategicCorporates = 0;
			strategicCorporatesPercent =
				strategicCorporates > 0 ? formatter.percent(strategicCorporates, 2) : "0.00%";
		}
		impliedRetail = 100 - (instituionsShare + managementOwnership + strategicCorporates);
		impliedRetailPercent = formatter.percent(impliedRetail, 2);
	}
	const renderErrorMessage = (error) => {
		let errorMessage = "There is currently no holdings breakdown data available.";
		return <Error errorMessge={t(errorMessage)}></Error>;
	};
	return (
		<div className={styles.container}>
			<Row className={styles.header}>
				<Col md={12}>{t("Breakdown")}</Col>
			</Row>
			<Row className={styles.row}>
				{error && renderErrorMessage(error)}
				{!error && (
					<div className={styles.bar}>
						{loading ? (
							<Skeleton className={styles.bdbarskeleton} />
						) : (
							<>
								<div
									className={styles.insti}
									style={{
										width: `${instituionsSharePercent}`,
										display: instituionsSharePercent === "0.00%" && "none",
									}}
								></div>
								<div
									className={styles.strategic}
									style={{
										width: `${strategicCorporatesPercent}`,
										display: strategicCorporatesPercent === "0.00%" && "none",
									}}
								></div>
								<div
									className={styles.management}
									style={{
										width: `${managementOwnershipPercent}`,
										display: managementOwnershipPercent === "0.00%" && "none",
									}}
								></div>
								<div
									className={styles.retail}
									style={{
										width: `${impliedRetailPercent}`,
										display: impliedRetailPercent === "0%" && "none",
									}}
								></div>
							</>
						)}
					</div>
				)}
				{!error && (
					<table className={styles.displayData}>
						<tr className={styles.innerDiv}>
							<td>
								{loading ? (
									<Skeleton className={styles.bdlabelskeleton} />
								) : (
									<>
										<div
											className={styles.circle}
											style={{ background: "#04223D" }}
										></div>
										<div className={styles.text}>{t("Institutions")}</div>
									</>
								)}
							</td>
							<td>
								{loading ? (
									<Skeleton className={styles.bdlabelskeleton} />
								) : (
									<>
										<div
											className={styles.circle}
											style={{ background: "#41748D" }}
										></div>
										<div className={styles.text}>{t("Strategic Corporates")}</div>
									</>
								)}
							</td>
							<td>
								{loading ? (
									<Skeleton className={styles.bdlabelskeleton} />
								) : (
									<>
										<div
											className={styles.circle}
											style={{ background: "#A0BBCF" }}
										></div>
										<div className={styles.text}>{t("Management Ownership")}</div>
									</>
								)}
							</td>
							<td>
								{loading ? (
									<Skeleton className={styles.bdlabelskeleton} />
								) : (
									<>
										<div
											className={styles.circle}
											style={{ background: "#D1DDE6" }}
										></div>
										<div className={styles.text}>{t("Implied Retail")}</div>
									</>
								)}
							</td>
						</tr>
						<tr className={styles.innerDiv}>
							{loading ? (
								<td>
									<Skeleton className={styles.bdvalueskeleton} />
								</td>
							) : (
								<td className={styles.data}>{instituionsSharePercent}</td>
							)}
							{loading ? (
								<td>
									<Skeleton className={styles.bdvalueskeleton} />
								</td>
							) : (
								<td className={styles.data}>{strategicCorporatesPercent}</td>
							)}
							{loading ? (
								<td>
									<Skeleton className={styles.bdvalueskeleton} />
								</td>
							) : (
								<td className={styles.data}>{managementOwnershipPercent}</td>
							)}
							{loading ? (
								<td>
									<Skeleton className={styles.bdvalueskeleton} />
								</td>
							) : (
								<td className={styles.data}>{impliedRetailPercent}</td>
							)}
						</tr>
					</table>
				)}
			</Row>
		</div>
	);
};

export default BreakDown;

import { useEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { deviceType } from "utils/utils";

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		if (ref.current) {
			const observeTarget = ref.current;
			const resizeObserver = new ResizeObserver((entries) => {
				entries.forEach((entry) => {
					setDimensions(entry.contentRect);
				});
			});
			resizeObserver.observe(observeTarget);
			return () => {
				resizeObserver.unobserve(observeTarget);
			};
		}
	}, [ref]);
	return dimensions;
};

export const getDeviceTypePeerComparison = () => {
	const screenWidth = document.documentElement.clientWidth;
	let device = deviceType.SmallDesktop;
	if (screenWidth >= 1440) {
		device = deviceType.LargeDesktop;
	} else if (screenWidth >= 1150 && screenWidth < 1440) {
		device = "DESKTOP";
	} else if (screenWidth > 1024 && screenWidth <= 1149) {
		device = deviceType.SmallDesktop;
	} else if (screenWidth >= 768 && screenWidth <= 1024) {
		device = deviceType.Tablet;
	} else if (screenWidth >= 375 && screenWidth <= 767) {
		device = deviceType.Mobile;
	}
	return device;
};

export const handleWindowResizePeerComparison = (callBackHandler) => {
	window.addEventListener("resize", () => {
		callBackHandler(getDeviceTypePeerComparison());
	});
};

export const getTotalCardsPeerComparison = () => {
	let device = getDeviceTypePeerComparison();
	switch (device) {
		case "DESKTOP":
			return 6;
		case "SMALLDESKTOP":
			return 7;
		case "TABLET":
			return 8;
		case "MOBILE":
			return 9;
		default:
			break;
	}
};

export default useResizeObserver;
